import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BaseInput, { IBaseInputProps } from '@/components/common/base-input';
import FormItem from '@/components/common/form-item';
import MaskedInput from '@/components/common/mask-input';

import { MAX_LENGTH_TEXT } from '../../constants';
import { ValidateRules } from '../../helpers';
import { AnswerType } from '../../modals';

export interface ITextProps extends IBaseInputProps {
  name: string;
  mask?: string;
  type?: AnswerType;
  maxLength?: number;
}

const Text: React.FC<ITextProps> = ({ value, type, name, mask, readOnly, placeholder, maxLength = MAX_LENGTH_TEXT, ...restProps }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const renderItem = () => {
    return !mask ? (
      <BaseInput
        className='w-[200px] truncate h-full'
        readOnly={readOnly}
        placeholder={readOnly ? '' : placeholder}
        hideBorder
        defaultValue={value}
        value={value}
        margin={{ left: 0 }}
        labelSeparator='：'
        {...restProps}
        id={name}
      />
    ) : (
      <MaskedInput id={name} readOnly={readOnly} placeholder={readOnly ? '' : placeholder} className='w-[200px] truncate h-full' mask={mask} />
    );
  };

  if (!name) {
    return renderItem();
  }

  if (readOnly) {
    return restProps.labelInput ? (
      <div className='flex w-full gap-2'>
        <span className={restProps.labelClassName}>{restProps.labelInput}:</span>
        <span>{getValues(name)}</span>
      </div>
    ) : (
      <p>{getValues(name)}</p>
    );
  }

  const validateObj =
    type && ValidateRules[type]
      ? {
          [type]: ValidateRules[type]
        }
      : undefined;

  return (
    <FormItem
      showErrorOption='icon'
      showLabel={false}
      label={t('project:question_detail:answer_title') ?? ''}
      name={name}
      rules={{ maxLength, validate: validateObj }}
      needTrimValue={true}
    >
      {renderItem()}
    </FormItem>
  );
};

export default Text;
