import { useAppDispatch } from '@/hooks';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Upload } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton, SizeType } from '@/components/base-button/BaseButton';

import { setAlertNotification } from '@/redux/globalReducer';

import { ERROR_DETAIL, MAX_CAPACITY_FILE, MODE_VIEW_ATTACHMENT, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { MAXIMUM_FILE_UPLOAD, MAXIMUM_SIZE_UPLOAD_TO_BYTE } from '@/utils/constants/announcement';
import { ALLOW_EXTENSION, handleDownloadSingleFile } from '@/utils/helpers/fileHelper';
import { decodeFileName } from '@/utils/helpers/globalHelper';
import { uploadMultipleFileWithType } from '@/utils/services/FileService';

import IconDownload from '@/assets/icons/IconDownload.svg';
import IconUploadSuccess from '@/assets/icons/IconUploadSuccess.svg';
import TrashBlack from '@/assets/icons/TrashBlack.svg?react';
import UploadImage from '@/assets/icons/UploadCompleted.svg?react';
import UploadSimpleActive from '@/assets/icons/UploadSimpleActive.svg';
import WarningCircle from '@/assets/icons/WarningCircle.svg';

import BaseModal from '../base-modal/BaseModal';
import FileAttachment from './FileAttachment';

import './FormAttachment.scss';

const { Dragger } = Upload;

export interface IFormAttachment {
  type: 'drag' | 'button';
  mode: 'edit' | 'view';
  onFilesUploaded?: (files: UploadFile[]) => void;
  onHandleRemoveFile?: (file: any, index: number) => void;
  onChange?: (file: any) => void;
  accept?: string;
  multiple?: boolean;
  maxFileSize?: number; // (byte)
  maxFiles?: number;
  viewMaxFiles?: number;
  defaultValue?: any[];
  documentType?: string;
  name: string;
  labelButton?: string;
  sizeButton?: SizeType;
  classNameButton?: string;
  className?: string;
}

interface IResponse {
  files: any[];
  filesError: any[];
}

export const FormAttachment = (props: IFormAttachment) => {
  const {
    onFilesUploaded,
    onHandleRemoveFile,
    onChange,
    name,
    type,
    viewMaxFiles,
    accept,
    multiple,
    maxFileSize,
    maxFiles,
    defaultValue,
    documentType,
    labelButton,
    sizeButton,
    classNameButton,
    mode,
    className
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { field } = useController({
    name,
    defaultValue
  });
  const [uploadedFiles, setUploadedFiles] = useState<any[]>(defaultValue || []);
  const [openModalViewMoreFiles, setOpenModalViewMoreFiles] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setUploadedFiles(defaultValue || []);
    // field.onChange(defaultValue); // TODO: do not update form in useEffect
  }, [defaultValue]);
  const validateFile = (file: any) => {
    const validFileTypes = accept?.split(', ') || [];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!!accept && (!fileExtension || !validFileTypes.includes(`.${fileExtension}`))) {
      return {
        id: file.uid,
        name: file.name,
        status: UPLOAD_STATUS.ERROR,
        msgErr: t('common:MSG_C_008', { files: accept })
      };
    }
    if (maxFileSize && file.size > maxFileSize) {
      return {
        id: file.uid,
        name: file.name,
        status: UPLOAD_STATUS.ERROR,
        msgErr: t('common:MSG_C_006')
      };
    }
    const isDuplicateName = !!uploadedFiles.some((item: any) => item.name === file.name);
    if (isDuplicateName) {
      return {
        id: file.uid,
        name: file.name,
        status: UPLOAD_STATUS.ERROR,
        msgErr: t('common:MSG_C_009', { fileName: file.name })
      };
    }
    return file;
  };
  const getErrorMessage = (error: string) => {
    const ErrorMapping = {
      [ERROR_DETAIL.FILE_NAME_OVER_SIZE]: t('common:MSG_C_030'),
      [ERROR_DETAIL.DOCUMENT_TYPE_NOT_SUPPORT]: t('common:MSG_C_008', { files: accept }),
      [ERROR_DETAIL.FILE_EXTENSION_IGNORE]: t('common:MSG_C_008', { files: accept })
    };
    return ErrorMapping[error] ? ErrorMapping[error] : t('common:MSG_FILE_INVALID');
  };
  const onUploadFile = async (files: any): Promise<IResponse> => {
    const formData = new FormData();
    files.forEach((item: any) => {
      const file = new File([item], item.name);
      formData.append(`files`, file);
    });
    return new Promise((resolve: any, reject: any) => {
      uploadMultipleFileWithType(documentType || '', formData)
        .then((res) => {
          const { data } = res;
          let filesNew = [];
          let filesError = [];
          if (data.files?.length) {
            filesNew = data.files.map((file: any) => ({
              ...file,
              status: UPLOAD_STATUS.DONE
            }));
          }
          if (data.errors?.length) {
            filesError = data.errors.map((file: any) => ({
              id: uuidv4(),
              name: file.name,
              msgErr: getErrorMessage(file.errorDetail),
              status: UPLOAD_STATUS.ERROR
            }));
          }
          resolve({
            files: filesNew,
            filesError
          });
        })
        .catch((error) => reject(new Error(error)));
    });
  };
  const handleRemoveFile = (file: any, index: number) => {
    const newFileList = uploadedFiles.filter((item) => item.id !== file.id);
    setUploadedFiles(newFileList);
    field.onChange(newFileList);
    onChange?.(newFileList);
    onHandleRemoveFile?.(file, index);
  };
  const beforeUpload = debounce(async (_, fileList: any) => {
    setLoading(true);
    if (maxFiles && fileList.length + uploadedFiles?.length > maxFiles) {
      dispatch(
        setAlertNotification({
          show: true,
          type: 'error',
          message: t('common:MSG_C_007')
        })
      );
      setLoading(false);
      return false;
    }
    const fileValidation = fileList.map((file: any) => validateFile(file));
    const fileValid = fileValidation.filter((file: any) => file?.status !== UPLOAD_STATUS.ERROR);
    const fileError = fileValidation.filter((file: any) => file?.status === UPLOAD_STATUS.ERROR);
    let updatedFiles = fileError;
    if (fileValid.length) {
      const res = await onUploadFile(fileValid);
      updatedFiles = [...updatedFiles, ...res.files, ...res.filesError];
    }
    setUploadedFiles((prevFiles) => {
      updatedFiles = [...prevFiles, ...updatedFiles];
      onFilesUploaded?.(updatedFiles);
      onChange?.(updatedFiles);
      field.onChange(updatedFiles);
      return updatedFiles;
    });
    setLoading(false);
    return true;
  }, 500);

  const showName = (name: string) => {
    if (!name) return '';
    if (name.length <= MAX_CAPACITY_FILE) return name;
    return name.substring(0, MAX_CAPACITY_FILE).concat('...');
  };

  return (
    <div className='form-upload-file'>
      {type === 'drag' && (
        <Dragger
          name='file'
          height={174}
          multiple={multiple}
          beforeUpload={beforeUpload}
          showUploadList={false}
          accept={accept}
          openFileDialogOnClick={false}
          disabled={loading}
        >
          <Space direction='horizontal'>
            <img src={UploadImage} alt='upload' className='h-[150px] pointer-events-none' />
            <div className='flex flex-col items-start'>
              <div className='text-[18px] font-semibold'>{t('basic_information:attachment_file_title')}</div>
              <div className='text-textGray mt-4'>{t('basic_information:attachment_file_description')}</div>
              <Upload name='file' multiple={multiple} beforeUpload={beforeUpload} showUploadList={false} accept={accept} disabled={loading}>
                <BaseButton type='secondary' disabled={loading} className='flex items-center upload-button'>
                  {loading ? (
                    <Spin
                      indicator={
                        <Loading3QuartersOutlined className='[&>svg]:w-[18px] [&>svg]:h-[18px] !leading-none' style={{ fontSize: 18 }} spin />
                      }
                    />
                  ) : (
                    <UploadSimpleActive className='w-[18px] h-[18px] [&>path]:duration-200 [&>path]:ease-[cubic-bezier(0.6450.0450.3551)]' />
                  )}
                  {t(labelButton || '')}
                </BaseButton>
              </Upload>
            </div>
          </Space>
        </Dragger>
      )}
      {type === 'drag' && uploadedFiles?.length > 0 && (
        <div className='files-upload mt-[16px]'>
          {uploadedFiles.map((file: any, index: number) => (
            <div key={String(index)} className={`box-file-item status-${file.status}`}>
              <div className='w-[526px] flex justify-between items-center border pb-1 mb-4 file-item'>
                <div className='flex items-center box-title'>
                  <span className='mr-8'>{index + 1}</span>
                  <IconUploadSuccess className='icon' />
                  <AppTooltip title={decodeFileName(file.name)}>
                    <span
                      className={`file-name max-w-[330px] truncate w-fit ml-4 font-normal cursor-pointer`}
                      onClick={() => {
                        return handleDownloadSingleFile(file.blobPath as string, file.name);
                      }}
                    >
                      {showName(decodeFileName(file.name))}
                    </span>
                  </AppTooltip>
                </div>
                <div className='flex items-center'>
                  {file.status === UPLOAD_STATUS.DONE ? (
                    <Button
                      type='text'
                      icon={<IconDownload className='h-[20px] w-[20px] download hover:cursor-pointer' />}
                      onClick={() => {
                        return handleDownloadSingleFile(file.blobPath as string, file.name);
                      }}
                    />
                  ) : (
                    <AppTooltip title={file.msgErr}>
                      <WarningCircle className='icon-circle mr-[8px]' />
                    </AppTooltip>
                  )}
                  <Button
                    type='text'
                    icon={<img src={TrashBlack} className='h-[20px] w-[20px] hover:cursor-pointer' alt='upload-simple' />}
                    onClick={() => {
                      handleRemoveFile(file, index);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          <Upload
            name='file'
            multiple={multiple}
            beforeUpload={beforeUpload}
            showUploadList={false}
            accept={accept}
            disabled={loading || (maxFiles !== undefined && uploadedFiles.length >= maxFiles)}
          >
            <BaseButton
              type='secondary'
              className='flex items-center upload-button'
              disabled={loading || (maxFiles !== undefined && uploadedFiles.length >= maxFiles)}
            >
              {loading ? (
                <Spin
                  indicator={<Loading3QuartersOutlined className='[&>svg]:w-[18px] [&>svg]:h-[18px] !leading-none' style={{ fontSize: 18 }} spin />}
                />
              ) : (
                <UploadSimpleActive className='w-[18px] h-[18px] [&>path]:duration-200 [&>path]:ease-[cubic-bezier(0.6450.0450.3551)]' />
              )}
              {t(labelButton || '')}
            </BaseButton>
          </Upload>
        </div>
      )}
      {type === 'button' && (
        <div className={classNames('files-upload flex flex-col gap-2', className ?? '')}>
          {uploadedFiles.slice(0, viewMaxFiles).map((file: any, index: number) => (
            <FileAttachment
              key={`file-type-button-${file.id}`}
              label={decodeFileName(file.name)}
              uploader={file.createdName}
              uploadDate={file.createdDate}
              showInfoUploader={true}
              status={file.status}
              msgErr={file.msgErr}
              onDelete={() => {
                handleRemoveFile(file, index);
              }}
              onDownload={() => {
                return handleDownloadSingleFile(file.blobPath as string, file.name);
              }}
            />
          ))}
          {viewMaxFiles && uploadedFiles.length > viewMaxFiles && (
            <span
              className='text-green cursor-pointer font-semibold underline'
              onClick={() => {
                setOpenModalViewMoreFiles(true);
              }}
            >
              {t('common:view_more_files')}
            </span>
          )}
          {mode === MODE_VIEW_ATTACHMENT.EDIT && (
            <Upload
              name='file'
              multiple={multiple}
              beforeUpload={beforeUpload}
              showUploadList={false}
              accept={accept}
              disabled={loading || (maxFiles !== undefined && uploadedFiles.length >= maxFiles)}
            >
              <BaseButton
                type='secondary'
                className={classNames('flex gap-2 items-center upload-button', classNameButton)}
                size={sizeButton}
                disabled={loading || (maxFiles !== undefined && uploadedFiles.length >= maxFiles)}
              >
                {loading ? (
                  <Spin
                    indicator={<Loading3QuartersOutlined className='[&>svg]:w-[18px] [&>svg]:h-[18px] !leading-none' style={{ fontSize: 18 }} spin />}
                  />
                ) : (
                  <UploadSimpleActive className='w-[18px] h-[18px] [&>path]:duration-200 [&>path]:ease-[cubic-bezier(0.6450.0450.3551)]' />
                )}
                {t(labelButton || '')}
              </BaseButton>
            </Upload>
          )}
          <BaseModal
            openModal={openModalViewMoreFiles}
            title={t('comment:attachment_files')}
            onCancel={() => {
              setOpenModalViewMoreFiles(false);
            }}
            contentElement={
              <div className='w-full pr-8 flex flex-col gap-2'>
                {uploadedFiles.map((file: any, index: number) => (
                  <FileAttachment
                    key={`view-more-${file.id}`}
                    className='w-[503px] !h-[12px]'
                    label={decodeFileName(file.name)}
                    uploader={file.createdName}
                    uploadDate={file.createdDate}
                    showInfoUploader={true}
                    status={file.status}
                    msgErr={file.msgErr}
                    onDelete={() => {
                      handleRemoveFile(file, index);
                    }}
                    onDownload={() => {
                      return handleDownloadSingleFile(file.blobPath as string, file.name);
                    }}
                  />
                ))}
              </div>
            }
          ></BaseModal>
        </div>
      )}
    </div>
  );
};
FormAttachment.defaultProps = {
  type: 'drag',

  viewMaxFiles: 5,
  accept: ALLOW_EXTENSION,
  multiple: true,
  maxFileSize: MAXIMUM_SIZE_UPLOAD_TO_BYTE,
  maxFiles: MAXIMUM_FILE_UPLOAD,
  defaultValue: [],
  documentType: 'attachments',
  labelButton: 'basic_information:browser_file',
  mode: MODE_VIEW_ATTACHMENT.EDIT
};
