import React from 'react';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';

import { FORMAT_DATE_EN_HH_MM_SS } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';

interface IEditHistoryData {
  createdDate?: string;
  createdName?: string;
  updatedDate?: string;
  updatedName?: string;
  publishedDate?: string;
  publishedName?: string;
}

interface IEditHistoryProps {
  data: IEditHistoryData;
  className?: string;
  showCreateTime?: boolean;
  showPublicTime?: boolean;
  labelCreateName?: string;
  labelCreateDate?: string;
}

const EditHistory = (props: IEditHistoryProps) => {
  const { t } = useTranslation();
  const { showCreateTime = true, showPublicTime = false, labelCreateName, labelCreateDate } = props;
  const { createdName, createdDate = '', updatedName, updatedDate = '', publishedName, publishedDate = '' } = props.data;

  return (
    <div className={`flex justify-end gap-4 mb-4 ${props.className}`}>
      <div className='flex flex-col gap-2 items-start font-normal text-[#525A68] leading-[19px]'>
        {showCreateTime && (
          <AppTooltip
            className='truncate inline-block w-[170px] text-[12px] body-400'
            title={DataViewer.localTimeBy(createdDate, FORMAT_DATE_EN_HH_MM_SS)}
          >
            {`${labelCreateDate ?? t('master_data:created_date')}: ${DataViewer.localTimeBy(createdDate, FORMAT_DATE_EN_HH_MM_SS)}`}
          </AppTooltip>
        )}
        {showPublicTime ? (
          <AppTooltip
            className='truncate inline-block w-[170px] text-[12px] body-400'
            title={DataViewer.localTimeBy(publishedDate, FORMAT_DATE_EN_HH_MM_SS)}
          >
            {`${t('master_data:public_date')}: ${DataViewer.localTimeBy(publishedDate, FORMAT_DATE_EN_HH_MM_SS)}`}
          </AppTooltip>
        ) : (
          <AppTooltip
            className='truncate inline-block w-[170px] text-[12px] body-400'
            title={DataViewer.localTimeBy(updatedDate, FORMAT_DATE_EN_HH_MM_SS)}
          >
            {`${t('master_data:date_updated')}: ${DataViewer.localTimeBy(updatedDate, FORMAT_DATE_EN_HH_MM_SS)}`}
          </AppTooltip>
        )}
      </div>
      <div className='flex flex-col gap-2 items-start font-normal text-[#525A68] leading-[19px]'>
        {showCreateTime && (
          <AppTooltip className='truncate inline-block w-[131px] text-[12px] body-400' title={DataViewer.display(createdName)}>
            {`${labelCreateName ?? t('master_data:author')}: ${DataViewer.display(createdName)}`}
          </AppTooltip>
        )}
        {showPublicTime ? (
          <AppTooltip className='truncate inline-block w-[131px] text-[12px] body-400' title={DataViewer.display(publishedName)}>
            {`${t('master_data:publisher')}: ${DataViewer.display(publishedName)}`}
          </AppTooltip>
        ) : (
          <AppTooltip className='truncate inline-block w-[131px] text-[12px] body-400' title={DataViewer.display(updatedName)}>
            {`${t('master_data:updater')}: ${DataViewer.display(updatedName)}`}
          </AppTooltip>
        )}
      </div>
    </div>
  );
};

export default EditHistory;
