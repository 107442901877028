import { IRelativeField } from '@/components/common/postal-code-input';

export const MAX_FIELD_PERSON_NUMBER = 5;

export const DEFAULT_SCENARIO_C_STEP_2 = {
  id: 'step2',
  title: 'project:scenario_b:applicant_title',
  keyErrors: ['projectApplicant', 'visaCategoryName'],
  projectApplicant: {
    lastName: '',
    firstName: '',
    lastNameRomaji: '',
    firstNameRomaji: '',
    currentCompany: '',
    postalCode: '',
    prefecture: '',
    prefectureRoman: '',
    city: '',
    cityRoman: '',
    suburb: '',
    suburbRoman: '',
    streetAddress: '',
    apartmentName: '',
    placeOfResidence: '',
    departureDate: ''
  },
  visaCategoryName: ''
};

export const DEFAULT_SCENARIO_C_STEP_3 = {
  id: 'step3',
  title: 'project:add:family_information:title',
  keyErrors: ['projectFamilies'],
  goWithFamily: '',
  projectFamilies: []
};

export const DEFAULT_SCENARIO_C_STEP_4 = {
  id: 'step4',
  title: 'project:add:assignment_info:title',
  keyErrors: ['projectAssignmentInfo'],
  projectAssignmentInfo: {
    name: '',
    companyName: '',
    countryId: null,
    address: ''
  }
};

export const DEFAULT_SCENARIO_C_STEP_5 = {
  id: 'step5',
  title: 'project:scenario_b:local_supporter',
  keyErrors: ['projectLocalSupporters'],
  localSupportOption: null,
  projectLocalSupporters: []
};

export const DEFAULT_SCENARIO_C_STEP_6 = {
  id: 'step6',
  title: 'stakeholder:title',
  keyErrors: ['projectStakeholders'],
  hasOtherStakeHolder: null,
  projectStakeholders: []
};

export const DEFAULT_SCENARIO_C_STEP_7 = {
  id: 'step7',
  title: 'basic_information:additional_information',
  keyErrors: ['note', 'projectAttachments'],
  note: '',
  projectAttachments: []
};
export const DEFAULT_SCENARIO_C_STEP_8 = {
  id: 'step8',
  title: 'project:scenario_b:answer_detail'
};
export const PROJECT_FAMILY_PATH = 'projectFamilies';

export const FAMILY_MEMBER_FIELDS = [
  'lastName',
  'firstName',
  'lastNameRomaji',
  'firstNameRomaji',
  'primaryNational',
  'secondaryNational',
  'dateOfBirth',
  'gender'
];

export const ASSIGNMENT_INFO_FIELDS = ['name', 'companyName', 'countryId', 'address'];
export const PROJECT_LOCAL_SUPPORTER_FIELDS = ['name', 'address', 'email', 'language'];

export const GO_WITH_LOCAL_SUPPORTER = {
  YES: 1,
  NO: 2,
  NOT_SURE: 3
};

export const HAS_OTHER_STAKE_HOLDER = {
  YES: 'YES',
  NO: 'NO'
};

export const POSTAL_CODE_RELATIVE_FIELDS_SCENARIO_C: IRelativeField[] = [
  {
    name: 'steps[0].projectApplicant.prefecture',
    key: 'prefecture'
  },
  {
    name: 'steps[0].projectApplicant.prefectureRoman',
    key: 'prefecture_roman'
  },
  {
    name: 'steps[0].projectApplicant.city',
    key: 'city'
  },
  {
    name: 'steps[0].projectApplicant.cityRoman',
    key: 'city_roman'
  },
  {
    name: 'steps[0].projectApplicant.suburb',
    key: 'suburb'
  },
  {
    name: 'steps[0].projectApplicant.suburbRoman',
    key: 'suburb_roman'
  },
  {
    name: 'steps[0].projectApplicant.streetAddress',
    key: 'street_address'
  },
  {
    name: 'steps[0].projectApplicant.apartmentName',
    key: 'office'
  }
];
export const APPLICANT_FIELDS_SCENARIO_C = [
  'lastName',
  'firstName',
  'lastNameRomaji',
  'firstNameRomaji',
  'primaryNational',
  'secondaryNational',
  'dateOfBirth',
  'gender',
  'postalCode',
  'prefecture',
  'prefectureRoman',
  'city',
  'cityRoman',
  'suburb',
  'suburbRoman',
  'streetAddress',
  'apartmentName',
  'placeOfResidence',
  'currentCompany',
  'departureDate'
];
