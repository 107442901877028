import { Tooltip } from 'antd';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { Control, useController } from 'react-hook-form';

import { ISelectCustomProps, SelectCustom } from '@/components/form-select/SelectCustom';

import { useMessageYupTranslation } from '../../hooks/useI18n';
import { CloseIcon } from '../icon-svg/IconSvg';

export type IFormSelect = ISelectCustomProps & {
  name: string;
  control?: Control;
  handleChange?: (val: any, op: any) => void;
  showCloseItem?: boolean;
  tagShowTooltip?: boolean;
  preTagClose?: (props: { label: React.ReactNode; value: React.ReactNode; closable: boolean; onClose: () => void }) => any;
  tagClassName?: string;
};
export type TagRenderFunctionType = IFormSelect['tagRender'] extends (...args: any[]) => any ? IFormSelect['tagRender'] : any;

export const TagRenderComponent = ({
  label,
  closable,
  showCloseItem,
  tagShowTooltip,
  rest,
  tagMouseDown,
  tagClosing,
  props
}: {
  label: React.ReactNode;
  closable: boolean;
  showCloseItem: boolean;
  tagShowTooltip: boolean;
  rest: any;
  tagMouseDown: (event: React.MouseEvent<HTMLSpanElement>) => void;
  tagClosing: (e: React.MouseEvent<SVGElement, MouseEvent>, props: TagRenderFunctionType['arguments']) => void;
  props: TagRenderFunctionType['arguments'];
}) => {
  const showCloseIcon = (closable || showCloseItem) && !rest?.disabled;
  return (
    <div className='multiple-item-selection flex items-center justify-between gap-[5px] mr-[8px]' onMouseDown={tagMouseDown}>
      {tagShowTooltip ? (
        <Tooltip title={label}>
          <div className={classNames('max-w-[200px] truncate w-fit custom-tag', rest?.disabled ? 'text-textGray' : '')}>{label}</div>
        </Tooltip>
      ) : (
        <div className={classNames('max-w-[200px] truncate w-fit custom-tag', rest?.disabled ? 'text-textGray' : '')}>{label}</div>
      )}
      {showCloseIcon && <CloseIcon width={18} height={18} onClick={(e) => tagClosing(e, props)} />}
    </div>
  );
};

export const FormSelect = forwardRef((props: IFormSelect, ref: any) => {
  const {
    name,
    defaultValue,
    options,
    labelInValue = false,
    handleChange,
    control,
    onKeyDown,
    onClick,
    showCloseItem = false,
    preTagClose,
    tagShowTooltip = false,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error }
  } = useController({
    name: name as string,
    control
  });

  const msgErr = useMessageYupTranslation(error?.message);

  // handle control tags render
  const tagMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const tagClosing = (e: React.MouseEvent<SVGElement, MouseEvent>, props: TagRenderFunctionType['arguments']) => {
    const { onClose } = props;
    if (preTagClose) {
      e.preventDefault();
      e.stopPropagation();
      preTagClose(props);
    } else onClose();
  };

  const tagRender = (props: TagRenderFunctionType['arguments']) => {
    const { label, closable } = props;
    return (
      <TagRenderComponent
        label={label}
        closable={closable}
        showCloseItem={showCloseItem}
        tagShowTooltip={tagShowTooltip}
        rest={rest}
        tagMouseDown={tagMouseDown}
        tagClosing={tagClosing}
        props={props}
      />
    );
  };
  // end handle control tags render

  // render
  return (
    <SelectCustom
      ref={ref}
      labelInValue={labelInValue}
      value={field.value ?? undefined}
      options={options}
      error={!!error}
      msgErr={msgErr}
      tagRender={tagRender}
      onChange={async (val, op) => {
        let newValue: any = val;
        if (labelInValue) {
          newValue = options?.find((opt) => opt.value === val?.value);
        }
        field.onChange(newValue ?? null);
        handleChange && handleChange(newValue, op);
      }}
      {...rest}
    />
  );
});
