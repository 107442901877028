import { IProjectDetail } from '@/widgets/Comment/models';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useFetch from '@hooks/useFetch';

import { API } from '@utils/constants/Apis';
import { DataViewer } from '@utils/helpers/common';

type Props = {
  project?: Pick<IProjectDetail, 'applicantName' | 'projectApplicant' | 'applicantCountryName' | 'visaCategoryName'>;
  className?: string;
};
export const ProjectDetailPreviewInfo = (props: Props) => {
  const { project: projectPropData, className } = props;
  const { t } = useTranslation();
  const { id, idProject } = useParams();
  const projectId = id || idProject || '';
  const { data: projectApiData } = useFetch<IProjectDetail>(API.GET_PROJECT_DETAIL(projectId), 'GET', undefined, undefined, {
    autoFetch: !projectPropData && !!projectId
  });

  const project = projectPropData ?? projectApiData;

  if (!project) {
    return null;
  }

  const listInfo = [
    {
      label: t('project_detail:application'),
      value: project?.applicantName,
      valueClassName: 'max-w-[300px]'
    },
    {
      label: t('project:fields:company_name'),
      value: project?.projectApplicant?.currentCompany,
      valueClassName: 'max-w-[300px]'
    },
    {
      label: t('project_detail:country_of_application'),
      value: project?.applicantCountryName,
      valueClassName: 'max-w-[200px]'
    },
    {
      label: t('project_detail:category'),
      value: project?.visaCategoryName,
      valueClassName: 'max-w-[200px]'
    }
  ];

  return (
    <div className={classNames('flex gap-[16px] min-w-0', className)}>
      {listInfo.map((item) => (
        <div key={item.label} className='flex whitespace-nowrap truncate'>
          <span>{item.label}：</span>
          <Tooltip title={item.value}>
            <span className={classNames('truncate font-bold', item.valueClassName)}>{DataViewer.display(item.value)}</span>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};
