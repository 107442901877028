import { IProjectMemoRes } from '../model';

export const DEFAULT_DETAIL_PROJECT_MEMO: IProjectMemoRes = {
  id: '',
  projectId: '',
  emoNumber: '',
  supportTypeId: '',
  folder: '',
  memo: '',
  version: '',
  location: '',
  visaCategoryId: [],
  visaCategoryName: ''
};

export const DEFAULT_KEY_JOIN_VISA_CATEGORY = ', ';
