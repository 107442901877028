import { Checkbox, Tooltip } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import EditHistory from '@/pages/master-data/components/edit-history/EditHistory';
import { ProjectDetailPreviewInfo } from '@pages/project-management/task/view/components/ProjectDetailPreviewInfo';

import AppLabelSpace from '@/components/app-label/AppLabelSpace';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import CommonTag from '@/components/common/tag/CommonTag';
import ConfirmCancelEditing from '@/components/confirm-popup/ConfirmCancelEditing';

import useAuthorization from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { TYPE } from '@/utils/constants/AppConstants';
import { ANNOUNCEMENT_LIST_URL, EDIT_ANNOUNCEMENT_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';

import PencilSimpleLine from '@/assets/icons/PencilSimpleLine.svg';
import TrashIcon from '@/assets/icons/TrashIcon.svg';

import { generateActionButton, getSubTitle } from '../components/RenderElement';
import {
  TagLabel,
  WrapHeaderAction,
  WrapHeaderTitle,
  WrapLabelWithContent,
  WrapLayout,
  WrapLayoutContent,
  WrapLayoutHeader
} from '../components/WrapLayout';
import AttachmentFiles from './componnents/AttachmentFiles';
import { ANNOUNCEMENT_STATUS, AnnouncementItem } from './models';

import './Announcement.scss';

const ViewAnnouncement = () => {
  const { t }: Ii18n = useTranslation();
  const navigate = useNavigate();
  const { id: projectId, announcementId } = useParams();
  const { user, isExternalRole, isInternalRole } = useAuthorization();

  const { data, fetchData } = useFetch<AnnouncementItem>(`/prj/projects/announcements/${announcementId}`, 'GET');

  const { loading, mutate: deleteAnnouncement } = useMutation(`prj/projects/${projectId}/announcements`, {
    method: 'DELETE',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('announcement:delete_button') || '' })
  });

  const { loading: loadingMarkRead, mutate: upsertMarkRead } = useMutation(`/prj/projects/${projectId}/announcements/${announcementId}/mark-read`, {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const { mutate: readAnnouncement } = useMutation(`/prj/projects/${projectId}/announcements/${announcementId}/readed`, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const isPublish = data?.status === ANNOUNCEMENT_STATUS.PUBLISH;
  const isShowEdit = isInternalRole && !isPublish;
  const userRead = data?.users.find((item) => item.userEmail === user?.email) || null;
  const showMarkRead = isPublish && userRead && !userRead.confirmed;
  const userConfirm = data?.users?.filter((el) => el.confirmed) || [];
  const userNotConfirm = data?.users?.filter((el) => !el.confirmed) || [];

  const handleDelete = async () => {
    const show = await showBasePopup({
      type: TYPE.DISCARD,
      title: `${t('announcement:dialog_delete:header')}`,
      msg: `${t('announcement:dialog_delete:content')}`
    });
    if (show === TYPE.CONFIRM) {
      const result = await deleteAnnouncement({ ids: [{ id: announcementId, version: data?.version }] });
      result && navigate(ANNOUNCEMENT_LIST_URL(projectId ?? ''));
    }
  };

  const handleEdit = () => {
    navigate(EDIT_ANNOUNCEMENT_URL(projectId ?? '', announcementId ?? ''));
  };

  const handleMarkRead = async () => {
    const result = await upsertMarkRead({});
    result && (await fetchData());
  };

  const havePermissionReadAnnouncement = () => {
    return data?.users.some((item) => item.userId === user?.id) ?? false;
  };

  useEffect(() => {
    if (data && !data?.userReaded) {
      (async () => {
        await readAnnouncement({ version: data?.version });
      })();
    }
  }, [data]);

  const allExternalConfirmed = userConfirm?.length === data?.users?.length && userConfirm?.length > 0;
  return (
    <WrapLayout className='announcement-view'>
      <WrapLayoutHeader className='gap-[16px]'>
        <WrapHeaderTitle className='min-w-0'>
          <div className='flex gap-[8px] items-center'>
            <div className='text-24 truncate flex'>
              {getSubTitle(t, data?.status)}
              <Tooltip title={data?.title}>
                <span className='title-24 truncate'>{data?.title}</span>
              </Tooltip>
            </div>
            {isExternalRole && isPublish && userRead?.confirmed && (
              <TagLabel value={t('announcement:confirmed')} className='tag-label-success shrink-0' />
            )}
          </div>
        </WrapHeaderTitle>
        <div className='flex items-center gap-[16px] min-w-0'>
          <ProjectDetailPreviewInfo />
          <WrapHeaderAction>
            {generateActionButton({
              showCondition: isInternalRole,
              onClickHandler: handleDelete,
              loading,
              icon: <TrashIcon width={16} height={16} />,
              buttonText: t('announcement:delete_button'),
              type: 'outline-red',
              className: 'delete-btn'
            })}
            {generateActionButton({
              showCondition: isShowEdit,
              onClickHandler: handleEdit,
              icon: <PencilSimpleLine width={16} />,
              buttonText: t('announcement:edit_button')
            })}
            {generateActionButton({
              showCondition: isExternalRole && havePermissionReadAnnouncement(),
              onClickHandler: handleMarkRead,
              loading: loadingMarkRead,
              icon: <Checkbox checked={!showMarkRead} disabled={!showMarkRead} />,
              buttonText: t('announcement:mark_read'),
              className: 'bnt-confirm-read',
              disabled: !showMarkRead
            })}
          </WrapHeaderAction>
        </div>
      </WrapLayoutHeader>
      <WrapLayoutContent className='flex flex-col'>
        <AppLabelSpace
          label={t('announcement:content')}
          titleClass='font-normal'
          labelClass='font-normal'
          title={<p dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(data?.content) }} />}
        />
        <AppLabelSpace
          label={t('announcement:target_audience')}
          labelClass='font-normal'
          title={
            <div className='flex gap-[8px] ml-0 flex-wrap'>
              {data?.users?.map((item, index) => (
                <TagLabel
                  key={index}
                  value={item.userStatus ? DataViewer.display(item.userName) : `(${t('announcement:invalid')})${DataViewer.display(item.userName)}`}
                />
              ))}
            </div>
          }
        />
        <div className='flex flex-col gap-2'>
          <AttachmentFiles
            attachments={
              data?.attachments.map((item) => ({
                ...item,
                updatedStatus: item.statusAccountUpload
              })) || []
            }
            showInfo={false}
            isExternalRole={isExternalRole}
          />
        </div>
        {isInternalRole && isPublish && (
          <WrapLabelWithContent
            className='pt-5'
            title={<div className={allExternalConfirmed ? 'min-w-[180px] max-w-[180px] font-normal' : ''}>{t('announcement:confirm_check')}</div>}
            total={data?.users.length ?? 0}
            questions={userConfirm.length}
            extra={allExternalConfirmed ? <CommonTag type={'success'} text={t('announcement:confirmed')} /> : null}
          >
            {userConfirm.length > 0 && (
              <AppLabelSpace
                label={t('announcement:confirmed')}
                labelClass='font-normal'
                title={
                  <div className='flex gap-[8px] ml-0 flex-wrap'>
                    {userConfirm.map((item, index) => (
                      <TagLabel
                        key={index}
                        value={
                          item.userStatus ? DataViewer.display(item.userName) : `(${t('announcement:invalid')})${DataViewer.display(item.userName)}`
                        }
                      />
                    ))}
                  </div>
                }
              />
            )}
            {userNotConfirm.length > 0 && (
              <AppLabelSpace
                label={t('announcement:unconfirmed')}
                labelClass='font-normal'
                title={
                  <div className='flex gap-[8px] ml-0 flex-wrap'>
                    {userNotConfirm.map((item, index) => (
                      <TagLabel
                        key={index}
                        value={
                          item.userStatus ? DataViewer.display(item.userName) : `(${t('announcement:invalid')})${DataViewer.display(item.userName)}`
                        }
                      />
                    ))}
                  </div>
                }
              />
            )}
          </WrapLabelWithContent>
        )}
      </WrapLayoutContent>
      {isInternalRole && <EditHistory className='mt-2' showPublicTime={true} data={data ?? {}} />}
      {isExternalRole && (
        <ConfirmCancelEditing
          condition={!!showMarkRead}
          labels={{
            header: `${t('announcement:dialog_back:header')}`,
            content: `${t('announcement:dialog_back:content')}`
          }}
          acceptBtn={{ type: 'primary' }}
        />
      )}
    </WrapLayout>
  );
};

export default ViewAnnouncement;
