import { useAppDispatch } from '@/hooks';
import { MAXIMUM_FILE_UPLOAD_TO_BYTE } from '@/widgets/Comment/constants';
import classNames from 'classnames';
import Quill, { QuillOptions } from 'quill';
import { Delta, Op, Range } from 'quill/core';
import Keyboard from 'quill/modules/keyboard';
import { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setAlertNotification } from '@/redux/globalReducer';

import { ALLOW_ALL_FILE, API_URL, MAPPING_ERROR_CODE_KEY, MAX_NUMBER_FILE, TYPE } from '@/utils/constants/AppConstants';
import { uploadSingleFileWithType } from '@/utils/services/FileService';

import { IBasicFormEditorProps } from '.';
import { CUSTOM_EVENTS } from './constants';
import { CUSTOM_MODULES, CustomModules, FileAttachment, MentionBlot } from './modules';
import Counter from './modules/Counter';
import EmbedImageBlot, { EmbedImageBlotStatus, STATUS_EMBED_IMAGE_BLOT } from './modules/EmbedImageBlot';
import LinkBlot from './modules/LinkBlot';
import MagicUrl from './modules/MagicUrl';
import Mention from './modules/Mention';
import { convertHtmlToDelta } from './utils';

const Parchment = Quill.import('parchment');

Quill.register(`modules/${CUSTOM_MODULES.MAGIC_URL}`, MagicUrl as any);
Quill.register(`modules/${CUSTOM_MODULES.COUNTER}`, Counter as any);
Quill.register(`modules/${CUSTOM_MODULES.MENTION}`, Mention as any);
Quill.register(`modules/${CUSTOM_MODULES.FILE_ATTACHMENT}`, FileAttachment as any);

Quill.register(LinkBlot as any, true);
Quill.register(MentionBlot);
Quill.register(EmbedImageBlot);

export interface IEditorProps {
  readOnly: boolean;
  defaultValue: any;
  onChange: Function;
  onChangeSelection: Function;
  init?: (ref: Quill) => void;
  beforeDestroy?: (ref: Quill) => void;
  destroy?: () => void;
  onKeyUp?: (e: any) => void;
  onFocus?: () => void;
  modules: Record<CustomModules, any> & { toolbar?: any[] };
  theme: string;
  placeholder: string;
  editorWrapperProps?: IBasicFormEditorProps['editorWrapperProps'];
}
const Editor = (props: IEditorProps, ref: any) => {
  const { readOnly, defaultValue, onChange, onChangeSelection, beforeDestroy, destroy, init, modules, theme, placeholder, onKeyUp, onFocus } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const defaultValueRef = useRef(defaultValue);
  const onTextChangeRef = useRef(onChange);
  const onSelectionChangeRef = useRef(onChangeSelection);
  const onKeyUpRef = useRef(onKeyUp);
  const { magicUrl, toolbar, counter, mention, fileAttachment, history, allowEmbedImage } = modules;
  const [isActive, setIsActive] = useState<boolean>(false);

  useLayoutEffect(() => {
    onTextChangeRef.current = onChange;
    onSelectionChangeRef.current = onChangeSelection;
    onKeyUpRef.current = onKeyUp;
  });

  useEffect(() => {
    ref.current?.enable(!readOnly);
  }, [ref, readOnly]);

  useEffect(() => {
    if (!ref.current) return;
    const currentValue = ref.current.getSemanticHTML();
    if (currentValue === defaultValue) return;
    const defaultDelta = convertHtmlToDelta(ref.current, defaultValue);
    if (!defaultDelta) return;
    ref.current.setContents(defaultDelta, 'silent');
    ref.current.root?.dispatchEvent(new Event(CUSTOM_EVENTS.DEFAULT_TEXT_CHANGE));
  }, [ref, defaultValue]);

  const onComposition = (e: any, type: 'start' | 'end') => {
    const editor = e?.target;
    if (!editor) return;
    if (type === 'start') {
      editor.classList.add('ql-composition');
    } else {
      editor.classList.remove('ql-composition');
    }
  };

  const onDoubleClick = (e: any) => {
    if (!e?.target) return;
    const linkElm = Parchment.Registry?.find(e.target) as LinkBlot;

    if (linkElm && linkElm instanceof LinkBlot) {
      const { link } = linkElm.formats(e.target) ?? {};
      if (link?.href) {
        window.open(link.href, link?.target ?? '_blank');
      }
    }
  };

  const onBlur = () => {
    setIsActive(false);
  };

  const insertFile = ({
    range,
    url,
    name,
    fileStatus,
    error
  }: {
    range: Range;
    url: string;
    name: string;
    fileStatus?: EmbedImageBlotStatus;
    error?: string;
  }) => {
    const status = fileStatus ?? STATUS_EMBED_IMAGE_BLOT.UPLOADING;
    const delta = ref.current.insertEmbed(range.index, CUSTOM_MODULES.EMBED_IMAGE_BLOT, { url, status, name, error: error ?? '' }, 'user');
    return delta;
  };

  const fileUploadRes = (url: string, status: EmbedImageBlotStatus, fileName: string, msgError?: string) => {
    return {
      url,
      status,
      name: fileName,
      error: msgError ?? ''
    };
  };

  const handleEmbedImageUpload = async (file: File, formData: FormData) => {
    try {
      if (file.size > MAXIMUM_FILE_UPLOAD_TO_BYTE) {
        return fileUploadRes('', STATUS_EMBED_IMAGE_BLOT.ERROR, file.name, t('common:MSG_C_033'));
      }
      const { data } = await uploadSingleFileWithType('attachments', formData, {});
      if (data?.id) {
        return fileUploadRes(`${API_URL}/sta/images/${data.publicPath}`, STATUS_EMBED_IMAGE_BLOT.UPLOADED, data.name);
      }
    } catch (e: any) {
      const messageError = MAPPING_ERROR_CODE_KEY[e?.response?.data?.fields?.[0].errorCode];
      return fileUploadRes('', STATUS_EMBED_IMAGE_BLOT.ERROR, file.name, t(messageError, { files: ALLOW_ALL_FILE }));
    }
  };

  const handleEmbedImage = (range: Range, files: File[]) => {
    if (!allowEmbedImage) return;

    if (files.length > 10) {
      return dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.ERROR,
          message: t('common:MSG_C_007')
        })
      );
    }
    const contents = ref.current.getContents();

    const embedImageUploaded = contents.ops.filter(
      (op: any) => op?.insert?.[CUSTOM_MODULES.EMBED_IMAGE_BLOT]?.status === STATUS_EMBED_IMAGE_BLOT.UPLOADED
    );
    const embedImageUploading = contents.ops.some(
      (op: any) => op?.insert?.[CUSTOM_MODULES.EMBED_IMAGE_BLOT]?.status === STATUS_EMBED_IMAGE_BLOT.UPLOADING
    );
    if (embedImageUploading) return;
    if (embedImageUploaded.length >= MAX_NUMBER_FILE || files.length + embedImageUploaded.length > MAX_NUMBER_FILE) {
      return dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.ERROR,
          message: t('common:MSG_C_007')
        })
      );
    }

    files.forEach((file: File) => {
      insertFile({ range, url: '', name: file.name });
    });
    const content = ref.current.getContents();

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      handleEmbedImageUpload(file, formData).then((data: any) => {
        const ops: Op[] = content.ops;
        const targetIndex = ops.findIndex((op) => op?.attributes?.name === file.name && op?.attributes?.status === STATUS_EMBED_IMAGE_BLOT.UPLOADING);

        const delta = insertFile({ range, url: data.url, name: data.name, fileStatus: data.status, error: data.error ?? '' });
        if (targetIndex !== -1) {
          const updatedOp = delta.ops.find((el: Op) => el.attributes?.name === data.name);
          if (updatedOp) {
            ops[targetIndex] = updatedOp;
          }
        }
        ref.current.setContents(ops);
      });
    }
    ref.current.setSelection(range.index + 2);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const editorBox = container.ownerDocument.createElement('div');

      // handle japanese keyboard for quilljs
      // https://github.com/quilljs/quill/issues/2237
      editorBox.addEventListener('compositionstart', (e) => onComposition(e, 'start'));
      editorBox.addEventListener('compositionend', (e) => onComposition(e, 'end'));
      editorBox.onkeyup = (e: any) => {
        onKeyUpRef.current?.(e);
      };

      const editorContainer = container.appendChild(editorBox);
      const quillOptions: QuillOptions = { theme, placeholder };

      quillOptions.modules = {
        uploader: {
          handler: (range: Range, files: File[]) => {
            handleEmbedImage(range, files);
          }
        }
      };
      if (toolbar) quillOptions.modules.toolbar = toolbar;
      if (magicUrl) quillOptions.modules.magicUrl = magicUrl;
      if (counter) quillOptions.modules.counter = counter;
      if (mention) quillOptions.modules.mention = mention;
      if (fileAttachment) {
        quillOptions.modules.fileAttachment = fileAttachment;
        quillOptions.modules.history = {
          delay: 2000,
          maxStack: 500,
          userOnly: true,
          ...history
        };
      }
      const quill = new Quill(editorContainer, quillOptions);

      // Remove binding tab key, using default behavior
      const keyboard = quill.getModule('keyboard') as Keyboard;
      keyboard.bindings['Tab'] && delete keyboard.bindings['Tab'];

      if (defaultValueRef.current) {
        const defVal = convertHtmlToDelta(quill, defaultValueRef.current);
        if (defVal) quill.setContents(defVal);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      quill.root.addEventListener('dblclick', onDoubleClick);
      quill.root.addEventListener('blur', () => onBlur());

      quill.clipboard.onPaste = (range: Range, { text, html }: { text?: string; html?: string }) => {
        if (!text) return '';
        const delta = new Delta().retain(range.index).delete(range.length).insert(text);
        const index = text.length + range.index;
        const length = 0;
        quill.updateContents(delta);
        quill.setSelection(index, length);
      };

      ref.current = quill;
      init && init(quill);
    }
    return () => {
      beforeDestroy && beforeDestroy(ref.current);
      ref.current = null;
      if (container) container.innerHTML = '';
      destroy && destroy();
    };
  }, []);

  return (
    <div
      className={classNames(isActive ? 'active' : '', 'quill-wrapper', props.editorWrapperProps?.className)}
      style={{ ...props.editorWrapperProps?.style }}
      ref={containerRef}
      onFocus={() => {
        setIsActive(true);
        onFocus && onFocus();
      }}
      onBlur={() => setIsActive(false)}
    ></div>
  );
};

export default forwardRef(Editor);
