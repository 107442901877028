import { useEffect } from 'react';
import { ControllerRenderProps, FieldValues, RegisterOptions, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { ErrorInput } from '@/components/error-input/ErrorInput';
import AppTooltip from '@components/app-tooltip/AppTooltip';
import BaseInput, { IBaseInputProps } from '@components/common/base-input';

import { useMessageYupTranslation } from '@/hooks/useI18n';
import usePostalCode from '@/hooks/usePostalCode';

import { REQUIRED_DOT } from '@/utils/constants/AppConstants';
import { IPostalCode } from '@/utils/interfaces';
import { ColorDefault } from '@/utils/themes/color';

import ErrorIcon from '@assets/icons/WarningCircle.svg';

import MaskedInput from '../mask-input';

import './index.scss';

/**
 * @field 都道府県import MaskedInput from '../mask-input';
 * @field 都道府県 - prefecture.
 * @field 都道府県（英語） - prefecture_roman.
 * @field 市区町村 - city + suburb
 * @field 市区町村（英語） - city_roman + suburb_roman
 * @field 番地 - street_address
 * @field 建物名・部屋番号 - office
 */

const DEFAULT_RELATIVE_FIELDS: IRelativeField[] = [
  {
    name: 'prefecture',
    key: 'prefecture'
  },
  {
    name: 'prefectureRoman',
    key: 'prefecture_roman'
  },
  {
    name: 'city',
    key: 'city'
  },
  {
    name: 'cityRoman',
    key: 'city_roman'
  },
  {
    name: 'suburb',
    key: 'suburb'
  },
  {
    name: 'suburbRoman',
    key: 'suburb_roman'
  },
  {
    name: 'streetAddress',
    key: 'street_address'
  },
  {
    name: 'apartmentName',
    key: 'office'
  }
];

const mappingError: { [key: string]: any } = {
  maxLength: { keyT: 'common:MSG_C_011' },
  number: { keyT: 'common:MSG_C_032' },
  fullsize: { keyT: 'common:MSG_C_027' },
  halfsize: { keyT: 'common:MSG_C_028' }
};

/**
 * @param name - The name of the input field
 * @param key - The field name mapping to the postal code data
 */
export interface IRelativeField {
  name: string;
  key: keyof IPostalCode | (keyof IPostalCode)[];
}

export interface IPostalCodeProps extends IBaseInputProps {
  name?: string;
  label?: string;
  required?: boolean;
  relativeFields?: IRelativeField[] | null;
  mask?: string;
  readOnly?: boolean;
  errorType?: 'tooltip' | 'inline';
  rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined;
  prefix?: React.ReactNode;
}

const InputPostalCode = (
  props: IPostalCodeProps & {
    field?: ControllerRenderProps<FieldValues, string>;
    errorMessage?: string;
    onSearch?: () => void;
  }
) => {
  const { mask, errorMessage, errorType, field, readOnly, onSearch, ...rest } = props;
  const { t } = useTranslation();

  const InputComponent = mask ? MaskedInput : BaseInput;

  const suffix = () => {
    if (errorMessage) {
      return (
        <AppTooltip title={errorMessage}>
          <ErrorIcon width={18} height={18} className='error-icon' />
        </AppTooltip>
      );
    }
    return !mask && readOnly ? undefined : (
      <BaseButton onClick={onSearch} className='!min-w-[63px] !h-[24px] !rounded-[4px]'>
        {t('postal_code:search')}
      </BaseButton>
    );
  };

  return (
    <>
      <InputComponent mask={mask ?? ''} {...field} labelClassName='label-input grow' {...rest} suffix={suffix()} inputMode='numeric' />
      {errorType !== 'tooltip' && errorMessage && <ErrorInput error={errorMessage ?? ''} />}
    </>
  );
};

const FormPostalCodeInput = ({
  name = 'postalCode',
  label,
  required,
  defaultValue = '',
  relativeFields = DEFAULT_RELATIVE_FIELDS,
  size,
  rules,
  ...restProps
}: IPostalCodeProps) => {
  const { control, setValue, clearErrors } = useFormContext();
  const {
    field,
    fieldState: { error }
  } = useController({ name, defaultValue, control, rules });
  const { data, searching } = usePostalCode();
  const { t } = useTranslation();

  const msgError = useMessageYupTranslation(
    error?.message
      ? error?.message
      : JSON.stringify({
          keyT: mappingError[error?.type?.toString() ?? '']?.keyT ?? '',
          options: { field: label ?? t('basic_information:postal_code_number'), limited: rules?.maxLength ?? 0 }
        })
  );

  const detectChange = (info: IPostalCode | null, fields: IPostalCodeProps['relativeFields']) => {
    if (!info || !fields?.length) return;
    for (const field of fields) {
      let value;
      if (Array.isArray(field.key)) {
        field.key.forEach((key, i) => {
          if (!info[key]) {
            value = null;
            return;
          }
          value = info[key];
          if (i === field.key.length - 1) return;
          value += ' ';
        });
        value = field.key.map((key) => info[key]).join('');
      } else {
        value = info[field.key];
      }
      setValue(field.name, value ?? '');
      if (field.name && value) clearErrors(field.name);
    }
  };

  useEffect(() => {
    if (!data || !relativeFields) return;
    detectChange(data, relativeFields);
  }, [data, JSON.stringify(relativeFields)]);

  return (
    <div className='postal-code-input-wrapper'>
      {label && (
        <div className='label-container'>
          <div>{label ?? ''}</div>
          {required && (
            <div className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </div>
          )}
        </div>
      )}

      <InputPostalCode {...restProps} name={name} field={field} errorMessage={msgError} onSearch={() => searching(field.value)} />
    </div>
  );
};

export default FormPostalCodeInput;
