import * as yup from 'yup';

import { schemaDateForm } from '@/components/common/date-input';

import { NOTE_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

const step2Schema = yup.object().shape({
  projectApplicant: yup.object().shape({
    lastName: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:last_name' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:last_name', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    firstName: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:first_name' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:first_name', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    lastNameRomaji: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:last_name_romaji' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:last_name_romaji', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    firstNameRomaji: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:first_name_romaji' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:first_name_romaji', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    primaryNational: yup.string().required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_select',
        optionsTx: { field: 'project:add:applicant_representative:nationality' }
      })
    ),
    dateOfBirth: schemaDateForm(true),
    gender: yup.string().required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_select',
        optionsTx: { field: 'project:add:applicant_representative:sex' }
      })
    ),
    postalCode: yup.string().required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:postal_code_number' }
      })
    ),
    prefecture: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:prefecture' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:prefecture', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    prefectureRoman: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:prefecture_roman' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:prefecture_roman', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    city: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:city' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:city', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    cityRoman: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:city_roman' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:city_roman', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    suburb: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:suburb' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:suburb', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    suburbRoman: yup
      .string()
      .halfSize(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_028'
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:suburb_roman' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:suburb_roman', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    streetAddress: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'postal_code:street_address' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'postal_code:street_address', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    apartmentName: yup.string().max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:office', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
    placeOfResidence: yup
      .string()
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: {
            field: 'project:add:applicant_representative:resident_registration_place',
            limited: TEXT_FIELD_MAX_LENGTH
          }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: {
            field: 'project:add:applicant_representative:resident_registration_place',
            limited: TEXT_FIELD_MAX_LENGTH
          }
        })
      ),
    currentCompany: yup
      .string()
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'project:add:applicant_representative:current_company' }
        })
      )
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'project:add:applicant_representative:current_company', limited: TEXT_FIELD_MAX_LENGTH }
        })
      ),
    departureDate: yup.string().required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:applicant_representative:departure_date' }
      })
    )
  }),
  visaCategoryName: yup.string().max(
    TEXT_FIELD_MAX_LENGTH,
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_011',
      optionsTx: {
        field: 'project:scenario_b:visa_category:label',
        limited: TEXT_FIELD_MAX_LENGTH
      }
    })
  )
});
const step3Schema = yup.object().shape({
  projectFamilies: yup.array().of(
    yup.object().shape({
      lastName: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:applicant_representative:last_name' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:applicant_representative:last_name', limited: TEXT_FIELD_MAX_LENGTH }
          })
        ),
      firstName: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:applicant_representative:first_name' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:applicant_representative:first_name', limited: TEXT_FIELD_MAX_LENGTH }
          })
        ),
      lastNameRomaji: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:applicant_representative:last_name_romaji' }
          })
        )
        .halfSize(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_028'
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: {
              field: 'project:add:applicant_representative:last_name_romaji',
              limited: TEXT_FIELD_MAX_LENGTH
            }
          })
        ),
      firstNameRomaji: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:applicant_representative:first_name_romaji' }
          })
        )
        .halfSize(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_028'
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: {
              field: 'project:add:applicant_representative:first_name_romaji',
              limited: TEXT_FIELD_MAX_LENGTH
            }
          })
        ),
      primaryNational: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'project:add:applicant_representative:nationality' }
        })
      ),
      dateOfBirth: schemaDateForm(true),
      gender: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'project:add:applicant_representative:sex' }
        })
      )
    })
  ),
  goWithFamily: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'project:question_detail:answer' }
    })
  )
});

const step4Schema = yup.object().shape({
  projectAssignmentInfo: yup.object().shape({
    name: yup
      .string()
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'basic_information:assignee_trainee_name', limited: TEXT_FIELD_MAX_LENGTH }
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'basic_information:assignee_trainee_name' }
        })
      ),
    companyName: yup
      .string()
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'basic_information:assignment_training_company_name', limited: TEXT_FIELD_MAX_LENGTH }
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'basic_information:assignment_training_company_name' }
        })
      ),
    countryId: yup.string().required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_select',
        optionsTx: { field: 'basic_information:destination_training_country' }
      })
    ),
    address: yup
      .string()
      .max(
        TEXT_FIELD_MAX_LENGTH,
        stringifyObjectValidateYup({
          keyT: 'common:MSG_C_011',
          optionsTx: { field: 'basic_information:assignment_training_city', limited: TEXT_FIELD_MAX_LENGTH }
        })
      )
      .required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_textbox',
          optionsTx: { field: 'basic_information:assignment_training_city' }
        })
      )
  })
});

const step5Schema = yup.object().shape({
  projectLocalSupporters: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:local_representative:name', limited: TEXT_FIELD_MAX_LENGTH }
          })
        )
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:local_representative:name' }
          })
        ),
      address: yup
        .string()
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:local_representative:address', limited: TEXT_FIELD_MAX_LENGTH }
          })
        )
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:local_representative:address' }
          })
        ),
      email: yup
        .string()
        .fullEmail(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_A_016',
            optionsTx: { field: 'project:add:local_representative:email' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:local_representative:email', limited: TEXT_FIELD_MAX_LENGTH }
          })
        )
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:local_representative:email' }
          })
        ),
      language: yup
        .string()
        .test(
          'language-required-first-item',
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_select',
            optionsTx: { field: 'project:add:local_representative:language' }
          }),
          function (value) {
            const index = (this?.options as { index?: number })?.index;
            if (index === 0) {
              return !!value;
            }
            return true;
          }
        )
        .notRequired()
    })
  ),
  localSupportOption: yup.number().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'project:question_detail:answer' }
    })
  )
});

const step6Schema = yup.object().shape({
  projectStakeholders: yup.array().of(
    yup.object().shape({
      stakeholderEmail: yup
        .string()
        .fullEmail(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_A_016',
            optionsTx: { field: 'project:add:project_stakeholders:email_address' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:project_stakeholders:email_address', limited: TEXT_FIELD_MAX_LENGTH }
          })
        )
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:project_stakeholders:email_address' }
          })
        )
        .stakeHolderEmailValidate()
    })
  ),
  hasOtherStakeHolder: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'project:question_detail:answer' }
    })
  )
});

const step7Schema = yup.object().shape({
  note: yup.string().htmlStrippedMaxLength(
    NOTE_MAX_LENGTH,
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_011',
      optionsTx: { field: 'basic_information:note', limited: NOTE_MAX_LENGTH }
    })
  ),
  projectAttachments: yup.array().of(
    yup.object().shape({
      status: yup.string().test(
        'file-invalid',
        stringifyObjectValidateYup({
          keyT: 'common:MSG_FILE_INVALID'
        }),
        (value) => {
          return value === UPLOAD_STATUS.DONE;
        }
      )
    })
  )
});

const dynamicSchema = yup.lazy((values) => {
  switch (values.id) {
    case 'step2':
      return step2Schema;
    case 'step3':
      return step3Schema;
    case 'step4':
      return step4Schema;
    case 'step5':
      return step5Schema;
    case 'step6':
      return step6Schema;
    case 'step7':
      return step7Schema;
    default:
      return yup.object().notRequired();
  }
});
export const FormValidationScenarioC = yup.object().shape({
  steps: yup.array().of(dynamicSchema)
});
