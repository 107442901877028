import { v4 as uuid } from 'uuid';

import { LANGUAGE } from '@/pages/project-management/project-list/constants';

import { APPLICANT_GENDER } from '@/utils/constants';

import { STEP_CRUMB_STATUS } from '../../contants/projectExternalProxy';
import { DEFAULT_FAMILY_MEMBER } from './form/step-three/constants';

export const DEFAULT_SCENARIO_B_STEP_2 = {
  stepId: 2,
  title: 'project:scenario_b:applicant_title',
  projectApplicant: {
    lastName: '',
    firstName: '',
    lastNameRomaji: '',
    firstNameRomaji: '',
    currentCompany: '',
    postalCode: '',
    prefecture: '',
    prefectureRoman: '',
    city: '',
    cityRoman: '',
    suburb: '',
    suburbRoman: '',
    streetAddress: '',
    apartmentName: '',
    placeOfResidence: '',
    departureDate: ''
  },
  projectAssignmentInfo: {},
  visaCategoryName: ''
};

export const DEFAULT_SCENARIO_B_STEP_3 = {
  stepId: 3,
  title: 'project:scenario_b:family_information',
  goWithFamily: true,
  familyAttendanceTime: '',
  projectFamilies: [{ ...DEFAULT_FAMILY_MEMBER, formId: uuid() }]
};

export const DEFAULT_SCENARIO_B_STEP_4 = {
  stepId: 4,
  title: 'project:scenario_b:local_supporter',
  needSupporter: null,
  projectLocalSupporters: []
};

export const DEFAULT_SCENARIO_B_STEP_5 = {
  stepId: 5,
  title: 'project:scenario_b:involved_members',
  involvedMembers: null,
  projectStakeholders: []
};

export const DEFAULT_SCENARIO_B_STEP_6 = {
  stepId: 6,
  title: 'project:scenario_b:additional_information',
  note: '',
  projectAttachments: []
};

export const DEFAULT_SCENARIO_B_STEP_7 = {
  stepId: 7,
  title: 'project:scenario_b:answer_detail'
};

export const DEFAULT_SCENARIO_B_STEP_STATUS = [
  { id: 2, value: STEP_CRUMB_STATUS.TODO },
  { id: 3, value: STEP_CRUMB_STATUS.TODO },
  { id: 4, value: STEP_CRUMB_STATUS.TODO },
  { id: 5, value: STEP_CRUMB_STATUS.TODO },
  { id: 6, value: STEP_CRUMB_STATUS.TODO },
  { id: 7, value: STEP_CRUMB_STATUS.TODO }
];

export const MAPPING_TITLE_WITH_STEP: { [key in number]: string } = {
  2: 'project:scenario_b:applicant_title',
  3: 'project:scenario_b:accompanying_information',
  4: 'project:scenario_b:local_supporter',
  5: 'project:scenario_b:involved_members',
  6: 'project:scenario_b:additional_information',
  7: 'project:scenario_b:answer_detail'
};

/**
 * The number different between stepId and index of it
 */
export const STEP_JUMP = 2;
export const GENDER_OPTIONS = [
  {
    label: 'common:male',
    value: APPLICANT_GENDER.MALE,
    id: APPLICANT_GENDER.MALE
  },
  {
    label: 'common:female',
    value: APPLICANT_GENDER.FEMALE,
    id: APPLICANT_GENDER.FEMALE
  }
];

export const LANGUAGE_OPTIONS = [
  {
    label: 'common:language_jp',
    value: LANGUAGE.japan,
    id: LANGUAGE.japan
  },
  {
    label: 'common:language_en',
    value: LANGUAGE.english,
    id: LANGUAGE.english
  }
];
