import dayjs from 'dayjs';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ErrorInput } from '@/components/error-input/ErrorInput';

import { useMessageYupTranslation } from '@/hooks/useI18n';

import { REQUIRED_DOT } from '@/utils/constants/AppConstants';
import { formatDateTime } from '@/utils/helpers/globalHelper';
import { stringifyObjectValidateYup } from '@/utils/method';
import { ColorDefault } from '@/utils/themes/color';

import FormItem from '../form-item';
import MaskedInput from '../mask-input';

import './index.scss';

interface IFormDate {
  year: string | null;
  month: string | null;
  day: string | null;
}

interface IPropsDateInput {
  name: string;
  showLabel?: boolean;
  labelTx?: string;
  required?: boolean;
}

export const convertStringToObjDate = (param?: string): IFormDate | null => {
  const day = param ? dayjs(param).get('date').toString().padStart(2, '0') : null;
  const month = param ? (dayjs(param).get('month') + 1).toString().padStart(2, '0') : null;
  const year = param ? dayjs(param).get('year').toString().padStart(4, '0') : null;

  return {
    year,
    month,
    day
  };
};

export const convertObjDatetoString = (params: IFormDate): string | null => {
  if (!params.year || !params.month || !params.day) return null;
  const combinedDate = `${params.year}-${params.month}-${params.day}`;
  return formatDateTime(combinedDate);
};

type inputDateType = string | number | undefined;
export const isValidDate = (year: inputDateType, month: inputDateType, day: inputDateType): boolean => {
  if (!year && !month && !day) {
    return true;
  }

  return dayjs(
    `${(year ?? '').toString().padStart(4, '0')}-${(month ?? '').toString().padStart(2, '0')}-${(day ?? '').toString().padStart(2, '0')}`,
    'YYYY-MM-DD',
    true
  ).isValid();
};

export function schemaDateForm(isRequired: boolean) {
  return yup.object().shape({
    year: yup
      .string()
      .nullable()
      .test('validate year', stringifyObjectValidateYup({ keyT: 'common:MSG_P_040' }), (value, ctx) => {
        const { month, day } = ctx.parent;
        const errorEmpty = isRequired && !value;
        const anotherFilled = Boolean(month || day);
        const invalidYear = value ? Number(value) < 1900 || Number(value) > 9998 : anotherFilled;
        if (errorEmpty) {
          return ctx.createError({
            message: stringifyObjectValidateYup({ keyT: 'common:MSG_P_040' }),
            type: 'required'
          });
        }
        if (invalidYear) {
          return ctx.createError({ message: stringifyObjectValidateYup({ keyT: 'common:MSG_P_041' }) });
        }
        if (value && month && day && !isValidDate(value, month, day)) {
          return ctx.createError({ message: stringifyObjectValidateYup({ keyT: 'common:MSG_P_041' }) });
        }
        return true;
      }),
    month: yup
      .string()
      .nullable()
      .test('validate month', stringifyObjectValidateYup({ keyT: 'common:MSG_P_040' }), (value, ctx) => {
        const { year, day } = ctx.parent;
        const errorEmpty = isRequired && !value;
        const anotherFilled = Boolean(year || day);
        const invalidMonth = value ? Number(value) < 1 || Number(value) > 12 : anotherFilled;
        if (errorEmpty) {
          return ctx.createError({
            message: stringifyObjectValidateYup({ keyT: 'common:MSG_P_040' }),
            type: 'required'
          });
        }
        if (invalidMonth) {
          return ctx.createError({ message: stringifyObjectValidateYup({ keyT: 'common:MSG_P_041' }) });
        }
        if (year && day && value && !isValidDate(year, value, day)) {
          return ctx.createError({ message: stringifyObjectValidateYup({ keyT: 'common:MSG_P_041' }) });
        }
        return true;
      }),
    day: yup
      .string()
      .nullable()
      .test('validate day', stringifyObjectValidateYup({ keyT: 'common:MSG_P_040' }), (value, ctx) => {
        const { year, month } = ctx.parent;
        const anotherFilled = Boolean(year || month);
        const errorEmpty = isRequired && !value;
        const invalidDay = value ? Number(value) < 1 || Number(value) > 31 : anotherFilled;
        if (errorEmpty) {
          return ctx.createError({
            message: stringifyObjectValidateYup({ keyT: 'common:MSG_P_040' }),
            type: 'required'
          });
        }
        if (invalidDay) {
          return ctx.createError({ message: stringifyObjectValidateYup({ keyT: 'common:MSG_P_041' }) });
        }
        if (year && month && value && !isValidDate(year, month, value)) {
          return ctx.createError({ message: stringifyObjectValidateYup({ keyT: 'common:MSG_P_041' }) });
        }
        return true;
      })
  });
}

const FormDateInput = ({ name = 'dateOfBirth', showLabel = true, labelTx, required }: IPropsDateInput) => {
  const { t } = useTranslation();
  const formMethod = useFormContext();
  const { errors } = formMethod.formState;
  const yearErrorMessage = get<any, string>(errors, `${name}.year.message`);
  const monthErrorMessage = get<any, string>(errors, `${name}.month.message`);
  const dayErrorMessage = get<any, string>(errors, `${name}.day.message`);
  const msgError = useMessageYupTranslation(yearErrorMessage || monthErrorMessage || dayErrorMessage);

  useEffect(() => {
    if (formMethod.formState.submitCount === 0) return;
    formMethod.trigger([`${name}.year`, `${name}.month`, `${name}.day`]);
  }, [formMethod.formState.submitCount]);

  const handleTrigger = () => {
    formMethod.trigger([`${name}.year`, `${name}.month`, `${name}.day`]);
  };

  const showError = yearErrorMessage || monthErrorMessage || dayErrorMessage;
  return (
    <div>
      {showLabel && labelTx && (
        <div className='label-container mb-[8px]'>
          <span>{t(labelTx)}</span>
          {required && (
            <div className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </div>
          )}
        </div>
      )}
      <div className='flex items-center gap-[10px]'>
        <FormItem name={`${name}.year`} showErrorOption='none'>
          <MaskedInput
            className='FormInput py-2 px-4 w-[77px]'
            required={true}
            maxLength={4}
            inputMode='numeric'
            placeholder='yyyy'
            mask='9999'
            maskChar={''}
            onChange={handleTrigger}
          />
        </FormItem>
        <span className='text-lnk font-medium'>{t('project:question_detail:year_month_date:year')}</span>
        <FormItem name={`${name}.month`} showErrorOption='none'>
          <MaskedInput
            className='FormInput py-2 px-4 w-[61px]'
            required={true}
            maxLength={2}
            inputMode='numeric'
            placeholder='mm'
            mask='99'
            maskChar={''}
            onChange={handleTrigger}
          />
        </FormItem>
        <span className='text-lnk font-medium'>{t('project:question_detail:year_month_date:month')}</span>
        <FormItem name={`${name}.day`} showErrorOption='none'>
          <MaskedInput
            className='FormInput py-2 px-4 w-[61px]'
            required={true}
            maxLength={2}
            inputMode='numeric'
            placeholder='dd'
            mask='99'
            maskChar={''}
            onChange={handleTrigger}
          />
        </FormItem>
        <span className='text-lnk font-medium'>{t('project:question_detail:year_month_date:date')}</span>
      </div>
      {showError && <ErrorInput classNameLabel={'truncate'} error={msgError ?? ''} />}
    </div>
  );
};

export default FormDateInput;
