import { useAppSelector } from '@/hooks';
import React, { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AnyObject } from 'yup';

import { ExternalProxyContext } from '@pages/project-management/add-edit/ProjectExternalProxy';
import { FormObserver } from '@pages/project-management/add-edit/models/projectExternalProxy';
import { OneOfStepType } from '@pages/project-management/add-edit/models/scenarioB';
import Header, { HEADER_EVENT, STEP } from '@pages/project-management/add-edit/scenario/components/header';
import ScenarioLayout from '@pages/project-management/add-edit/scenario/components/layout';
import StepCrumb, { ICrumb, STEP_CRUMB_STATUS } from '@pages/project-management/add-edit/scenario/components/step-crumb';
import { getFirstErrorName } from '@pages/project-management/add-edit/scenario/utils';

import { BasePopupProps, TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';

import { getProjectDetails } from '@/redux/project-v2/projectSelector';

import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { CREATE_PROJECT_SUCCESS, PROJECT_LIST_URL, SAVE_AS_DRAFT_PROJECT_SUCCESS } from '@/utils/constants/RouteContants';
import { validateWithoutErrorTypes } from '@/utils/helpers/validateHelper';

import { MAPPING_TITLE_WITH_STEP, STEP_JUMP } from './constants';
import ScenarioBStepFive from './form/step-five';
import ScenarioBStepFour from './form/step-four';
import ScenarioBStepSeven from './form/step-seven';
import ScenarioBStepSix from './form/step-six';
import ScenarioBStepThree from './form/step-three';
import ScenarioBStepTwo from './form/step-two';
import { DirectorConverter, ScenarioBodyConverter } from './helpers';

import './styles.scss';

const ScenarioB = () => {
  const { setLoading } = useContext(ExternalProxyContext);
  const formContext = useFormContext<FormObserver<'scenario-b'>>();
  const [selectedStep, stepStatus] = formContext.watch(['selectedStep', 'stepStatus']) as [
    OneOfStepType['stepId'],
    FormObserver<'scenario-b'>['stepStatus']
  ];
  const allFieldValues = formContext.watch();
  const { errors, isSubmitting } = formContext.formState;
  const { data: projectDetails } = useAppSelector(getProjectDetails);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const FIST_STEP_CRUMB: ICrumb = {
    id: 1,
    status: STEP_CRUMB_STATUS.COMPLETED,
    title: 'project:scenario:request_details',
    onClick: () => validateStepAndJumpTo(1)
  };

  const getStepIndex = (value: number) => {
    const stepIndex = selectedStep - STEP_JUMP;
    const stepStatusIndex = selectedStep - 1;
    const newStepStatusIndex = value - 1;
    return {
      current: stepIndex,
      next: newStepStatusIndex,
      statusIndex: stepStatusIndex
    };
  };

  const validateStepAndJumpTo = async (newStep: number) => {
    try {
      const { current: stepIndex, statusIndex: stepStatusIndex } = getStepIndex(newStep);
      if (stepIndex < 0) throw new Error('Invalid step index');
      const isValid = await formContext.trigger(`steps[${stepIndex}]`);
      if (!isValid) {
        const controlFieldsStep = (formContext.control._fields.steps as AnyObject)?.[stepIndex];
        const errorName = getFirstErrorName(formContext.formState.errors.steps as AnyObject[], {
          projectApplicant: Object.keys(controlFieldsStep?.projectApplicant ?? {}),
          projectFamilies: Object.keys(controlFieldsStep?.projectFamilies?.[0] ?? {})
        });
        formContext.setFocus(errorName);
        // Scroll to select field because setFocus on select not working
        const selectElemById = document.getElementById(errorName);
        selectElemById && selectElemById.scrollIntoView({ block: 'center' });
        return;
      }
      formContext.setValue('selectedStep', newStep);
      formContext.setValue(`stepStatus[${stepStatusIndex}].value`, STEP_CRUMB_STATUS.COMPLETED);
    } catch (error) {}
  };

  const goBackTo = async (newStep: number) => {
    try {
      const { current: stepIndex, statusIndex: stepStatusIndex } = getStepIndex(newStep);
      if (stepIndex < 0) throw new Error('Invalid step index');
      const { invalid } = formContext.getFieldState(`steps[${stepIndex}]`);
      if (invalid) return;
      formContext.setValue('selectedStep', newStep);
      const currentStatus = formContext.getValues(`stepStatus[${stepStatusIndex}].value`);
      const newStatus = currentStatus === STEP_CRUMB_STATUS.COMPLETED ? STEP_CRUMB_STATUS.COMPLETED : STEP_CRUMB_STATUS.TODO;
      formContext.setValue(`stepStatus[${stepStatusIndex}].value`, newStatus);
    } catch (error) {}
  };

  const stepCrumbs = useMemo(() => {
    return (
      stepStatus.map((step, index) => {
        if (index === 0) return { ...step, ...FIST_STEP_CRUMB };

        const stepId = step.id;
        const title = MAPPING_TITLE_WITH_STEP[stepId];
        const status = stepId === selectedStep ? STEP_CRUMB_STATUS.INPROGRESS : step.value;
        const newItem: any = {
          id: stepId,
          status,
          title: title ?? `Step ${stepId}`,
          _formData: step
        };
        newItem.onClick = () => {
          if (stepId !== selectedStep && status !== STEP_CRUMB_STATUS.TODO) {
            validateStepAndJumpTo(stepId);
          }
        };
        return newItem;
      }) ?? []
    );
  }, [stepStatus, selectedStep]);

  const { mutate: create, loading: loadingPost } = useMutation(API.CREATE_PROJECT_EXTERNAL('scenario-b'), {
    method: 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const { mutate: update, loading: loadingPut } = useMutation(API.CREATE_PROJECT_EXTERNAL('scenario-b'), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const handleDraftSuccess = (response: any) => {
    if (!response?.data) return;
    formContext.reset((prev) => ({ ...prev, isDirty: false }), { keepValues: true, keepDirty: false });

    setTimeout(() => {
      setLoading(false);
      navigate(SAVE_AS_DRAFT_PROJECT_SUCCESS(response?.data || ''));
    }, 500);
  };

  const onSubmit = async (formValues: FormObserver<'scenario-b'>) => {
    const request = id ? update : create;
    let resolve: any = () => {};
    let reject: any = () => {};
    const holders = new Promise((r, j) => {
      resolve = r;
      reject = j;
    });
    try {
      const bodyBuilder = new ScenarioBodyConverter(formValues);
      const bodyDirector = new DirectorConverter(bodyBuilder);
      bodyDirector.processPublished(projectDetails);
      const bodyPublish = bodyDirector.getJSON();
      if (!bodyPublish) return;
      const res = await request(bodyPublish);
      if (!res) {
        setLoading(false);
        reject('error');
        return;
      }
      formContext.reset((prev) => ({ ...prev, isDirty: false }), { keepValues: true, keepDirty: false });
      setTimeout(() => {
        setLoading(false);
        navigate(CREATE_PROJECT_SUCCESS);
        resolve('success');
      }, 500);
    } catch (error) {
      setLoading(false);
      reject(error);
    }
    return holders;
  };

  const onError = (error: any) => {
    const stepStatus = formContext.getValues('stepStatus');
    const newStepStatus = stepStatus.map((step, i) => {
      const stepIndex = i - 1;
      const errorStep = error?.steps?.[stepIndex];
      if (errorStep) {
        return { ...step, value: STEP_CRUMB_STATUS.ERROR };
      }
      return step;
    });
    formContext.setValue('stepStatus', newStepStatus);
  };

  const handleRegister = async () => {
    const content: BasePopupProps = {
      type: TYPE_ICON.CONFIRM,
      title: t('common:MSG_P_001_title'),
      msg: t('common:MSG_P_001_content')
    };
    const response = await showBasePopup(content);
    if (response !== TYPE_ICON.CONFIRM) return;
    setLoading(true);
    formContext.handleSubmit(onSubmit, onError)();
  };

  const handleDraft = async () => {
    const bodyBuilder = new ScenarioBodyConverter(allFieldValues);
    const bodyDirector = new DirectorConverter(bodyBuilder);
    const request = id ? update : create;
    bodyDirector.processDraft(projectDetails);
    const bodyDraft = bodyDirector.getJSON();
    if (!bodyDraft) return;
    const hasErrorNotRequired = validateWithoutErrorTypes(errors, ['required', 'optionality', 'nullable']);
    if (hasErrorNotRequired) return;
    const showPopup = await showBasePopup({
      title: String(t('common:MSG_P_002_title')),
      msg: String(t('common:MSG_P_002_content')),
      type: TYPE_ICON.CONFIRM
    });
    if (showPopup !== TYPE_ICON.CONFIRM) return;
    setLoading(true);
    request(bodyDraft)
      .then(handleDraftSuccess)
      .catch()
      .finally(() => setLoading(false));
  };

  const headerOnClick = (event: HEADER_EVENT) => {
    switch (event) {
      case HEADER_EVENT.CANCEL:
        navigate(PROJECT_LIST_URL);
        break;
      case HEADER_EVENT.NEXT:
        const nexStep = selectedStep + 1;
        if (nexStep <= 7) {
          validateStepAndJumpTo(nexStep);
        }
        break;
      case HEADER_EVENT.BACK:
        const prevStep = selectedStep - 1;
        if (prevStep >= 1) {
          goBackTo(prevStep);
        }
        break;
      case HEADER_EVENT.SAVE:
        handleRegister();
        break;
      case HEADER_EVENT.DRAFT:
        handleDraft();
        break;
      default:
        break;
    }
  };

  const convertStepForHeader = (step: number) => {
    switch (step) {
      case 1:
        return STEP.FIRST;
      case 7:
        return STEP.LAST;
      default:
        return step;
    }
  };

  const renderFormStep = (step: number) => {
    switch (step) {
      case 2:
        return <ScenarioBStepTwo />;
      case 3:
        return <ScenarioBStepThree />;
      case 4:
        return <ScenarioBStepFour />;
      case 5:
        return <ScenarioBStepFive />;
      case 6:
        return <ScenarioBStepSix />;
      case 7:
        return <ScenarioBStepSeven />;
      default:
        return null;
    }
  };

  const buttonPropForHeader = () => {
    let backBtn, cancelBtn, nextBtn, draftBtn, saveBtn;
    const notEditAnything = !formContext?.formState?.isDirty && !!id;
    nextBtn = { disabled: selectedStep > 6 || loadingPost || loadingPut };
    draftBtn = { disabled: loadingPost || loadingPut || notEditAnything };
    saveBtn = { disabled: loadingPost || isSubmitting };
    return [backBtn, cancelBtn, nextBtn, draftBtn, saveBtn];
  };

  return (
    <ScenarioLayout
      header={<Header buttonProps={buttonPropForHeader()} step={convertStepForHeader(selectedStep)} onClick={headerOnClick} />}
      title={
        id ? (
          <>
            <span className='title-24 text-negative'>{t('basic_information:isDraft')}</span> {projectDetails?.code}
          </>
        ) : (
          t('project:add:title')
        )
      }
    >
      <StepCrumb data={stepCrumbs} />
      <div className='scenario-b-form-wrapper mt-[12px]'>{renderFormStep(selectedStep)}</div>
    </ScenarioLayout>
  );
};

export const useFormScenarioBContext = <S extends string>() => useFormContext<FormObserver<'scenario-b', S>>();
export default ScenarioB;
