import * as yup from 'yup';

import { schemaDateForm } from '@/components/common/date-input';

import { TEXT_FIELD_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

const projectApplicantSchema = yup.object().shape({
  lastName: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:applicant_representative:last_name' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:applicant_representative:last_name', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  firstName: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:applicant_representative:first_name' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:applicant_representative:first_name', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  lastNameRomaji: yup
    .string()
    .halfSize(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_028'
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:applicant_representative:last_name_romaji' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:applicant_representative:last_name_romaji', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  firstNameRomaji: yup
    .string()
    .halfSize(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_028'
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:applicant_representative:first_name_romaji' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:applicant_representative:first_name_romaji', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  primaryNational: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_001_select',
      optionsTx: { field: 'project:add:applicant_representative:nationality' }
    })
  ),
  dateOfBirth: schemaDateForm(true),
  gender: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'project:add:applicant_representative:sex' }
    })
  ),
  postalCode: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_textbox',
      optionsTx: { field: 'basic_information:postal_code_number' }
    })
  ),
  prefecture: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'postal_code:prefecture' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:prefecture', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  prefectureRoman: yup
    .string()
    .halfSize(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_028'
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'postal_code:prefecture_roman' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:prefecture_roman', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  city: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'postal_code:city' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:city', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  cityRoman: yup
    .string()
    .halfSize(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_028'
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'postal_code:city_roman' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:city_roman', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  suburb: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'postal_code:suburb' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:suburb', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  suburbRoman: yup
    .string()
    .halfSize(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_028'
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'postal_code:suburb_roman' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:suburb_roman', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  streetAddress: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'postal_code:street_address' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:street_address', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  apartmentName: yup
    .string()
    .notRequired()
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'postal_code:office', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  placeOfResidence: yup
    .string()
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: {
          field: 'project:add:applicant_representative:resident_registration_place',
          limited: TEXT_FIELD_MAX_LENGTH
        }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: {
          field: 'project:add:applicant_representative:resident_registration_place',
          limited: TEXT_FIELD_MAX_LENGTH
        }
      })
    ),
  currentCompany: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:applicant_representative:current_company' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:applicant_representative:current_company', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  departureDate: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_textbox',
      optionsTx: { field: 'project:add:applicant_representative:departure_date' }
    })
  )
});

const assignmentSchema = yup.object().shape({
  companyName: yup
    .string()
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:assignment_training_company_name', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:assignment_training_company_name' }
      })
    ),
  countryId: yup.string().required(
    stringifyObjectValidateYup({
      keyT: 'common:MSG_001_select',
      optionsTx: { field: 'basic_information:destination_training_country' }
    })
  ),
  address: yup
    .string()
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'basic_information:assignment_training_city', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'basic_information:assignment_training_city' }
      })
    )
});

export const stepTwoSchema = yup.object().shape({
  projectApplicant: projectApplicantSchema,
  projectAssignmentInfo: assignmentSchema,
  visaCategoryName: yup.string().max(
    TEXT_FIELD_MAX_LENGTH,
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_011',
      optionsTx: {
        field: 'project:scenario_b:visa_category:label',
        limited: TEXT_FIELD_MAX_LENGTH
      }
    })
  )
});
