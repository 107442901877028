import * as yup from 'yup';

import { NOTE_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

export const FormProjectMemo = yup.object().shape({
  location: yup.string().max(
    TEXT_FIELD_MAX_LENGTH,
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_011',
      optionsTx: { field: 'project_memo:location', limited: TEXT_FIELD_MAX_LENGTH }
    })
  ),
  emoNumber: yup.string().max(
    TEXT_FIELD_MAX_LENGTH,
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_011',
      optionsTx: { field: 'project_memo:e_mo_number', limited: TEXT_FIELD_MAX_LENGTH }
    })
  ),
  folder: yup.string().htmlStrippedMaxLength(
    NOTE_MAX_LENGTH,
    stringifyObjectValidateYup({
      keyT: 'common:MSG_C_011',
      optionsTx: { field: 'project_memo:folder', limited: NOTE_MAX_LENGTH }
    })
  ),
  memo: yup
    .string()
    .nullable()
    .htmlStrippedMaxLength(
      NOTE_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project_memo:memo', limited: NOTE_MAX_LENGTH }
      })
    )
});
