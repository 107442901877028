import { createRef, forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '../base-button/BaseButton';
import BaseModal, { IBaseModalType } from '../base-modal/BaseModal';

import './BasePopup.scss';

export const TYPE_ICON = {
  DISCARD: 'discard',
  DELETE: 'delete',
  CONFIRM: 'confirm',
  REFERENCE: 'reference',
  CONFIRM_CANCEL_EDIT: 'confirm_cancel_edit'
} as const;

export type TypePopup = (typeof TYPE_ICON)[keyof typeof TYPE_ICON];
export type TypeHandlePopup = 'cancel' | 'close' | 'confirm';
export type BasePopupProps = {
  msg?: string;
  title?: string;
  icon?: any;
  textCancel?: string;
  textConfirm?: string;
  type?: TypePopup;
  modalConfigs?: Partial<IBaseModalType>;
};
const BasePopupComponent = forwardRef((_: BasePopupProps, ref) => {
  // state
  const [t] = useTranslation();
  const [visible, setVisible] = useState(false);
  const [_title, setTitle] = useState<string>();
  const [_msg, setMsg] = useState<string>();
  const [_textCancel, setTextCancel] = useState<string>();
  const [_textConfirm, setTextConfirm] = useState<string>();
  const [promiseCallback, setPromiseCallback] = useState<{ resolve: (value: TypeHandlePopup) => void; reject: any }>();
  const [_type, setType] = useState<TypePopup>();
  const [_modalConfigs, setModalConfigs] = useState<BasePopupProps['modalConfigs']>();

  const iconWithType = useCallback((type: TypePopup) => {
    switch (type) {
      case TYPE_ICON.DISCARD:
      case TYPE_ICON.DELETE:
        setTitle(t('template_layout:modal:task:are_you_sure') ?? '');
        setMsg(t('template_layout:modal:task:you_made_change') ?? '');
        setTextConfirm(t('template_layout:yes') ?? '');
        setTextCancel(t('template_layout:no') ?? '');
        break;

      case TYPE_ICON.CONFIRM:
        setTitle(t('upload:title') ?? '');
        setTextConfirm(t('template_layout:yes') ?? '');
        setTextCancel(t('template_layout:no') ?? '');
        break;
      case TYPE_ICON.REFERENCE:
        setTitle(t('template_layout:are_u_sure_this_template') ?? '');
        setMsg(t('template_layout:message_change_refer') ?? '');
        setTextConfirm(t('template_layout:yes') ?? '');
        setTextCancel(t('template_layout:no') ?? '');
        break;
      case TYPE_ICON.CONFIRM_CANCEL_EDIT:
        setTitle(t('common:MSG_C_002:title') ?? '');
        setMsg(t('common:MSG_C_002:description') ?? '');
        setTextConfirm(t('template_layout:yes') ?? '');
        setTextCancel(t('template_layout:no') ?? '');
        break;
      default:
        setTitle(t('popup:save_change_route:title') ?? '');
        setMsg(t('popup:save_change_route:des') ?? '');
        setTextConfirm(t('template_layout:yes') ?? '');
        setTextCancel(t('template_layout:no') ?? '');
        break;
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      show: ({ title, msg, icon, type, textConfirm, textCancel, modalConfigs: defaultModalConfigs }: BasePopupProps) => {
        if (type) {
          setType(type);
          iconWithType(type);
        }
        setModalConfigs(defaultModalConfigs);
        textConfirm && setTextConfirm(textConfirm);
        textCancel && setTextCancel(textCancel);
        title && setTitle(title);
        msg !== undefined && setMsg(msg);
        setVisible(true);
        return new Promise<TypeHandlePopup>((resolve, reject) => {
          setPromiseCallback({ resolve, reject });
        });
      },
      hide: () => setVisible(false)
    }),
    [iconWithType]
  );
  // function
  const handleCancel = () => {
    setVisible(false);
    promiseCallback?.resolve('cancel');
  };
  const handleConfirm = () => {
    setVisible(false);
    promiseCallback?.resolve('confirm');
  };
  const handleClose = () => {
    promiseCallback?.resolve('close');
    setVisible(false);
  };

  const primaryBtnType = () => {
    const listDangerType: TypePopup[] = [TYPE_ICON.DISCARD, TYPE_ICON.REFERENCE, TYPE_ICON.CONFIRM_CANCEL_EDIT];
    if (_type && listDangerType.includes(_type)) {
      return 'danger';
    }
    return 'primary';
  };
  // render
  return (
    <BaseModal
      onCancel={handleClose}
      maskClosable={false}
      openModal={visible}
      contentElement={
        <div className='base-popup'>
          <div className='font-[700] text-[20px] text-lnk leading-[120%]'>{_title}</div>
          <div className='font-[500] text-[14px] text-textGray mt-[16px] whitespace-pre-line leading-[160%]'>{_msg}</div>
          <div className='flex-base-button grid-cols-2 gap-4 mt-[24px]'>
            <BaseButton onClick={handleCancel} type='tertiary' size='medium'>
              {_textCancel}
            </BaseButton>
            <BaseButton
              onClick={handleConfirm}
              size='medium'
              type={primaryBtnType()}
              danger={_type === TYPE_ICON.DISCARD || _type === TYPE_ICON.REFERENCE}
            >
              {_textConfirm}
            </BaseButton>
          </div>
        </div>
      }
      {..._modalConfigs}
    />
  );
});
type BasePopupRef = {
  show: (data: BasePopupProps) => string | undefined;
  hide: () => void;
};
export const popupErrorRef = createRef<BasePopupRef>();
export const BasePopup = memo(() => <BasePopupComponent ref={popupErrorRef} />);
export const showBasePopup = (data: BasePopupProps): string | undefined => {
  return popupErrorRef.current?.show(data);
};
export const hideBasePopup = () => popupErrorRef.current?.hide();
