export const NEED_APPROVE = {
  NONE: 'NONE',
  NEED: 'NEED'
} as const;
export const DEFAULT_VALUE_FORM_TASK_OF_PROJECT = {
  countryId: null,
  taskMasterId: null,
  processId: null,
  category: null,
  description: '',
  projectTaskAttachmentFiles: [],
  taskInternalDocuments: [],
  taskRequireDocuments: []
};
export const DEFAULT_VALUE_TASK_REQUIRE_DOCUMENT = {
  projectRequiredDocumentId: null,
  documentName: '',
  numberOfCopy: '',
  note: '',
  isConfirm: false,
  nameConfirm: '',
  dateConfirm: '',
  displayOrder: '',
  taskRequireDocumentAttachmentFiles: [],
  id: null
};
export const DEFAULT_VALUE_TASK_INTERNAL_DOCUMENT = {
  projectInternalDocumentId: null,
  documentName: '',
  numberOfCopy: '',
  note: '',
  isConfirm: false,
  nameConfirm: '',
  dateConfirm: '',
  displayOrder: '',
  id: null
};
export const DEFAULT_DETAIL_TASK_OF_PROJECT = {
  id: null,
  projectProcessId: null,
  projectProcessName: null,
  taskId: null,
  taskType: null,
  taskName: null,
  managementCode: null,
  detail: null,
  statusId: null,
  prevStatusId: null,
  statusName: null,
  displayOrder: null,
  deleted: null,
  version: null,
  countryId: null,
  countryName: null,
  createdName: null,
  updatedName: null,
  completeName: null,
  completeDate: null,
  completeBy: null,
  deadline: null,
  createdBy: null,
  createdDate: null,
  updatedBy: null,
  updatedDate: null,
  departureDate: null,
  projectTaskAttachmentFiles: [],
  taskInternalDocuments: [],
  taskRequireDocuments: [],
  projectTaskPics: []
};

export const TASK_TYPE = {
  TODO: 'TODO',
  OTHER: 'OTHER',
  MOT: 'MOT',
  SUPPORT: 'SUPPORT'
} as const;

export const TYPE_CONFIRM = {
  STATUS: 'status',
  PIC: 'pic',
  DEADLINE: 'deadline',
  NONE: 'none'
} as const;

export const DEFAULT_ACTIVE_COLLAPSE = ['1'];

export const ATTACHMENT_STATUS = {
  APPROVAL: 'A',
  REJECT: 'R',
  WAITING: 'W',
  DONE: 'done',
  UPLOADING: 'uploading',
  ERROR: 'error'
} as const;

export const DEADLINE_STATUS = {
  ERROR: 'error',
  WARNING: 'warning'
} as const;
