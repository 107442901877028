import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import FormBaseFilter, { RefActionControl } from '@/components/base-filter';
import BaseTableV2 from '@/components/common/table-fetch';
import CommonTag from '@/components/common/tag/CommonTag';

import useAuthorization, { V } from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import { useFilterConfigs } from '@/hooks/useFilterConfigs';

import { ROUTER_IDS } from '@/utils/constants';
import { CREATE_NEW_ACCOUNT_MASTER_LIST, TABLE_ACCOUNT_MASTER_LIST } from '@/utils/constants/AccountMaster';
import { FORMAT_DATE_EN_HH_MM_SS } from '@/utils/constants/AppConstants';
import { APPROVE_REJECT_ACCOUNT_MASTER_URL, CREATE_ACCOUNT_MASTER_URL, UPDATE_ACCOUNT_MASTER_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { formatDateTime, getEndOfDay } from '@/utils/helpers/globalHelper';
import { IAccountMasterRow } from '@/utils/interfaces/AccountMasterListInterface';

import IconAddNew from '@/assets/icons/base-filter/icon-add-new.svg';

import Filter from './components/Filter';
import { IQueryFilterParams } from './models';
import { generateFilter } from './utils';

import './List.scss';

export default function List() {
  const { t } = useTranslation();
  const { hasPermission, isInternalRole } = useAuthorization();
  const navigate = useNavigate();

  const headerPageRef = useRef<HTMLDivElement>(null);
  const filterTableRef = useRef<RefActionControl>({
    toggle: () => {}
  });
  const { data: companyName, loading: loadingCompany } = useFetch<DefaultOptionType[]>(`/usr/organizations`, 'GET');
  const { data: role, loading: loadingRole } = useFetch<DefaultOptionType[]>(`/usr/roles`, 'GET');
  const { data: defaultFilterConfigs, update: updateFilterConfigs } = useFilterConfigs<FieldValues>(ROUTER_IDS.ACCOUNT_MASTER_LIST);

  const generateDefaultFilterForm = () => {
    const results = {
      form: {},
      query: {}
    };
    if (!defaultFilterConfigs?.primary) return results;
    const { updatedDateFrom, updatedDateTo } = defaultFilterConfigs.primary;
    results.form = {
      primary: {
        ...defaultFilterConfigs.primary,
        organizationId: isInternalRole ? defaultFilterConfigs.primary.organizationId : '',
        updatedDateFrom: updatedDateFrom ? dayjs(updatedDateFrom) : undefined,
        updatedDateTo: updatedDateTo ? dayjs(updatedDateTo) : undefined
      }
    };
    results.query = {
      filter: generateFilter({
        keyword: '',
        organizationId: isInternalRole ? defaultFilterConfigs.primary.organizationId : '',
        roleId: defaultFilterConfigs?.primary?.roleId,
        status: defaultFilterConfigs?.primary?.status,
        updateDateFrom: defaultFilterConfigs?.primary?.updatedDateFrom ?? undefined,
        updateDateTo: defaultFilterConfigs?.primary?.updatedDateTo ?? undefined
      })
    };
    return results;
  };

  const formFilter = useForm({ defaultValues: generateDefaultFilterForm().form });
  const [filter, setFilter] = useState(generateDefaultFilterForm().query);

  const updateFilter = (values: FieldValues) => {
    const updatedDateFrom = values?.primary?.updatedDateFrom ? formatDateTime(values?.primary?.updatedDateFrom) : undefined;
    const updatedDateTo = values?.primary?.updatedDateTo ? getEndOfDay(values?.primary?.updatedDateTo) : undefined;
    const formValues: IQueryFilterParams = {
      keyword: values?.search,
      organizationId: isInternalRole ? values?.primary?.organizationId : '',
      roleId: values?.primary?.roleId,
      status: values?.primary?.status,
      updateDateFrom: updatedDateFrom,
      updateDateTo: updatedDateTo
    };
    setFilter({ filter: generateFilter(formValues) });
    updateFilterConfigs({
      ...values,
      primary: {
        ...values.primary,
        organizationId: isInternalRole ? values?.primary?.organizationId : '',
        updatedDateFrom,
        updatedDateTo
      }
    });
  };

  const generateFilterHeader = () => {
    return (
      <div className='mb-1'>
        <FormProvider {...formFilter}>
          <FormBaseFilter
            onSubmit={(values) => {
              updateFilter(values);
            }}
            searchBox={{ placeholder: DataViewer.display(t('common:ACCOUNT_MASTER_FIELDS_SEARCH_PLACEHOLDER')) }}
            tagSection={{
              renderTag: (fieldKey, fieldValue) => {
                if (fieldKey === 'organizationId') {
                  if (loadingCompany) return <></>;
                  return <>{companyName?.find((i) => i.id === fieldValue)?.name}</>;
                }

                if (fieldKey === 'roleId') {
                  if (loadingRole) return <></>;
                  return <>{role?.find((i) => i.id === fieldValue)?.name}</>;
                }

                if (['updatedDateFrom', 'updatedDateTo'].includes(fieldKey)) {
                  return <>{formatDateTime(fieldValue)}</>;
                }

                return <>{fieldValue}</>;
              }
            }}
            primaryAction={{
              label: 'button:filter',
              name: 'primary',
              popoverContent: (
                <Filter
                  defaultValues={formFilter.getValues('primary')}
                  onSubmit={(values) => {
                    formFilter.reset((previous) => ({ ...previous, primary: { ...values } }));
                    formFilter.handleSubmit((values) => updateFilter(values))();
                  }}
                />
              ),
              refControl: filterTableRef
            }}
            secondaryAction={null}
          />
        </FormProvider>
      </div>
    );
  };

  const columns = [
    {
      title: t('account_master:list:username'),
      dataIndex: 'userName',
      key: 'userName',
      width: 200,
      className: 'max-w-[200px] h-[38px]',
      render(_: any, record: IAccountMasterRow) {
        return (
          <>
            {!hasPermission(CREATE_NEW_ACCOUNT_MASTER_LIST, V) ? (
              <AppTooltip className='block max-w-full w-fit truncate' title={record.userName}>
                <div className='block truncate text-[14px]'>
                  <span>{record?.userName}</span>
                </div>
              </AppTooltip>
            ) : (
              <Link
                to={record?.waitingCreate ? APPROVE_REJECT_ACCOUNT_MASTER_URL(record.id) : UPDATE_ACCOUNT_MASTER_URL(record.id)}
                style={{ display: 'inline' }}
              >
                <AppTooltip className='block max-w-full w-fit truncate' title={record.userName}>
                  <div className='truncate text-[14px]'>
                    <span className='underline'>{record?.userName}</span>
                  </div>
                </AppTooltip>
              </Link>
            )}
          </>
        );
      }
    },
    {
      title: t('account_master:list:company_name'),
      dataIndex: 'organizationName',
      key: 'organizationName',
      width: 440,
      className: 'max-w-[440px] h-[38px]',
      render(_: any, record: IAccountMasterRow) {
        return (
          <AppTooltip className='block max-w-full w-fit truncate text-[14px]' title={record?.organizationName}>
            {record?.organizationName}
          </AppTooltip>
        );
      }
    },
    {
      title: t('account_master:list:email_address'),
      dataIndex: 'email',
      key: 'email',
      className: 'max-w-[440px] h-[38px]',
      render(_: any, record: IAccountMasterRow) {
        return (
          <AppTooltip className='block max-w-full w-fit truncate text-[14px]' title={record?.email}>
            {record?.email}
          </AppTooltip>
        );
      }
    },
    {
      title: t('account_master:list:role'),
      dataIndex: 'roleName',
      key: 'roleName',
      width: 150,
      className: 'max-w-[150px] h-[38px]',
      render(_: any, record: IAccountMasterRow) {
        return (
          <AppTooltip className='block max-w-full w-fit truncate text-[14px]' title={record?.roleName}>
            {record?.roleName}
          </AppTooltip>
        );
      }
    },
    {
      title: t('account_master:list:status'),
      dataIndex: 'waitingCreate',
      key: 'waitingCreate',
      width: 100,
      className: 'max-w-[100px] h-[38px]',
      render(_: any, record: IAccountMasterRow) {
        return (
          <>
            {(() => {
              switch (record?.waitingCreate) {
                case true:
                  return <div className='text-yellow'>{t('account_master:list:pending')}</div>;
                case false:
                  if (record?.status === true) {
                    return <CommonTag type={'success'} text={t('account_master:list:valid')} />;
                  }
                default:
                  return <CommonTag type={'default'} text={t('account_master:list:invalid')} />;
              }
            }).call(this)}
          </>
        );
      }
    },
    {
      title: t('account_master:list:changer'),
      dataIndex: 'updatedName',
      key: 'updatedName',
      width: 140,
      className: 'max-w-[140px] h-[38px]',
      render(_: any, record: IAccountMasterRow) {
        return (
          <AppTooltip className='block max-w-full w-fit truncate text-[14px]' title={record?.updatedName}>
            {record?.updatedName}
          </AppTooltip>
        );
      }
    },
    {
      title: t('account_master:list:updated_date'),
      dataIndex: 'updatedDate',
      key: 'updatedDate',
      width: 170,
      className: 'max-w-[170px] h-[38px]',
      sorter: true,
      render: (_: any, record: IAccountMasterRow) => (
        <AppTooltip className='text-[14px]' title={DataViewer.localTimeBy(record?.updatedDate || record?.createdDate, FORMAT_DATE_EN_HH_MM_SS)}>
          {DataViewer.localTimeBy(record?.updatedDate || record?.createdDate, FORMAT_DATE_EN_HH_MM_SS)}
        </AppTooltip>
      )
    }
  ];

  const addNewAccountMaster = () => {
    navigate(CREATE_ACCOUNT_MASTER_URL);
  };

  return (
    <div className='account-master-list'>
      <div ref={headerPageRef} className='grid grid-cols-2 gap-4 page-list-header mb-[12px]'>
        <div className='title-24'>{t('sider:account_master_list')}</div>
        <div className='text-end'>
          {hasPermission(CREATE_NEW_ACCOUNT_MASTER_LIST, V) && (
            <BaseButton size='medium' icon={<IconAddNew style={{ verticalAlign: 'sub' }} />} onClick={addNewAccountMaster} className='float-right'>
              {t('account_master:buttons:title_add')}
            </BaseButton>
          )}
        </div>
      </div>
      {hasPermission(TABLE_ACCOUNT_MASTER_LIST, V) && (
        <div className='page-data'>
          <BaseTableV2
            tableHeader={generateFilterHeader()}
            apiEndpoint='/usr/users/search'
            apiMethod='POST'
            rowSelection={null}
            columns={columns}
            filterDefault={filter}
            scrollTable={{ maxHeight: '100vh - 239px' }}
            emptyDataWithoutFilter={
              <EmptyDataWithIcon
                title={{
                  content: 'account_master:list:no_data:title'
                }}
                description={{
                  content: 'account_master:list:no_data:description'
                }}
                button={
                  hasPermission(CREATE_NEW_ACCOUNT_MASTER_LIST, V)
                    ? {
                        label: 'account_master:buttons:title_add',
                        props: {
                          onClick: addNewAccountMaster
                        }
                      }
                    : null
                }
              />
            }
          />
        </div>
      )}
    </div>
  );
}
