import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Radio from './Radio';
import Table, { ITableItem } from './Table';
import Text from './Text';
import YearMonthDate from './YearMonthDate';

export interface IEducation {
  name: string;
  className?: string;
  readOnly?: boolean;
  config: IEducationConfig;
  isTranslateData?: boolean;
}

export interface IEducationConfig {
  count: number;
  bachelorOptions: {
    id: string;
    title: string;
    titleEn?: string;
    allowChangeLabel?: boolean;
  }[];
  countryAPI: string;
}

const Education: React.FC<IEducation> = ({ name, className, readOnly, config, isTranslateData }) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const value = form.getValues(name);

  const generateItems = ({ name, readOnly }: { name: string; readOnly?: boolean }) => {
    const items: ITableItem[][] = [];
    const headerFontClass = 'body-700 whitespace-pre-line';
    const headerClass = `!w-[100px]`;

    // Push title
    items.push([
      {
        content: <p className={headerFontClass}>{t('project:question_detail:education:title')}</p>,
        className: classNames(headerClass, 'bg-gray2 w-full text-center')
      }
    ]);

    // Push items for each group
    new Array(config.count).fill(null).forEach((_, index) => {
      items.push([
        {
          className: classNames(headerClass, 'bg-gray1'),
          content: (
            <p className={headerFontClass}>
              {t('project:question_detail:education:header')}
              {index + 1}
            </p>
          )
        }
      ]); // header
      items.push([
        {
          className: headerClass,
          content: <p className={headerFontClass}>{t('project:question_detail:education:school_name')}</p>,
          isTitle: true
        },
        {
          content: (
            <Text placeholder={t('project:question_detail:education:common_placeholder')} readOnly={readOnly} name={`${name}.${index}.schoolName`} />
          )
        }
      ]); // schoolName
      items.push([
        {
          className: headerClass,
          content: <p className={headerFontClass}>{t('project:question_detail:education:department_name')}</p>,
          isTitle: true
        },
        {
          content: (
            <Text placeholder={t('project:question_detail:education:common_placeholder')} readOnly={readOnly} name={`${name}.${index}.department`} />
          )
        }
      ]); // department
      items.push([
        {
          className: headerClass,
          content: <p className={headerFontClass}>{t('project:question_detail:education:bachelor_type')}</p>,
          isTitle: true
        },
        {
          content: (
            <Radio
              key={`${name}.${index}.bachelor.id`}
              options={
                !config?.bachelorOptions
                  ? []
                  : config?.bachelorOptions?.map((i) => {
                      return {
                        label: isTranslateData ? i.titleEn ?? i.title : i.title,
                        value: i.id,
                        couldChangeLabel: !!i.allowChangeLabel,
                        labelInputConfig: {
                          placeholder: '____',
                          value: value && value[index] ? value[index].bachelor?.label : ''
                        }
                      };
                    }) ?? []
              }
              readOnly={readOnly}
              name={`${name}.${index}.bachelor.id`}
              optionLabelName={`${name}.${index}.bachelor.title`}
              optionContentName={`${name}.${index}.bachelor.label`}
            />
          )
        }
      ]); // bachelor
      items.push([
        {
          className: headerClass,
          content: <p className={headerFontClass}>{t('project:question_detail:education:duration')}</p>,
          isTitle: true
        },
        {
          content: (
            <div className='flex'>
              <div className='w-[200px]'>
                <YearMonthDate readOnly={readOnly} name={`${name}.${index}.from`} />
              </div>
              ～
              <div className='w-[200px] ml-6'>
                <YearMonthDate readOnly={readOnly} name={`${name}.${index}.to`} />
              </div>
            </div>
          )
        }
      ]); // from - to
    });

    return items;
  };

  return (
    <div className={className}>
      <Table items={generateItems({ name, readOnly })} fixedWidth={[100, -1]} />
    </div>
  );
};

export default Education;
