import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Dropdown from './Dropdown';
import Radio from './Radio';
import Table, { ITableItem, ITableTypeProps } from './Table';
import YearMonthDate from './YearMonthDate';

export interface ITravelHistory extends Pick<ITableTypeProps, 'fixedWidth'> {
  name: string;
  className?: string;
  readOnly?: boolean;
  config: ITravelHistoryConfig;
  isTranslateData?: boolean;
}

export interface ITravelHistoryConfig {
  count: number;
  purposeOptions: {
    id: string;
    title: string;
    titleEn?: string;
    allowChangeLabel?: boolean;
  }[];
  countryAPI?: string;
  countryOptions?: {
    id: string;
    title: string;
    titleEn?: string;
    code?: string;
  }[];
}

const TravelHistory: React.FC<ITravelHistory> = ({ name, className, isTranslateData, readOnly, config, fixedWidth = [200, '30%', '40%'] }) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const value = form.getValues(name);

  const generateItems = ({ name, readOnly }: { name: string; readOnly?: boolean }) => {
    if (!config) {
      return [];
    }

    const items: ITableItem[][] = [];
    const headerFontClass = 'body-700 whitespace-pre-line';
    const headerClass = `!w-[100px]`;

    // Push title
    items.push([
      {
        content: <p className={headerFontClass}>{t('project:question_detail:travel_history:title')}</p>,
        className: classNames(headerClass, 'bg-gray2 w-full text-center')
      }
    ]);

    // Push table header
    items.push([
      {
        content: <p className={headerFontClass}>{t('project:question_detail:travel_history:time')}</p>,
        className: classNames(headerClass, 'bg-gray2 w-full text-center')
      },
      {
        content: <p className={headerFontClass}>{t('project:question_detail:travel_history:country')}</p>,
        className: classNames(headerClass, 'bg-gray2 w-full text-center')
      },
      {
        content: <p className={headerFontClass}>{t('project:question_detail:travel_history:purpose')}</p>,
        className: classNames(headerClass, 'bg-gray2 w-full text-center')
      }
    ]);

    // Push items for each row
    new Array(config.count).fill(null).forEach((_, index) => {
      const row: ITableItem[] = [];
      row.push({
        content: (
          <Table
            items={[
              [
                {
                  content: <p className={headerFontClass}>{t('project:question_detail:travel_history:from')}</p>
                },
                {
                  content: <YearMonthDate readOnly={readOnly} name={`${name}.${index}.from`} />
                }
              ],
              [
                {
                  content: <p className={headerFontClass}>{t('project:question_detail:travel_history:to')}</p>
                },
                {
                  content: <YearMonthDate readOnly={readOnly} name={`${name}.${index}.to`} />
                }
              ]
            ]}
            fixedWidth={[70, -1]}
          />
        )
      });
      row.push({
        content: (
          <Dropdown
            key={`${name}.${index}.country`}
            placeholder={t('project:question_detail:travel_history:common_placeholder')}
            readOnly={readOnly}
            name={`${name}.${index}.country`}
            keyLabel={'title'}
            keyValue={'id'}
            endPoint={config.countryOptions ? undefined : config.countryAPI}
            options={
              config.countryOptions
                ? config?.countryOptions.map((i) => ({
                    label: isTranslateData ? i.titleEn ?? i.title : i.title,
                    value: i.id,
                    code: i.code
                  }))
                : undefined
            }
            isCountryDropdown
          />
        )
      });

      // Travel purpose
      row.push({
        content: (
          <Radio
            key={`${name}.${index}.purpose.id`}
            options={
              !config?.purposeOptions
                ? []
                : config?.purposeOptions?.map((i) => {
                    return {
                      label: isTranslateData ? i.titleEn ?? i.title : i.title,
                      value: i.id,
                      couldChangeLabel: !!i.allowChangeLabel,
                      labelInputConfig: {
                        placeholder: '____',
                        value: value && value[index] ? value[index]?.purpose?.label : ''
                      }
                    };
                  }) ?? []
            }
            readOnly={readOnly}
            name={`${name}.${index}.purpose.id`}
            optionLabelName={`${name}.${index}.purpose.title`}
            optionContentName={`${name}.${index}.purpose.label`}
          />
        )
      });
      items.push(row);
    });

    return items;
  };

  return (
    <>
      <Table items={generateItems({ name, readOnly })} fixedWidth={fixedWidth} />
    </>
  );
};

export default TravelHistory;
