import { DefaultOptionType } from 'antd/es/select';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IStepTwo } from '@/pages/project-management/add-edit/models/scenarioB';

import { BaseCard } from '@/components/base-card/BaseCard';

import { ICountryOption } from '@/hooks/useOptionsGlobal';

import { DataViewer } from '@/utils/helpers/common';
import { Counters } from '@/utils/helpers/counters';

import CardInfo from './CardInfo';
import { convertObjDatetoString } from '@/components/common/date-input';

interface ITransferredInfoProps {
  data: IStepTwo;
  countries: ICountryOption[];
  genders: DefaultOptionType[];
}
const TransfereeInfo = ({ data, countries, genders }: ITransferredInfoProps) => {
  const { t } = useTranslation();
  const { projectApplicant } = data ?? {};

  const schema = {
    data: [
      'lastName',
      'firstName',
      'lastNameRomaji',
      'firstNameRomaji',
      'primaryNational',
      'secondaryNational',
      'dateOfBirth',
      'gender',
      'postalCode',
      'prefecture',
      'prefectureRoman',
      'city',
      'cityRoman',
      'suburb',
      'suburbRoman',
      'streetAddress',
      'apartmentName',
      'placeOfResidence',
      'currentCompany',
      'departureDate'
    ]
  };
  const counters = new Counters(schema, projectApplicant);
  const primaryNationalName = countries?.find((item) => item.value === projectApplicant?.primaryNational)?.label;
  const secondaryNationalName = countries?.find((item) => item.value === projectApplicant?.secondaryNational)?.label;
  const genderLabel = genders.find((item) => item.value === (projectApplicant?.gender as unknown))?.label;
  const columns = [
    {
      items: [
        { title: DataViewer.display(projectApplicant?.lastName), label: 'basic_information:surname' },
        { title: DataViewer.display(projectApplicant?.firstName), label: 'basic_information:given_name' },
        { title: DataViewer.display(projectApplicant?.lastNameRomaji), label: 'basic_information:last_name_romaji' },
        { title: DataViewer.display(projectApplicant?.firstNameRomaji), label: 'basic_information:first_name_romaji' },
        { title: DataViewer.display(primaryNationalName), label: 'basic_information:nationality' },
        { title: DataViewer.display(secondaryNationalName), label: 'basic_information:second_nationality' },
        { title: convertObjDatetoString(projectApplicant?.dateOfBirth), label: 'basic_information:date_of_birth' },
        { title: DataViewer.display(genderLabel), label: 'basic_information:gender' },
        { title: DataViewer.display(projectApplicant?.postalCode), label: 'basic_information:postal_code' },
        { title: DataViewer.display(projectApplicant?.prefecture), label: 'project:scenario_b:prefecture' },
        { title: DataViewer.display(projectApplicant?.prefectureRoman), label: 'project:scenario_b:prefecture_roman' }
      ]
    },
    {
      items: [
        { title: DataViewer.display(projectApplicant?.city), label: 'project:scenario_b:city' },
        { title: DataViewer.display(projectApplicant?.cityRoman), label: 'project:scenario_b:city_roman' },
        { title: DataViewer.display(projectApplicant?.suburb), label: 'project:scenario_b:town_area' },
        { title: DataViewer.display(projectApplicant?.suburbRoman), label: 'project:scenario_b:town_area_roman' },
        { title: DataViewer.display(projectApplicant?.streetAddress), label: 'project:scenario_b:street_address' },
        { title: DataViewer.display(projectApplicant?.apartmentName), label: 'project:scenario_b:office' },
        { title: DataViewer.display(projectApplicant?.placeOfResidence), label: 'basic_information:resident_registration_place' },
        { title: DataViewer.display(projectApplicant?.currentCompany), label: 'basic_information:local_contact_affiliation_company_name' },
        { title: DataViewer.displayTime(projectApplicant?.departureDate), label: 'basic_information:scheduled_travel_date' }
      ]
    }
  ];
  return (
    <BaseCard
      title={t('project:scenario_b:transferee_information')}
      total={schema.data.length}
      questions={counters.get('data')}
      className='scenario-b-card'
    >
      <CardInfo columns={columns} />
    </BaseCard>
  );
};

export default TransfereeInfo;
