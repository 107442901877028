import { DefaultOptionType } from 'antd/es/select';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { IDataDetail, OptionTypeMotPic } from '@/pages/project-management/detail/utils';
import { ProjectStatusTag } from '@pages/project-management/components/ProjectStatusTag';
import { PROCESS_TRAVEL_NAME } from '@pages/project-management/project-list-v2/models';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import { FormSelect } from '@/components/form-select/FormSelect';
import SelectionPreview from '@/components/form-select/SelectionPreview';
import { IconExport } from '@/components/icon-svg/IconSvg';

import useAuthorization from '@/hooks/useAuthorization';
import useMutation from '@/hooks/useMutation';

import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { downloadSingleFileFromURI } from '@/utils/helpers/fileHelper';
import { Ii18n } from '@/utils/interfaces/i18n';
import { PROJECT_STATUS_ID } from '@utils/constants/project';

import XIcon from '@assets/icons/X.svg';

import TemplateFilter from './TemplateFilter';

import '../ProjectDetail.scss';

interface HeaderProps {
  handleOnChangeMotPic?: (value: any) => void;
  handleCancelProject?: () => void;
  handleActiveProject?: () => void;
  optionsVisaCategory: DefaultOptionType[];
  formOptions: DefaultOptionType[];
  handleChangeTemplate: (value: any) => void;
  countryId: string;
  dataDetail: IDataDetail;
  userMotPicOptions: OptionTypeMotPic[];
}

const Title = ({ dataDetail }: { dataDetail: IDataDetail }) => {
  const { t }: Ii18n = useTranslation();
  const projectUnpublished = !dataDetail?.isPublished ? t('announcement:list:label:unpublished') : '';
  const isDoneAllTask =
    !!dataDetail.projectProcesses?.length &&
    !dataDetail.projectProcesses.some((process) => process.processName !== PROCESS_TRAVEL_NAME && !process.completedDate);
  const isCompleted = dataDetail?.statusId === PROJECT_STATUS_ID.COMPLETED || isDoneAllTask;

  return (
    <div className='title-24 whitespace-nowrap flex items-center'>
      {projectUnpublished && <span className='title-24 text-negative'>{projectUnpublished}</span>}
      {t('sider:project_details')}
      {dataDetail?.code && `（${dataDetail.code}）`}
      {isCompleted && <ProjectStatusTag statusId={PROJECT_STATUS_ID.COMPLETED} className='ml-[8px]' />}
    </div>
  );
};

const Header = ({
  handleOnChangeMotPic,
  handleCancelProject,
  handleActiveProject,
  optionsVisaCategory,
  formOptions,
  handleChangeTemplate,
  countryId,
  dataDetail,
  userMotPicOptions
}: HeaderProps) => {
  const { t }: Ii18n = useTranslation();
  const formMethods = useFormContext();
  const { isExternalRole, isInternalRole } = useAuthorization();
  const { id } = useParams();

  const { mutate: exportPdf } = useMutation(
    `/prj/projects/${id}/exportpdf`,
    {
      method: 'POST',
      bodyType: 'json',
      showToastError: true
    },
    {
      responseType: 'blob'
    }
  );

  const labelMotPic = useMemo(() => {
    const picName = formMethods.getValues('picName');
    if (isNil(dataDetail?.picStatus) || !picName) return '';
    return dataDetail.picStatus ? picName : `(${t('account_list:status_account:inactive')})${picName}`;
  }, [formMethods, dataDetail]);

  const exportPDF = async () => {
    if (!id) return;
    const res = await exportPdf({});
    if (res?.status === HTTP_STATUS_CODE.SUCCESS) {
      const applicationName = dataDetail.projectInfor?.fullName;
      const fileName = `${dataDetail.code}_${applicationName}_${t('project_detail:title')}.pdf`;
      downloadSingleFileFromURI(res?.data, fileName);
    }
  };

  const handePreChangeMotPic = async (value: any) => {
    const showPopup = await showBasePopup({
      type: 'confirm',
      title: t(`common:MSG_P_009:title`) || '',
      msg: t('common:MSG_P_009:content')
    });
    if (showPopup !== 'confirm') {
      if (!dataDetail.picId) return null;
      throw new Error('');
    }
    return value;
  };
  return (
    <>
      <div className='header-detail-project flex gap-[16px] h-[38px] mb-[12px] items-center justify-between'>
        <Title dataDetail={dataDetail} />
        <div className='flex items-center justify-end gap-[16px]'>
          <div className=' flex items-center justify-center gap-[8px]'>
            <div className='subtitle-14'>{t('project_detail:mot_pick:label')}</div>
            <div className='w-[199px]'>
              <SelectionPreview options={userMotPicOptions} value={formMethods.getValues('picId')} label={labelMotPic}>
                <AppTooltip title={labelMotPic}>
                  <div className='w-full'>
                    <FormSelect
                      allowClear={false}
                      name='picId'
                      label=''
                      options={userMotPicOptions}
                      placeholder={t('project_detail:mot_pick:placeholder')}
                      disabled={isExternalRole}
                      handleChange={handleOnChangeMotPic}
                      preChange={handePreChangeMotPic}
                    />
                  </div>
                </AppTooltip>
              </SelectionPreview>
            </div>
          </div>
          {isInternalRole && (
            <>
              <div className='flex items-center justify-end'>
                <div className='subtitle-14 mr-[8px]'>{t('project_detail:template_name')}</div>
                <TemplateFilter
                  optionsVisaCategory={optionsVisaCategory}
                  formOptions={formOptions}
                  countryId={countryId}
                  handleChangeTemplate={handleChangeTemplate}
                  defaultTemplateName={dataDetail.templateName ?? ''}
                />
              </div>
              <div className='flex items-center justify-end gap-[16px]'>
                <BaseButton
                  onClick={exportPDF}
                  icon={<IconExport style={{ verticalAlign: 'sub' }} />}
                  type='tertiary'
                  size='medium'
                  className='!py-2 !px-3 '
                >
                  {t('project_detail:buttons:export_pdf')}
                </BaseButton>
                {dataDetail.active ? (
                  <BaseButton
                    icon={<XIcon style={{ verticalAlign: 'sub', width: '22px', height: '22px' }} />}
                    type='outline-red'
                    size='medium'
                    className='!py-2 !px-3'
                    onClick={handleCancelProject}
                  >
                    <span> {t('project_detail:buttons:cancel_project')} </span>
                  </BaseButton>
                ) : (
                  <BaseButton type='primary' size='medium' className='!py-2 !px-3' onClick={handleActiveProject}>
                    <span> {t('project_detail:buttons:undo_cancel_project')} </span>
                  </BaseButton>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
