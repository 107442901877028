import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import lodash from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCard } from '@/components/base-card/BaseCard';
import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';
import { convertObjDatetoString, convertStringToObjDate } from '@/components/common/date-input';
import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import { IAlertNotificationState, setAlertNotification } from '@/redux/globalReducer';
import { cleanupTravelTypes, fetchTravelTypes } from '@/redux/project-v2/projectReducer';

import useCompany from '@/hooks/useCompany';
import useMotPic from '@/hooks/useMotPic';

import { ERROR_CODE, TYPE } from '@/utils/constants/AppConstants';
import { PROJECT_LIST_URL } from '@/utils/constants/RouteContants';
import { handleCommonError } from '@/utils/helpers/common';
import { formatDateTime } from '@/utils/helpers/globalHelper';
import { validateWithoutErrorTypes } from '@/utils/helpers/validateHelper';
import { getProjectInfo, postRegisterProjectInternal, putProjectInternal } from '@/utils/services/ProjectApiService';

import { GENDER, GO_WITH_FAMILY } from '../project-list/constants';
import ApplicantRepresentativeComponent from './components/ApplicantRepresentativeComponent';
import FamilyInformationComponent from './components/FamilyInformationComponent';
import LocalRepresentativeComponent from './components/LocalRepresentativeComponent';
import ProjectStakeholdersComponent from './components/ProjectStakeholdersComponent';
import RequestDetailComponent from './components/RequestDetailComponent';
import TrainingDestinationInformationComponent from './components/TrainingDestinationInformationComponent';
import TravelTypeComponent from './components/TravelTypeComponent';
import UploadFilesComponent from './components/UploadFilesComponent';
import VisaInformationComponent from './components/VisaInformationComponent';
import { PROJECT_DEFAULT_VALUE } from './contants';
import { IFormMethodRegisterProject, IProjectStakeholder } from './models';
import { FormRegisterProject } from './validation/FormValidation';

import './AddEditProject.scss';

const AddEditProjectInternal = () => {
  const navigate = useNavigate();
  const { userMotPicOptions } = useMotPic();
  const { companyOptions, loadingCompanyForAccount } = useCompany();
  const [showLocalRepresentative, setShowLocalRepresentative] = useState<boolean>(false);
  const [disabledAssignee, setDisabledAssignee] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<IFormMethodRegisterProject>(PROJECT_DEFAULT_VALUE);
  const [loading, setLoading] = useState(false);
  const applicantNameRef = useRef<any>(null);
  const applicantCompanyRef = useRef<any>(null);
  const projectApplicantRef = useRef<any>(null);
  const projectFamiliesRef = useRef<any>(null);
  const projectLocalSupportersRef = useRef<any>(null);
  const projectStakeholdersRef = useRef<any>(null);
  const projectAssignmentInfoRef = useRef<any>(null);
  const projectAttachmentsRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const isEdit = !!id;
  const watchFieldMotPic = ['motPic'];
  const watchFieldsApplicant = ['applicantName', 'applicantCompany'];
  const formMethod = useForm<IFormMethodRegisterProject>({
    mode: 'all',
    resolver: yupResolver(FormRegisterProject),
    defaultValues: () => getDetailProject()
  });
  const { isDirty, isSubmitSuccessful, isSubmitting } = formMethod.formState;
  useEffect(() => {
    dispatch(fetchTravelTypes() as any);
    return () => {
      dispatch(cleanupTravelTypes() as any);
    };
  }, [dispatch]);

  const getDetailProject = async () => {
    if (!isEdit) return PROJECT_DEFAULT_VALUE;
    try {
      const { data } = await getProjectInfo(String(id));
      const { code, applicantName, requestTypeId, supportTypeId, goWithFamily, projectLocalSupporters, note, travelTypeId, familyAttendanceTime } =
        data || {};
      const motPic = data.picId
        ? {
            value: data.picId,
            label: data.picName
          }
        : undefined;
      const applicantCompany = data.applicantCompanyId
        ? {
            value: data.applicantCompanyId,
            label: data.applicantCompanyName
          }
        : undefined;
      const projectApplicant = {
        ...data.projectApplicant,
        dateOfBirth: convertStringToObjDate(data.projectApplicant.dateOfBirth),
        departureDate: data.projectApplicant.departureDate ? dayjs(data.projectApplicant.departureDate).format('YYYY/MM/DD') : undefined,
        gender: data.projectApplicant.gender ? GENDER.female : data.projectApplicant.gender === false ? GENDER.male : null
      };
      const projectFamilies = data.projectFamilies.map((item: any) => ({
        ...item,
        dateOfBirth: convertStringToObjDate(item.dateOfBirth),
        gender: item.gender ? GENDER.female : item.gender === false ? GENDER.male : null
      }));
      const visaCategoryName = data.visaCategoryName ?? '';
      const projectStakeholders = data.projectStakeholders?.length ? data.projectStakeholders : PROJECT_DEFAULT_VALUE.projectStakeholders;
      const projectAttachments = data.projectAttachments.map((item: any) => ({
        ...item,
        status: 'done'
      }));
      const projectAssignmentInfo = {
        ...data.projectAssignmentInfo
      };
      const detailValues = {
        code,
        motPic,
        applicantName,
        applicantCompany,
        requestTypeId,
        supportTypeId,
        projectApplicant,
        goWithFamily: goWithFamily ? GO_WITH_FAMILY.YES : goWithFamily === false ? GO_WITH_FAMILY.NO : null,
        projectFamilies,
        projectAssignmentInfo,
        projectLocalSupporters,
        visaCategoryName,
        travelTypeId,
        note,
        projectAttachments,
        projectStakeholders,
        statusId: data.statusId,
        version: data.version,
        familyAttendanceTime
      };
      setDefaultValues(detailValues);
      return detailValues;
    } catch (error) {
      return PROJECT_DEFAULT_VALUE;
    }
  };
  const watchFields = (fields: any[]) => {
    const watchFields = formMethod.watch(fields);
    const watchFieldsValid = watchFields.filter((field: any) => field);
    return watchFieldsValid.length;
  };

  const handleSaveAsDraft = async () => {
    formMethod.trigger('projectApplicant.dateOfBirth');
    formMethod.trigger('projectFamilies');
    const hasErrorNotRequired = validateWithoutErrorTypes(formMethod.formState.errors, ['required', 'optionality']);

    if (hasErrorNotRequired) return;
    const showPopup = await showBasePopup({
      title: String(t('common:MSG_P_002_title')),
      msg: String(t('common:MSG_P_002_content')),
      type: TYPE_ICON.CONFIRM
    });
    if (showPopup !== TYPE_ICON.CONFIRM) return;
    setLoading(true);
    try {
      const payload = getPayloadRequest(formMethod.getValues(), true);
      const res = await (isEdit ? putProjectInternal(id, payload) : postRegisterProjectInternal(payload));
      if (res.status === 200) {
        // reset with keepValues to avoid form flicker old value
        formMethod.reset(undefined, { keepValues: true });
        setLoading(false);
        setTimeout(() => {
          navigate(PROJECT_LIST_URL);
          dispatch(
            setAlertNotification({
              show: true,
              type: TYPE.SUCCESS,
              message: t('common:MSG_C_003', { item: t('button:save_daft') })
            })
          );
        }, 500);
        return;
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const callback = (mess: IAlertNotificationState) => dispatch(setAlertNotification(mess));
      handleProjectException(error, callback);
    }
  };
  const onSubmitRegister = async (val: any) => {
    const showPopup = await showBasePopup({
      title: String(t('common:MSG_P_001_title')),
      msg: String(t('common:MSG_P_001_content')),
      type: TYPE_ICON.CONFIRM
    });
    if (showPopup !== TYPE_ICON.CONFIRM) return;
    setLoading(true);
    try {
      const payload = getPayloadRequest(val, false);
      const res = await (isEdit ? putProjectInternal(id, payload) : postRegisterProjectInternal(payload));
      if (res.status === 200) {
        setTimeout(() => {
          setLoading(false);
          navigate(PROJECT_LIST_URL);
          dispatch(
            setAlertNotification({
              show: true,
              type: TYPE.SUCCESS,
              message: t('common:MSG_C_003', { item: t('button:register') })
            })
          );
        }, 500);
        return;
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const callback = (mess: IAlertNotificationState) => dispatch(setAlertNotification(mess));
      handleProjectException(error, callback);
    }
  };

  const handleProjectException = (err: any, callback: (mess: IAlertNotificationState) => void) => {
    let errorMessage = t('common:MSG_C_014');
    const responseError = err?.request?.response;

    if (responseError) {
      const dataError = JSON.parse(responseError);

      switch (dataError?.errorCode) {
        case ERROR_CODE.VALIDATION:
          if (dataError?.fields[0]?.name === 'PicId' && dataError?.fields[0]?.errorCode === ERROR_CODE.USER_INACTIVE) {
            formMethod.setError('motPic', { type: 'VALIDATION', message: String(t('common:MSG_C_024')) });
            return;
          }
          break;
        default:
          break;
      }
    }

    handleCommonError(err, errorMessage, callback);
  };

  const onError = async (err: any) => {
    let firstInValidKey: string | undefined = Object.keys(err)?.[0];
    const formCollapseMapping = {
      applicantName: applicantNameRef,
      applicantCompany: applicantCompanyRef,
      projectApplicant: projectApplicantRef,
      projectLocalSupporters: projectLocalSupportersRef,
      projectAssignmentInfo: projectAssignmentInfoRef,
      projectFamilies: projectFamiliesRef,
      projectStakeholders: projectStakeholdersRef,
      projectAttachments: projectAttachmentsRef,
      note: projectAttachmentsRef
    };
    // Open all collapse invalid
    for (let key in err) {
      if (formCollapseMapping.hasOwnProperty(key) && key in formCollapseMapping) {
        const formRef = formCollapseMapping[key as keyof typeof formCollapseMapping];
        const idRef = formRef.current?.getIdElement?.();
        idRef && formRef.current?.setCollapse(idRef);
      }
    }
    // Scroll to first collapse invalid
    if (firstInValidKey !== undefined && formCollapseMapping.hasOwnProperty(firstInValidKey) && firstInValidKey in formCollapseMapping) {
      const formRef = formCollapseMapping[firstInValidKey as keyof typeof formCollapseMapping];
      const idRef = formRef.current?.getIdElement?.();
      if (idRef) {
        const element = document.getElementById(`base-collapse-${idRef}`);
        element?.scrollIntoView({ behavior: 'smooth' });
      }

      // applicantName and applicantCompany not working with autofocus of handleSubmit so need to handle manual
      if (['applicantName', 'applicantCompany'].includes(firstInValidKey)) {
        // Overwrite autofocus of handleSubmit
        setTimeout(() => {
          formRef.current?.focus?.();
          !formRef.current?.input && formRef.current?.blur?.();
        }, 50);
      }
    }
  };

  const handleRegister = () => {
    formMethod.handleSubmit(onSubmitRegister, onError)();
  };

  const handleOnChangeMotPic = (value: any) => {
    if (!value) {
      return;
    }
    if (value?.status) {
      formMethod.clearErrors('motPic');
      return;
    }
    formMethod.setError('motPic', { message: String(t('common:MSG_001_textbox', { field: t('project:add:projectPIC:name') })) });
  };
  const determineGender = (gender: any) => {
    if (gender === GENDER.female) return true;
    if (gender === GENDER.male) return false;
    return null;
  };
  const formatProjectFamilies = (formData: any) => {
    if (formData?.goWithFamily === GO_WITH_FAMILY.NO || !formData?.goWithFamily) return [];
    let projectFamilies =
      formData?.projectFamilies?.map((item: any, index: number) => ({
        ...item,
        dateOfBirth: convertObjDatetoString(item.dateOfBirth),
        gender: determineGender(item?.gender),
        displayOrder: index
      })) || [];

    defaultValues?.projectFamilies?.forEach((item: any) => {
      if (!projectFamilies.find((i: any) => i.id === item.id) && item.id) {
        projectFamilies.push({
          ...item,
          dateOfBirth: convertObjDatetoString(item.dateOfBirth),
          gender: determineGender(item?.gender),
          deleted: true
        });
      }
    });

    return projectFamilies;
  };

  const formatProjectLocalSupporters = (formData: any) => {
    if (!showLocalRepresentative) return [];

    let projectLocalSupporters =
      formData?.projectLocalSupporters?.map((item: any, index: number) => ({
        ...item,
        displayOrder: index,
        language: formData?.projectLocalSupporters[0]?.language || null,
        deleted: item?.deleted ?? false
      })) || [];

    defaultValues?.projectLocalSupporters?.forEach((item) => {
      if (!projectLocalSupporters.find((i: any) => i.id === item.id) && item.id) {
        projectLocalSupporters.push({ ...item, deleted: true });
      }
    });

    return projectLocalSupporters;
  };

  const formatProjectStakeholders = (formData: any) => {
    const uniqueStakeholders = lodash.uniqWith(
      formData?.projectStakeholders.filter((item: IProjectStakeholder) => item.stakeholderEmail),
      (accountA: IProjectStakeholder, accountB: IProjectStakeholder) =>
        accountA.stakeholderEmail === accountB.stakeholderEmail && !accountA.rejected && !accountB.rejected
    );

    let projectStakeholders = uniqueStakeholders.map((item: any, index: number) => ({
      ...item,
      displayOrder: index,
      deleted: item?.deleted ?? false
    }));

    defaultValues?.projectStakeholders?.forEach((item) => {
      if (!projectStakeholders.find((i) => i.id === item.id) && item.stakeholderEmail && item.id) {
        projectStakeholders.push({ ...item, deleted: true });
      }
    });

    return projectStakeholders;
  };

  const formatProjectAttachments = (formData: any) => {
    let projectAttachments = formData?.projectAttachments
      ?.filter((file: any) => file.status === 'done')
      ?.map((file: any, index: number) => ({
        ...file,
        displayOrder: index,
        deleted: file?.deleted ?? false
      }));

    defaultValues?.projectAttachments?.forEach((item) => {
      if (!projectAttachments.find((i: any) => i.id === item.id) && item.id) {
        projectAttachments.push({ ...item, deleted: true });
      }
    });

    return projectAttachments;
  };

  const getProjectApplicant = (formData: any) => ({
    ...formData?.projectApplicant,
    dateOfBirth: convertObjDatetoString(formData?.projectApplicant?.dateOfBirth),
    gender: determineGender(formData?.projectApplicant?.gender),
    departureDate: formData?.projectApplicant?.departureDate ? formatDateTime(formData?.projectApplicant?.departureDate) : null
  });

  const getProjectAssignmentInfo = (formData: any) => ({
    ...formData?.projectAssignmentInfo
  });

  const getPayloadRequest = (formData: any, isDraft: boolean) => ({
    picId: formData?.motPic?.value || null,
    picName: formData?.motPic?.label,
    picEmail: formData?.motPic?.email,
    picStatus: formData?.motPic?.status,
    applicantName: formData?.applicantName,
    applicantCompanyId: formData?.applicantCompany?.value,
    applicantCompanyName: formData?.applicantCompany?.label,
    requestTypeId: formData?.requestTypeId,
    supportTypeId: formData?.supportTypeId,
    goWithFamily: formData?.goWithFamily === GO_WITH_FAMILY.YES ? true : formData?.goWithFamily === GO_WITH_FAMILY.NO ? false : undefined,
    visaCategoryName: formData?.visaCategoryName ?? '',
    travelTypeId: formData?.travelTypeId,
    note: formData?.note,
    isDraft,
    statusId: defaultValues?.statusId,
    projectFamilies: formatProjectFamilies(formData),
    projectApplicant: getProjectApplicant(formData),
    projectAssignmentInfo: getProjectAssignmentInfo(formData),
    projectLocalSupporters: formatProjectLocalSupporters(formData),
    projectStakeholders: formatProjectStakeholders(formData),
    projectAttachments: formatProjectAttachments(formData),
    version: defaultValues?.version,
    familyAttendanceTime: formData?.familyAttendanceTime
  });

  return (
    <div id='project-managment'>
      <div className='project-container'>
        <FormProvider {...formMethod}>
          <div className='header sticky flex justify-between'>
            {isEdit ? (
              <div>
                <p className='title-24 whitespace-pre-line font-bold text-24'>
                  <span className='title-24 text-negative'>{t('basic_information:isDraft')}</span> {defaultValues?.code}
                </p>
              </div>
            ) : (
              <div className='title-24 whitespace-pre-line font-bold text-24'>{t('project:add:title')}</div>
            )}

            <Space size={16}>
              <div className='min-w-[120px]'>
                <BaseButton
                  disabled={(isEdit && !isDirty) || isSubmitting || loading}
                  block
                  size='medium'
                  type='tertiary'
                  onClick={() => navigate(PROJECT_LIST_URL)}
                >
                  {t('basic_information:cancel')}
                </BaseButton>
              </div>
              {
                <div className='min-w-[120px]'>
                  <BaseButton
                    disabled={(isEdit && !isDirty) || isSubmitting || loading}
                    type='secondary'
                    block
                    size='medium'
                    onClick={handleSaveAsDraft}
                  >
                    {t('button:save_daft')}
                  </BaseButton>
                </div>
              }
              <div className='min-w-[120px]'>
                <BaseButton block size='medium' disabled={isSubmitting || loading} onClick={handleRegister}>
                  <span> {t('button:register')} </span>
                </BaseButton>
              </div>
            </Space>
          </div>
          <div className='project-form project-tabs-content px-5 py-4 rounded-[10px] border-[#DBDBE0] gap-3'>
            <Row gutter={16}>
              <Col span={12}>
                <BaseCard
                  title={t('project:add:projectPIC:project_title')}
                  classTitle='text-bold text-[18px] font-semibold text-lnk'
                  total={1}
                  questions={watchFields(watchFieldMotPic)}
                >
                  <FormSelect
                    labelInValue
                    name='motPic'
                    label={String(t('project:add:projectPIC:name'))}
                    placeholder={String(t('project:add:please_select_field', { field: t('project:add:projectPIC:name') }))}
                    options={userMotPicOptions}
                    handleChange={handleOnChangeMotPic}
                  />
                </BaseCard>
              </Col>
              <Col span={12}>
                <BaseCard
                  gap-2
                  title={t('project:add:applicant:applicant_information')}
                  classTitle='text-bold text-[18px] font-semibold text-lnk'
                  total={2}
                  questions={watchFields(watchFieldsApplicant)}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <FormInput
                        ref={applicantNameRef}
                        required={true}
                        defaultValue={formMethod.getValues('applicantName')}
                        name='applicantName'
                        labelTx='project:add:applicant:applicant_name'
                        placeholderTx={String(t('placeholder:text_box', { field: t('project:add:applicant:applicant_name') }))}
                      />
                    </Col>
                    <Col span={12}>
                      <FormSelect
                        labelInValue
                        ref={applicantCompanyRef}
                        name='applicantCompany'
                        required={true}
                        label={String(t('project:add:applicant:applicant_company'))}
                        placeholder={String(t('project:add:please_select_field', { field: t('project:add:applicant:applicant_company') }))}
                        options={companyOptions}
                        handleChange={() => {
                          const guid = uuid();
                          formMethod.setValue('projectStakeholders', [
                            {
                              stakeholderEmail: '',
                              canDelete: true,
                              uid: guid
                            }
                          ]);
                          formMethod.clearErrors(`projectStakeholders.${0}.stakeholderEmail`);
                        }}
                        loading={loadingCompanyForAccount}
                      />
                    </Col>
                  </Row>
                </BaseCard>
              </Col>
            </Row>

            <RequestDetailComponent
              formMethod={formMethod}
              setShowLocalRepresentative={setShowLocalRepresentative}
              setDisabledAssignee={setDisabledAssignee}
            />
            <ApplicantRepresentativeComponent ref={projectApplicantRef} formMethod={formMethod} />
            <FamilyInformationComponent ref={projectFamiliesRef} formMethod={formMethod} />
            <TrainingDestinationInformationComponent ref={projectAssignmentInfoRef} formMethod={formMethod} disabledAssignee={disabledAssignee} />
            {showLocalRepresentative && <LocalRepresentativeComponent ref={projectLocalSupportersRef} formMethod={formMethod} />}
            <VisaInformationComponent formMethod={formMethod} />
            <TravelTypeComponent travelTypePath='travelTypeId' />
            <ProjectStakeholdersComponent companyOptions={companyOptions} ref={projectStakeholdersRef} formMethod={formMethod} />
            <UploadFilesComponent ref={projectAttachmentsRef} formMethod={formMethod} />
          </div>
        </FormProvider>
      </div>
      <ConfirmCancelEditing condition={isDirty && !isSubmitSuccessful} />
    </div>
  );
};

export default AddEditProjectInternal;
