import { DatePicker } from 'antd';
import { PickerDateProps } from 'antd/es/date-picker/generatePicker';
import locale from 'antd/es/date-picker/locale/ja_JP';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showBasePopup } from '@/components/base-popup/BasePopup';

import { FORMAT_DATE_EN, FORMAT_DATE_JP, REQUIRED_DOT } from '@/utils/constants/AppConstants';
import { checkValidDate } from '@/utils/helpers/globalHelper';
import { disableMinMaxDate } from '@/utils/helpers/validateHelper';
import { ColorDefault } from '@/utils/themes/color';

import CalendarBlank from '@/assets/icons/CalendarBlank.svg';

import './index.scss';

export type BaseDatePickerPropsType = PickerDateProps<Dayjs> & {
  hideBorder?: boolean;
  labelInput?: string;
  labelClassName?: string;
  required?: boolean;
  //   onChange?: (e: Dayjs | null) => void;
  //   preChange?: (val: any) => Promise<any>;
  //   disabledFeatureDay?: (data: boolean) => void;
  triggerShowModal?: 'icon' | 'both';
  needConfirmChange?: boolean;
  confirmMsg?: string;
  confirmTitle?: string;
  readOnly?: boolean;
  labelSeparator?: string;
  padding?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
};

const BaseDatePicker: React.FC<BaseDatePickerPropsType> = ({
  triggerShowModal = 'both',
  required,
  hideBorder,
  className,
  labelInput,
  labelSeparator = ':',
  labelClassName,
  disabledDate,
  defaultValue,
  needConfirmChange,
  confirmMsg,
  confirmTitle,
  onChange,
  onBlur,
  readOnly,
  allowClear,
  padding,
  value: valueInput,
  ...restProps
}) => {
  // state
  const [shouldDisplayPopup, setShouldDisplayPopup] = useState<boolean>(false);
  const [t] = useTranslation();
  const [dateValue, setDateValue] = useState<Dayjs | null>((valueInput && dayjs(valueInput)) || (defaultValue && dayjs(defaultValue)) || null);

  const generatePaddingClass = (padding: BaseDatePickerPropsType['padding']) => {
    if (padding) {
      const { left, right, top, bottom } = padding;
      return `pl-${left ?? 0} pr-${right ?? 0} pt-${top ?? 0} pb-${bottom ?? 0}`;
    }
    return '';
  };

  // func
  const getInputDate = (value: Dayjs | string) => {
    if (value) {
      if (typeof value === 'string') {
        const date = dayjs(value);
        if (date.isValid() && checkValidDate(value, FORMAT_DATE_EN)) return date;
        return null;
      }
      return value;
    }
    return null;
  };

  const handleChange = async (value: Dayjs | null, dateString: string) => {
    if (readOnly) {
      return;
    }

    if (needConfirmChange) {
      const showPopup = await showBasePopup({
        type: 'confirm',
        title: confirmTitle ?? '',
        msg: confirmMsg ?? ''
      });
      if (showPopup !== 'confirm') {
        return;
      }
    }

    let date = value ? dayjs(value).hour(12).minute(1) : null;
    if (disabledDate && date && disabledDate(date)) {
      date = null;
    }
    setDateValue(date);
    onChange && onChange(date, dateString);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const dateString = e.target.value;
    const date = getInputDate(e.target.value);
    if (date !== dateValue) {
      handleChange(date, dateString);
    }
    onBlur && onBlur(e);
  };

  useEffect(() => {
    if (valueInput === null) {
      setDateValue(null);
    }
    if (valueInput) {
      setDateValue((valueInput && dayjs(valueInput)) || (defaultValue && dayjs(defaultValue)) || null);
    }
  }, [valueInput]);

  // render
  return (
    <div className={classNames('date-container flex w-full body-400', 'items-baseline')}>
      {labelInput && (
        <div>
          <span className={labelClassName ?? ''}>
            {labelInput}
            {labelSeparator}{' '}
          </span>
          {required && (
            <span className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </span>
          )}
        </div>
      )}

      <div className={classNames('input-container', hideBorder ? 'non-border' : '')}>
        <DatePicker
          bordered={false}
          popupClassName={!shouldDisplayPopup || readOnly ? 'hidden' : ''}
          onOpenChange={(open) => {
            if (triggerShowModal === 'both') {
              setShouldDisplayPopup(open);
              return;
            }
            !open && setShouldDisplayPopup(open);
          }}
          className={classNames(className, 'input-date', generatePaddingClass(padding))}
          placement='bottomLeft'
          size='large'
          locale={locale}
          format={t('placeholder:date') ?? FORMAT_DATE_JP}
          disabledDate={(e) => {
            return (disabledDate && disabledDate(e)) || disableMinMaxDate(e);
          }}
          suffixIcon={
            !readOnly ? (
              <span>
                <CalendarBlank />
              </span>
            ) : (
              <></>
            )
          }
          onBlur={handleBlur}
          onChange={handleChange}
          value={dateValue}
          allowClear={readOnly ? false : allowClear}
          inputReadOnly={readOnly}
          {...restProps}
        />
      </div>
    </div>
  );
};

export default BaseDatePicker;
