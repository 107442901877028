import BaseDatePicker, { BaseDatePickerPropsType } from '@/components/common/base-date-picker';
import FormItem from '@/components/common/form-item';

interface IDateTimeProps extends BaseDatePickerPropsType {
  readOnly?: boolean;
  name: string;
}

const Datetime: React.FC<IDateTimeProps> = ({ readOnly, placeholder, name, ...restProps }) => {
  const nameEn = name.replace('answeredEn', 'answered');
  if (nameEn) {
    return (
      <FormItem name={nameEn}>
        <BaseDatePicker
          id={nameEn}
          className='h-full'
          readOnly={readOnly}
          placeholder={readOnly ? '' : placeholder}
          hideBorder
          labelSeparator='：'
          {...restProps}
        />
      </FormItem>
    );
  }
  return (
    <BaseDatePicker className='h-full' readOnly={readOnly} placeholder={readOnly ? '' : placeholder} hideBorder labelSeparator='：' {...restProps} />
  );
};

export default Datetime;
