import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import classNames from 'classnames';
import { CSSProperties, forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useMutation from '@/hooks/useMutation';

import { REQUIRED_DOT, SEARCH_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { arrayToDict, convertFullWidthToHalfWidth } from '@/utils/helpers/globalHelper';
import { IMutationRequest } from '@/utils/interfaces/api';
import { ColorDefault } from '@/utils/themes/color';

import CaretDown from '../../assets/icons/CaretDown.svg';

import './SelectCustom.scss';

export interface IAddOptionRequest extends IMutationRequest {
  mappingParams: (inputValue: string) => Record<string, any>;
}

export interface ISelectCustomProps extends SelectProps {
  label?: string;
  height?: number;
  required?: boolean;
  error?: any;
  msgErr?: string;
  maxLengthInputSearch?: number;
  allowAddOnSearch?: boolean;
  addRequest?: IAddOptionRequest;
  onAddNew?: (item: DefaultOptionType) => void;
  onAddError?: () => void;
  preChange?: (val: any) => Promise<any>;
  optionLoading?: boolean;
  labelPosition?: 'top' | 'left';
  hideBorder?: boolean;
  readOnly?: boolean;
  addOptionKeys?: string[];
  optionRender?: (option: DefaultOptionType) => JSX.Element;
  allowValueNotInOptions?: boolean;
}

export const SelectCustom = forwardRef((props: ISelectCustomProps, ref: any) => {
  // props
  const {
    value,
    label,
    height = 38,
    required = false,
    disabled,
    allowClear = true,
    error = false,
    msgErr,
    onSearch,
    loading,
    maxLengthInputSearch = SEARCH_MAX_LENGTH,
    addRequest,
    allowAddOnSearch,
    onSelect,
    onChange,
    filterOption,
    options,
    preChange,
    onAddError,
    onDropdownVisibleChange,
    optionLoading, // handle init loading option done
    labelPosition = 'top',
    placement = 'bottomLeft',
    size = 'large',
    hideBorder = false,
    addOptionKeys = [],
    readOnly,
    status,
    optionRender,
    onAddNew,
    allowValueNotInOptions = true,
    mode,
    ...rest
  } = props;
  const emptyValue = mode ? [] : null;
  const [open, setOpen] = useState(rest?.open);
  const [selectOptions, setSelectOptions] = useState<DefaultOptionType[]>(options ?? []);
  const optionDict = useMemo(() => {
    return arrayToDict(options ?? [], 'value');
  }, [options]);
  const ID_REGISTER = '----';
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [valueSelect, setValueSelect] = useState<string | string[] | undefined | null>(value ?? emptyValue);
  const showData = loading ? false : allowValueNotInOptions || (!mode ? !!selectOptions.find((i) => i.value === valueSelect) : selectOptions.length);

  const {
    loading: loadingNew,
    error: errorNew,
    mutate: addNew,
    clearError
  } = useMutation(addRequest?.apiEndPoint ?? '', {
    method: addRequest?.method ?? 'POST',
    bodyType: 'json',
    showToastError: true,
    paramMsg: { field: label }
  });

  const customFilterOption = filterOption
    ? filterOption
    : (input: string, option?: DefaultOptionType) => {
        const labelToCheck = ((optionDict[option?.value ?? '']?.label as string) ?? '').trim();
        if (!labelToCheck) {
          return true;
        }
        const formattedLabel = convertFullWidthToHalfWidth(labelToCheck) ?? '';
        const lowercaseInput = input.toLowerCase().trim();
        const formattedLabelLowercase = formattedLabel.toLowerCase();
        return formattedLabelLowercase.startsWith(convertFullWidthToHalfWidth(lowercaseInput));
      };

  const renderOptions = (ops: DefaultOptionType[]) => {
    return ops.map((i) => {
      const newLabel = allowAddOnSearch && i.value === ID_REGISTER ? `${String(i.label).trim()} (${t('button:add_new')})` : i.label;
      return {
        ...i,
        label: optionRender && typeof newLabel === 'string' ? optionRender(i) : newLabel
      };
    });
  };

  // state
  const [t] = useTranslation();
  const [focused, setFocused] = useState<boolean>(false);
  const selectStyle: CSSProperties = {
    minHeight: `${height}px`,
    height: `${height}px`
  };

  const borderColor = useMemo(() => {
    switch (true) {
      case disabled:
        return ColorDefault.gray2;
      case error:
        return ColorDefault.negative;
      case focused:
        return ColorDefault.action;
      default:
        return ColorDefault.gray3;
    }
  }, [disabled, error, focused]);

  const nonBorderStyle: CSSProperties = {
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  };

  const containerStyle: CSSProperties = {
    borderColor,
    backgroundColor: disabled ? ColorDefault.gray1 : ColorDefault.white,
    ...(hideBorder ? nonBorderStyle : {})
  };

  const notFoundContent = useMemo(() => {
    if (!loading) {
      return <div className='p-5 text-center'>{t('common:MSG_038')}</div>;
    }
    return <div className='p-5 text-center'></div>;
  }, [loading, searchValue, t]);

  useEffect(() => {
    setSelectOptions(options ?? []);
    addRequest && clearError();
  }, [options]);

  const handleSearch = (val: string) => {
    const str = maxLengthInputSearch ? val.slice(0, maxLengthInputSearch) : val;
    if (allowAddOnSearch) {
      const searchString = val.toString().trim();
      const newList = [...(selectOptions ?? [])].filter((i) => i.value !== ID_REGISTER);
      const isInOptions = newList.some((i) => i.label === searchString);
      if (isInOptions || !searchString) {
        setSelectOptions([...newList]);
      } else {
        setSelectOptions([{ value: ID_REGISTER, label: searchString }, ...newList]);
      }
      addRequest && clearError();
    }
    setSearchValue(str);
    onSearch && onSearch(str);
  };

  const handleSelect = async (val: any, op: DefaultOptionType) => {
    if (allowAddOnSearch && addRequest && val === ID_REGISTER) {
      const label = searchValue ?? '';
      if (addRequest && addNew) {
        const result = await addNew({
          ...addRequest.defaultParams,
          ...addRequest.mappingParams(label)
        });

        if (result?.status === HTTP_STATUS_CODE.SUCCESS && typeof result?.data === 'string') {
          const item: DefaultOptionType = {
            ...op,
            label,
            value: result?.data,
            name: label
          };
          addOptionKeys.forEach((key) => {
            item[key] = label;
          });
          setSelectOptions([{ ...item }, ...selectOptions.filter((i) => i.value !== ID_REGISTER)]);
          setValueSelect(val);
          onChange && onChange(item.value, item);
          onAddNew && onAddNew(item);
        } else {
          setValueSelect(null);
          setSearchValue(label);
          onAddError && onAddError();
          onSelect && onSelect(val, op);
          setOpen(true);
        }
      }
    }

    onSelect && onSelect(val, op);
  };

  const handleChange = async (val: any, options: DefaultOptionType | DefaultOptionType[]) => {
    try {
      let newValue = val;
      if (preChange) newValue = await preChange(val);
      setValueSelect(newValue);
      if (allowAddOnSearch && newValue === ID_REGISTER) {
        return;
      }
      onChange && onChange(newValue, options);
    } catch (error) {}
  };

  useEffect(() => {
    setValueSelect(value ?? emptyValue);
  }, [value]);

  // render
  return (
    <div className={classNames('select-container-phase2', labelPosition === 'left' ? 'flex space-x-2' : 'flex-col')}>
      {label && (
        <div className='label-container font-medium mr-2 flex items-center'>
          <span>{label ?? ''}</span>
          {required && (
            <span className='require' color={ColorDefault.negative}>
              {REQUIRED_DOT}
            </span>
          )}
        </div>
      )}
      <div
        className={classNames(
          `input-container flex flex-nowrap`,
          disabled ? 'select-cursor-not-allowed' : 'select-cursor-pointer',
          labelPosition === 'left' ? 'grow' : '',
          hideBorder ? `non-border` : ''
        )}
        style={containerStyle}
      >
        <Select
          showSearch
          value={!optionLoading && !loading && showData ? valueSelect : emptyValue}
          searchValue={searchValue}
          options={renderOptions(selectOptions)}
          ref={ref}
          listHeight={250}
          notFoundContent={notFoundContent}
          suffixIcon={
            <CaretDown
              onClick={() => {
                setOpen(!open);
                onDropdownVisibleChange && onDropdownVisibleChange(!open);
              }}
            />
          }
          maxTagPlaceholder
          bordered={false}
          disabled={disabled}
          loading={loading || loadingNew}
          open={!readOnly && open}
          style={selectStyle}
          popupClassName={classNames('!bg-white')}
          dropdownStyle={hideBorder ? nonBorderStyle : {}}
          className={classNames(`select-input`, rest?.className)}
          onKeyDown={(e) => {
            if (readOnly || e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              return false;
            }
          }}
          onDropdownVisibleChange={(open: boolean) => {
            if ((loadingNew || errorNew) && !open) {
              addRequest && clearError();
              return;
            }
            if (!open && allowAddOnSearch) {
              setSelectOptions(selectOptions.filter((el) => el.value !== ID_REGISTER));
            }
            if (!open) {
              setSearchValue('');
            }
            setOpen(open);
            onDropdownVisibleChange && onDropdownVisibleChange(open);
          }}
          onChange={handleChange}
          onSearch={handleSearch}
          onBlur={() => {
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
          onSelect={handleSelect}
          filterOption={customFilterOption}
          allowClear={allowClear}
          placement={placement}
          size={size}
          status='error'
          mode={mode ?? undefined}
          {...rest}
        />
      </div>
      {error && <div className='h-[26px] mt-2 text-negative'>{msgErr}</div>}
    </div>
  );
});
