import * as yup from 'yup';

import { CustomError } from '@/pages/stakeholder-management/FormValidation';

import {
  MAX_CAPACITY_FILE,
  NOTE_MAX_LENGTH,
  NOTE_MIN_LENGTH,
  TEXT_FIELD_MIN_LENGTH,
  TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH,
  UPLOAD_STATUS
} from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

import { REQUEST_DETAIL } from '../scenario/type-d/contants';

export const FormValidationScenarioD = yup.object().shape({
  steps: yup.array().of(
    yup.object().shape({
      phoneNumber: yup
        .string()
        .max(
          TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'inquiry:phone_label', limited: TEXT_FIELD_PHONE_NUMBER_MAX_LENGTH }
          })
        )
        .halfSize(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_028'
          })
        ),
      requestDetail: yup.array().test('requestDetail-validation', 'requestDetail invalid', async (value, ctx: any) => {
        try {
          if (!value || value.length < NOTE_MIN_LENGTH) {
            throw new CustomError({
              type: 'min',
              message: stringifyObjectValidateYup({ keyT: 'project:scenario:title_scenario' })
            });
          }
        } catch (error: any) {
          return ctx.createError({ type: error.type, message: error.message });
        }

        return true;
      }),
      noteSpecial: yup.string().test('noteSpecial-validation', 'noteSpecial invalid', async (value, ctx: any) => {
        const haveTranslation = (ctx.from[0]?.value.requestDetail ?? []).includes(REQUEST_DETAIL.TRANSLATION);
        if (haveTranslation) {
          try {
            if (!value) {
              throw new CustomError({
                type: 'required',
                message: stringifyObjectValidateYup({
                  keyT: 'common:MSG_001_textbox',
                  optionsTx: { field: 'basic_information:translation_language' }
                })
              });
            }
            if (value.length > MAX_CAPACITY_FILE) {
              throw new CustomError({
                type: 'max',
                message: stringifyObjectValidateYup({
                  keyT: 'common:MSG_C_011',
                  optionsTx: { field: 'basic_information:translation_language', limited: MAX_CAPACITY_FILE }
                })
              });
            }
          } catch (error: any) {
            return ctx.createError({ type: error.type, message: error.message });
          }
        }

        return true;
      }),
      requestContent: yup
        .string()
        .nullable()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'basic_information:contact_information' }
          })
        )
        .min(
          TEXT_FIELD_MIN_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'basic_information:contact_information', limited: TEXT_FIELD_MIN_LENGTH }
          })
        )
        .htmlStrippedMaxLength(
          NOTE_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'basic_information:contact_information', limited: NOTE_MAX_LENGTH }
          })
        ),
      projectAttachments: yup.array().of(
        yup.object().shape({
          status: yup.string().test(
            'file-invalid',
            stringifyObjectValidateYup({
              keyT: 'common:MSG_FILE_INVALID'
            }),
            (value) => {
              return value === UPLOAD_STATUS.DONE;
            }
          )
        })
      )
    })
  )
});

export const FormValidationRegisterStep1 = yup.object().shape({});
