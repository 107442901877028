import CommentWidget from '@/widgets/Comment';
import { IProjectDetail } from '@/widgets/Comment/models';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, createContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { TASK_TYPE } from '@/pages/template-management/view/constants';
import { TaskDetailQuickUpdateForm } from '@pages/project-management/task/view/components/TaskDetailQuickUpdateForm';

import { BaseButton } from '@/components/base-button/BaseButton';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { FILE_ATTACHMENT_STATUS } from '@/components/form-attachment/FileAttachment';

import useAuthorization from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';
import useResize from '@/hooks/useResize';

import { API } from '@/utils/constants/Apis';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { EDIT_TASK_OF_PROJECT_URL } from '@/utils/constants/RouteContants';
import { Ii18n } from '@/utils/interfaces/i18n';

import PencilSimpleLine from '@/assets/icons/PencilSimpleLine.svg';

import { ATTACHMENT_STATUS, DEFAULT_DETAIL_TASK_OF_PROJECT } from '../constants';
import { IDetailTaskData, IFormDetailTask, ProjectTaskPic, TaskRequireDocument, TaskRequireDocumentAttachmentFile } from '../models';
import { FormDetailTaskData } from './Validation';
import DetailTaskExternal from './components/DetailTaskExternal';
import DetailTaskInternal from './components/DetailTaskInternal';
import HeaderTaskDetail from './components/Header';

import './index.scss';

export const TaskDetailContext = createContext<{
  isWarningDeadline: boolean;
  setIsWarningDeadline: Dispatch<SetStateAction<boolean>>;
}>({
  isWarningDeadline: false,
  setIsWarningDeadline: () => {}
});

const ViewTaskDetail = () => {
  const { t }: Ii18n = useTranslation();
  const { idProject = '', idTask } = useParams();
  const { isExternalRole, isInternalRole } = useAuthorization();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [detailData, setDetailData] = useState<IDetailTaskData>(DEFAULT_DETAIL_TASK_OF_PROJECT);
  const { data, fetchData } = useFetch<any>(API.GET_DETAIL_TASK(idProject ?? '', idTask ?? ''), 'GET');
  const { data: project } = useFetch<IProjectDetail>(API.GET_PROJECT_DETAIL(idProject), 'GET', undefined, undefined, { autoFetch: !!idProject });
  const headerRef = useRef<HTMLDivElement>(null);
  const sizes = useResize({ elRef: headerRef, delay: 1000 });
  const [hasComment, setHasComment] = useState<boolean>(false);
  const [isWarningDeadline, setIsWarningDeadline] = useState(false);

  const form = useForm<IFormDetailTask>({
    mode: 'all',
    shouldUnregister: false,
    resolver: yupResolver(FormDetailTaskData),
    defaultValues: {}
  });

  const handleCancelUploadFileExternal = async (e: any) => {
    e.stopPropagation();
    const showPopup = await showBasePopup({
      type: 'discard',
      title: t('common:MSG_C_002:title') ?? '',
      msg: t('common:MSG_C_002:description') ?? ''
    });
    if (showPopup === 'confirm') {
      fetchData();
    }
  };

  const { mutate: updateTaskProject } = useMutation(API.UPDATE_TASK_OF_PROJECT(idTask ?? ''), {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:submit') }) ?? ''
  });

  const getFormAndOriginalFiles = (form: any[] = [], original: any[] = []) => {
    // detail and form: no item
    if (!form?.length && !original?.length) return [];
    // detail: no item, form: have item
    if (!original?.length && !!form?.length) {
      return form.map((item: any, index: number) => {
        return {
          ...item,
          displayOrder: index + 1
        };
      });
    }
    // detail: have item, form: no item
    if (!!original?.length && !form?.length) {
      return original.map((item: any) => {
        return {
          ...item,
          deleted: true
        };
      });
    }
  };

  const getTaskAttachmentFiles = (form: TaskRequireDocumentAttachmentFile[] = [], original: TaskRequireDocumentAttachmentFile[] = []) => {
    const filesResult = getFormAndOriginalFiles(form, original);
    if (filesResult?.length) return filesResult;

    // detail and form: have item
    if (!!original?.length && !!form?.length) {
      let removeAttachmentFiles: TaskRequireDocumentAttachmentFile[] = [];
      original?.forEach((item: any) => {
        const found = form?.find((i: any) => i.blobPath === item.blobPath);
        if (!found) {
          removeAttachmentFiles.push({
            ...item,
            deleted: true
          });
        }
      });

      return [
        ...removeAttachmentFiles,
        ...form.map((item: TaskRequireDocumentAttachmentFile, index: number) => {
          return {
            ...item,
            requestApproval: item.status === ATTACHMENT_STATUS.WAITING,
            displayOrder: index + 1
          };
        })
      ];
    }
  };

  const handleUploadFileExternal = (e: any) => {
    e.stopPropagation();
    form.handleSubmit(async () => {
      const { taskRequireDocuments } = form.getValues();
      const detailRequiredFiles = detailData.taskRequireDocuments || [];
      detailRequiredFiles.forEach((item: TaskRequireDocument, index: number) => {
        const files = taskRequireDocuments[index]?.taskRequireDocumentAttachmentFiles || [];
        const originalFiles = item.taskRequireDocumentAttachmentFiles;
        const results = getTaskAttachmentFiles(files, originalFiles);
        item.taskRequireDocumentAttachmentFiles = results || [];
      });

      const documentPayload = [...detailRequiredFiles];
      const { projectProcessId, taskId, version } = detailData;
      const payload = {
        projectId: idProject,
        processId: projectProcessId,
        taskMasterId: taskId,
        taskRequireDocuments: documentPayload,
        version
      };
      const result = await updateTaskProject(payload);
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
        setTimeout(() => {
          form.reset((pre) => ({ ...pre, isDirty: false }));
          fetchData();
        }, 0);
      }
    })();
  };

  useEffect(() => {
    setLoading(true);
    if (data) {
      setDetailData(data);
      const { projectTaskPics } = data;
      const convertToOptions = (item: ProjectTaskPic) => ({
        id: item.id,
        label: item?.picStatus ? item.picName : `(${t('account_list:status_account:inactive')})${item.picName}`,
        value: item.picEmail
      });
      const picValues = projectTaskPics?.map(convertToOptions) ?? [];
      const requiredFiles = data.taskRequireDocuments.map((item: TaskRequireDocument) => {
        return {
          ...item,
          taskRequireDocumentAttachmentFiles: item.taskRequireDocumentAttachmentFiles.map((file: TaskRequireDocumentAttachmentFile) => {
            return {
              ...file,
              status: isInternalRole ? FILE_ATTACHMENT_STATUS.VIEW : FILE_ATTACHMENT_STATUS.EDIT
            };
          })
        };
      });
      form.reset({
        deadline: !data?.deadline ? null : dayjs(data.deadline),
        picId: picValues,
        status: data?.prevStatusId || null,
        taskRequireDocuments: requiredFiles
      });
      setLoading(false);
    }
  }, [data]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const detectResizeCommentWidget = debounce(() => {
    if (!sizes?.height || !wrapperRef?.current) return;
    wrapperRef.current?.style?.setProperty('--header-height', `${sizes.height}px`);
    wrapperRef.current?.style?.setProperty('--out-height', `${sizes.height + 98}px`);
  }, 500);

  useEffect(() => {
    detectResizeCommentWidget();
  }, [sizes]);

  const goToEditPage = async () => {
    if (!hasComment) {
      navigate(EDIT_TASK_OF_PROJECT_URL(idProject ?? '', idTask ?? ''));
      setHasComment(false);
    } else {
      const showPopup = await showBasePopup({
        type: 'discard',
        title: t('common:MSG_C_005:title') ?? '',
        msg: t('common:MSG_C_005:description') ?? ''
      });
      if (showPopup !== 'confirm') return;
      navigate(EDIT_TASK_OF_PROJECT_URL(idProject ?? '', idTask ?? ''));

      setHasComment(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className='text-center'>
          <Spin size='large' />
        </div>
      ) : (
        <TaskDetailContext.Provider value={{ isWarningDeadline, setIsWarningDeadline }}>
          <div className='view-task-detail-project gap-[12px]' ref={wrapperRef}>
            <FormProvider {...form}>
              <HeaderTaskDetail
                ref={headerRef}
                detailData={{
                  ...project,
                  ...detailData
                }}
                fetchData={fetchData}
              />
              {isInternalRole && (
                <div
                  className={
                    'flex flex-col px-[20px] py-[12px] bg-white gap-[12px] border border-gray2 rounded-[8px] view-task-detail-project__content'
                  }
                >
                  {isInternalRole && (
                    <div className='flex justify-between gap-2'>
                      <TaskDetailQuickUpdateForm detailData={detailData} fetchData={fetchData} />
                      <BaseButton size='medium' type='secondary' icon={<PencilSimpleLine />} onClick={() => goToEditPage()}>
                        {t('button:edit')}
                      </BaseButton>
                    </div>
                  )}
                  <DetailTaskInternal detailData={detailData} />
                </div>
              )}
              {isExternalRole && (
                <div className='view-task-detail-project__content'>
                  <DetailTaskExternal
                    detailData={detailData}
                    handleCancelUploadFileExternal={handleCancelUploadFileExternal}
                    handleUploadFileExternal={handleUploadFileExternal}
                  />
                </div>
              )}
              <CommentWidget.Container
                projectId={String(idProject)}
                projectProcessId={detailData?.projectProcessId}
                taskId={String(idTask)}
                taskType={detailData?.taskType ?? TASK_TYPE.TODO}
                setHasComment={setHasComment}
                className='from-container-chat'
              />
            </FormProvider>
          </div>
        </TaskDetailContext.Provider>
      )}
      <ConfirmCancelEditing condition={hasComment} />
    </>
  );
};

export default ViewTaskDetail;
