import { SVGProps } from 'react';

const HomeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
      <path
        d='M10.6875 13.967V10.592C10.6875 10.4428 10.6282 10.2997 10.5227 10.1943C10.4173 10.0888 10.2742 10.0295 10.125 10.0295H7.875C7.72582 10.0295 7.58274 10.0888 7.47725 10.1943C7.37176 10.2997 7.3125 10.4428 7.3125 10.592V13.967C7.3125 14.1162 7.25324 14.2593 7.14775 14.3648C7.04226 14.4702 6.89918 14.5295 6.75 14.5295H3.375C3.22582 14.5295 3.08274 14.4702 2.97725 14.3648C2.87176 14.2593 2.8125 14.1162 2.8125 13.967V7.4631C2.81376 7.38526 2.83058 7.30845 2.86198 7.2372C2.89337 7.16596 2.93871 7.10172 2.99531 7.04826L8.62031 1.93654C8.72401 1.84167 8.85946 1.78906 9 1.78906C9.14054 1.78906 9.27599 1.84167 9.37969 1.93654L15.0047 7.04826C15.0613 7.10172 15.1066 7.16596 15.138 7.2372C15.1694 7.30845 15.1862 7.38526 15.1875 7.4631V13.967C15.1875 14.1162 15.1282 14.2593 15.0227 14.3648C14.9173 14.4702 14.7742 14.5295 14.625 14.5295H11.25C11.1008 14.5295 10.9577 14.4702 10.8523 14.3648C10.7468 14.2593 10.6875 14.1162 10.6875 13.967Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const HomeFilledIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
      <path
        d='M9.25315 3.23113L9.25315 3.23113C9.18402 3.16789 9.09372 3.13281 9.00003 3.13281C8.90633 3.13281 8.81603 3.16789 8.7469 3.23113L8.74506 3.23282L8.74505 3.23282L3.1219 8.34285C3.12167 8.34306 3.12144 8.34327 3.12121 8.34349C3.08314 8.37849 3.05273 8.42098 3.03187 8.46831C3.01092 8.51585 3.00008 8.56722 3.00003 8.61917C3.00003 8.61921 3.00003 8.61925 3.00003 8.61929C3.00003 8.61933 3.00003 8.61937 3.00003 8.61941L3.00003 15.095V15.107L2.99993 15.107C2.99835 15.2052 3.03265 15.3006 3.09635 15.3752C3.1307 15.4137 3.17275 15.4445 3.2198 15.4657L2.91203 16.1496L3.21981 15.4657C3.26771 15.4872 3.31967 15.4983 3.3722 15.4981L3.37503 15.4981L6.56253 15.4981V12.3106C6.56253 11.9625 6.70081 11.6286 6.94695 11.3825C7.19309 11.1364 7.52693 10.9981 7.87503 10.9981H10.125C10.4731 10.9981 10.807 11.1364 11.0531 11.3825C11.2992 11.6286 11.4375 11.9625 11.4375 12.3106V15.4981H14.625H14.6316L14.6316 15.4981C14.6907 15.4986 14.7488 15.4841 14.8006 15.4559L14.8007 15.4558C14.8609 15.4231 14.9111 15.3747 14.9462 15.3158C14.9812 15.2571 14.9998 15.1902 15 15.1218V8.61942L9.25315 3.23113ZM9.25315 3.23113L9.255 3.23282M9.25315 3.23113L9.255 3.23282M9.255 3.23282L14.8782 8.34285M9.255 3.23282L14.8782 8.34285M14.8782 8.34285C14.8784 8.34307 14.8786 8.34329 14.8789 8.34351M14.8782 8.34285L14.8789 8.34351M14.8789 8.34351C14.9169 8.37851 14.9473 8.42099 14.9682 8.46831C14.9891 8.51585 15 8.56722 15 8.61917L14.8789 8.34351Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  );
};

const FileDocIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
      <path
        d='M4.07812 14.5312C4.52568 14.5312 4.9549 14.3535 5.27137 14.037C5.58784 13.7205 5.76562 13.2913 5.76562 12.8438C5.76562 12.3962 5.58784 11.967 5.27137 11.6505C4.9549 11.334 4.52568 11.1563 4.07812 11.1562H3.09375V14.5312H4.07812Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.14062 14.5312C9.99494 14.5312 10.6875 13.7757 10.6875 12.8438C10.6875 11.9118 9.99494 11.1562 9.14062 11.1562C8.28631 11.1562 7.59375 11.9118 7.59375 12.8438C7.59375 13.7757 8.28631 14.5312 9.14062 14.5312Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.9062 14.1445C14.6371 14.3906 14.2865 14.5283 13.9219 14.5312C13.0641 14.5312 12.375 13.7789 12.375 12.8438C12.375 11.9086 13.0641 11.1562 13.9219 11.1562C14.2865 11.1592 14.6371 11.2969 14.9062 11.543'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.375 8.34375V2.15625C3.375 2.00707 3.43426 1.86399 3.53975 1.7585C3.64524 1.65301 3.78832 1.59375 3.9375 1.59375H10.6875L14.625 5.53125V8.34375'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10.6875 1.59375V5.53125H14.625' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

const ListCheckedIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
      <path d='M9 8.34375H15.1875' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 3.84375H15.1875' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 12.8438H15.1875' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M6.46875 2.71875L4.02891 4.96875L2.8125 3.84375'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.46875 7.21875L4.02891 9.46875L2.8125 8.34375'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.46875 11.7188L4.02891 13.9688L2.8125 12.8438'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const Diamonds4Icon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
      <path
        d='M8.59967 1.43134L6.5811 3.44991C6.36143 3.66958 6.36143 4.02573 6.5811 4.2454L8.59817 6.26248C8.81784 6.48215 9.174 6.48215 9.39367 6.26248L11.4122 4.24391C11.6319 4.02424 11.6319 3.66809 11.4122 3.44842L9.39516 1.43134C9.17549 1.21167 8.81934 1.21167 8.59967 1.43134Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1004 5.93134L11.0818 7.94991C10.8622 8.16958 10.8622 8.52573 11.0818 8.7454L13.0989 10.7625C13.3186 10.9822 13.6747 10.9822 13.8944 10.7625L15.913 8.74391C16.1326 8.52424 16.1326 8.16809 15.913 7.94842L13.8959 5.93134C13.6762 5.71167 13.3201 5.71167 13.1004 5.93134Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.09967 5.92353L2.0811 7.9421C1.86143 8.16177 1.86143 8.51792 2.0811 8.73759L4.09817 10.7547C4.31784 10.9743 4.674 10.9743 4.89367 10.7547L6.91224 8.7361C7.13191 8.51643 7.13191 8.16027 6.91224 7.9406L4.89516 5.92353C4.67549 5.70386 4.31934 5.70386 4.09967 5.92353Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.6004 10.4235L6.58183 12.4421C6.36216 12.6618 6.36216 13.0179 6.58183 13.2376L8.59891 15.2547C8.81858 15.4743 9.17473 15.4743 9.3944 15.2547L11.413 13.2361C11.6326 13.0164 11.6326 12.6603 11.413 12.4406L9.39589 10.4235C9.17622 10.2039 8.82007 10.2039 8.6004 10.4235Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const Users4Icon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
      <path
        d='M5.625 13.4062C6.86764 13.4062 7.875 12.3989 7.875 11.1562C7.875 9.91361 6.86764 8.90625 5.625 8.90625C4.38236 8.90625 3.375 9.91361 3.375 11.1562C3.375 12.3989 4.38236 13.4062 5.625 13.4062Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.25 15.0938C2.64296 14.5698 3.15252 14.1445 3.73832 13.8516C4.32411 13.5587 4.97006 13.4063 5.625 13.4062C6.27994 13.4063 6.92589 13.5587 7.51168 13.8516C8.09748 14.1445 8.60704 14.5698 9 15.0938'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.625 6.09375C6.86764 6.09375 7.875 5.08639 7.875 3.84375C7.875 2.60111 6.86764 1.59375 5.625 1.59375C4.38236 1.59375 3.375 2.60111 3.375 3.84375C3.375 5.08639 4.38236 6.09375 5.625 6.09375Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.25 7.78125C2.64296 7.2573 3.15252 6.83203 3.73832 6.53914C4.32411 6.24624 4.97006 6.09375 5.625 6.09375C6.27994 6.09375 6.92589 6.24624 7.51168 6.53914C8.09748 6.83203 8.60704 7.2573 9 7.78125'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.375 13.4062C13.6176 13.4062 14.625 12.3989 14.625 11.1562C14.625 9.91361 13.6176 8.90625 12.375 8.90625C11.1324 8.90625 10.125 9.91361 10.125 11.1562C10.125 12.3989 11.1324 13.4062 12.375 13.4062Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 15.0938C9.39296 14.5698 9.90252 14.1445 10.4883 13.8516C11.0741 13.5587 11.7201 13.4062 12.375 13.4062C13.0299 13.4062 13.6759 13.5587 14.2617 13.8516C14.8475 14.1445 15.357 14.5698 15.75 15.0938'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.375 6.09375C13.6176 6.09375 14.625 5.08639 14.625 3.84375C14.625 2.60111 13.6176 1.59375 12.375 1.59375C11.1324 1.59375 10.125 2.60111 10.125 3.84375C10.125 5.08639 11.1324 6.09375 12.375 6.09375Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 7.78125C9.39296 7.2573 9.90252 6.83203 10.4883 6.53914C11.0741 6.24624 11.7201 6.09375 12.375 6.09375C13.0299 6.09375 13.6759 6.24624 14.2617 6.53914C14.8475 6.83203 15.357 7.2573 15.75 7.78125'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const PaperclipIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
      <path
        d='M11.5683 5.93342L5.71127 11.8889C5.51736 12.1029 5.41318 12.3833 5.42029 12.672C5.4274 12.9607 5.54525 13.2356 5.74945 13.4398C5.95365 13.644 6.22856 13.7618 6.51726 13.7689C6.80595 13.776 7.08633 13.6719 7.30033 13.478L14.2824 6.39749C14.6702 5.96948 14.8785 5.40873 14.8643 4.83134C14.8501 4.25395 14.6144 3.70413 14.206 3.29573C13.7976 2.88733 13.2478 2.65162 12.6704 2.6374C12.093 2.62319 11.5322 2.83155 11.1042 3.21936L4.1222 10.2998C3.49004 10.932 3.13489 11.7894 3.13489 12.6834C3.13489 13.5774 3.49004 14.4348 4.1222 15.067C4.75437 15.6992 5.61178 16.0543 6.5058 16.0543C7.39982 16.0543 8.25722 15.6992 8.88939 15.067L14.662 9.30842'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const PencilCircleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
      <path
        d='M9 15.0938C12.7279 15.0938 15.75 12.0717 15.75 8.34375C15.75 4.61583 12.7279 1.59375 9 1.59375C5.27208 1.59375 2.25 4.61583 2.25 8.34375C2.25 12.0717 5.27208 15.0938 9 15.0938Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.375 14.1937V11.7188L9 4.40625L5.625 11.7188V14.1937'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 12.8438C9 12.3962 9.17779 11.967 9.49426 11.6505C9.81072 11.334 10.2399 11.1563 10.6875 11.1562C11.1351 11.1563 11.5643 11.334 11.8807 11.6505C12.1972 11.967 12.375 12.3962 12.375 12.8438'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 15.0938V12.8438C9 12.3962 8.82221 11.967 8.50574 11.6505C8.18928 11.334 7.76005 11.1563 7.3125 11.1562C6.86495 11.1563 6.43572 11.334 6.11926 11.6505C5.80279 11.967 5.625 12.3962 5.625 12.8438'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7.18604 8.34375H10.8142' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

const EnvelopeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none' {...props}>
      <path
        d='M2.25 3.28125H15.75V12.8438C15.75 12.9929 15.6907 13.136 15.5852 13.2415C15.4798 13.347 15.3367 13.4062 15.1875 13.4062H2.8125C2.66332 13.4062 2.52024 13.347 2.41475 13.2415C2.30926 13.136 2.25 12.9929 2.25 12.8438V3.28125Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M15.75 3.28125L9 9.46875L2.25 3.28125' stroke='#116E63' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export { HomeIcon, HomeFilledIcon, FileDocIcon, ListCheckedIcon, Diamonds4Icon, Users4Icon, PaperclipIcon, PencilCircleIcon, EnvelopeIcon };
