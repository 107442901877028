import { ProjectDetailPreviewInfo } from '@pages/project-management/task/view/components/ProjectDetailPreviewInfo';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { TagTaskType } from '@/components/common/task/TagTaskType';

import { DataViewer } from '@/utils/helpers/common';

import { IDetailTaskData } from '../../models';

import '../index.scss';

interface HeaderExternalRoleProps {
  detailData: IDetailTaskData;
}

const HeaderExternalRole = ({ detailData }: HeaderExternalRoleProps) => {
  return (
    <div className='view-task-project-header flex items-center justify-between gap-[16px]'>
      <div className='flex items-center gap-[8px] w-full'>
        <TagTaskType type={detailData?.taskType ?? ''} />
        <AppTooltip className='truncate inline-block max-w-[800px]' title={DataViewer.display(detailData?.taskName)}>
          <div className='text-lnk text-[24px] font-bold truncate inline-block max-w-[800px]'>{DataViewer.display(detailData?.taskName)}</div>
        </AppTooltip>
        {detailData && <ProjectDetailPreviewInfo project={detailData} className='ml-auto mr-[4px]' />}
      </div>
    </div>
  );
};

export default HeaderExternalRole;
