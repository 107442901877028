import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from 'antd';
import { Delta } from 'quill/core';
import React, { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ProjectDetailPreviewInfo } from '@pages/project-management/task/view/components/ProjectDetailPreviewInfo';

import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';
import { FormAttachmentTable } from '@/components/form-attachment-table/FormAttachmentTable';
import { EditorWithCounter } from '@/components/form-box-editor';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import SelectionMultiplePreview from '@/components/form-select/SelectionMultiplePreview';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization, { C } from '@/hooks/useAuthorization';
import useFetch from '@/hooks/useFetch';
import useMutation from '@/hooks/useMutation';

import { NOTE_ANNOUNCEMENT_MAX_LENGTH, TYPE } from '@/utils/constants/AppConstants';
import { VIEW_ANNOUNCEMENT_URL } from '@/utils/constants/RouteContants';
import { APPOVED_REJECT_ANNOUNCEMENT_ATTACHMENT, DELETE_ANNOUNCEMENT_ATTACHMENT } from '@/utils/constants/announcement';
import { checkIsFirstAnnouncement } from '@/utils/services/AnnouncementApiService';

import { ButtonGroupAction, getSubTitle } from '../components/RenderElement';
import SelectedPhrase from '../components/SelectedPhrase';
import { WrapHeaderTitle, WrapLabelWithContent, WrapLayout, WrapLayoutContent, WrapLayoutHeader } from '../components/WrapLayout';
import {
  ANNOUNCEMENT_STATUS,
  ATTACHMENT_STATUS,
  AnnouncementItem,
  AnnouncementStatus,
  AttachmentFile,
  AttachmentFileType,
  AttachmentStatus
} from '../view/models';
import { FormEditAnnouncement } from './FormValidation';
import { PharseMasterType, StakeholderItem } from './models';

import './Add.scss';

export interface IAttachment {
  name: string;
  blobPath: string;
  contentType: string;
  fileSize: string;
  displayOrder: number;
  requestApproval: boolean;
  status: AttachmentStatus;
}

const ATTACHMENT_STATUS_UPLOADED = [ATTACHMENT_STATUS.APPROVAL, ATTACHMENT_STATUS.WAITING, ATTACHMENT_STATUS.REJECT];

const Edit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasPermission, user } = useAuthorization();
  const dispatch = useDispatch();
  const editorRef = useRef<any>(null);
  const { id: projectId, announcementId } = useParams();
  const [oldAttachmentFile, setOldAttachmentFile] = useState<AttachmentFileType[] | null>(null);
  const [fileDeleted, setFileDeleted] = useState<AttachmentFileType[]>([]);
  const formMethod = useForm<any>({
    mode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(FormEditAnnouncement)
  });

  const { loading, mutate: updatedAnnouncement } = useMutation(`prj/projects/${projectId}/announcements/${announcementId}`, {
    method: 'PUT',
    bodyType: 'json',
    showToastSuccess: false,
    showToastError: true
  });

  const { data: announcementData } = useFetch<AnnouncementItem>(`/prj/projects/announcements/${announcementId}`, 'GET');
  const { data: stakeholder } = useFetch<StakeholderItem[]>(`/prj/projects/${projectId}/stakeholders?query=activeOnly`, 'GET');
  const { data: phraseMaster } = useFetch<PharseMasterType[]>(`/prj/phrases`, 'GET');

  const generateCompanyNameOptions = () => {
    const users = announcementData?.users || [];
    const options =
      (stakeholder ?? [])
        .filter((i) => i.stakeHolderStatus || users.some((u) => u.userId === i.stakeHolderId))
        .map((stakeholder) => ({
          label: `${!stakeholder.stakeHolderStatus ? `(${t('announcement:invalid')})` : ''}${stakeholder.stakeHolderName}`,
          value: stakeholder.stakeHolderEmail,
          disabled: !stakeholder.stakeHolderStatus
        })) || [];
    return options;
  };

  const convertPhasesData = () => {
    const groupedData = phraseMaster?.reduce((acc: any, { id, title, content, categoryName }) => {
      if (!acc[categoryName as string]) {
        acc[categoryName as string] = [];
      }
      acc[categoryName as string].push({ id, title, content, categoryName });
      return acc;
    }, {});

    return Object.entries(groupedData || {}).map(([categoryName, data]) => ({ name: categoryName, data }));
  };

  const allowedUser = () => {
    if (!hasPermission(APPOVED_REJECT_ANNOUNCEMENT_ATTACHMENT, C) || !announcementId) return false;
    const { attachments } = formMethod.watch() || [];
    const accepted = attachments?.find((item: any) => {
      const fileNeedApprove = item.status === ATTACHMENT_STATUS.WAITING && item.createdBy !== user?.id && item.createdBy;
      return fileNeedApprove;
    });
    return !!accepted;
  };
  useEffect(() => {
    const attachmentFiles =
      announcementData?.attachments.map((item: AttachmentFile) => {
        return {
          ...item,
          requestApproval: [ATTACHMENT_STATUS.WAITING, ATTACHMENT_STATUS.APPROVAL, ATTACHMENT_STATUS.REJECT].includes(item.status)
        };
      }) || [];
    formMethod.setValue('title', announcementData?.title);
    formMethod.setValue('content', announcementData?.content);
    formMethod.setValue(
      'organizationId',
      announcementData?.users.map((item) => item.userEmail)
    );
    formMethod.setValue('attachments', attachmentFiles);

    formMethod.setValue('version', announcementData?.version);
    const oldAttachment = attachmentFiles.map((el) => {
      return {
        id: el.id ?? '',
        name: el.name,
        requestApproval: !!el.requestApproval
      };
    });
    setOldAttachmentFile(oldAttachment);
  }, [announcementData]);

  const getDiffTwoAttachments = (newFiles: AttachmentFileType[], oldFiles: AttachmentFileType[]) => {
    return newFiles.filter((obj1) => !oldFiles.some((obj2) => obj1.id === obj2.id));
  };

  const saveAnnouncement = async () => {
    const { attachments } = formMethod.getValues();
    const status = attachments.some((item: IAttachment) => item.requestApproval) ? ANNOUNCEMENT_STATUS.WAITING : ANNOUNCEMENT_STATUS.SAVE;
    const newAttachments = oldAttachmentFile ? getDiffTwoAttachments(attachments, oldAttachmentFile) : [];
    const newStatus = newAttachments.some((item) => item.requestApproval) ? ANNOUNCEMENT_STATUS.WAITING : ANNOUNCEMENT_STATUS.SAVE;
    const result = await updatedAnnouncement(getPayloadRequest(formMethod.getValues(), status));
    result && handleNavigate(t(newStatus === ANNOUNCEMENT_STATUS.WAITING ? 'announcement:approval_request' : 'button:keep'));
  };

  const getMsgFail = (template: boolean, questionnaire: boolean) => {
    if (template && questionnaire) return t('common:MSG_P_044');
    if (template) return t('common:MSG_P_042');
    if (questionnaire) return t('common:MSG_P_043');
    return '';
  };

  const publishAnnouncement = async () => {
    try {
      // Call the api to check if this is the first announcement and if any conditions are missing
      const { data } = await checkIsFirstAnnouncement(String(projectId));

      // Show popup warning announcement first lack of conditions
      if (data.isFirstAnnouncement) {
        const showPopup = await showBasePopup({
          title: t('dialog:cancel_editing:send'),
          msg: getMsgFail(data.templateWarning, data.questionnaireWarning),
          type: TYPE_ICON.CONFIRM
        });
        if (showPopup !== TYPE_ICON.CONFIRM) return;
      }

      const value = formMethod.getValues();
      const result = await updatedAnnouncement(getPayloadRequest(value, ANNOUNCEMENT_STATUS.PUBLISH));
      result && handleNavigate(t('button:send'));
    } catch (error) {}
  };

  const handleNavigate = (msg: string) => {
    flushSync(() => formMethod.reset({}, { keepValues: true }));
    onBack();
    dispatch(
      setAlertNotification({
        show: true,
        type: TYPE.SUCCESS,
        message: t('common:MSG_C_003', { item: msg })
      })
    );
  };

  const getPayloadRequest = (formData: any, status?: AnnouncementStatus) => {
    const stakeholders =
      formData.organizationId.map((email: string) => {
        const existStakeholder = announcementData?.users.find((user) => user.userEmail === email);
        return existStakeholder ? { id: existStakeholder?.id, userEmail: email } : { userEmail: email };
      }) || [];

    const deleteStakeholders =
      announcementData?.users
        .filter((item) => !formData.organizationId.includes(item.userEmail))
        ?.map((e) => ({ id: e.id, userEmail: e.userEmail, deleted: true })) || [];

    const attachmentValue = formData.attachments.map((item: IAttachment, index: number) => ({
      ...item,
      displayOrder: index + 1,
      requestApproval: ATTACHMENT_STATUS_UPLOADED.includes(item.status as any) ? item.status === ATTACHMENT_STATUS.WAITING : item.requestApproval,
      status: ATTACHMENT_STATUS_UPLOADED.includes(item.status as any) ? item.status : undefined
    }));

    const removeFileDeleted = fileDeleted.reduce((acc: any[], file: any) => {
      if (file.blobPath) {
        acc.push({
          ...file,
          deleted: true
        });
      }
      return acc;
    }, []);

    return {
      title: formData?.title,
      content: formData?.content,
      users: [...stakeholders, ...deleteStakeholders],
      status,
      attachments: [...attachmentValue, ...removeFileDeleted],
      version: formData?.version
    };
  };

  const handleSelectPhrase = (value: string) => {
    if (editorRef?.current) {
      const cursorPosition = editorRef.current.selection.savedRange?.index;
      const clipboardDelta = editorRef.current.clipboard.convert({ html: value });
      const delta = new Delta().retain(cursorPosition).concat(clipboardDelta);
      editorRef.current.updateContents(delta, 'user');
    }
  };

  const onBack = () => navigate(VIEW_ANNOUNCEMENT_URL(projectId ?? '', announcementId ?? ''));

  const onRemoveFile = (file: any, index: number) => {
    setFileDeleted([...fileDeleted, file]);
  };

  return (
    <FormProvider {...formMethod}>
      <WrapLayout id='announcement-management'>
        <WrapLayoutHeader className='gap-[16px]'>
          <WrapHeaderTitle className='min-w-0'>
            <div className='text-24 flex truncate'>
              {getSubTitle(t, announcementData?.status)}
              <Tooltip title={announcementData?.title}>
                <span className='title-24 truncate'>{announcementData?.title}</span>
              </Tooltip>
            </div>
          </WrapHeaderTitle>
          <div className='flex items-center gap-[16px]'>
            <ProjectDetailPreviewInfo />
            <ButtonGroupAction
              name='attachments'
              loading={loading}
              onCancel={onBack}
              onSave={formMethod.handleSubmit(saveAnnouncement)}
              onPublish={publishAnnouncement}
            />
          </div>
        </WrapLayoutHeader>
        <WrapLayoutContent className='announcement-container announcement-form  '>
          <FormInput
            required={true}
            name='title'
            labelTx='announcement:add:title:label'
            placeholderTx={String(
              t('announcement:add:title:placeholder', {
                field: t('announcement:add:title:label')
              })
            )}
          />
          <div>
            <EditorWithCounter
              defaultValue={announcementData?.content}
              ref={editorRef}
              required={true}
              name='content'
              limit={NOTE_ANNOUNCEMENT_MAX_LENGTH}
              styleCounterLabel={'text-gray-3'}
              label={t('announcement:add:content:label') ?? ''}
              placeholder={t('announcement:add:content:placeholder') ?? ''}
              editorWrapperProps={{ className: '!h-[360px]' }}
              extraElement={<SelectedPhrase pharses={convertPhasesData()} onClickItem={handleSelectPhrase} />}
            />
          </div>
          <SelectionMultiplePreview value={formMethod.watch('organizationId')} options={generateCompanyNameOptions()}>
            <FormSelect
              mode={'multiple'}
              required={true}
              name='organizationId'
              label={String(t('announcement:add:target_audience:label'))}
              placeholder={String(
                t('announcement:add:target_audience:placeholder', {
                  field: t('announcement:add:target_audience:label')
                })
              )}
              options={generateCompanyNameOptions()}
              showCloseItem={true}
            />
          </SelectionMultiplePreview>
          <WrapLabelWithContent title={t('announcement:add:attachment')}>
            <FormAttachmentTable
              name='attachments'
              documentType='attachments'
              isEdit={true}
              allowRemove={hasPermission(DELETE_ANNOUNCEMENT_ATTACHMENT, C)}
              allowApproveReject={allowedUser()}
              wrapperClassName={allowedUser() ? 'file-edit' : ''}
              optionsData={{ status: announcementData?.status }}
              onHandleRemoveFile={onRemoveFile}
              requestApproval
            />
          </WrapLabelWithContent>
        </WrapLayoutContent>
      </WrapLayout>
    </FormProvider>
  );
};

export default Edit;
