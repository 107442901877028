import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IFetchColumnType } from '@/components/common/table-base';

import { FORMAT_DATE_EN_HH_MM_SS } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';

import { QUESTION_VERSIONS } from '../../../constanst';
import { QUESTION_TITLE_CELL_WIDTH } from '../constants';
import { CONTENT_TYPE, IQuestionRecord, IQuestionnaire } from '../modals';
import QuestionContent from './QuestionContent';
import QuestionTable from './QuestionTable';

interface QuestionPDFProps {
  dataQuestionnaire: any;
  questionnaireVersion: string;
  dataSource: any[];
  width?: number;
  onRenderSuccessfully?: () => void;
}

export const QuestionPDF: React.FC<QuestionPDFProps> = ({ dataQuestionnaire, questionnaireVersion, dataSource, width, onRenderSuccessfully }) => {
  const form = useFormContext();
  const { t } = useTranslation();

  const columnsPdf: IFetchColumnType<IQuestionRecord>[] = useMemo(() => {
    let fixedWidth = QUESTION_TITLE_CELL_WIDTH + 1;
    const contentWidth = (width || 0) - fixedWidth;
    const columnOptions: IFetchColumnType<IQuestionRecord>[] = [
      {
        title: t('project:question_detail:question'),
        width: QUESTION_TITLE_CELL_WIDTH,
        className: `max-w-[${QUESTION_TITLE_CELL_WIDTH}px] bg-green10 cell-question`,
        render: (_value: any, record: IQuestionRecord, _index: number) => {
          return record.type !== CONTENT_TYPE.NONE ? (
            <span
              className={classNames(!record.visibleExternalUser ? 'header-text-disable' : '', 'body-700')}
            >{`${record.no ? `${record.no}. ` : ``}${record.title}`}</span>
          ) : (
            <></>
          );
        },
        tooltip: (_value: any, record: IQuestionRecord, _index: number) => record.title ?? '',
        ellipsis: false
      },
      {
        title: t('project:question_detail:answer'),
        key: 'answer',
        width: contentWidth,
        render: (_: any, record: IQuestionRecord, index: number) => {
          const formKey = `questionCategories.${record?.categoryIndex ?? -1}.children.${index}`;
          return (
            <QuestionContent
              key={record.id}
              question={form.getValues(formKey as FieldPath<IQuestionnaire>)}
              mode={'view'}
              name={`questionCategories.${record?.categoryIndex}.children.${index}`}
              showTranslationData={false}
              showTranslateFlg={false}
              showCopyFlg={false}
              isAncestor={true}
              cellWidth={contentWidth}
            />
          );
        }
      }
    ];

    return columnOptions;
  }, [dataQuestionnaire]);

  useEffect(() => {
    onRenderSuccessfully && setTimeout(() => onRenderSuccessfully(), 3000);
  }, []);

  return (
    <div className='pdf-content'>
      <div className='flex-col description-row'>
        <span className='title-24'>{t('project:question_detail:title')}</span>
        <div className='flex gap-x-10 body-400 mt-2'>
          {dataQuestionnaire && (
            <span className='min-w-[143px] max-w-[400px] truncate'>
              {`${t('project:question_detail:owner_name')}：${[dataQuestionnaire.lastName ?? '', dataQuestionnaire.firstName ?? ''].join(
                ' '
              )} （${dataQuestionnaire.objectName ?? ''}）`}
            </span>
          )}
          {questionnaireVersion === QUESTION_VERSIONS.ORIGINAL ? (
            <span className='min-w-[143px] max-w-[400px] truncate'>
              {`${t('project:question_detail:submit_by')}：${DataViewer.display(dataQuestionnaire?.submittedBy ?? '')}`}
            </span>
          ) : (
            ''
          )}
          {questionnaireVersion === QUESTION_VERSIONS.ORIGINAL ? (
            <span className='min-w-[143px] max-w-[400px] truncate'>
              {`${t('project:question_detail:submit_at')}：${DataViewer.localTimeBy(dataQuestionnaire?.submittedAt ?? '', FORMAT_DATE_EN_HH_MM_SS)}`}
            </span>
          ) : (
            ''
          )}
          {questionnaireVersion === QUESTION_VERSIONS.UPDATED ? (
            <span className='min-w-[143px] max-w-[400px] truncate'>
              {`${t('project:question_detail:updated_by')}：${DataViewer.display(dataQuestionnaire?.updatedBy ?? '')}`}
            </span>
          ) : (
            ''
          )}
          {questionnaireVersion === QUESTION_VERSIONS.UPDATED ? (
            <span className='min-w-[143px] max-w-[400px] truncate'>
              {`${t('project:question_detail:updated_at')}：${DataViewer.localTimeBy(dataQuestionnaire?.updatedAt ?? '', FORMAT_DATE_EN_HH_MM_SS)}`}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>

      <QuestionTable scroll={undefined} alwaysExpandGroup columns={columnsPdf} dataSource={dataSource} rowKey={(record) => record.id} />
    </div>
  );
};
