import i18n from '@/i18n';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { PROJECT_STATUS_ID } from '@utils/constants/project';

const STATUS_TAG_MAPPER: Record<string, { text: ReactNode; bgColor: string }> = {
  [PROJECT_STATUS_ID.NOT_STARTED]: {
    text: i18n.t('project:status:not_started'),
    bgColor: 'bg-textGray'
  },
  [PROJECT_STATUS_ID.COMPLETED]: {
    text: i18n.t('project:status:completed'),
    bgColor: 'bg-positive'
  },
  [PROJECT_STATUS_ID.CANCELLED]: {
    text: i18n.t('project:status:cancelled'),
    bgColor: 'bg-[#CE2B2C]'
  }
};

type Props = {
  statusId: string;
  className?: string;
};
export const ProjectStatusTag = (props: Props) => {
  const { statusId, className } = props;
  const status = STATUS_TAG_MAPPER[statusId];
  if (!status) {
    return null;
  }
  return (
    <span
      className={classNames(
        'flex items-center justify-center rounded min-w-[52px] px-[8px] py-[6px] text-12 leading-none text-white font-medium',
        status.bgColor,
        className
      )}
    >
      {status.text}
    </span>
  );
};
