import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setAlertNotification } from '@/redux/globalReducer';
import { AppDispatch } from '@redux/store';

import { IJPPostalCode, IPostalCode } from '@/utils/interfaces';

/**
 * Fetch postal code data from postcode.teraren.com
 * @link https://jp-postal-code-api.ttskch.com
 */
const endpoint = 'https://jp-postal-code-api.ttskch.com/api/v1';

/**
 *
 * @param data response from jp-postal-code-api.ttskch.com
 * @param code postal code
 * @returns data mapping to IPostalCode which is interface of postcode.teraren.com
 */
const migration = (data?: IJPPostalCode, code?: string): IPostalCode => {
  const results: IPostalCode = {};
  if (!data?.addresses) return results;
  const address = data.addresses[0];
  results.new = data.postalCode ?? code;
  results.prefecture = address.ja?.prefecture;
  results.prefecture_roman = address.en?.prefecture;
  results.city = address.ja?.address1;
  results.city_roman = address.en?.address1;
  results.suburb = address.ja?.address2;
  results.suburb_roman = address.en?.address2;
  results.street_address = null;
  results.office = null;
  return results;
};

const usePostalCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<IPostalCode | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const searching = async (code: string) => {
    if (!code) {
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const rawCode = code.replaceAll('-', '');
      const response = await fetch(`${endpoint}/${code.replaceAll('-', '')}.json`);
      if (!response.ok) throw new Error('Failed to fetch');
      const json = await response.json();
      setData(migration(json, rawCode));
    } catch (error) {
      setError(error as Error);
      dispatch(
        setAlertNotification({
          show: true,
          type: 'error',
          message: t('common:MSG_C_031')
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, searching };
};

export default usePostalCode;
