import { IDataDetail, IFormDetailProject } from '../utils';

export const DEFAULT_DETAIL_PROJECT_FORM: IFormDetailProject = {
  picId: null,
  templateId: null,
  primary: {
    category: null,
    form: null
  }
};

export const DEFAULT_DETAIL_PROJECT: IDataDetail = {
  id: null,
  code: null,
  version: null,
  projectInfor: null,
  templateId: null,
  countryId: null,
  projectProcesses: null,
  statusName: null,
  active: true,
  templateName: null,
  picId: null,
  isPublished: true
};

export const DEFAULT_PAGE = 0;

export const STATUS_NAME = {
  TODO: '開始前',
  IN_PROGRESS: '進行中',
  PENDING: '遅延',
  COMPLETED: '完了',
  CANCELLED: '中止',
  PENDING_VERIFICATION: '確認待ち',
  SUBMITTED: '提出済',
  NOT_SUBMITTED: '未提出',
  DOUBLE_CHECK: 'ダブルチェック',
  APPLICANT: '申請者'
} as const;
export type StatusName = (typeof STATUS_NAME)[keyof typeof STATUS_NAME];

export const FILTER_TEMPLATE = {
  TEMPLATE: 'template',
  FILTER: 'filter',
  NONE: 'none'
} as const;
export type FilterTemplate = (typeof FILTER_TEMPLATE)[keyof typeof FILTER_TEMPLATE];

export const TASK_TYPE = {
  TODO: 'TODO',
  OTHER: 'OTHER',
  MOT: 'MOT',
  SUPPORT: 'SUPPORT'
} as const;
export type TaskType = (typeof TASK_TYPE)[keyof typeof TASK_TYPE];
