import { useAppDispatch, useAppSelector } from '@/hooks';
import React, { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import { FetchColumnsType } from '@/components/common/table-base';
import TableFetch from '@/components/common/table-fetch';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization, * as s from '@/hooks/useAuthorization';
import { useFilterConfigs } from '@/hooks/useFilterConfigs';
import useMutation from '@/hooks/useMutation';

import { ROUTER_IDS } from '@/utils/constants';
import { FORMAT_DATE_EN_HH_MM_SS, TYPE } from '@/utils/constants/AppConstants';
import { CREATE_TEMPLATE_URL, VIEW_TEMPLATE_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { arrayToDict } from '@/utils/helpers/globalHelper';

import IconAddNew from '@/assets/icons/base-filter/icon-add-new.svg';
import DefaultEmptySvg from '@/assets/icons/table/base-table-empty-with-smile-face.svg';

import TableHeader from './Header';
import { DEFAULT_FILTER_FORM } from './constant';
import { IFormFilter, Template } from './models';
import { IQueryFilterParams, generateFilter } from './ultis';

export default function TemplateList() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hasPermission, isInternalAdmin } = useAuthorization();
  const countries = useAppSelector((state) => state.global.countries);
  const countryIndexDict = arrayToDict(countries, 'id');
  const { data: defaultFilterConfigs, update: updateFilterConfigs } = useFilterConfigs<FieldValues>(ROUTER_IDS.TEMPLATE_LIST);
  const formFilter = useForm<IFormFilter>({ defaultValues: generateDefaultFilterForm().form });
  const [filter, setFilter] = useState(generateDefaultFilterForm().query);
  const { mutate: deleteTemplate } = useMutation(`/tmpl/templates`, {
    method: 'DELETE',
    bodyType: 'json',
    showToastSuccess: true,
    showToastError: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('questionnaire:actions:delete') }) ?? '',
    defaultErrorMsg: t('common:MSG_032') + t('common:error_asynchronous_data_delete')
  });
  function generateDefaultFilterForm() {
    const results = {
      form: DEFAULT_FILTER_FORM,
      query: {}
    };
    if (!defaultFilterConfigs?.primary) return results;
    results.form = {
      primary: {
        ...defaultFilterConfigs.primary
      }
    };
    results.query = {
      filter: generateFilter({
        keyword: '',
        ...defaultFilterConfigs?.primary
      })
    };
    return results;
  }
  const getEmptyDataAlert = (): React.ReactNode => {
    return (
      <div className='flex flex-col items-center'>
        <DefaultEmptySvg width={100} height={100} />
        {hasPermission(s.CREATE_TEMPLATE_SAVE, s.V) && (
          <div>
            <p className='text-16 font-semibold text-[#12212E] my-1'>{t('template:list:no_data:title')}</p>
            <p className='text-base font-[400] text-[#525A68] mb-3'>{t('template:list:no_data:description')}</p>
          </div>
        )}
        <BaseButton
          size='medium'
          icon={<IconAddNew style={{ verticalAlign: 'sub' }} />}
          onClick={() => {
            navigate(CREATE_TEMPLATE_URL);
          }}
          disabled={!hasPermission(s.CREATE_TEMPLATE_SAVE, s.C)}
        >
          {t('project:list:add_new_btn_label')}
        </BaseButton>
      </div>
    );
  };
  const columns: FetchColumnsType<any> = [
    {
      title: 'NO',
      key: 'no',
      width: 50,
      className: 'max-w-[50px] h-[38px] align-top',
      render: (_value: any, record: any, index: number) => {
        return <div className='text-center whitespace-nowrap align-top'>{record?.indexGroup ?? index + 1}</div>;
      }
    },
    {
      title: t('template:template_name'),
      key: 'name',
      className: 'max-w-[560px] h-[38px] align-top',
      render: (item) => {
        if ('label' in item) {
          return <></>;
        }
        return (
          <AppTooltip title={item.name}>
            <Link className='base-table-template-name w-fit max-w-full truncate block text-link align-top' to={`${VIEW_TEMPLATE_URL(item.id)}`}>
              {item.name}
            </Link>
          </AppTooltip>
        );
      },
      ellipsis: true
    },
    {
      title: t('template:country_name'),
      key: 'countryName',
      width: 140,
      className: 'max-w-[140px] h-[38px] align-top',
      render: (item: Template) => (
        <AppTooltip title={item?.countryName}>
          <span className='truncate inline-block max-w-full align-top'>{item?.countryName}</span>
        </AppTooltip>
      ),
      ellipsis: true
    },
    {
      title: t('template:form'),
      dataIndex: 'formName',
      key: 'formName',
      width: 150,
      className: 'max-w-[150px] h-[38px] align-top',
      ellipsis: true
    },
    {
      title: t('template:category'),
      dataIndex: 'categoryName',
      key: 'categoryName',
      width: 130,
      className: 'max-w-[130px] h-[38px] align-top',
      ellipsis: true
    },
    {
      title: t('template:pattern'),
      dataIndex: 'patternName',
      key: 'patternName',
      width: 250,
      className: 'max-w-[250px] h-[38px] align-top',
      ellipsis: true
    },
    {
      title: t('template:changer'),
      width: 150,
      className: 'max-w-[150px] h-[38px] align-top',
      key: 'updater',
      render: (item: Template) => {
        return (
          <AppTooltip title={item?.updatedName ?? item?.createdName}>
            <span className='truncate inline-block max-w-full align-top'>{item?.updatedName ?? item?.createdName}</span>
          </AppTooltip>
        );
      },
      ellipsis: true
    },
    {
      title: t('template:update_datetime'),
      key: 'updatedDate',
      width: 170,
      className: 'max-w-[170px] h-[38px] align-top',
      render: (record: Template) => (
        <AppTooltip title={DataViewer.localTimeBy(record?.updatedDate ?? record?.createdDate, FORMAT_DATE_EN_HH_MM_SS)}>
          {DataViewer.localTimeBy(record?.updatedDate ?? record?.createdDate, FORMAT_DATE_EN_HH_MM_SS)}
        </AppTooltip>
      )
    }
  ];

  const updateFilter = (values: FieldValues) => {
    const formValues: IQueryFilterParams = {
      keyword: values?.search,
      ...values?.primary
    };
    setFilter({ filter: generateFilter(formValues) });
    updateFilterConfigs({
      ...values,
      primary: {
        ...values.primary
      }
    });
  };

  const handleDelete = async (keys: React.Key[], records: any[]) => {
    const idsToDelete = records.map((itemDocument) => {
      return {
        id: itemDocument.id || '',
        version: itemDocument.version || ''
      };
    });
    if (!idsToDelete.length) {
      return 0;
    }
    const result = await deleteTemplate({
      ids: idsToDelete
    });
    if (!result) return 0;
    const { data } = result;
    if (data.successCount === keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_003', { item: t('questionnaire:actions:delete') })
        })
      );
    }
    if (data.successCount < keys.length) {
      dispatch(
        setAlertNotification({
          show: true,
          type: TYPE.SUCCESS,
          message: t('common:MSG_C_020', { deletable: data.successCount, total: keys.length })
        })
      );
    }
    return data.successCount;
  };
  return (
    <div>
      <div className='grid grid-cols-2 gap-4 page-list-header mb-[12px]'>
        <div className='title-24'>{t('template_layout:template_list')}</div>
        <div className='text-end'>
          {hasPermission(s.CREATE_TEMPLATE_SAVE, s.C) && (
            <BaseButton
              size='medium'
              icon={<IconAddNew style={{ verticalAlign: 'sub' }} />}
              onClick={() => {
                navigate(CREATE_TEMPLATE_URL);
              }}
              className='float-right'
            >
              {t('template_layout:create_button')}
            </BaseButton>
          )}
        </div>
      </div>
      <div className='page-content'>
        <div className='data-table'>
          <TableFetch
            tableHeader={
              <FormProvider {...formFilter}>
                <TableHeader onSubmit={updateFilter}></TableHeader>
              </FormProvider>
            }
            apiEndpoint='/tmpl/templates/search'
            apiMethod='POST'
            showAddLine={false}
            columns={columns}
            filterDefault={filter}
            groupBy='countryId'
            groupRowRender={(record) => {
              return (
                <div className='font-bold'>
                  <span>{countryIndexDict[record.id].countryName}</span>
                  <span>({record.children.length})</span>
                </div>
              );
            }}
            defaultSort={[{ field: 'countryOrder', order: 'ascend' }]}
            groupSort={(group1Id, group2Id) => {
              return (countryIndexDict?.[group1Id]?.order || 0) - (countryIndexDict[group2Id]?.order || 0);
            }}
            scrollTable={{ maxHeight: '100vh - 248px' }}
            showDelete={isInternalAdmin}
            showDownload={false}
            handleDeleteClick={hasPermission(s.DELETE_TEMPLATE_LIST, s.C) ? handleDelete : undefined}
            deleteConfirmPopup={{
              type: TYPE.DISCARD,
              title: t('common:MSG_029.1_title', { item: t('schedule:template') }) ?? '',
              msg:
                `${t('common:MSG_029.1_description', { item: t('schedule:template') })}${t('common:MSG_029_description2', {
                  item: t('schedule:template')
                })}` ?? ''
            }}
            emptyDataWithoutFilter={getEmptyDataAlert()}
            rowSelection={isInternalAdmin ? {} : null}
          />
        </div>
      </div>
    </div>
  );
}
