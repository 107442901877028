import { useAppSelector } from '@/hooks';
import CaretDown from '@/layouts/assets/icons/CaretDown.svg';
import { Button, Col, Collapse, Row } from 'antd';
import dayjs from 'dayjs';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GENDER, LANGUAGE } from '@/pages/project-management/project-list/constants';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseCard } from '@/components/base-card/BaseCard';
import { convertObjDatetoString } from '@/components/common/date-input';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import { IconDownload } from '@/components/icon-svg/IconSvg';

import useAuthorization from '@/hooks/useAuthorization';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { DataViewer } from '@/utils/helpers/common';
import { handleDownloadSingleFile } from '@/utils/helpers/fileHelper';
import { decodeFileName } from '@/utils/helpers/globalHelper';

import CheckedIcon from '@/assets/icons/Checked.svg';
import IconPaperclip from '@/assets/icons/Paperclip.svg';
import WarningWaitingApprove from '@/assets/icons/WarningWaitingApprove.svg';
import CloseIcon from '@/assets/icons/close.svg';

import { IProjectStakeholder } from '../../../models';
import { STEP_CRUMB_STATUS } from '../../components/step-crumb';
import { relativePath } from '../../utils';
import {
  APPLICANT_FIELDS_SCENARIO_C,
  ASSIGNMENT_INFO_FIELDS,
  FAMILY_MEMBER_FIELDS,
  PROJECT_FAMILY_PATH,
  PROJECT_LOCAL_SUPPORTER_FIELDS
} from '../constants';
import CommonViewInfo from './CommonViewInfor';

interface ILabelTitle {
  title: string;
  questions?: number;
  total?: number;
  size?: number | string;
}
const LabelTitle = (props: ILabelTitle) => {
  const { title, questions, total, size = 18 } = props;
  const { t } = useTranslation();
  return (
    <div className='flex flex-row'>
      <span className={`subtitle-${size}`}>{t(title)}</span>
      {!!total && (
        <div className='ml-[8px] pr-[8px] pl-[8px] bg-[#DBDBE0] rounded text-[14px] font-bold'>
          {questions}/{total}
        </div>
      )}
    </div>
  );
};

export default function Step8() {
  const travelTypes = useAppSelector((state) => state.projectV2.travelTypes.data);
  const { t } = useTranslation();
  const { getValues, watch, setValue } = useFormContext();
  const { user } = useAuthorization();
  const { countryOptions } = useOptionsGlobal();
  const [activeKey, setActiveKey] = useState<string[]>(['1', '2', '3', '4', '5', '6', '7']);
  const renderExpandIcon = (isActive: boolean | undefined) => {
    return <CaretDown type='caret-right' className={`${isActive ? 'rotate-0' : '-rotate-90'}`} />;
  };
  const ITEM_CLASS_NAME = '!rounded-[8px] !border-[1px] !border-solid !border-[#DBDBE0] my-4 shadow-base-card';
  const PATHS = {
    PROJECT_APPLICANT: relativePath('projectApplicant', 'steps[0]'),
    GO_WITH_FAMILY: relativePath('goWithFamily', 'steps[1]'),
    PROJECT_FAMILIES: relativePath(PROJECT_FAMILY_PATH, 'steps[1]'),
    VISA_CATEGORY: relativePath('visaCategoryName', 'steps[0]'),
    TRAVEL_TYPE: relativePath('travelTypeId', 'steps[2]'),
    PROJECT_ASSIGNMENT_INFO: relativePath('projectAssignmentInfo', 'steps[2]'),
    PROJECT_LOCAL_SUPPORTER: relativePath('projectLocalSupporters', 'steps[3]'),
    GO_WITH_LOCAL_SUPPORTER: relativePath('localSupportOption', 'steps[3]'),
    PROJECT_STAKEHOLDERS: relativePath('projectStakeholders', 'steps[4]'),
    HAS_OTHER_STAKE_HOLDER: relativePath('hasOtherStakeHolder', 'steps[4]'),
    NOTE: relativePath('note', 'steps[5]'),
    PROJECT_ATTACHMENTS: relativePath('projectAttachments', 'steps[5]')
  };
  const gender = [
    {
      label: t('common:male'),
      value: GENDER.male,
      id: GENDER.male
    },
    {
      label: t('common:female'),
      value: GENDER.female,
      id: GENDER.female
    }
  ];
  const language = [
    {
      label: t('common:language_jp'),
      value: LANGUAGE.japan,
      id: LANGUAGE.japan
    },
    {
      label: t('common:language_en'),
      value: LANGUAGE.english,
      id: LANGUAGE.english
    }
  ];
  useEffect(() => {
    setValue(`stepStatus[7].value`, STEP_CRUMB_STATUS.COMPLETED);
  }, []);
  const counters = (index: number = 0) => {
    let questionsLocalSupporters = 0;
    const { name, address, email, language } = getValues(`${PATHS.PROJECT_LOCAL_SUPPORTER}.${index}`) ?? {};
    if (name) questionsLocalSupporters++;
    if (address) questionsLocalSupporters++;
    if (email) questionsLocalSupporters++;
    if (language && index === 0) questionsLocalSupporters++;
    const results = {
      applicantInformation: {
        total: 2,
        questions: [user?.name, user?.organizationId].filter(Boolean).length
      },
      projectApplicant: {
        total: APPLICANT_FIELDS_SCENARIO_C.length,
        questions: getValues(APPLICANT_FIELDS_SCENARIO_C.map((field) => relativePath(field, `${PATHS.PROJECT_APPLICANT}`))).filter(Boolean).length
      },
      projectFamilies: {
        total: FAMILY_MEMBER_FIELDS.length,
        questions: getValues(FAMILY_MEMBER_FIELDS.map((field) => relativePath(field, `${PATHS.PROJECT_FAMILIES}[${index}]`))).filter(Boolean).length
      },
      projectAssignmentInfo: {
        total: ASSIGNMENT_INFO_FIELDS.length,
        questions: getValues(ASSIGNMENT_INFO_FIELDS.map((field) => relativePath(field, `${PATHS.PROJECT_ASSIGNMENT_INFO}`))).filter(Boolean).length
      },
      visaCategoryName: {
        total: 1,
        questions: getValues(PATHS.VISA_CATEGORY) ? 1 : 0
      },
      projectLocalSupporters: {
        total: PROJECT_LOCAL_SUPPORTER_FIELDS.length,
        questions: questionsLocalSupporters
      },
      projectStakeholders: {
        total: 1,
        questions: getValues(`${PATHS.PROJECT_STAKEHOLDERS}[${index}].stakeholderEmail`) ? 1 : 0
      },
      note: {
        total: 1,
        questions: DataViewer.isEmptyHTML(getValues(PATHS.NOTE)) ? 0 : 1
      },
      projectAttachments: {
        total: 1,
        questions: getValues(PATHS.PROJECT_ATTACHMENTS).length ? 1 : 0
      },
      travelType: {
        total: 1,
        questions: getValues(PATHS.TRAVEL_TYPE) ? 1 : 0
      }
    };
    return results;
  };
  const handleChange = (key: string | string[]) => {
    setActiveKey(Array.isArray(key) ? key : [key]);
  };
  const showLabelItem = (value: string | number, options: any[], valueKey: string = 'value', labelKey: string = 'label') => {
    const item = options.find((item: any) => item.value === value);
    return item ? item[labelKey] : '';
  };
  const applicantInformationSection = {
    key: '1',
    label: (
      <LabelTitle
        title={t('basic_information:applicant_information')}
        total={counters().applicantInformation.total}
        questions={counters().applicantInformation.questions}
      ></LabelTitle>
    ),
    className: ITEM_CLASS_NAME,
    children: (
      <Row gutter={16}>
        <Col span={12}>
          <FormInput value={user?.name} disabled name='projectApplicant.lastNameRomaji' labelTx='basic_information:applicant_name' placeholderTx='' />
        </Col>
        <Col span={12}>
          <FormSelect
            disabled
            value={user?.organizationName}
            options={[]}
            name=''
            label={t('basic_information:basic_information') || ''}
            placeholder='project:add:applicant_representative:example_taro_passport'
          />
        </Col>
      </Row>
    )
  };
  const projectApplicantSection = {
    key: '2',
    label: <LabelTitle title={t('project:scenario_b:applicant_title')}></LabelTitle>,
    className: ITEM_CLASS_NAME,
    children: (
      <BaseCard
        title={String(t('basic_information:applicant_infomation'))}
        total={counters().projectApplicant.total}
        questions={counters().projectApplicant.questions}
      >
        <CommonViewInfo
          id='project-applicant'
          columns={[
            {
              items: [
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.lastName`), label: 'basic_information:last' },
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.firstName`), label: 'project:add:applicant_representative:first_name' },
                {
                  title: getValues(`${PATHS.PROJECT_APPLICANT}.lastNameRomaji`),
                  label: 'project:add:applicant_representative:last_name_romaji'
                },
                {
                  title: getValues(`${PATHS.PROJECT_APPLICANT}.firstNameRomaji`),
                  label: 'project:add:applicant_representative:first_name_romaji'
                },
                {
                  title: showLabelItem(getValues(`${PATHS.PROJECT_APPLICANT}.primaryNational`), countryOptions),
                  label: 'project:add:applicant_representative:nationality'
                },
                {
                  title: showLabelItem(getValues(`${PATHS.PROJECT_APPLICANT}.secondaryNational`), countryOptions),
                  label: 'project:add:applicant_representative:second_nationality'
                },
                {
                  title: getValues(`${PATHS.PROJECT_APPLICANT}.dateOfBirth`)
                    ? convertObjDatetoString(getValues(`${PATHS.PROJECT_APPLICANT}.dateOfBirth`))
                    : '',
                  label: 'project:add:applicant_representative:date_of_birth'
                },
                { title: showLabelItem(getValues(`${PATHS.PROJECT_APPLICANT}.gender`), gender), label: 'project:add:applicant_representative:sex' },
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.postalCode`), label: 'basic_information:postal_code' },
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.prefecture`), label: 'postal_code:prefecture' },
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.prefectureRoman`), label: 'postal_code:prefecture_roman' }
              ]
            },
            {
              items: [
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.city`), label: 'postal_code:city' },
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.cityRoman`), label: 'postal_code:city_roman' },
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.suburb`), label: 'postal_code:suburb' },
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.suburbRoman`), label: 'postal_code:suburb_roman' },
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.streetAddress`), label: 'postal_code:street_address' },
                { title: getValues(`${PATHS.PROJECT_APPLICANT}.apartmentName`), label: 'postal_code:office' },
                {
                  title: getValues(`${PATHS.PROJECT_APPLICANT}.placeOfResidence`),
                  label: 'project:add:applicant_representative:resident_registration_place'
                },
                {
                  title: getValues(`${PATHS.PROJECT_APPLICANT}.currentCompany`),
                  label: 'project:add:applicant_representative:current_company'
                },
                {
                  title: getValues(`${PATHS.PROJECT_APPLICANT}.departureDate`)
                    ? dayjs(getValues(`${PATHS.PROJECT_APPLICANT}.departureDate`)).format('YYYY/MM/DD')
                    : '',
                  label: 'project:add:applicant_representative:departure_date'
                }
              ]
            }
          ]}
        />
      </BaseCard>
    )
  };
  const projectFamiliesSection = {
    key: '3',
    label: <LabelTitle title={t('basic_information:family_information')}></LabelTitle>,
    className: ITEM_CLASS_NAME,
    children: (
      <>
        {getValues(PATHS.PROJECT_FAMILIES).map((field: any, index: number) => (
          <BaseCard
            className='mb-3'
            title={`${t('basic_information:family_information')} ${index + 1}`}
            total={counters(index).projectFamilies.total}
            questions={counters(index).projectFamilies.questions}
          >
            <CommonViewInfo
              id={`project-families-${index}`}
              columns={[
                {
                  items: [
                    { title: field.lastName, label: 'project:add:applicant_representative:last_name' },
                    { title: field.firstName, label: 'project:add:applicant_representative:first_name' },
                    { title: field.lastNameRomaji, label: 'project:add:applicant_representative:last_name_romaji' },
                    { title: field.firstNameRomaji, label: 'project:add:applicant_representative:first_name_romaji' }
                  ]
                },
                {
                  items: [
                    { title: showLabelItem(field.primaryNational, countryOptions), label: 'project:add:applicant_representative:nationality' },
                    {
                      title: showLabelItem(field.secondaryNational, countryOptions),
                      label: 'project:add:applicant_representative:second_nationality'
                    },
                    {
                      title: field.dateOfBirth ? convertObjDatetoString(field.dateOfBirth) : '',
                      label: 'project:add:applicant_representative:date_of_birth'
                    },
                    { title: showLabelItem(field.gender, gender), label: 'project:add:applicant_representative:sex' }
                  ]
                }
              ]}
            />
          </BaseCard>
        ))}
      </>
    )
  };
  const projectAssignmentInfoSection = {
    key: '4',
    label: <LabelTitle title={t('project:add:assignment_info:title')}></LabelTitle>,
    className: ITEM_CLASS_NAME,
    children: (
      <>
        <BaseCard
          title={t('project:scenario_b:assignment_information')}
          total={counters().projectAssignmentInfo.total}
          questions={counters().projectAssignmentInfo.questions}
        >
          <CommonViewInfo
            id='project-assignment-info'
            columns={[
              {
                items: [
                  { title: getValues(`${PATHS.PROJECT_ASSIGNMENT_INFO}.name`), label: 'basic_information:assignee_trainee_name' },
                  {
                    title: getValues(`${PATHS.PROJECT_ASSIGNMENT_INFO}.companyName`),
                    label: 'basic_information:assignment_training_company_name'
                  },
                  {
                    title: showLabelItem(getValues(`${PATHS.PROJECT_ASSIGNMENT_INFO}.countryId`), countryOptions),
                    label: 'basic_information:destination_training_country'
                  },
                  { title: getValues(`${PATHS.PROJECT_ASSIGNMENT_INFO}.address`), label: 'basic_information:assignment_training_city' }
                ]
              }
            ]}
          />
        </BaseCard>
        <BaseCard
          title={t('basic_information:visa_information_label')}
          total={counters().visaCategoryName.total}
          questions={counters().visaCategoryName.questions}
          className='mt-4'
        >
          <CommonViewInfo
            id='project-visa-category'
            columns={[
              {
                items: [{ title: watch(`${PATHS.VISA_CATEGORY}`), label: 'project:scenario_b:visa_category:label' }]
              }
            ]}
          />
        </BaseCard>
        <BaseCard
          title={t('basic_information:travel_type')}
          total={counters().travelType.total}
          questions={counters().travelType.questions}
          className='mt-4'
        >
          <CommonViewInfo
            id='project-visa-category'
            columns={[
              {
                items: [{ title: showLabelItem(watch(`${PATHS.TRAVEL_TYPE}`), travelTypes), label: 'basic_information:travel_type' }]
              }
            ]}
          />
        </BaseCard>
      </>
    )
  };
  const projectLocalSupporterSection = {
    key: '5',
    label: <LabelTitle title={t('project:scenario_b:local_supporter')}></LabelTitle>,
    className: ITEM_CLASS_NAME,
    children: (
      <>
        {getValues(PATHS.PROJECT_LOCAL_SUPPORTER).map((field: any, index: number) => (
          <BaseCard
            title={`${t('basic_information:local_contact')} ${index + 1}`}
            total={index === 0 ? 4 : 3}
            questions={counters(index).projectLocalSupporters.questions}
            className='mb-3'
          >
            <CommonViewInfo
              id={`project-local-supporter-${index}`}
              columns={[
                {
                  items: [
                    { title: field.name, label: 'project:add:local_representative:name' },
                    { title: field.email, label: 'project:add:local_representative:email' }
                  ]
                },
                {
                  items: [
                    { title: field.address, label: 'project:add:local_representative:address' },
                    {
                      title: index === 0 ? showLabelItem(field.language, language) : ' ',
                      label: index === 0 ? 'project:add:local_representative:language' : ' '
                    }
                  ]
                }
              ]}
            />
          </BaseCard>
        ))}
      </>
    )
  };
  const uniqueStakeholders = lodash.uniqWith(
    getValues(PATHS.PROJECT_STAKEHOLDERS).filter((item: IProjectStakeholder) => item.stakeholderEmail),
    (accountA: IProjectStakeholder, accountB: IProjectStakeholder) =>
      accountA.stakeholderEmail === accountB.stakeholderEmail && !accountA.rejected && !accountB.rejected
  );
  const projectStakeholdersSection = {
    key: '6',
    label: <LabelTitle title={t('stakeholder:title')}></LabelTitle>,
    className: ITEM_CLASS_NAME,
    children: (
      <>
        <BaseCard title={`${t('stakeholder:title')} ${1}`} total={1} questions={1}>
          <CommonViewInfo
            id='project-stakeholder'
            columns={[
              {
                items: [
                  { title: user?.email, label: 'basic_information:email_address' },
                  {
                    title: (
                      <div className='flex items-center overflow-hidden'>
                        <span className='mr-2 truncate max-w-full font-semibold whitespace-pre'>{user?.name}</span>
                        <span className='w-[18px] h-[18px]'>
                          <CheckedIcon width={18} height={18} className='icon-check' />
                        </span>
                      </div>
                    ),
                    label: 'basic_information:username'
                  }
                ]
              }
            ]}
          />
        </BaseCard>
        {uniqueStakeholders.map((field: any, index: number) => {
          let items: any = [];

          const itemEmail = {
            title: (
              <div className='flex items-center'>
                <AppTooltip className='mr-4 truncate' title={field.stakeholderEmail}>
                  {field.stakeholderEmail}
                </AppTooltip>{' '}
                <span className='w-[18px] h-[18px]'>
                  {field.rejected ? <CloseIcon width={18} height={18} /> : field.waitCreateAccount && <WarningWaitingApprove />}
                </span>
              </div>
            ),
            label: 'basic_information:email_address'
          };
          const itemText = {
            title: (
              <>
                {DataViewer.display(
                  <div className={`${field.rejected ? 'text-negative' : 'text-warning'} font-medium`}>
                    {t(`${field.rejected ? 'basic_information:account_rejected' : 'project_management:required_documents_tab:waiting_approve'}`)}
                  </div>
                )}
              </>
            ),
            label: ' '
          };
          const itemReason = {
            title: (
              <>
                {DataViewer.display(
                  <div className='flex'>
                    <span className='mr-4 !font-normal min-w-[93px]'>{t('basic_information:account_reason')}</span>
                    <span dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(field.rejectReason) }}></span>
                  </div>
                )}
              </>
            ),
            label: ' '
          };
          const isInactive = field.stakeHolderStatus === false && !field?.waitCreateAccount && !field?.rejected;
          const useName = `${isInactive ? `(${t('announcement:invalid')})` : ''} ${field.stakeholderName}`;
          const itemUsername = {
            title: (
              <div className='flex items-center'>
                <AppTooltip className='mr-2 truncate max-w-full font-semibold whitespace-pre' styles={{ whiteSpace: 'pre-wrap' }} title={useName}>
                  {useName}
                </AppTooltip>{' '}
                <span className='w-[18px] h-[18px]'>
                  {!field.rejected && !field.waitCreateAccount && <CheckedIcon width={18} height={18} className='icon-check' />}
                </span>
              </div>
            ),
            label: 'basic_information:username'
          };
          const itemCompanyName = { title: field.stakeholderCompanyName, label: 'basic_information:company_name' };
          const itemRole = { title: field.roleName, label: 'account_list:role' };

          if (!field.rejected && !field.waitCreateAccount) {
            items = [itemEmail, itemUsername];
          } else if (field.rejected && !field.waitCreateAccount) {
            items = [itemEmail, itemText, itemReason, itemUsername, itemCompanyName, itemRole];
          } else if (!field.rejected && field.waitCreateAccount) {
            items = [itemEmail, itemText, itemUsername, itemCompanyName, itemRole];
          }
          return (
            <BaseCard
              key={field.uid}
              className='mt-3'
              title={`${t('stakeholder:title')} ${index + 2}`}
              total={counters(index).projectStakeholders.total}
              questions={counters(index).projectStakeholders.questions}
            >
              <CommonViewInfo
                id={`project-stakeholder-${index}`}
                columns={[
                  {
                    colSpan: 24,
                    items
                  }
                ]}
              />
            </BaseCard>
          );
        })}
      </>
    )
  };
  const attachmentFileAndNoteSection = {
    key: '7',
    label: <LabelTitle title={t('basic_information:additional_information')}></LabelTitle>,
    className: ITEM_CLASS_NAME,
    children: (
      <>
        <BaseCard title={t('basic_information:contact_information')} total={counters().note.total} questions={counters().note.questions}>
          <CommonViewInfo
            id='project-note'
            columns={[
              {
                colSpan: 24,
                items: [
                  {
                    title: <div dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(getValues(PATHS.NOTE)) }} />,
                    label: 'basic_information:special_note'
                  }
                ]
              }
            ]}
          />
        </BaseCard>
        <BaseCard className='mt-3' title={t('basic_information:attachment_file')}>
          <CommonViewInfo
            id='project-attachments'
            columns={[
              {
                items: [
                  {
                    title: (
                      <>
                        {getValues(PATHS.PROJECT_ATTACHMENTS).length
                          ? getValues(PATHS.PROJECT_ATTACHMENTS).map((file: any, i: number) => (
                              <div key={`file-${file.id}`} className='flex justify-start items-center font-normal'>
                                <p className='mr-[8px] w-[18px]'>{i + 1}</p>
                                <div className='w-[500px] h-[38px] grow flex justify-start items-center border border-solid border-t-0	border-r-0	border-l-0 border-b-gray2'>
                                  <div className='flex justify-center items-center'>
                                    <IconPaperclip width={18} height={18} />
                                  </div>
                                  <AppTooltip className='px-[10px] truncate' title={decodeFileName(file.name)}>
                                    <span
                                      className='cursor-pointer'
                                      onClick={() => {
                                        return handleDownloadSingleFile(file.blobPath as string, file.name);
                                      }}
                                    >
                                      {decodeFileName(file.name)}
                                    </span>
                                  </AppTooltip>
                                  <div className='flex justify-center items-center ml-auto'>
                                    <Button
                                      type='text'
                                      icon={<IconDownload className='h-[20px] w-[20px] download hover:cursor-pointer' />}
                                      onClick={() => {
                                        return handleDownloadSingleFile(file.blobPath as string, file.name);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))
                          : '--'}
                      </>
                    ),
                    label: getValues(PATHS.PROJECT_ATTACHMENTS).length ? '' : 'basic_information:attachment_file'
                  }
                ]
              }
            ]}
          />
        </BaseCard>
      </>
    )
  };
  const renderItems = () => {
    let items = [
      applicantInformationSection,
      projectApplicantSection,
      projectFamiliesSection,
      projectAssignmentInfoSection,
      projectLocalSupporterSection,
      projectStakeholdersSection,
      attachmentFileAndNoteSection
    ];
    return items.filter((item) => {
      if (item.key === '3' && !getValues(PATHS.PROJECT_FAMILIES)?.length) return false;
      if (item.key === '5' && !getValues(PATHS.PROJECT_LOCAL_SUPPORTER)?.length) return false;
      return true;
    });
  };
  return (
    <div className='mt-5'>
      <Collapse
        activeKey={activeKey}
        collapsible='header'
        className='py-4 bg-white'
        onChange={handleChange}
        items={renderItems()}
        bordered={false}
        expandIcon={({ isActive }) => renderExpandIcon(isActive)}
      />
    </div>
  );
}
