import { get, has, isNil, isObject, keys } from 'lodash';

export interface ICounterSchema<T> {
  [counterKey: string]: T[];
}

// this class help you check key and value of an Object Data
// if ObjectData has not key or ObjectData[key] is null | undefined | NaN, it is not counted in data response
export class Counters<T extends string | number | symbol> {
  private data: { [key in string]: number } = {};
  constructor(schema: ICounterSchema<T>, objectValues: any) {
    if (!objectValues) return;
    if (!isObject(objectValues)) {
      console.warn('second parameter must be object!');
      return;
    }
    keys(schema).forEach((counterKey) => {
      if (this.data[counterKey] === undefined) this.data[counterKey] = 0;
      const keys = schema[counterKey];
      if (!keys?.length) return;
      this.calculate(keys, counterKey, objectValues);
    });
  }

  calculate(keys: T[], counterKey: string, values: any) {
    for (const key of keys) {
      if (!has(values, key)) continue;
      const val = get(values, key);
      if (!val && val !== 0 && val !== false) continue;
      if (val && typeof val === 'object' && Object.values(val).every((i) => isNil(i) || i === '')) continue;
      ++this.data[counterKey];
    }
  }

  getAll() {
    return this.data;
  }

  get(counterKey: string) {
    return this.data[counterKey] ?? 0;
  }
}
