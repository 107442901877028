import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import EmptyDataWithIcon from '@/components/EmptyData/EmptyDataWithIcon';
import FormBaseFilter from '@/components/base-filter';
import { FetchColumnsType } from '@/components/common/table-base';
import TableFetch from '@/components/common/table-fetch';
import { IconDownload } from '@/components/icon-svg/IconSvg';

import { API } from '@/utils/constants/Apis';
import { FORMAT_DATE_EN_HH_MM_SS } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';
import { downloadMultiFiles, handleDownloadSingleFile } from '@/utils/helpers/fileHelper';

import IconUploadSuccess from '@/assets/icons/IconUploadSuccess.svg';

import { IQueryFilterParams, generateFilter } from '../ultis';
import { FILE_GROUP_KEY } from '../ultis/constanst';

export interface IProjectFile {
  id: string;
  projectId: string;
  name: string;
  blobPath: string;
  contentType: string;
  fileSize: number;
  deleted: boolean;
  createdDate: string;
  createdName: string;
  sourceTable: string;
  projectCode: string;
  firstName: string;
  lastName: string;
  displayOrder: number;
}

export default function ProjectFilesList() {
  const { id } = useParams();
  const { t } = useTranslation();
  const mounted = useRef<any>(false);
  const refList = useRef<any>(null);
  const [mappingGroupName, setMappingGroupName] = useState<{ [key: string]: string }>({});
  const [groupOrders, setGroupOrders] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const mappingGroupName = {
      [FILE_GROUP_KEY.PROJECT_ATTACHMENT]: t('project:file_list:group:project_attachment'),
      [FILE_GROUP_KEY.INQUIRY_ATTACHMENT]: t('project:file_list:group:inquiry_attachment'),
      [FILE_GROUP_KEY.ANNOUNCEMENT_ATTACHMENT]: t('project:file_list:group:announcement_attachment'),
      [FILE_GROUP_KEY.PROCESS_A_1]: t('project:file_list:group:process_pattern:a_1'),
      [FILE_GROUP_KEY.PROCESS_A_2]: t('project:file_list:group:process_pattern:a_2'),
      [FILE_GROUP_KEY.PROCESS_A_3]: t('project:file_list:group:process_pattern:a_3'),
      [FILE_GROUP_KEY.PROCESS_A_4]: t('project:file_list:group:process_pattern:a_4'),
      [FILE_GROUP_KEY.PROCESS_A_5]: t('project:file_list:group:process_pattern:a_5'),
      [FILE_GROUP_KEY.PROCESS_B_1]: t('project:file_list:group:process_pattern:b_1'),
      [FILE_GROUP_KEY.PROCESS_B_2]: t('project:file_list:group:process_pattern:b_2'),
      [FILE_GROUP_KEY.PROCESS_B_3]: t('project:file_list:group:process_pattern:b_3'),
      [FILE_GROUP_KEY.PROCESS_B_4]: t('project:file_list:group:process_pattern:b_4'),
      [FILE_GROUP_KEY.PROCESS_B_5]: t('project:file_list:group:process_pattern:b_5'),
      [FILE_GROUP_KEY.PROCESS_C_1]: t('project:file_list:group:process_pattern:c_1'),
      [FILE_GROUP_KEY.PROCESS_C_2]: t('project:file_list:group:process_pattern:c_2'),
      [FILE_GROUP_KEY.PROCESS_C_3]: t('project:file_list:group:process_pattern:c_3'),
      [FILE_GROUP_KEY.PROCESS_D_1]: t('project:file_list:group:process_pattern:d_1'),
      [FILE_GROUP_KEY.PROCESS_D_2]: t('project:file_list:group:process_pattern:d_2'),
      [FILE_GROUP_KEY.PROCESS_D_3]: t('project:file_list:group:process_pattern:d_3'),
      [FILE_GROUP_KEY.PROCESS_E_1]: t('project:file_list:group:process_pattern:e_1'),
      [FILE_GROUP_KEY.PROCESS_E_2]: t('project:file_list:group:process_pattern:e_2'),
      [FILE_GROUP_KEY.PROCESS_E_3]: t('project:file_list:group:process_pattern:e_3')
    };
    const groupOrders = Object.keys(mappingGroupName).reduce((obj: { [key: string]: number }, key, index) => {
      obj[key] = index;
      return obj;
    }, {});
    setMappingGroupName(mappingGroupName);
    setGroupOrders(groupOrders);
  }, []);

  const generateDefaultFilterForm = () => {
    const results = {
      form: {},
      query: {}
    };
    results.query = {
      filter: generateFilter({
        keyword: ''
      })
    };
    return results;
  };

  const formFilter = useForm({ defaultValues: generateDefaultFilterForm().form });
  const [filter, setFilter] = useState(generateDefaultFilterForm().query);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const columns: FetchColumnsType<IProjectFile> = [
    {
      title: 'NO',
      key: 'no',
      width: 50,
      className: 'max-w-[50px]',
      render: (_value: any, _record: IProjectFile, index: number) => {
        return <span>{index + 1}</span>;
      },
      ellipsis: true
    },
    {
      title: t('project:file_list:file_name'),
      key: 'name',
      render: (_value: any, record: IProjectFile) => {
        return (
          <div className='flex justify-between'>
            <div className='flex items-center space-x-2 truncate max-w-full'>
              <IconUploadSuccess className='icon' />
              <span className='truncate cursor-pointer' onClick={() => handleDownloadSingleFile(record.blobPath, record.name)}>
                {record.name}
              </span>
            </div>
            <Button
              type='text'
              size='small'
              className='!h-[20px] !w-[20px]'
              icon={<IconDownload className='h-[20px] w-[20px] download hover:cursor-pointer' />}
              onClick={() => handleDownloadSingleFile(record.blobPath, record.name)}
            />
          </div>
        );
      },
      ellipsis: true,
      tooltip: (_value: any, record: IProjectFile) => record.name
    },
    {
      title: t('project:file_list:upload_by'),
      dataIndex: 'createdName',
      width: 200,
      className: 'max-w-[200px]',
      key: 'updater',
      render: (_: any, record: IProjectFile) => <span>{DataViewer.display(record?.createdName)}</span>,
      tooltip: (_: any, record: IProjectFile) => DataViewer.display(record?.createdName),
      ellipsis: true
    },
    {
      title: t('project:file_list:upload_at'),
      dataIndex: 'createdDate',
      key: 'updatedDate',
      width: 200,
      className: 'max-w-[200px]',
      render: (_: any, record: IProjectFile) => DataViewer.localTimeBy(record?.createdDate, FORMAT_DATE_EN_HH_MM_SS),
      tooltip: (_: any, record: IProjectFile) => DataViewer.localTimeBy(record?.createdDate, FORMAT_DATE_EN_HH_MM_SS),
      ellipsis: true
    }
  ];

  const updateFilter = (values: FieldValues) => {
    const formValues: IQueryFilterParams = {
      keyword: values?.search
    };
    setFilter({ filter: generateFilter(formValues) });
  };

  const generateFilterHeader = () => {
    return (
      <div className='mb-[12px]'>
        <FormProvider {...formFilter}>
          <FormBaseFilter
            onSubmit={(values) => {
              updateFilter(values);
            }}
            searchBox={{
              placeholder: DataViewer.display(`${t('project:file_list:place_holder_search')}`)
            }}
            primaryAction={null}
            secondaryAction={null}
          />
        </FormProvider>
      </div>
    );
  };

  const handleDownloadMultiple = async (_selectedRowKeys: React.Key[], selectedItems: IProjectFile[]) => {
    if (selectedItems.length === 0) return;
    await downloadMultiFiles(
      selectedItems,
      `${selectedItems[0].projectCode}_${selectedItems[0].lastName} ${selectedItems[0].firstName}_${t('project:file_list:title')}`
    );
  };

  return (
    <div ref={refList}>
      <div className='grid grid-cols-2 gap-4 page-list-header h-[50px]'>
        <div className='title-24'>{t('project:file_list:title')}</div>
      </div>
      <div>
        <TableFetch
          tableHeader={generateFilterHeader()}
          apiEndpoint={API.GET_PROJECT_FILE_LIST(id ?? '')}
          apiMethod='POST'
          columns={columns}
          filterDefault={filter}
          scrollTable={{ maxHeight: '100vh - 239px' }}
          showDelete={false}
          showDownload
          groupBy='sourceTable'
          groupRowRender={(record) => {
            const label = mappingGroupName[record.id] ?? record.id;
            return (
              <>
                {label}
                <span>({record.children.length})</span>
              </>
            );
          }}
          groupSort={(groupKey1, groupKey2) => {
            return groupOrders[groupKey1] - groupOrders[groupKey2];
          }}
          handleDownloadClick={handleDownloadMultiple}
          defaultSort={[
            {
              field: 'displayOrder',
              order: 'ascend'
            }
          ]}
          emptyDataWithoutFilter={
            <EmptyDataWithIcon
              title={null}
              description={{
                content: 'common:no_data'
              }}
              button={null}
            />
          }
        />
      </div>
    </div>
  );
}
