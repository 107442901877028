import { useAppSelector } from '@/hooks';
import CaretDown from '@/layouts/assets/icons/CaretDown.svg';
import { Button, Col, Collapse, Row } from 'antd';
import dayjs from 'dayjs';
import lodash from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IProjectStakeholder } from '@/pages/project-management/add-edit/models';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseCard } from '@/components/base-card/BaseCard';
import { convertObjDatetoString } from '@/components/common/date-input';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import { IconDownload } from '@/components/icon-svg/IconSvg';

import useAuthorization from '@/hooks/useAuthorization';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { DataViewer } from '@/utils/helpers/common';
import { handleDownloadSingleFile } from '@/utils/helpers/fileHelper';
import { decodeFileName } from '@/utils/helpers/globalHelper';

import CheckedIcon from '@/assets/icons/Checked.svg';
import IconPaperclip from '@/assets/icons/Paperclip.svg';
import WarningWaitingApprove from '@/assets/icons/WarningWaitingApprove.svg';
import CloseIcon from '@/assets/icons/close.svg';

import { relativePath } from '../../../utils';
import CommonViewInfo from '../../CommonViewInfor';
import { gender, language } from '../../constants';
import { LOCAL_REPRESENTATIVE_FIELDS } from '../step-three/constants';
import { APPLICANT_FIELDS, ASSIGNMENT_INFO_FIELDS } from '../step-two/constants';

interface ILabelTitle {
  title: string;
  questions?: number;
  total?: number;
  size?: number | string;
}
const LabelTitle = (props: ILabelTitle) => {
  const { title, questions, total, size = 18 } = props;
  const { t } = useTranslation();
  return (
    <div className='flex flex-row'>
      <span className={`subtitle-${size}`}>{t(title)}</span>
      {!!total && (
        <div className='ml-[8px] pr-[8px] pl-[8px] bg-[#DBDBE0] rounded text-[14px] font-bold'>
          {questions}/{total}
        </div>
      )}
    </div>
  );
};

export default function ScenarioAStepSix() {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext();
  const { user } = useAuthorization();
  const { countryOptions } = useOptionsGlobal();
  const travelTypes = useAppSelector((state) => state?.projectV2.travelTypes.data);
  const [activeKey, setActiveKey] = useState<string[]>(['1', '2', '3', '4', '5']);
  const renderExpandIcon = (isActive: boolean | undefined) => {
    return <CaretDown type='caret-right' className={`${isActive ? 'rotate-0' : '-rotate-90'}`} />;
  };
  const ITEM_CLASS_NAME = '!rounded-[8px] !border-[1px] !border-solid !border-[#DBDBE0] my-4';
  const PATHS = {
    PROJECT_APPLICANT: relativePath('projectApplicant', 'steps[0]'),
    VISA_CATEGORY: relativePath('visaCategoryName', 'steps[0]'),
    TRAVEL_TYPE: relativePath('travelTypeId', 'steps[0]'),
    PROJECT_ASSIGNMENT_INFO: relativePath('projectAssignmentInfo', 'steps[0]'),
    PROJECT_LOCAL_SUPPORTER: relativePath('projectLocalSupporters', 'steps[1]'),
    GO_WITH_LOCAL_SUPPORTER: relativePath('goWithLocalSupporter', 'steps[1]'),
    PROJECT_STAKEHOLDERS: relativePath('projectStakeholders', 'steps[2]'),
    HAS_OTHER_STAKE_HOLDER: relativePath('hasOtherStakeHolder', 'steps[2]'),
    NOTE: relativePath('note', 'steps[3]'),
    PROJECT_ATTACHMENTS: relativePath('projectAttachments', 'steps[3]')
  };
  const [noteValue] = watch([PATHS.NOTE]);
  const counters = (index: number = 0) => {
    let questionsLocalSupporters = 0;
    const { name, address, email, language } = getValues(`${PATHS.PROJECT_LOCAL_SUPPORTER}.${index}`) ?? {};
    if (name) questionsLocalSupporters++;
    if (address) questionsLocalSupporters++;
    if (email) questionsLocalSupporters++;
    if (language && index === 0) questionsLocalSupporters++;
    const results = {
      applicantInformation: {
        total: 2,
        questions: [user?.name, user?.organizationId].filter(Boolean)?.length
      },
      projectApplicant: {
        total: APPLICANT_FIELDS?.length,
        questions: watch(APPLICANT_FIELDS.map((field) => relativePath(field, `${PATHS.PROJECT_APPLICANT}`))).filter(Boolean)?.length
      },
      projectAssignmentInfo: {
        total: ASSIGNMENT_INFO_FIELDS?.length,
        questions: watch(ASSIGNMENT_INFO_FIELDS.map((field) => relativePath(field, `${PATHS.PROJECT_ASSIGNMENT_INFO}`))).filter(Boolean)?.length
      },
      visaCategoryName: {
        total: 1,
        questions: watch(PATHS.VISA_CATEGORY) ? 1 : 0
      },
      projectLocalSupporters: {
        total: LOCAL_REPRESENTATIVE_FIELDS?.length,
        questions: questionsLocalSupporters
      },
      projectStakeholders: {
        total: 1,
        questions: watch(`${PATHS.PROJECT_STAKEHOLDERS}[${index}].stakeholderEmail`) ? 1 : 0
      },
      note: {
        total: 1,
        questions: DataViewer.isEmptyHTML(noteValue) ? 0 : 1
      },
      projectAttachments: {
        total: 1,
        questions: watch(PATHS.PROJECT_ATTACHMENTS)?.length ? 1 : 0
      },
      travelType: {
        total: 1,
        questions: watch(PATHS.TRAVEL_TYPE) ? 1 : 0
      }
    };
    return results;
  };
  const handleChange = (key: string | string[]) => {
    setActiveKey(Array.isArray(key) ? key : [key]);
  };
  const showLabelItem = (value: string | number, options: any[], valueKey: string = 'value', labelKey: string = 'label') => {
    const item = options.find((item: any) => item.value === value);
    return item ? item[labelKey] : '';
  };
  const renderItems = () => {
    const applicantInformationSection = {
      key: '1',
      label: (
        <LabelTitle
          title={t('basic_information:applicant_information')}
          total={counters().applicantInformation.total}
          questions={counters().applicantInformation.questions}
        ></LabelTitle>
      ),
      className: ITEM_CLASS_NAME,
      children: (
        <Row gutter={16}>
          <Col span={12}>
            <FormInput
              value={user?.name}
              disabled
              name='projectApplicant.lastNameRomaji'
              labelTx='basic_information:applicant_name'
              placeholderTx=''
            />
          </Col>
          <Col span={12}>
            <FormSelect
              disabled
              value={user?.organizationName}
              options={[]}
              name=''
              label={t('basic_information:basic_information') || ''}
              placeholder='project:add:applicant_representative:example_taro_passport'
            />
          </Col>
        </Row>
      )
    };
    const projectApplicantSection = {
      key: '2',
      label: <LabelTitle title={t('project:scenario_b:applicant_title')}></LabelTitle>,
      className: ITEM_CLASS_NAME,
      children: (
        <>
          <BaseCard
            title={String(t('basic_information:applicant_infomation'))}
            total={counters().projectApplicant.total}
            questions={counters().projectApplicant.questions}
          >
            <CommonViewInfo
              columns={[
                {
                  items: [
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.lastName`), label: 'basic_information:last' },
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.firstName`), label: 'project:add:applicant_representative:first_name' },
                    {
                      title: getValues(`${PATHS.PROJECT_APPLICANT}.lastNameRomaji`),
                      label: 'project:add:applicant_representative:last_name_romaji'
                    },
                    {
                      title: getValues(`${PATHS.PROJECT_APPLICANT}.firstNameRomaji`),
                      label: 'project:add:applicant_representative:first_name_romaji'
                    },
                    {
                      title: showLabelItem(getValues(`${PATHS.PROJECT_APPLICANT}.primaryNational`), countryOptions),
                      label: 'project:add:applicant_representative:nationality'
                    },
                    {
                      title: showLabelItem(getValues(`${PATHS.PROJECT_APPLICANT}.secondaryNational`), countryOptions),
                      label: 'project:add:applicant_representative:second_nationality'
                    },
                    {
                      title: getValues(`${PATHS.PROJECT_APPLICANT}.dateOfBirth`)
                        ? convertObjDatetoString(getValues(`${PATHS.PROJECT_APPLICANT}.dateOfBirth`))
                        : '',
                      label: 'project:add:applicant_representative:date_of_birth'
                    },
                    {
                      title: showLabelItem(getValues(`${PATHS.PROJECT_APPLICANT}.gender`), gender),
                      label: 'project:add:applicant_representative:sex'
                    },
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.postalCode`), label: 'basic_information:postal_code_number' },
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.prefecture`), label: 'postal_code:prefecture' },
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.prefectureRoman`), label: 'postal_code:prefecture_roman' }
                  ]
                },
                {
                  items: [
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.city`), label: 'postal_code:city' },
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.cityRoman`), label: 'postal_code:city_roman' },
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.suburb`), label: 'postal_code:suburb' },
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.suburbRoman`), label: 'postal_code:suburb_roman' },
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.streetAddress`), label: 'postal_code:street_address' },
                    { title: getValues(`${PATHS.PROJECT_APPLICANT}.apartmentName`), label: 'postal_code:office' },
                    {
                      title: getValues(`${PATHS.PROJECT_APPLICANT}.placeOfResidence`),
                      label: 'project:add:applicant_representative:resident_registration_place'
                    },
                    {
                      title: getValues(`${PATHS.PROJECT_APPLICANT}.currentCompany`),
                      label: 'project:add:applicant_representative:current_company'
                    },
                    {
                      title: getValues(`${PATHS.PROJECT_APPLICANT}.departureDate`)
                        ? dayjs(getValues(`${PATHS.PROJECT_APPLICANT}.departureDate`)).format('YYYY/MM/DD')
                        : '',
                      label: 'project:add:applicant_representative:departure_date'
                    }
                  ]
                }
              ]}
            />
          </BaseCard>
          <BaseCard
            title={t('project:scenario_b:assignment_information')}
            total={counters().projectAssignmentInfo.total}
            questions={counters().projectAssignmentInfo.questions}
            className='mt-[8px]'
          >
            <CommonViewInfo
              columns={[
                {
                  items: [
                    {
                      title: getValues(`${PATHS.PROJECT_ASSIGNMENT_INFO}.companyName`),
                      label: 'basic_information:assignment_training_company_name'
                    },
                    {
                      title: showLabelItem(getValues(`${PATHS.PROJECT_ASSIGNMENT_INFO}.countryId`), countryOptions),
                      label: 'basic_information:destination_training_country'
                    },
                    { title: getValues(`${PATHS.PROJECT_ASSIGNMENT_INFO}.address`), label: 'basic_information:assignment_training_city' }
                  ]
                }
              ]}
            />
          </BaseCard>
          <BaseCard
            title={t('project:scenario_a:visa_information')}
            total={counters().visaCategoryName.total}
            questions={counters().visaCategoryName.questions}
            className='mt-[8px]'
          >
            <CommonViewInfo
              columns={[
                {
                  items: [{ title: watch(`${PATHS.VISA_CATEGORY}`), label: 'project:scenario_b:visa_category:label' }]
                }
              ]}
            />
          </BaseCard>
          <BaseCard
            title={t('basic_information:travel_type')}
            total={counters().travelType.total}
            questions={counters().travelType.questions}
            className='mt-[8px]'
          >
            <CommonViewInfo
              columns={[
                {
                  items: [{ title: showLabelItem(watch(`${PATHS.TRAVEL_TYPE}`), travelTypes), label: 'basic_information:travel_type' }]
                }
              ]}
            />
          </BaseCard>
        </>
      )
    };

    const projectLocalSupporterSection = {
      key: '3',
      label: <LabelTitle title={t('project:scenario_b:local_supporter')}></LabelTitle>,
      className: ITEM_CLASS_NAME,
      children: (
        <>
          {getValues(PATHS.PROJECT_LOCAL_SUPPORTER).map((field: any, index: number) => {
            const keyItem = `project-local-supporter-${index}`;
            return (
              <BaseCard
                className='mb-3'
                key={keyItem}
                title={`${t('basic_information:local_contact')} ${index + 1}`}
                total={index === 0 ? 4 : 3}
                questions={counters(index).projectLocalSupporters.questions}
              >
                <CommonViewInfo
                  columns={[
                    {
                      items: [
                        { title: field.name, label: 'project:add:local_representative:name' },
                        { title: field.email, label: 'project:add:local_representative:email' }
                      ]
                    },
                    {
                      items: [
                        { title: field.address, label: 'project:add:local_representative:address' },
                        {
                          title: index === 0 ? showLabelItem(field.language, language) : ' ',
                          label: index === 0 ? 'project:add:local_representative:language' : ' '
                        }
                      ]
                    }
                  ]}
                />
              </BaseCard>
            );
          })}
        </>
      )
    };
    const uniqueStakeholders = lodash.uniqWith(
      getValues(PATHS.PROJECT_STAKEHOLDERS)?.filter((item: IProjectStakeholder) => item.stakeholderEmail),
      (accountA: IProjectStakeholder, accountB: IProjectStakeholder) =>
        accountA.stakeholderEmail === accountB.stakeholderEmail && !accountA.rejected && !accountB.rejected
    );
    const projectStakeholdersSection = {
      key: '4',
      label: <LabelTitle title={t('stakeholder:title')}></LabelTitle>,
      className: ITEM_CLASS_NAME,
      children: (
        <>
          <BaseCard title={`${t('stakeholder:title')} ${1}`} total={1} questions={1}>
            <CommonViewInfo
              columns={[
                {
                  items: [
                    { title: user?.email || '', label: 'basic_information:email_address' },
                    {
                      title: (
                        <div className='flex items-center overflow-hidden'>
                          <span className='mr-2 truncate max-w-full font-semibold whitespace-pre'>{user?.name}</span>
                          <span className='w-[18px] h-[18px]'>
                            <CheckedIcon width={18} height={18} className='icon-check' />
                          </span>
                        </div>
                      ),
                      label: 'basic_information:username'
                    }
                  ]
                }
              ]}
            />
          </BaseCard>
          {uniqueStakeholders?.map((field: any, index: number) => {
            let items: any = [];

            const itemEmail = {
              title: (
                <div className='flex items-center'>
                  <AppTooltip className='mr-4 truncate' title={field.stakeholderEmail}>
                    {field.stakeholderEmail}
                  </AppTooltip>{' '}
                  <span className='w-[18px] h-[18px]'>
                    {field.rejected ? <CloseIcon width={18} height={18} /> : field.waitCreateAccount && <WarningWaitingApprove />}
                  </span>
                </div>
              ),
              label: 'basic_information:email_address'
            };
            const itemText = {
              title: (
                <>
                  {DataViewer.display(
                    <div className={`${field.rejected ? 'text-negative' : 'text-warning'} font-medium`}>
                      {t(`${field.rejected ? 'basic_information:account_rejected' : 'project_management:required_documents_tab:waiting_approve'}`)}
                    </div>
                  )}
                </>
              ),
              label: ' '
            };
            const itemReason = {
              title: (
                <>
                  {DataViewer.display(
                    <div className='flex'>
                      <span className='mr-4 !font-normal min-w-[93px]'>{t('basic_information:account_reason')}</span>
                      <span dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(field.rejectReason) }}></span>
                    </div>
                  )}
                </>
              ),
              label: ' '
            };
            const isInactive = field.stakeHolderStatus === false && !field?.waitCreateAccount && !field?.rejected;
            const useName = `${isInactive ? `(${t('announcement:invalid')})` : ''} ${field.stakeholderName}`;
            const itemUsername = {
              title: (
                <div className='flex items-center'>
                  <AppTooltip className='mr-2 truncate max-w-full font-semibold whitespace-pre' styles={{ whiteSpace: 'pre-wrap' }} title={useName}>
                    {useName}
                  </AppTooltip>{' '}
                  <span className='w-[18px] h-[18px]'>
                    {!field.rejected && !field.waitCreateAccount && <CheckedIcon width={18} height={18} className='icon-check' />}
                  </span>
                </div>
              ),
              label: 'basic_information:username'
            };
            const itemCompanyName = { title: field.stakeholderCompanyName, label: 'basic_information:company_name' };
            const itemRole = { title: field.roleName, label: 'account_list:role' };

            if (!field.rejected && !field.waitCreateAccount) {
              items = [itemEmail, itemUsername];
            } else if (field.rejected && !field.waitCreateAccount) {
              items = [itemEmail, itemText, itemReason, itemUsername, itemCompanyName, itemRole];
            } else if (!field.rejected && field.waitCreateAccount) {
              items = [itemEmail, itemText, itemUsername, itemCompanyName, itemRole];
            }
            const keyItem = `stakeholder-${index}`;
            return (
              <BaseCard
                key={keyItem}
                className='mt-[12px]'
                title={`${t('stakeholder:title')} ${index + 2}`}
                total={counters(index).projectStakeholders.total}
                questions={counters(index).projectStakeholders.questions}
              >
                <CommonViewInfo
                  columns={[
                    {
                      colSpan: 24,
                      items
                    }
                  ]}
                />
              </BaseCard>
            );
          })}
        </>
      )
    };
    const attachmentFileAndNoteSection = {
      key: '5',
      label: <LabelTitle title={t('basic_information:additional_information')}></LabelTitle>,
      className: ITEM_CLASS_NAME,
      children: (
        <>
          <BaseCard title={t('basic_information:contact_information')} total={counters().note.total} questions={counters().note.questions}>
            <CommonViewInfo
              columns={[
                {
                  items: [
                    {
                      title: <div dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(getValues(PATHS.NOTE)) }} />,
                      label: 'basic_information:special_note'
                    }
                  ],
                  colSpan: 24
                }
              ]}
            />
          </BaseCard>
          <BaseCard className='mt-[12px]' title={t('basic_information:attachment_file')}>
            <CommonViewInfo
              columns={[
                {
                  items: [
                    {
                      title: getValues(PATHS.PROJECT_ATTACHMENTS)?.length ? (
                        <>
                          {getValues(PATHS.PROJECT_ATTACHMENTS).map((file: any, i: number) => {
                            const keyItem = `attachment-file-${i}`;
                            return (
                              <div key={keyItem} className='flex justify-start items-center'>
                                <p className='mr-[8px] w-[18px]'>{i + 1}</p>
                                <div className='w-[500px] h-[38px] grow flex justify-start items-center border border-solid border-t-0	border-r-0	border-l-0 border-b-gray2'>
                                  <div className='flex justify-center items-center'>
                                    <IconPaperclip width={18} height={18} />
                                  </div>
                                  <AppTooltip className='px-[10px] truncate' title={decodeFileName(file.name)}>
                                    <span
                                      className='cursor-pointer'
                                      onClick={() => {
                                        return handleDownloadSingleFile(file.blobPath as string, file.name);
                                      }}
                                    >
                                      {decodeFileName(file.name)}
                                    </span>
                                  </AppTooltip>
                                  <div className='flex justify-center items-center ml-auto'>
                                    <Button
                                      type='text'
                                      icon={<IconDownload className='h-[20px] w-[20px] download hover:cursor-pointer' />}
                                      onClick={() => {
                                        return handleDownloadSingleFile(file.blobPath as string, file.name);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        '--'
                      ),
                      label: getValues(PATHS.PROJECT_ATTACHMENTS).length ? '' : 'basic_information:attachment_file'
                    }
                  ]
                }
              ]}
              customTitle='body-400'
            />
          </BaseCard>
        </>
      )
    };
    let items = [
      applicantInformationSection,
      projectApplicantSection,
      projectLocalSupporterSection,
      projectStakeholdersSection,
      attachmentFileAndNoteSection
    ];
    return items.filter((item) => {
      if (item.key === '3' && !getValues(PATHS.PROJECT_LOCAL_SUPPORTER)?.length) return false;
      return true;
    });
  };
  return (
    <div className='mt-[12px]'>
      <Collapse
        activeKey={activeKey}
        collapsible='header'
        className='bg-white'
        onChange={handleChange}
        items={renderItems()}
        bordered={false}
        expandIcon={({ isActive }) => renderExpandIcon(isActive)}
      />
    </div>
  );
}
