import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getProjectPublicInfo } from '@/utils/services/ProjectApiService';

import { ILinkedListId, LINKED_LIST_ID } from './BreadCrumb';

type Props = { path: ILinkedListId; className?: string };
const projectLogs: { [key: string]: string } = {};
const BreadCrumbTitle = (props: Props) => {
  const { path, className } = props;
  const { t } = useTranslation();
  const [titlePrefix, setTitlePrefix] = useState<string>('');
  const [titleSuffix, setTitleSuffix] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();

  useEffect(() => {
    const pathMap: { [key: string]: string } = {
      [LINKED_LIST_ID.VIEW_PROJECT]: 'sider:project_details',
      [LINKED_LIST_ID.ADD_TASK_OF_PROJECT]: 'sider:project_details',
      [LINKED_LIST_ID.EDIT_TASK_OF_PROJECT]: 'sider:project_details',
      [LINKED_LIST_ID.VIEW_TASK_OF_PROJECT]: 'sider:project_details',
      [LINKED_LIST_ID.EDIT_PROJECT]: 'basic_information:isDraft'
    };
    let prefix = pathMap[path] ? t(pathMap[path]) : '';

    setTitlePrefix(prefix);
  }, [path]);

  useEffect(() => {
    const { id, idProject } = params ?? {};
    if (id && projectLogs[id]) return setTitleSuffix(`（${projectLogs[id]}）`);
    if (id || idProject) {
      fetchProjectInfo(id ?? idProject ?? '');
    }
  }, [params]);

  const fetchProjectInfo = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await getProjectPublicInfo(id);
      const { code } = data || {};
      if (!code) return;
      projectLogs[id] = code;
      setTitleSuffix(`（${code}）`);
    } catch (error) {
      setTitleSuffix('');
    } finally {
      setLoading(false);
    }
  };
  return <span className={className}>{`${titlePrefix}${loading ? '' : titleSuffix}`}</span>;
};

export default BreadCrumbTitle;
