import { DefaultOptionType } from 'antd/es/select';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import BaseInput, { IBaseInputProps } from '@/components/common/base-input';
import FormItem from '@/components/common/form-item';
import { ISelectCustomProps } from '@/components/form-select/SelectCustom';

import { MAX_LENGTH_TEXT } from '../../constants';
import { ValidateRules } from '../../helpers';
import { AnswerType } from '../../modals';
import Dropdown from './Dropdown';

export interface ITextProps extends IBaseInputProps {
  name: string;
  type?: AnswerType;
  maxLength?: number;
  unitProps?: ISelectCustomProps;
  onChangeUnit?: (value: string, label: string) => void;
}

const UnitSelect: React.FC<ITextProps> = ({
  value,
  type,
  name,
  readOnly,
  placeholder = '________',
  maxLength = MAX_LENGTH_TEXT,
  unitProps,
  onChangeUnit,
  className,
  ...restProps
}) => {
  const { t } = useTranslation();
  const renderInput = () => {
    return (
      <BaseInput
        id={`${name}.value`}
        readOnly={readOnly}
        placeholder={readOnly ? '' : placeholder}
        hideBorder
        defaultValue={value}
        value={value}
        margin={{ left: 0 }}
        className={classNames(className ?? '', 'text-right')}
        {...restProps}
      />
    );
  };

  const validateObj =
    type && ValidateRules[type]
      ? {
          [type]: ValidateRules[type]
        }
      : undefined;

  const unitName = name.replace('.answeredEn', '.answered');

  return (
    <div className='flex space-x-2 items-baseline'>
      <div className='w-[80px]'>
        <FormItem
          label={t('project:question_detail:answer_title') ?? ''}
          showErrorOption='icon'
          showLabel={false}
          name={`${name}.value`}
          rules={{ maxLength, validate: validateObj }}
          needTrimValue={true}
        >
          {renderInput()}
        </FormItem>
      </div>
      <div className='w-[100px]'>
        <Dropdown
          name={`${unitName}.unit`}
          id={`${unitName}.unit`}
          readOnly={readOnly}
          placeholder={readOnly ? '' : t('project:question_detail:unit_select:place_holder')}
          value={value}
          onChange={(value, option) => {
            onChangeUnit?.(value, (option as DefaultOptionType)?.label?.toString() ?? '');
          }}
          {...unitProps}
        />
      </div>
    </div>
  );
};

export default UnitSelect;
