import { Col, Row, Space } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCard } from '@/components/base-card/BaseCard';
import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import FormDateInput from '@/components/common/date-input';
import { FormInputSelect } from '@/components/form-input-select/FormInputSelect';
import { FormInput } from '@/components/form-input/FormInput';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { isEmptyField } from '@/utils/helpers/globalHelper';

import PlusIcon from '@/assets/icons/PlusIcon.svg';

import { GENDER, GO_WITH_FAMILY } from '../../project-list/constants';
import { MAX_FIELD_PERSON_NUMBER, PROJECT_DEFAULT_VALUE } from '../contants';

type Props = {
  formMethod: any;
};

const FamilyInformationComponent = forwardRef((props: Props, ref) => {
  const { formMethod } = props;
  const [isWithFamily, setIsWithFamily] = useState<boolean>(false);
  const { t } = useTranslation();
  const { countryOptions } = useOptionsGlobal();
  const gender = [
    {
      label: t('common:male'),
      value: GENDER.male,
      id: GENDER.male
    },
    {
      label: t('common:female'),
      value: GENDER.female,
      id: GENDER.female
    }
  ];
  const watchGoWithFamily = formMethod.watch('goWithFamily');
  const goWithFamily = [
    {
      label: t('project:add:family_information:hasFamily'),
      value: GO_WITH_FAMILY.YES,
      id: GO_WITH_FAMILY.YES
    },
    {
      label: t('project:add:family_information:noFamily'),
      value: GO_WITH_FAMILY.NO,
      id: GO_WITH_FAMILY.NO
    }
  ];
  const watchFieldGoWithFamily = formMethod.watch(['goWithFamily']);
  const watchFieldGoWithFamilyValid = watchFieldGoWithFamily.filter((field: any) => field);
  const { fields, append, remove } = useFieldArray({
    name: 'projectFamilies',
    control: formMethod.control
  });

  useEffect(() => {
    if (watchGoWithFamily === GO_WITH_FAMILY.YES) {
      setIsWithFamily(true);
    } else {
      formMethod.setValue('familyAttendanceTime', null);
      formMethod.setValue('projectFamilies', PROJECT_DEFAULT_VALUE.projectFamilies);
      formMethod.clearErrors('projectFamilies');
      setIsWithFamily(false);
    }
  }, [watchGoWithFamily]);

  const addNewProjectLocalSupporters = () => {
    if (fields.length >= MAX_FIELD_PERSON_NUMBER) return;
    append(PROJECT_DEFAULT_VALUE.projectFamilies[0]);
  };

  const watchFields = (index: number) => {
    const fields = [
      `projectFamilies.${index}.lastName`,
      `projectFamilies.${index}.firstName`,
      `projectFamilies.${index}.lastNameRomaji`,
      `projectFamilies.${index}.firstNameRomaji`,
      `projectFamilies.${index}.primaryNational`,
      `projectFamilies.${index}.secondaryNational`,
      `projectFamilies.${index}.dateOfBirth`,
      `projectFamilies.${index}.gender`,
      `projectFamilies.${index}.attendanceTime`
    ];
    const watchFields = formMethod.watch(fields);
    const watchFieldsValid = watchFields.filter((field: any) => !isEmptyField(field));
    return watchFieldsValid.length;
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <BaseCollapse
      ref={ref}
      className='gap-2'
      id='projectFamilies'
      title={t('project:add:family_information:title')}
      total={1}
      questions={watchFieldGoWithFamilyValid.length}
      isError={!!formMethod?.formState?.errors?.projectFamilies}
    >
      <Row gutter={16}>
        <Col span={12}>
          <FormInputSelect
            name='goWithFamily'
            label={t('project:add:family_information:go_with_family')}
            options={goWithFamily}
            placeholderTx={String(t('basic_information:place_holder_go_with_family'))}
            width={'50%'}
          />
        </Col>
        <Col span={12}>
          <FormInput
            defaultValue={formMethod.getValues(`familyAttendanceTime`)}
            name={'familyAttendanceTime'}
            disabled={!watchFieldGoWithFamilyValid.length || watchGoWithFamily === GO_WITH_FAMILY.NO}
            labelTx='project:add:family_information:attendance_time'
            placeholderTx='project:add:family_information:attendance_time_placeholder'
          />
        </Col>
      </Row>
      {isWithFamily &&
        fields.map((field: any, index: number) => (
          <BaseCard
            key={field.id}
            showIconRemove={fields.length > 1}
            index={index}
            onRemove={handleRemove}
            title={t('project:add:family_information:family_number', { number: index + 1 })}
            total={8}
            questions={watchFields(index)}
          >
            <div className='grid gap-4'>
              <Row gutter={16}>
                <Col span={6}>
                  <FormInput
                    defaultValue={formMethod.getValues(`projectFamilies.${index}.lastName`)}
                    name={`projectFamilies.${index}.lastName`}
                    labelTx='project:add:applicant_representative:last_name'
                    placeholderTx='project:add:applicant_representative:example_yamada'
                  />
                </Col>
                <Col span={6}>
                  <FormInput
                    defaultValue={formMethod.getValues(`projectFamilies.${index}.firstName`)}
                    name={`projectFamilies.${index}.firstName`}
                    labelTx='project:add:applicant_representative:first_name'
                    placeholderTx='project:add:applicant_representative:example_taro'
                  />
                </Col>
                <Col span={6}>
                  <FormInput
                    defaultValue={formMethod.getValues(`projectFamilies.${index}.lastNameRomaji`)}
                    name={`projectFamilies.${index}.lastNameRomaji`}
                    labelTx='project:add:applicant_representative:last_name_romaji'
                    placeholderTx='project:add:applicant_representative:example_yamada_passport_notation'
                  />
                </Col>
                <Col span={6}>
                  <FormInput
                    defaultValue={formMethod.getValues(`projectFamilies.${index}.firstNameRomaji`)}
                    name={`projectFamilies.${index}.firstNameRomaji`}
                    labelTx='project:add:applicant_representative:first_name_romaji'
                    placeholderTx='basic_information:place_holder_first_name_romaji_families'
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <FormInputSelect
                    name={`projectFamilies.${index}.primaryNational`}
                    label={t('project:add:applicant_representative:nationality')}
                    options={countryOptions}
                    placeholderTx={String(t('project:add:applicant_representative:example_japan'))}
                    width={'50%'}
                  />
                </Col>
                <Col span={6}>
                  <FormInputSelect
                    name={`projectFamilies.${index}.secondaryNational`}
                    label={t('project:add:applicant_representative:second_nationality')}
                    options={countryOptions}
                    placeholderTx={String(t('project:add:applicant_representative:example_america'))}
                    width={'50%'}
                  />
                </Col>
                <Col span={6}>
                  <FormDateInput name={`projectFamilies.${index}.dateOfBirth`} labelTx='project:add:applicant_representative:date_of_birth' />
                </Col>
                <Col span={6}>
                  <FormInputSelect
                    name={`projectFamilies.${index}.gender`}
                    label={t('project:add:applicant_representative:sex')}
                    options={gender}
                    placeholderTx={String(t('project:add:please_select_field', { field: t('project:add:applicant_representative:sex') }))}
                    width={'50%'}
                  />
                </Col>
              </Row>
            </div>
          </BaseCard>
        ))}
      {isWithFamily && (
        <Space size={16}>
          <div className='min-w-[140px]'>
            <BaseButton
              icon={<PlusIcon />}
              disabled={fields.length === MAX_FIELD_PERSON_NUMBER}
              type='secondary'
              block
              size='medium'
              onClick={addNewProjectLocalSupporters}
            >
              {t('project:add:family_information:add_family_member_button')}
            </BaseButton>
          </div>
        </Space>
      )}
    </BaseCollapse>
  );
});

export default FamilyInformationComponent;
