import Quill from 'quill';

const BlockEmbed: any = Quill.import('blots/block/embed');

export const STATUS_EMBED_IMAGE_BLOT = {
  UPLOADING: 'uploading',
  UPLOADED: 'uploaded',
  ERROR: 'error'
} as const;
export type EmbedImageBlotStatus = (typeof STATUS_EMBED_IMAGE_BLOT)[keyof typeof STATUS_EMBED_IMAGE_BLOT];

const EMBED_IMAGE_BLOT_TYPE = 'quill-type';
const EMBED_BLOT_TYPE = 'embed-image';
const EMBED_DEFAULT_SIZE = 'h-[90px] w-[90px]';
const EMBED_DEFAULT_IMAGE_SIZE = 'h-[72px] w-[72px]';
const EMBED_TEXT_LOADING = '読み込み中';
const EMBED_TEXT_ERROR = 'エラー';

export default class EmbedImageBlot extends BlockEmbed {
  static blotName = 'EmbedImageBlot';
  static tagName = 'div';

  private static _generateLabel(url: string, status: EmbedImageBlotStatus, node: HTMLElement, error?: string) {
    const wrapper = document.createElement('div');
    wrapper.className = 'embed-attachment-label flex items-center';

    let element;

    if (status === STATUS_EMBED_IMAGE_BLOT.UPLOADING) {
      wrapper.className += ' h-full';
      element = document.createElement('div');
      element.className = `loading--image`;
      element.innerText = EMBED_TEXT_LOADING;
    }
    if (status === STATUS_EMBED_IMAGE_BLOT.UPLOADED && url) {
      wrapper.className += ' p-[8px]';
      element = document.createElement('img');
      element.src = url;
      element.className = `object-cover ${EMBED_DEFAULT_IMAGE_SIZE}`;
    }
    if (status === STATUS_EMBED_IMAGE_BLOT.ERROR) {
      wrapper.className += ' relative h-full border border-solid border-negative rounded-[2px] w-full justify-center group';
      element = document.createElement('div');

      const imgError = document.createElement('div');
      imgError.className = 'relative embed-attachment-error';
      const textError = document.createElement('div');
      textError.className = 'text-center text-negative embed-text-error';
      textError.innerText = EMBED_TEXT_ERROR;

      element.appendChild(imgError);
      element.appendChild(textError);
    }

    if (element) wrapper.appendChild(element);
    return wrapper;
  }

  private static _generateActionBtn(node: HTMLElement, error?: string) {
    const parentNode = document.createElement('div');
    parentNode.className = `back-drop absolute -left-px -top-px ${EMBED_DEFAULT_IMAGE_SIZE} m-[8px] flex items-center justify-center opacity-0 group-hover:opacity-100 bg-[rgba(0,0,0,0.45)] rounded-[8px] group-hover:rounded-none group-hover:top-0 group-hover:left-0`;
    parentNode.setAttribute('title', error ?? '');
    const trashButton = document.createElement('div');
    // trash icon must required set background image at your css file
    trashButton.className = 'embed-attachment-action w-[18px] h-[18px] cursor-pointer';
    trashButton.addEventListener('click', () => {
      node.remove();
    });
    parentNode.appendChild(trashButton);

    return parentNode;
  }

  private static _packed(node: HTMLElement, status: EmbedImageBlotStatus, children: HTMLElement[]) {
    // Make the content non-editable
    node.setAttribute('contenteditable', 'false');
    node.className =
      'embed-attachment-container relative group select-none !inline-block mr-[8px] mb-[8px] overflow-hidden ' +
      ` ${EMBED_DEFAULT_SIZE} flex items-center justify-center gap-[8px] ` +
      ' bg-white p-[8px] ' +
      ' rounded-[2px] border border-dashed border-[darkgray] ';
    if (status === STATUS_EMBED_IMAGE_BLOT.UPLOADED) {
      node.className += '!p-0 border-solid overflow-hidden !border-[#D9D9D9]';
    }
    if (status === STATUS_EMBED_IMAGE_BLOT.ERROR) {
      node.className += '!border-negative';
    }
    if (status === STATUS_EMBED_IMAGE_BLOT.UPLOADING) {
      node.className += 'bg-[#FAFAFA] !border-[#D9D9D9]';
    }
    node.append(...children);
    return this;
  }

  static formats(node: HTMLElement) {
    const format = {
      url: node.getAttribute('data-url'),
      name: node.getAttribute('data-name'),
      status: node.getAttribute('data-status'),
      error: node.getAttribute('data-error')
    };
    return format;
  }

  static create({ url, status, name, error }: { url: string; status: EmbedImageBlotStatus; name: string; error?: string }) {
    const node = super.create();
    if (!name) return node;
    node.setAttribute('data-url', url);
    node.setAttribute('data-name', name);
    node.setAttribute('data-status', status);
    node.setAttribute('data-error', error);
    node.setAttribute(EMBED_IMAGE_BLOT_TYPE, EMBED_BLOT_TYPE);

    const label = this._generateLabel(url, status, node, error);

    if (status === STATUS_EMBED_IMAGE_BLOT.UPLOADING) {
      this._packed(node, status, [label]);
    } else {
      const trashButton = this._generateActionBtn(node, error);
      this._packed(node, status, [label, trashButton]);
    }
    return node;
  }

  static value(node: HTMLElement) {
    const type = node.getAttribute(EMBED_IMAGE_BLOT_TYPE);
    if (type !== EMBED_BLOT_TYPE) return null;
    return {
      url: node.getAttribute('data-url'),
      name: node.getAttribute('data-name'),
      status: node.getAttribute('data-status') as EmbedImageBlotStatus,
      error: node.getAttribute('data-error') as string,
      node
    };
  }
}

// required init for custom blots
EmbedImageBlot.blotName = 'EmbedImageBlot';
EmbedImageBlot.tagName = 'div';
