import { v4 as uuid } from 'uuid';

import { IRelativeField } from '@/components/common/postal-code-input';

export const PROJECT_DEFAULT_VALUE = {
  projectStakeholders: [
    {
      stakeholderEmail: '',
      canDelete: true,
      uid: uuid()
    }
  ],
  projectLocalSupporters: [
    {
      name: '',
      email: '',
      address: '',
      language: '',
      displayOrder: 0
    }
  ],
  projectFamilies: [
    {
      lastName: '',
      firstName: '',
      lastNameRomaji: '',
      firstNameRomaji: '',
      primaryNational: '',
      primaryNationalName: '',
      secondaryNational: '',
      secondaryNationalName: '',
      dateOfBirth: {
        year: null,
        month: null,
        day: null
      },
      attendanceTime: ''
    }
  ],
  projectAttachments: []
};

export const MAX_FIELD = 10;
export const MAX_FIELD_STAKE_HOLDER = 9;
export const MAX_FIELD_PERSON_NUMBER = 5;
export const FIRST_FIELD = 0;

export enum BASIC_INFO_SECTION_KEYS {
  INFO = 'info',
  REQUEST_CONTENT = 'request_content',
  APPLICANT_DETAIL = 'applicant_detail',
  ACCOMPANYING_FAMILY = 'accompanying_family',
  ASSIGNMENT_INFO = 'assignment_info',
  SUPPORT_INFO = 'support_info',
  VISA_INFO = 'visa_info',
  TRAVEL_TYPE_INFO = 'travel_type_info',
  ATTACHMENT_INFO = 'attachment_info'
}
export const WATCH_FIELDS_PROJECT_APPLICANT = [
  'projectApplicant.lastName',
  'projectApplicant.firstName',
  'projectApplicant.lastNameRomaji',
  'projectApplicant.firstNameRomaji',
  'projectApplicant.primaryNational',
  'projectApplicant.secondaryNational',
  'projectApplicant.dateOfBirth',
  'projectApplicant.gender',
  'projectApplicant.postalCode',
  'projectApplicant.prefecture',
  'projectApplicant.prefectureRoman',
  'projectApplicant.city',
  'projectApplicant.cityRoman',
  'projectApplicant.suburb',
  'projectApplicant.suburbRoman',
  'projectApplicant.streetAddress',
  'projectApplicant.apartmentName',
  'projectApplicant.placeOfResidence',
  'projectApplicant.currentCompany',
  'projectApplicant.departureDate'
];
export const WATCH_FIELDS_PROJECT_APPLICANT_REQUIRED = [
  'projectApplicant.lastName',
  'projectApplicant.firstName',
  'projectApplicant.lastNameRomaji',
  'projectApplicant.firstNameRomaji',
  'projectApplicant.currentCompany',
  'projectApplicant.postalCode',
  'projectApplicant.prefecture',
  'projectApplicant.prefectureRoman',
  'projectApplicant.city',
  'projectApplicant.cityRoman',
  'projectApplicant.suburb',
  'projectApplicant.suburbRoman',
  'projectApplicant.streetAddress',
  'projectApplicant.apartmentName'
];
export const SCENARIO_TYPES = {
  SCENARIO_A: 'scenario-a',
  SCENARIO_B: 'scenario-b',
  SCENARIO_C: 'scenario-c',
  SCENARIO_D: 'scenario-d',
  SCENARIO_E: 'scenario-e',
  EMPTY: 'empty'
} as const;
export type SCENARIO_TYPES = (typeof SCENARIO_TYPES)[keyof typeof SCENARIO_TYPES];
export const DEFAULT_VALUE_REGISTER_SCENARIO_E = {
  stepId: 2,
  userName: '',
  email: '',
  companyName: '',
  phoneNumber: '',
  requestContent: '',
  projectAttachments: []
};

export const POSTAL_CODE_RELATIVE_FIELDS: IRelativeField[] = [
  {
    name: 'projectApplicant.prefecture',
    key: 'prefecture'
  },
  {
    name: 'projectApplicant.prefectureRoman',
    key: 'prefecture_roman'
  },
  {
    name: 'projectApplicant.city',
    key: 'city'
  },
  {
    name: 'projectApplicant.cityRoman',
    key: 'city_roman'
  },
  {
    name: 'projectApplicant.suburb',
    key: 'suburb'
  },
  {
    name: 'projectApplicant.suburbRoman',
    key: 'suburb_roman'
  },
  {
    name: 'projectApplicant.streetAddress',
    key: 'street_address'
  },
  {
    name: 'projectApplicant.apartmentName',
    key: 'office'
  }
];
