import { IPages } from '@/utils/interfaces';

export enum QUERY_OPERATION {
  NONE = '',
  EQUAL = 'eq',
  NOT_EQUAL = 'neq',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'ncontains',
  START_WITH = 'sw',
  NOT_START_WITH = 'nsw',
  END_WITH = 'ew',
  NOT_END_WITH = 'new',
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  IN = 'in',
  NOT_IN = 'nin'
}

export enum QUERY_TYPE {
  NONE = '',
  TEXT = 'text',
  NUMBER = 'number',
  GUID = 'guid',
  BOOLEAN = 'boolean',
  DATETIME = 'datetime',
  NULLABLE_DATE = 'nullable_date'
}

export enum QUERY_CONDITION {
  AND = 'and',
  OR = 'or',
  EMPTY = ''
}

export enum QUERY_SORT {
  ASC = 'asc',
  DESC = 'desc'
}

export const ANTD_TO_QUERY_SORT = {
  descend: QUERY_SORT.DESC,
  ascend: QUERY_SORT.ASC
};

export const DEFAULT_PAGE_SIZE_OPTIONS = [50, 100, 150];
export const DEFAULT_PAGES: IPages = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE_OPTIONS[0]
};

export const DRAFT_PREFIX_FLAG = '一時保存';
export const UNPUBLISHED_PREFIX_FLAG = '未公開';
export const PHONE_INPUT_MASK = '999 9999 9999 9999';
export const POSTAL_CODE_MASK = '999-9999';

/** Value must be lowercase */
export const ROUTER_IDS = {
  PROJECT_LIST: 'project_list',
  TASK_LIST: 'task_list',
  TEMPLATE_LIST: 'template_list',
  COMPANY_MASTER_LIST: 'company_master_list',
  PHRASE_MASTER_LIST: 'phrase_master_list',
  ACCOUNT_MASTER_LIST: 'account_master_list',
  ANNOUNCEMENT_LIST: 'announcement_list',
  DOCUMENT_MASTER_LIST: 'document_master_list',
  TASK_MASTER_LIST: 'task_master_list',
  PROJECT_DOCUMENT_LIST: 'project_document_list',
  NOTIFICATION_SIDEBAR: 'notification_sidebar'
} as const;
export type RouterIdType = (typeof ROUTER_IDS)[keyof typeof ROUTER_IDS];

export const WINDOW_EVENTS = {
  BEFORE_UNLOAD: 'beforeunload',
  UNLOAD: 'unload',
  POP_STATE: 'popstate',
  STORAGE: 'storage',
  MESSAGE: 'message',
  ONLINE: 'online',
  OFFLINE: 'offline'
} as const;
export type WindowEvent = (typeof WINDOW_EVENTS)[keyof typeof WINDOW_EVENTS];

export const APPLICANT_GENDER = {
  MALE: 'male',
  FEMALE: 'female'
} as const;
export type APPLICANT_GENDER = (typeof APPLICANT_GENDER)[keyof typeof APPLICANT_GENDER];

export const APPLICANT_FAMILY = {
  GO_WITH_FAMILY: 'yes',
  GO_WITHOUT_FAMILY: 'no'
} as const;
export type APPLICANT_FAMILY = (typeof APPLICANT_FAMILY)[keyof typeof APPLICANT_FAMILY];

export interface ICustomError {
  type: string;
  message: string;
}
export class CustomError extends Error {
  type: string;

  constructor({ type, message }: ICustomError) {
    super(message);
    this.type = type;

    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

export const SESSION_STORAGE = {
  RETURN_URL: 'returnUrl',
  LOGGING_OUT: 'loggingOut'
};

export const TASK_TYPE = {
  TODO: 'TODO',
  SUPPORT: 'SUPPORT',
  MOT: 'MOT',
  OTHER: 'OTHER'
} as const;

export const TASK_TODO_SUPPORT = {
  [TASK_TYPE.TODO]: TASK_TYPE.TODO,
  [TASK_TYPE.SUPPORT]: TASK_TYPE.SUPPORT
} as const;

export const TASK_MOT_OTHER = {
  [TASK_TYPE.MOT]: TASK_TYPE.MOT,
  [TASK_TYPE.OTHER]: TASK_TYPE.OTHER
} as const;
