import { Col, Row } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { showBasePopup } from '@/components/base-popup/BasePopup';

import { TYPE } from '@/utils/constants/AppConstants';
import { _generateFilterSearchQuery, _generateQuery, _generateQueryCheckDuplicate } from '@/utils/helpers/globalHelper';

import { FormInputSelect } from '../../../../../components/form-input-select/FormInputSelect';
import { FormInput } from '../../../../../components/form-input/FormInput';
import ReferenceTemplateFilter from '../../../../../components/reference-template-filter/ReferenceTemplateFilter';
import useOptionsGlobal from '../../../../../hooks/useOptionsGlobal';
import { ICountry } from '../../../../../utils/interfaces/template';

interface IScheduleProps {
  method?: any;
  name: 'schedules';
  getDocumentOptions: (countryId: string) => void;
  getTemplateDetail: (templateId?: string) => void;
  fetchAssignmentForm: () => void;
  setDataCategories: (country: ICountry) => void;
  optionsVisaCategory: DefaultOptionType[];
  formOptions: DefaultOptionType[];
}

function AddEditScheduleTemplate(props: IScheduleProps) {
  const { method, getTemplateDetail, formOptions, optionsVisaCategory } = props;
  const [t] = useTranslation();
  const { countryOptions } = useOptionsGlobal();
  const { id } = useParams();

  const { countryId, templateName } = method?.watch();

  useEffect(() => {
    if (!templateName) return;
    method.trigger('templateName');
  }, [countryId]);

  const onSelectCountry = async (values: string) => {
    if (
      method?.watch('categoryId') ||
      method?.watch('templateId') ||
      method?.watch('patternId') ||
      method?.watch('materials')?.length ||
      method?.watch('tasks')?.length ||
      !values
    ) {
      const show = await showBasePopup({
        type: TYPE.CONFIRM,
        title: t('common:MSG_C_023.title') || '',
        msg: t('common:MSG_C_023.content') ?? ''
      });
      if (show === TYPE.CONFIRM) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <FormInputSelect
            required
            allowClear={false}
            disabled={Boolean(id)}
            label={t('template_layout:country_name')}
            name='countryId'
            id='countryId'
            options={countryOptions}
            placeholder={t('placeholder:select', { field: t('template_layout:country_name') }) as string}
            isConfirmPopup={true}
            handleSelect={(values: any) => {
              return onSelectCountry(values);
            }}
          />
        </Col>
        <Col span={8}>
          <FormInputSelect
            options={optionsVisaCategory}
            name='categoryId'
            disabled={!countryId}
            labelTx='basic_information:category'
            placeholder={t('placeholder:select', { field: t('basic_information:category') }) as string}
          />
        </Col>
        <Col span={8}>
          <FormInputSelect
            name='formId'
            labelTx='template_layout:form'
            options={formOptions}
            placeholder={t('placeholder:select', { field: t('template_layout:form') }) as string}
          />
        </Col>
      </Row>
      <Row gutter={16} className='mt-3'>
        <Col span={12}>
          <FormInput
            required
            name='templateName'
            id='templateName'
            labelTx='template:template_name'
            placeholder={t('placeholder:text_box', { field: t('template:template_name') }) as string}
            defaultValue={method?.getValues('templateName')}
          />
        </Col>
        <Col span={12}>
          <ReferenceTemplateFilter
            getTemplateDetail={getTemplateDetail}
            disabled={!countryId}
            dataBasicInfo={{ countryId }}
            method={method}
            categoryOptions={optionsVisaCategory}
            formOptions={formOptions}
          />
        </Col>
      </Row>
    </div>
  );
}

export default AddEditScheduleTemplate;
