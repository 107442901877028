import classNames from 'classnames';
import React from 'react';

export interface ITableItem {
  className?: string;
  isTitle?: boolean;
  content: React.ReactNode;
  style?: any;
}

type WidthType = number | string;

export interface ITableTypeProps {
  items: ITableItem[][];
  className?: string;
  fixedWidth?: WidthType[];
}

const Table: React.FC<ITableTypeProps> = ({ items, fixedWidth }) => {
  const maxCol = items.reduce((max, item) => Math.max(max, item.length), 0);

  const getWidth = (width: WidthType) => {
    if (isNaN(+width)) {
      return width;
    }

    if (!width || +width < 0) {
      return '100%';
    }

    return `${width}px`;
  };

  return (
    <div className={classNames('w-full', 'h-full')}>
      <table className='sub-table h-full table-fixed'>
        {fixedWidth && (
          <colgroup>
            {fixedWidth.map((width: number | string) => (
              <col style={{ width: getWidth(width) }} />
            ))}
          </colgroup>
        )}
        <tbody>
          {items.map((row, index) => (
            <tr key={index}>
              {row.map((cell, cIndex) => {
                return (
                  <td
                    key={cIndex}
                    className={classNames(cell.className)}
                    style={{
                      whiteSpace: cell?.isTitle ? 'initial' : undefined,
                      ...cell?.style,
                      height: '100%'
                    }}
                    colSpan={cIndex === row.length - 1 && row.length < maxCol ? maxCol - row.length + 1 : 1}
                  >
                    {cell.content ?? <></>}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    // <></>
  );
};

export default Table;
