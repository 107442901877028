import { yupResolver } from '@hookform/resolvers/yup';
import { Space } from 'antd';
import React, { useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ACTION } from '@/pages/account-master-management/add-edit/contants';

import { BaseButton } from '@/components/base-button/BaseButton';
import { ConfirmCancelEditing } from '@/components/confirm-popup';

import { setAlertNotification, setLoadingPage } from '@/redux/globalReducer';

import useCompany from '@/hooks/useCompany';
import useMutation from '@/hooks/useMutation';

import { TYPE } from '@/utils/constants/AppConstants';
import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { STAKEHOLDER_VIEW_URL } from '@/utils/constants/RouteContants';
import { arrayToDict, toLowercaseKeys } from '@/utils/helpers/globalHelper';
import { IBasicInformation } from '@/utils/interfaces/Project';
import { getProjectInfo } from '@/utils/services/ProjectApiService';
import { getStakeholderDetail } from '@/utils/services/StakeholderApiService';

import { FormStakeholder } from '../FormValidation';
import StakeholderModal from '../components/StakeholderModal';
import { IFormMethodStakeholder, IStakeholderProps, IStakeholderViewProps } from '../models';

import '../Styles.scss';

const Edit = () => {
  const dispatch = useDispatch();
  const { companyOptions } = useCompany();
  const stakeholdersRef = useRef<any>(null);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const formMethod = useForm<IFormMethodStakeholder>({
    mode: 'all',
    resolver: yupResolver(FormStakeholder),
    defaultValues: () => getDetailStakeholder()
  });
  const { isDirty, isSubmitSuccessful, isSubmitting } = formMethod.formState;

  const [defaultValues, setDefaultValues] = useState<IStakeholderProps[]>([]);
  const [projectInfo, setProjectInfo] = useState<IBasicInformation>();
  const [loading, setLoading] = useState(false);
  const getDetailStakeholder = async () => {
    try {
      dispatch(setLoadingPage(true));
      const { data: projectData } = await getProjectInfo(String(id));
      const { data } = await getStakeholderDetail(String(id));
      const applicantCompany = projectData.applicantCompanyId
        ? {
            value: projectData.applicantCompanyId,
            label: projectData.applicantCompanyName
          }
        : undefined;
      const stakeholders = data?.length
        ? data.map((value: IStakeholderViewProps) => ({
            id: value.id,
            rejected: value.rejected,
            waitCreateAccount: value.waitCreateAccount,
            stakeholderCompanyName: value.stakeHolderCompanyName,
            stakeholderId: value.stakeHolderId,
            stakeholderName: value.stakeHolderName,
            stakeholderEmail: value.stakeHolderEmail,
            stakeholderStatus: value.stakeHolderStatus,
            stakeholderCompanyView: value.stakeHolderCompanyName,
            roleView: value.role,
            rejectReason: value.rejectReason,
            version: value.version,
            displayOrder: value.displayOrder,
            isWorked: true,
            canDelete: value.canDelete
          }))
        : [];
      const detailValues = {
        applicantCompany,
        projectStakeholders: stakeholders
      };
      setProjectInfo(projectData);
      setDefaultValues(detailValues.projectStakeholders);
      dispatch(setLoadingPage(false));
      return detailValues;
    } catch (error) {
      return {};
    }
  };

  const { mutate: registerAndDeleteStakeholder } = useMutation(`prj/stakeholder`, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true
  });

  const { mutate: confirmRequestAccount } = useMutation(`usr/users/confirm-request-accounts`, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true
  });

  const getPayloadRequest = (stakeholders?: IStakeholderProps[]) => {
    // Get deleted item by remaining ids and mails
    const stakeholdersSet = new Set(stakeholders?.map(({ stakeholderId, stakeholderEmail }) => `${stakeholderId}|${stakeholderEmail}`));
    const deletedItems = defaultValues
      .filter(({ stakeholderId, stakeholderEmail }) => !stakeholdersSet.has(`${stakeholderId}|${stakeholderEmail}`))
      .map((item) => ({ ...item, deleted: true }));

    const deletedItemsIds = new Set(deletedItems.map((i) => i.stakeholderId));
    const listDefaultWithoutDeletedItem = defaultValues.filter((i) => !deletedItemsIds.has(i.stakeholderId));

    // Remove email type rejected
    const listEmailsWithoutRejected = listDefaultWithoutDeletedItem.filter((i) => !i.rejected).map((i) => i.stakeholderEmail);
    const newEmailsWithoutRejected = stakeholders?.filter((i) => !i.rejected) ?? [];

    // Convert email uppercase to lowercase
    const lowercaseEmail = toLowercaseKeys(newEmailsWithoutRejected, 'stakeholderEmail');

    // Get new added emails
    const emailSet = new Set(listEmailsWithoutRejected);
    const newEmailAdded = lowercaseEmail?.filter((i) => !emailSet.has(i.stakeholderEmail)) ?? [];

    // Remove duplicate item by email
    const newEmailDict = arrayToDict(newEmailAdded, 'stakeholderEmail');
    const uniqueEmails =
      Object.values(newEmailDict).map((i) => ({
        canDelete: i.isNewAccount,
        displayOrder: i.displayOrder ?? 0,
        stakeholderEmail: i.stakeholderEmail
      })) ?? [];

    return [...uniqueEmails, ...deletedItems];
  };

  const findAccountApproveReject = (stakeholders?: IStakeholderProps[]) => {
    return (
      stakeholders
        ?.filter((item) => item.waitCreateAccount === true)
        .filter((item) => item.rejected === true || item.stakeholderStatus === true)
        .map((item) => ({
          id: item.stakeholderId,
          action: item.stakeholderStatus ? ACTION.APPROVE : ACTION.REJECT,
          version: item.version,
          reasonReject: item.rejectReason,
          urlRedirect: window.location.origin + STAKEHOLDER_VIEW_URL(String(id)),
          projectCode: projectInfo?.code,
          projectId: id
        })) ?? []
    );
  };

  const handleRegister: SubmitHandler<IFormMethodStakeholder> = async (val) => {
    if (registerSuccess) {
      return;
    }
    setLoading(true);
    let resultRequest;
    const confirmListRequest = findAccountApproveReject(val.projectStakeholders);
    if (confirmListRequest.length > 0) {
      resultRequest = await confirmRequestAccount({
        confirmListRequest
      });
    }

    const resultAdd = await registerAndDeleteStakeholder({
      projectId: id,
      stakeholders: getPayloadRequest(val.projectStakeholders)
    });

    if (resultAdd?.status === HTTP_STATUS_CODE.SUCCESS && (confirmListRequest.length === 0 || resultRequest?.status === HTTP_STATUS_CODE.SUCCESS)) {
      setRegisterSuccess(true);
      setTimeout(() => {
        setLoading(false);
        navigate(STAKEHOLDER_VIEW_URL(String(id)));
        dispatch(
          setAlertNotification({
            show: true,
            type: TYPE.SUCCESS,
            message: t('common:MSG_C_003', {
              item: t('button:keep')
            })
          })
        );
      }, 500);
      return;
    }
    setLoading(false);
  };

  return (
    <div id='stakeholder-management' className='overflow-hidden h-full'>
      <div className='stakeholder-container h-full'>
        <FormProvider {...formMethod}>
          <div className='header flex justify-between sticky top-0 bg-gray1'>
            <div className='title-24 whitespace-pre-line font-bold text-24 leading-7'>{t('stakeholder:name')}</div>
            <Space size={16}>
              <div className='min-w-[120px]'>
                <BaseButton block size='medium' type='tertiary' onClick={() => navigate(STAKEHOLDER_VIEW_URL(id ?? ''))}>
                  {t('button:cancel')}
                </BaseButton>
              </div>
              <div className='min-w-[120px]'>
                <BaseButton block size='medium' disabled={isSubmitting || loading} onClick={formMethod.handleSubmit(handleRegister)}>
                  <span> {t('button:keep')} </span>
                </BaseButton>
              </div>
            </Space>
          </div>
          <div className='max-h-[calc(100vh-138px)] overflow-y-scroll stakeholder-form stakeholder-tabs-content px-5 py-5 pb-1 rounded-[10px] border-[#DBDBE0] gap-3'>
            <StakeholderModal companyOptions={companyOptions} ref={stakeholdersRef} formMethod={formMethod} />
          </div>
        </FormProvider>
      </div>
      <ConfirmCancelEditing condition={isDirty && !isSubmitSuccessful} />
    </div>
  );
};

export default Edit;
