import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IBaseInputProps } from '@/components/common/base-input';
import FormItem from '@/components/common/form-item';
import MaskedInput from '@/components/common/mask-input';

interface IDateTimeProps extends IBaseInputProps {
  name: string;
}

const YearMonth: React.FC<IDateTimeProps> = ({ readOnly, name, ...restProps }) => {
  const formMethod = useFormContext();
  const { t } = useTranslation();
  const nameOrigin = name.replace('answeredEn', 'answered');

  return (
    <div className='w-full'>
      <FormItem name={`${name}`} isContainer={true} showErrorOption='icon'>
        <div className='h-full flex space-x-2' id={nameOrigin}>
          <div className='w-[40px] truncate h-full'>
            <FormItem name={`${nameOrigin}.year`}>
              <MaskedInput
                mask='99'
                inputMode='numeric'
                maskChar={''}
                readOnly={readOnly}
                placeholder={readOnly ? '' : '____'}
                hideBorder
                margin={{ left: 0 }}
                onChange={(value) => {
                  formMethod.trigger(name);
                }}
                className='text-right'
                {...restProps}
              />
            </FormItem>
          </div>
          <div>
            <span>{t('project:question_detail:year_month:year')}</span>
          </div>
          <div className='w-[20px] truncate h-full'>
            <FormItem name={`${nameOrigin}.month`}>
              <MaskedInput
                mask='99'
                inputMode='numeric'
                maskChar={''}
                readOnly={readOnly}
                placeholder={readOnly ? '' : '__'}
                hideBorder
                margin={{ left: 0 }}
                onChange={(value) => {
                  formMethod.trigger(name);
                }}
                className='text-right'
                {...restProps}
              />
            </FormItem>
          </div>
          <div>
            <span>{t('project:question_detail:year_month:month')}</span>
          </div>
        </div>
      </FormItem>
    </div>
  );
};

export default YearMonth;
