import classNames from 'classnames';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { STATUS_NAME } from '@/pages/project-management/detail/constant';
import { DEADLINE_STATUS } from '@/pages/project-management/task/constants';
import { TaskDetailContext } from '@pages/project-management/task/view';
import { ProjectDetailPreviewInfo } from '@pages/project-management/task/view/components/ProjectDetailPreviewInfo';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal from '@/components/base-modal/BaseModal';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import { TagTaskType } from '@/components/common/task/TagTaskType';

import useMutation from '@/hooks/useMutation';

import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { VIEW_PROJECT_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';

import Trash from '@/assets/icons/TrashIcon.svg';

import { IDetailTaskData } from '../../models';

import '../index.scss';

interface HeaderInternalRoleProps {
  detailData: IDetailTaskData;
  fetchData?: () => Promise<void>;
}

const HeaderInternalRole = ({ detailData }: HeaderInternalRoleProps) => {
  const { t }: Ii18n = useTranslation();
  const { idProject, idTask } = useParams();
  const form = useFormContext();
  const navigate = useNavigate();

  const { isWarningDeadline, setIsWarningDeadline } = useContext(TaskDetailContext);

  const { mutate: deleteTask } = useMutation(`/prj/projects/${idProject}/task`, {
    method: 'DELETE',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:delete') }) ?? ''
  });

  const deadline = form.watch('deadline');

  const messageWarningRequired = () => {
    if ((!detailData?.deadline && detailData?.statusName === STATUS_NAME.TODO) || !detailData?.departureDate) return '';
    if (!deadline && detailData?.statusName !== STATUS_NAME.TODO) return DEADLINE_STATUS.ERROR;
    if (!!detailData?.deadline) {
      const isWarning = dayjs(detailData?.deadline).isAfter(dayjs(detailData?.departureDate));
      if (!isWarning) return;
      return DEADLINE_STATUS.WARNING;
    }
  };

  const handleConfirmDeadline = () => {
    form.resetField('status', { defaultValue: detailData?.statusName, keepDirty: false });
    setIsWarningDeadline(false);
  };

  const handleDeleteTask = async () => {
    const showPopup = await showBasePopup({
      type: 'discard',
      title: t('common:MSG_C_012:title', { field: t('project:task_of_project:task') }) ?? '',
      msg: t('common:MSG_C_012:description', { field: t('project:task_of_project:task') }) ?? ''
    });
    if (showPopup === 'confirm') {
      try {
        const payload = {
          tasks: [
            {
              id: idTask,
              version: detailData?.version || ''
            }
          ]
        };
        const result = await deleteTask(payload);
        if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
          if (!idProject) return;
          navigate(VIEW_PROJECT_URL(idProject));
        }
      } catch (error) {}
    }
  };

  return (
    <div className='view-task-project-header flex items-center justify-between gap-[16px]'>
      <div className='flex items-start gap-[8px] max-w-[490px] grow'>
        <div className='mt-[8px]'>
          <TagTaskType type={detailData?.taskType ?? ''} />
        </div>
        <AppTooltip className='truncate inline-block w-full' title={DataViewer.display(detailData?.taskName)}>
          <div className='text-lnk text-[24px] font-bold truncate inline-block w-full'>{DataViewer.display(detailData?.taskName)}</div>
        </AppTooltip>
      </div>
      <div className='flex gap-[16px] items-center min-w-0'>
        {detailData && <ProjectDetailPreviewInfo project={detailData} className='mr-[4px]' />}
        {detailData?.statusName === STATUS_NAME.TODO && (
          <BaseButton
            type='outline-red'
            size='medium'
            icon={<Trash />}
            onClick={handleDeleteTask}
            className={classNames('delete-btn !min-w-[0] !rounded-[8px]', messageWarningRequired() ? 'mt-[2px]' : '')}
          >
            {t('project:task_of_project:delete')}
          </BaseButton>
        )}
      </div>
      <BaseModal
        openModal={isWarningDeadline}
        maskClosable={false}
        onCancel={() => handleConfirmDeadline()}
        contentElement={
          <div className='base-popup'>
            <div className='font-[700] text-[20px] text-lnk leading-[120%]'>{t('project:task_of_project:warning_change_status_deadline') || ''}</div>
            <div className='font-[500] text-[14px] text-textGray mt-[16px] whitespace-pre-line leading-[160%]'>{t('common:MSG_P_030')}</div>
            <div className='flex-base-button grid-cols-2 gap-4 mt-[24px]'>
              <BaseButton onClick={() => handleConfirmDeadline()} size='medium' type='primary'>
                {t('common:yes')}
              </BaseButton>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default HeaderInternalRole;
