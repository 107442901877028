import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { GENDER } from '@/pages/project-management/project-list/constants';

import { BaseCard } from '@/components/base-card/BaseCard';
import FormDateInput from '@/components/common/date-input';
import FormPostalCodeInput from '@/components/common/postal-code-input';
import { FormInputDate } from '@/components/form-input-date/FormInputDate';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import { InfoIcon } from '@/components/icon-svg/IconSvg';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { POSTAL_CODE_MASK } from '@/utils/constants';
import { isEmptyField } from '@/utils/helpers/globalHelper';

import { useFormScenarioCContext } from '..';
import { relativePath } from '../../utils';
import { APPLICANT_FIELDS_SCENARIO_C, POSTAL_CODE_RELATIVE_FIELDS_SCENARIO_C } from '../constants';

const PARENT_PATH = 'steps[0]';

export default function Step1() {
  const { t } = useTranslation();
  const form = useFormScenarioCContext<'step-2'>();
  const { customCountryOptions, filterOption } = useOptionsGlobal();
  const PATHS = {
    VISA_CATEGORY: relativePath('visaCategoryName', PARENT_PATH),
    PROJECT_APPLICANT: relativePath('projectApplicant', PARENT_PATH)
  };
  const gender = [
    {
      label: t('common:male'),
      value: GENDER.male,
      id: GENDER.male
    },
    {
      label: t('common:female'),
      value: GENDER.female,
      id: GENDER.female
    }
  ];
  const watchingFieldPath = {
    all: APPLICANT_FIELDS_SCENARIO_C.map((field) => relativePath(field, `${PATHS.PROJECT_APPLICANT}`))
  };
  const watchFields = form.watch(watchingFieldPath.all);
  const counters = () => {
    const watchFieldsValid = watchFields.filter((field: any) => !isEmptyField(field));
    const results = {
      total: APPLICANT_FIELDS_SCENARIO_C.length,
      questions: watchFieldsValid.length ?? 0
    };
    return results;
  };
  return (
    <div className='mt-5'>
      <p className='title-20 text-black'>{t('project:scenario_b:applicant_title')}</p>
      <div className='flex py-2'>
        <div className='py-[2px] mr-[4px]'>
          <InfoIcon width={14.63} height={14.63} />
        </div>
        <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
          <p>{t('project:scenario_b:applicant_description')}</p>
        </div>
      </div>
      <BaseCard title={t('basic_information:applicant_infomation')} total={counters()?.total} questions={counters()?.questions}>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              required={true}
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.lastName`)}
              name={`${PATHS.PROJECT_APPLICANT}.lastName`}
              labelTx='project:add:applicant_representative:last_name'
              placeholderTx='project:add:applicant_representative:example_yamada'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.firstName`)}
              required={true}
              name={`${PATHS.PROJECT_APPLICANT}.firstName`}
              labelTx='project:add:applicant_representative:first_name'
              placeholderTx='project:add:applicant_representative:example_taro'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required={true}
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.lastNameRomaji`)}
              name={`${PATHS.PROJECT_APPLICANT}.lastNameRomaji`}
              labelTx='project:add:applicant_representative:last_name_romaji'
              placeholderTx='project:add:applicant_representative:example_yamada_passport_notation'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required={true}
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.firstNameRomaji`)}
              name={`${PATHS.PROJECT_APPLICANT}.firstNameRomaji`}
              labelTx='project:add:applicant_representative:first_name_romaji'
              placeholderTx='project:add:applicant_representative:example_taro_passport'
            />
          </Col>
        </Row>
        <Row gutter={16} className='mt-3'>
          <Col span={6}>
            <FormSelect
              required
              id={`${PATHS.PROJECT_APPLICANT}.primaryNational`}
              name={`${PATHS.PROJECT_APPLICANT}.primaryNational`}
              label={t('project:add:applicant_representative:nationality') || ''}
              options={customCountryOptions}
              filterOption={filterOption}
              placeholder={String(t('project:add:applicant_representative:example_japan'))}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              name={`${PATHS.PROJECT_APPLICANT}.secondaryNational`}
              label={t('project:add:applicant_representative:second_nationality') || ''}
              options={customCountryOptions}
              filterOption={filterOption}
              placeholder={String(t('project:add:applicant_representative:example_america'))}
            />
          </Col>
          <Col span={6}>
            <FormDateInput required name={`${PATHS.PROJECT_APPLICANT}.dateOfBirth`} labelTx='project:add:applicant_representative:date_of_birth' />
          </Col>
          <Col span={6}>
            <FormSelect
              required
              id={`${PATHS.PROJECT_APPLICANT}.gender`}
              name={`${PATHS.PROJECT_APPLICANT}.gender`}
              label={t('project:add:applicant_representative:sex') || ''}
              options={gender}
              placeholder={String(t('project:add:please_select_field', { field: t('project:add:applicant_representative:sex') }))}
            />
          </Col>
        </Row>
        <Row gutter={16} className='mt-3'>
          <Col span={6}>
            <FormPostalCodeInput
              id={`${PATHS.PROJECT_APPLICANT}.postalCode`}
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.postalCode`)}
              name={`${PATHS.PROJECT_APPLICANT}.postalCode`}
              label={t('basic_information:postal_code_number') ?? ''}
              placeholder={t('postal_code:postal_code_placeholder') ?? ''}
              relativeFields={POSTAL_CODE_RELATIVE_FIELDS_SCENARIO_C}
              mask={POSTAL_CODE_MASK}
              required
              prefix={t('basic_information:postal_code_text_prefix')}
            />
          </Col>
        </Row>
        <Row gutter={16} className='mt-3'>
          <Col span={6}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.prefecture`)}
              required={true}
              name={`${PATHS.PROJECT_APPLICANT}.prefecture`}
              labelTx='postal_code:prefecture'
              placeholderTx='postal_code:prefecture_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.prefectureRoman`)}
              required={true}
              name={`${PATHS.PROJECT_APPLICANT}.prefectureRoman`}
              labelTx='postal_code:prefecture_roman'
              placeholderTx='postal_code:prefecture_roman_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.city`)}
              required={true}
              name={`${PATHS.PROJECT_APPLICANT}.city`}
              labelTx='postal_code:city'
              placeholderTx='postal_code:city_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.cityRoman`)}
              required={true}
              name={`${PATHS.PROJECT_APPLICANT}.cityRoman`}
              labelTx='postal_code:city_roman'
              placeholderTx='postal_code:city_roman_placeholder'
            />
          </Col>
        </Row>
        <Row gutter={16} className='mt-3'>
          <Col span={6}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.suburb`)}
              required={true}
              name={`${PATHS.PROJECT_APPLICANT}.suburb`}
              labelTx='postal_code:suburb'
              placeholderTx='postal_code:suburb_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.suburbRoman`)}
              required={true}
              name={`${PATHS.PROJECT_APPLICANT}.suburbRoman`}
              labelTx='postal_code:suburb_roman'
              placeholderTx='postal_code:suburb_roman_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.streetAddress`)}
              required={true}
              name={`${PATHS.PROJECT_APPLICANT}.streetAddress`}
              labelTx='postal_code:street_address'
              placeholderTx='postal_code:street_address_placeholder'
            />
          </Col>
          <Col span={6}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.apartmentName`)}
              name={`${PATHS.PROJECT_APPLICANT}.apartmentName`}
              labelTx='postal_code:office'
              placeholderTx='postal_code:office_placeholder'
            />
          </Col>
        </Row>
        <Row gutter={16} className='mt-3'>
          <Col span={12}>
            <FormInput
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.placeOfResidence`)}
              name={`${PATHS.PROJECT_APPLICANT}.placeOfResidence`}
              labelTx='project:add:applicant_representative:resident_registration_place'
              placeholderTx='project:add:applicant_representative:please_enter_registration_location'
            />
          </Col>
        </Row>
        <Row gutter={16} className='mt-3'>
          <Col span={12}>
            <FormInput
              required={true}
              defaultValue={form.getValues(`${PATHS.PROJECT_APPLICANT}.currentCompany`)}
              name={`${PATHS.PROJECT_APPLICANT}.currentCompany`}
              labelTx='project:add:applicant_representative:current_company'
              placeholderTx='project:add:applicant_representative:example_company_name_placeholder'
            />
          </Col>
          <Col span={12}>
            <FormInputDate
              required
              name={`${PATHS.PROJECT_APPLICANT}.departureDate`}
              labelTx='project:add:applicant_representative:departure_date'
              placeholderTx={t('basic_information:place_holder_departure_date') ?? ''}
            />
          </Col>
        </Row>
      </BaseCard>
      <BaseCard
        title={t('basic_information:visa_information_label')}
        total={1}
        questions={form.watch(`${PATHS.VISA_CATEGORY}`) ? 1 : 0}
        className='mt-3'
      >
        <Row gutter={16}>
          <Col span={12}>
            <FormInput
              name={PATHS.VISA_CATEGORY}
              labelTx={t('project:scenario_b:visa_category:label')}
              placeholderTx={t('project:scenario_b:visa_category:placeholder')}
              style={{ flex: 'auto', height: '40px', borderRadius: '10px' }}
            />
          </Col>
        </Row>
      </BaseCard>
    </div>
  );
}
