import { t } from 'i18next';

import { LANGUAGE } from '@/pages/project-management/project-list/constants';

import { APPLICANT_GENDER } from '@/utils/constants';

import { STEP_CRUMB_STATUS } from '../../contants/projectExternalProxy';

export const DEFAULT_SCENARIO_A_STEP_2 = {
  id: 'step2',
  stepId: 2,
  title: 'project:scenario_a:applicant_title',
  projectApplicant: {
    lastName: '',
    firstName: '',
    lastNameRomaji: '',
    firstNameRomaji: '',
    currentCompany: '',
    postalCode: '',
    prefecture: '',
    prefectureRoman: '',
    city: '',
    cityRoman: '',
    suburb: '',
    suburbRoman: '',
    streetAddress: '',
    apartmentName: '',
    placeOfResidence: '',
    departureDate: ''
  },
  projectAssignmentInfo: {},
  visaCategoryName: ''
};

export const DEFAULT_SCENARIO_A_STEP_3 = {
  id: 'step3',
  stepId: 3,
  title: 'project:scenario_a:local_supporter',
  needSupporter: null,
  projectLocalSupporters: []
};

export const DEFAULT_SCENARIO_A_STEP_4 = {
  id: 'step4',
  stepId: 4,
  title: 'project:scenario_a:involved_members',
  involvedMembers: null,
  projectStakeholders: []
};

export const DEFAULT_SCENARIO_A_STEP_5 = {
  id: 'step5',
  stepId: 5,
  title: 'project:scenario_a:additional_information',
  note: '',
  projectAttachments: []
};

export const DEFAULT_SCENARIO_A_STEP_6 = {
  id: 'step6',
  stepId: 6,
  title: 'project:scenario_a:answer_detail'
};

export const STEP_JUMP = 2;

export const DEFAULT_SCENARIO_A_STEP_STATUS = [
  { id: 2, value: STEP_CRUMB_STATUS.TODO },
  { id: 3, value: STEP_CRUMB_STATUS.TODO },
  { id: 4, value: STEP_CRUMB_STATUS.TODO },
  { id: 5, value: STEP_CRUMB_STATUS.TODO },
  { id: 6, value: STEP_CRUMB_STATUS.TODO }
];

export const MAPPING_TITLE_WITH_STEP: { [key in number]: string } = {
  2: 'project:scenario_a:applicant_title',
  3: 'project:scenario_a:local_supporter',
  4: 'project:scenario_a:involved_members',
  5: 'project:scenario_a:additional_information',
  6: 'project:scenario_a:answer_detail'
};

export const gender = [
  {
    label: t('common:male'),
    value: APPLICANT_GENDER.MALE,
    id: APPLICANT_GENDER.MALE
  },
  {
    label: t('common:female'),
    value: APPLICANT_GENDER.FEMALE,
    id: APPLICANT_GENDER.FEMALE
  }
];
export const language = [
  {
    label: t('common:language_jp'),
    value: LANGUAGE.japan,
    id: LANGUAGE.japan
  },
  {
    label: t('common:language_en'),
    value: LANGUAGE.english,
    id: LANGUAGE.english
  }
];
