import { useAppDispatch, useAppSelector } from '@/hooks';
import { Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import EditHistory from '@/pages/master-data/components/edit-history/EditHistory';

import { showBasePopup } from '@/components/base-popup/BasePopup';

import { setLoadingPageGlobal } from '@redux/globalReducer';

import useFetch from '@/hooks/useFetch';
import useMotPic from '@/hooks/useMotPic';
import useMutation from '@/hooks/useMutation';

import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { VIEW_PROJECT_URL } from '@/utils/constants/RouteContants';
import { formatDayjsToString } from '@/utils/helpers/globalHelper';
import { Ii18n } from '@/utils/interfaces/i18n';
import { getForms } from '@/utils/services/master';

import { DEFAULT_DETAIL_PROJECT } from '../constant';
import { ICategory, ICountry, IDataDetail, IForm, IFormDetailProject } from '../utils';
import Announcement from './Announcement';
import Header from './Header';
import Information from './Information';
import ProcessDetail from './Process';
import TaskList from './task-list/TaskList';

import '../ProjectDetail.scss';

const ViewProject = () => {
  const { t }: Ii18n = useTranslation();
  const { id } = useParams();
  const [dataDetail, setDataDetail] = useState<IDataDetail>(DEFAULT_DETAIL_PROJECT);
  const { loading: loadingMotPic, userMotPicOptions: userMotPicOptions } = useMotPic();
  const [listProjectProcess, setListProjectProcess] = useState([]);
  const navigate = useNavigate();
  const [optionsVisaCategory, setOptionsVisaCategory] = useState<DefaultOptionType[]>([]);
  const [formOptions, setFormOptions] = useState<DefaultOptionType[]>([]);
  const [announcements, setAnnouncements] = useState<DefaultOptionType[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState<string | null>(null);
  const countriesData = useAppSelector((state) => state.global.countries);
  const { data, loading, fetchData } = useFetch<any>(`/prj/projects/${id}/detail`, 'GET');
  const loadingPageGlobal: boolean = useAppSelector((state) => state.global.loadingPageGlobal);
  const dispatch = useAppDispatch();
  const formMethod = useForm<IFormDetailProject>({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {}
  });

  const setDataCategories = (country: ICountry) => {
    try {
      if (!country || !country.categories) return;
      const categories: DefaultOptionType[] = country?.categories.reduce((list: DefaultOptionType[], c: ICategory) => {
        if (c.id) {
          list.push({
            value: c.id,
            label: c.name
          });
        }
        return list;
      }, []);
      setOptionsVisaCategory(categories);
    } catch (error) {}
  };

  const fetchAssignmentForm = async () => {
    try {
      const { data } = await getForms();
      if (!data?.data) return;
      const newData = data.data.map((f: IForm) => ({
        ...f,
        label: f.name,
        value: f.id
      }));
      setFormOptions(newData);
    } catch (error) {}
  };

  const showApplication = (name?: string) => {
    return (name ?? '').replaceAll(', ', '、');
  };

  const getDetailProjectInfor = async (data: any) => {
    if (!id || !data) return;
    const {
      projectApplicant,
      applicantName,
      visaCategoryName,
      applicantCountryName,
      templateId,
      applicantCountryId,
      announcements,
      projectProcesses,
      statusId,
      statusName,
      active,
      templateName,
      picId,
      isPublished,
      picStatus,
      updatedStatus,
      travelTypeName
    } = data;
    const projectInfor = {
      applicant: showApplication(applicantName),
      departureDate: projectApplicant.departureDate ? formatDayjsToString(projectApplicant.departureDate) : null,
      applicantCountry: applicantCountryName,
      visaCategory: visaCategoryName,
      fullName: `${projectApplicant?.lastName} ${projectApplicant?.firstName}`,
      travelTypeName
    };
    setDataDetail({
      id: data.id,
      code: data.code,
      version: data.version,
      projectInfor,
      templateId,
      countryId: applicantCountryId,
      projectProcesses,
      statusId,
      statusName,
      active,
      templateName,
      picId,
      isPublished,
      picStatus,
      updatedStatus
    });
    setAnnouncements(announcements);
    await fetchAssignmentForm();
    setListProjectProcess(projectProcesses);
  };

  useEffect(() => {
    if (data) {
      formMethod.reset({
        picId: data.picId,
        picName: data.picName,
        templateId: data.templateId,
        createdName: data.createdName,
        createdDate: data.createdDate,
        updatedName: data.updatedName,
        updatedDate: data.updatedDate,
        primary: {
          category: '',
          form: ''
        }
      });
      setCurrentTemplate(data.templateId);
      getDetailProjectInfor(data);
    }
  }, [data]);

  useEffect(() => {
    const countrySelected = countriesData?.find((c: any) => c.id === dataDetail?.countryId) as ICountry;
    setDataCategories(countrySelected);
  }, [dataDetail?.countryId]);

  const { mutate: updateAssignPic } = useMutation(`/prj/projects/${id}/assign`, {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', { item: t('button:keep') }) ?? ''
  });

  const handleOnChangeMotPic = async (value: string) => {
    if (!value || !id) return;
    dispatch(setLoadingPageGlobal(true));
    const motPicType = userMotPicOptions.find((item: any) => item.value === value);
    try {
      const body = {
        id: dataDetail?.id,
        picId: motPicType?.value,
        picName: motPicType?.label,
        picEmail: motPicType?.email,
        version: dataDetail?.version
      };
      const result = await updateAssignPic(body);
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
        fetchData();
      }
    } catch (error) {
    } finally {
      dispatch(setLoadingPageGlobal(false));
    }
  };

  const { mutate: updateActiveProject } = useMutation(`/prj/projects/${id}/update-active`, {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const updateActiveCancelProject = async (isActive: boolean) => {
    try {
      const result = await updateActiveProject({ active: isActive });
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
        if (!id) return;
        fetchData();
        navigate(VIEW_PROJECT_URL(id));
      }
    } catch (error) {}
  };

  const handleCancelProject = async () => {
    const showPopup = await showBasePopup({
      type: 'discard',
      title: t(`common:MSG_P_003:title`) || '',
      msg: t('common:MSG_P_003:content')
    });
    if (showPopup === 'confirm') {
      updateActiveCancelProject(false);
    }
  };

  const handleActiveProject = async () => {
    const showPopup = await showBasePopup({
      type: 'confirm',
      title: t(`common:MSG_P_004:title`) || '',
      msg: t('common:MSG_P_004:content')
    });
    if (showPopup === 'confirm') {
      updateActiveCancelProject(true);
    }
  };

  const { mutate: updateTemplate } = useMutation(`/prj/projects/${id}/change-template`, {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: t('common:MSG_C_003', {
      item: t('common:button:keep')
    })
  });

  const handleChangeTemplate = async (value: string) => {
    if (!value) return;
    const showPopup = await showBasePopup({
      type: 'confirm',
      title: t(`common:MSG_C_010:title`) || '',
      msg: t('common:MSG_C_010:content')
    });
    if (showPopup === 'confirm') {
      dispatch(setLoadingPageGlobal(true));
      try {
        const result = await updateTemplate({ templateId: value, version: dataDetail.version });
        if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
          if (!id) return;
          fetchData();
        }
      } catch (error) {
      } finally {
        dispatch(setLoadingPageGlobal(false));
      }
      setCurrentTemplate(value);
    } else {
      formMethod.setValue('templateId', currentTemplate);
    }
  };

  return (
    <>
      {loading || loadingPageGlobal || loadingMotPic ? (
        <div className='text-center'>
          <Spin size='large' />
        </div>
      ) : (
        <>
          <FormProvider {...formMethod}>
            <Header
              handleOnChangeMotPic={handleOnChangeMotPic}
              handleCancelProject={handleCancelProject}
              handleActiveProject={handleActiveProject}
              optionsVisaCategory={optionsVisaCategory}
              formOptions={formOptions}
              handleChangeTemplate={handleChangeTemplate}
              countryId={dataDetail?.countryId ?? ''}
              dataDetail={dataDetail}
              userMotPicOptions={userMotPicOptions}
            />
            <div className='grid grid-cols-3 gap-[16px] mb-[12px]'>
              <div className='flex flex-col col-span-2'>
                <Information data={dataDetail} />
                <ProcessDetail data={dataDetail} />
              </div>
              <Announcement announcements={announcements} />
            </div>
          </FormProvider>
          <TaskList listProjectProcess={listProjectProcess} fetchData={() => fetchData()} />
          <div className='form-infor-user-update mt-3 d-flex justify-end w-full'>
            <EditHistory data={formMethod.getValues()} />
          </div>
        </>
      )}
    </>
  );
};

export default ViewProject;
