import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Checkbox, Divider, Row, Table } from 'antd';
import { ColumnType, ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { isNil } from 'lodash';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HolderOutlined from '@assets/icons/Drag.svg';

import { useAppSelector } from '../../hooks';
import { IFilterForm } from '../../utils/interfaces/form';
import { BaseFilterSecurity } from '../../utils/interfaces/security';
import AppTooltip from '../app-tooltip/AppTooltip';
import { BaseButton } from '../base-button/BaseButton';
import BaseFilter, { IBaseFilterLocaleConfig, ISecondFilter } from '../filter/BaseFilter';
import { Filter, Filtered } from '../icon-svg/IconSvg';
import NextIcon from './../../assets/icons/table/icon-next.svg';
import PrevIcon from './../../assets/icons/table/icon-prev.svg';
import EmptyData from './components/EmptyData';
import TableFooter from './components/TableFooter';
import TableHeader from './components/TableHeader';
import { BaseTableWrapper } from './styles/BaseTableWrapper';

import './styles/BaseTable.scss';

type Result = {
  current: number | undefined;
  pageSize: number | undefined;
  total: number | undefined;
};
interface IFilter {
  refFilter?: any;
  baseFilterSecurityProps?: BaseFilterSecurity;
  localeFilter?: IBaseFilterLocaleConfig;
  classNameFilter?: string;
  optionsFilter?: { [key: string]: any };
  showFilterNumber?: boolean;
  inputSearchSecurityProps?: boolean;
  isShowFilter?: boolean;
  isShowSearch?: boolean;
  searchKeyword?: string;
  searchMaxLength?: number;
  isToDisplaySearchAndFilter?: boolean;
  defaultValues?: any;
  validateForm?: any;
  secondFilter?: ISecondFilter;
  placeholder?: string;
  name?: string;
  onSearchFilter?: (keyword: string) => void;
  onChangeFilter?: (value: string) => void;
  onClearFilter?: (values: IFilterForm) => void;
  onApplyFilter?: (values?: IFilterForm) => void;
  renderFormContent?: (formMethod: any) => JSX.Element | string;
  onClearAllFilter?: () => void;
}

interface ITableFooter extends Result {
  showFooter?: boolean;
}
export interface IBaseTableProps<T> extends TableProps<T> {
  // table props
  dataSource?: T[];
  columns: ColumnsType<T>;
  pagination?: false | TablePaginationConfig;
  showAddLine?: boolean;
  disabledSelectionRows?: number[] | string;
  isShowDelete?: boolean;
  isShowTooltip?: boolean;
  isShowDownload?: boolean;
  emptyDataAlert?: React.ReactNode;
  isSearch?: boolean;
  onRowAble?: boolean;
  baseFilter?: IFilter;
  footerCount?: Result;
  footerTable?: ITableFooter;
  isTableInListPage?: boolean;
  showImgEmpty?: boolean;
  heightTableBody?: string;
  showTableFooter?: boolean;
  classEmptyCustom?: string;
  setFiltered?: (value: any) => void;
  handleDownloadClick?: (keys: React.Key[]) => void;
  handleDeleteClick?: (keys: React.Key[], row?: T[]) => void;
  handleFilter?: () => void;
  onSelected?: (keys: React.Key[], rows: T[]) => void;
  onDataSourceChanged?: () => void;
  onDragEnd?: ({ active, over }: DragEndEvent) => void;
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const RowContext = React.createContext<RowContextProps>({});

export const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type='text'
      size='small'
      icon={<HolderOutlined width={16} height={16} className='!cursor-move rotate-90' />}
      className='flex items-center justify-center !cursor-move'
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const RowTable: React.FC<RowProps> = (props) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({ id: props['data-row-key'] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 99 } : {})
  };

  const contextValue = useMemo<RowContextProps>(() => ({ setActivatorNodeRef, listeners }), [setActivatorNodeRef, listeners]);

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

/**
 * @deprecated This component will be remove in the feature.
 */
const BaseTable = forwardRef<any, IBaseTableProps<any>>(<T extends {}>(props: IBaseTableProps<T>, ref: any) => {
  const {
    // table props
    columns,
    dataSource = [],
    showAddLine = false,
    disabledSelectionRows = [],
    isShowDelete,
    isShowTooltip = true,
    isShowDownload,
    scroll,
    emptyDataAlert,
    isSearch,
    // filter props
    baseFilter,
    footerCount,
    footerTable,
    isTableInListPage = false,
    heightTableBody,
    showImgEmpty,
    classEmptyCustom,
    handleFilter,
    setFiltered,
    handleDownloadClick,
    handleDeleteClick,
    onSelected,
    onDataSourceChanged: onDataSourceChanged,
    onDragEnd,
    ...restProps
  } = props;
  const [showPagination, setShowPagination] = useState<boolean>(true);
  let { locale: customLocale } = props;
  let { pagination = false } = props;
  const [heightPageHeader, setHeightPageHeader] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<T[]>([]);
  const [disabledSelectedRows, setDisabledSelectedRows] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<string>('');
  const [t] = useTranslation();
  const [columnsTable, setColumnsTable] = useState<ColumnType<T>[]>([]);
  const baseTableRef = useRef<HTMLDivElement>(null);
  const baseTableFilterRef = useRef<any>(null);
  const [rowSelection, setRowSelection] = useState<TableRowSelection<T> | undefined>();
  const isLoadingPage = useAppSelector((x) => x.global.loadingPage);
  const isGroup = dataSource.findIndex((e: any) => !isNil(e.children)) > -1;
  const dataList = isGroup ? dataSource.map((e: any) => e.children).flat() : dataSource;

  const paginationInfo = useMemo(() => {
    return (
      pagination || {
        current: undefined,
        pageSize: undefined,
        total: undefined
      }
    );
  }, [pagination]);

  useEffect(() => {
    if (!baseTableFilterRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (selectedRowKeys && selectedRowKeys.length > 0) {
        setHeightPageHeader(134);
      } else if (baseTableFilterRef?.current) {
        const heightFilter: number = baseTableFilterRef.current?.clientHeight;
        setHeightPageHeader(heightFilter + 90);
      } else {
        setHeightPageHeader(134);
      }
    });
    resizeObserver.observe(baseTableFilterRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [baseTableFilterRef, selectedRowKeys]);

  // Custom empty when page has no data
  customLocale = {
    ...customLocale,
    emptyText: isLoadingPage ? (
      '.'
    ) : (
      <EmptyData classEmptyCustom={classEmptyCustom} showImgEmpty={showImgEmpty} emptyDataAlert={isSearch ? undefined : emptyDataAlert} />
    )
  };

  // Set Next and Prev Icons
  if (pagination) {
    pagination = {
      ...pagination,
      nextIcon: <NextIcon />,
      prevIcon: <PrevIcon />
    };
  }

  const handleDragEnd = (e: DragEndEvent) => {
    if (!e?.over) return;
    onDragEnd && onDragEnd(e);
  };

  // handle when select item
  const onSelectChange = (newSelectedRowKeys: React.Key[], rows: T[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(rows);
  };

  const onClickSelection = () => {
    if (!dataSource) return;
    if (selectedRowKeys.length < dataList.length) {
      const keys = dataList.map((e: any) => e[restProps.rowKey || 'id']);
      onSelected && onSelected(keys, dataList);
      onSelectChange(keys, dataList);
      return;
    }
    onSelected && onSelected([], []);
    onSelectChange([], []);
  };

  useEffect(() => {
    const keys = dataList.map((e: any) => e[restProps.rowKey || 'id']);
    setSelectedRowKeys(selectedRowKeys.filter((key) => keys.includes(key)));
  }, [dataSource]);

  useEffect(() => {
    const isChecked = Boolean(dataList?.length && selectedRowKeys.length === dataList.length);
    if (isLoadingPage || !onSelected) return;
    const newConfig = {
      selectedRowKeys,
      columnTitle: (
        <div className='ant-table-selection'>
          <Checkbox
            defaultChecked={false}
            checked={isChecked}
            indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < dataList.length}
            onClick={onClickSelection}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                onClickSelection();
              }
            }}
          />
        </div>
      ),
      onChange: (keys: React.Key[], rows: T[]) => {
        onSelected && onSelected(keys, rows);
        onSelectChange(keys, rows);
      },
      renderCell: (_checked: boolean, _record: T, _index: number, originNode: React.ReactNode) => {
        return React.cloneElement(originNode as any, {
          onKeyDown: (e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              (e.target as any).click();
            }
          }
        });
      },
      getCheckboxProps: (record: any) => {
        let disabled = false;
        disabled = disabledSelectedRows;
        if (Array.isArray(disabledSelectionRows) && dataSource) {
          if (disabledSelectionRows.includes(record.key)) disabled = true;
        } else if (disabledSelectionRows === 'all') disabled = true;
        // Hidden checkbox for records with label
        const style = {
          display: 'label' in record ? 'none' : ''
        };
        // Disable checkbox for records with label
        if ('label' in record) {
          disabled = true;
        }
        return {
          className: 'base-table-checkbox',
          disabled,
          style
        };
      }
    };
    setRowSelection(newConfig);
  }, [dataSource, isLoadingPage, selectedRowKeys, selectedRow, onSelected]);
  // Handle when select rows

  const onScroll = (e: any) => {
    setShowFilter('');
  };

  useEffect(() => {
    const elementsScroll = document.querySelectorAll('.container-scroll');
    for (let index = 0; index < elementsScroll.length; index++) {
      const elementScroll = elementsScroll[index];
      elementScroll.removeEventListener('scroll', onScroll);
      elementScroll.addEventListener('scroll', onScroll, { passive: true });
    }

    return () => {
      for (let index = 0; index < elementsScroll.length; index++) {
        const elementScroll = elementsScroll[index];
        elementScroll?.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  // Func render filter icon
  const _renderFilterIcon = (filtered: boolean) => {
    return filtered ? <Filtered /> : <Filter />;
  };

  // Func render colum props
  const _getColumnSearchProps = (col: ColumnType<T>): ColumnType<T> => ({
    render: (text: any, record: any, index: any) => {
      if (col.render) {
        return col.render(text, record, index);
      }
      return isShowTooltip ? (
        <AppTooltip title={text}>
          <span className='truncate inline-block max-w-full'>{text}</span>
        </AppTooltip>
      ) : (
        text
      );
    },
    filterIcon: (filtered: boolean) => _renderFilterIcon(filtered),
    filterDropdownOpen: showFilter === col.key,
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setShowFilter(col.key as string);
      } else {
        setShowFilter('');
      }
    },
    filterDropdown: col?.filters
      ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
          <div onKeyDown={(e) => e.stopPropagation()}>
            <Checkbox.Group className='flex flex-col gap-2 pt-[16px] pb-[8px]' onChange={setSelectedKeys} value={selectedKeys}>
              {col?.filters &&
                col?.filters?.map((e: any) => (
                  <div className='px-[24px] py-[8px]' key={e.value}>
                    <Checkbox value={e.value} className='base-table_filter-checkbox'>
                      {e.text}
                    </Checkbox>
                  </div>
                ))}
            </Checkbox.Group>
            <Divider className='m-0' />
            <Row className='p-[24px]' justify={'space-between'} gutter={8}>
              <BaseButton
                type='tertiary'
                size='small'
                className='!w-[124px] mr-[8px] table-filter-button'
                onClick={clearFilters}
                disabled={selectedKeys.length === 0}
              >
                {t('table:filter:btn_reset')}
              </BaseButton>
              <BaseButton
                type='primary'
                size='small'
                className='!w-[124px] table-filter-button'
                onClick={() => {
                  if (selectedKeys && selectedKeys.length > 0) {
                    setFiltered && setFiltered(true);
                  } else {
                    setFiltered && setFiltered(false);
                  }
                  handleFilter && handleFilter();
                  confirm({ closeDropdown: true });
                }}
              >
                <span>{t('table:filter:btn_apply')} </span>
              </BaseButton>
            </Row>
          </div>
        )
      : undefined
  });

  // Func render footer
  const _renderFooter = () => {
    return (
      <TableFooter
        isFilter={!!isSearch}
        paginationInfo={{
          current: footerTable ? footerTable.current : paginationInfo.pageSize,
          pageSize: footerTable ? footerTable.pageSize : paginationInfo.pageSize,
          total: footerTable ? footerTable.total : dataSource?.length
        }}
      />
    );
  };

  const handleScroll = () => {
    if (scroll) return scroll;
    if (isTableInListPage) {
      // return { y: `calc(100vh - 260px - ${heightPageHeader}px)` };
    }
    return undefined;
  };

  useEffect(() => {
    if (dataSource?.length) {
      setShowPagination(false);
    } else {
      setShowPagination(true);
    }
  }, [dataSource, paginationInfo]);

  useEffect(() => {
    setColumnsTable(columns);
  }, [columns, selectedRowKeys]);

  useImperativeHandle(ref, () => ({
    // When action complete please remove selected items
    clearSelected: () => {
      onSelectChange([], []);
    },
    setDisabledSelectedRows(disabled: boolean) {
      setDisabledSelectedRows(disabled);
    }
  }));

  return (
    <BaseTableWrapper heightheader={heightPageHeader}>
      <div className={`base-table ${showAddLine ? 'add-line-table' : ''} ${isTableInListPage && 'base-table-in-list-page'}`}>
        <TableHeader
          isShowDelete={!!isShowDelete}
          isShowDownload={!!isShowDownload}
          selectedRowKeys={selectedRowKeys}
          selectedRow={selectedRow}
          numberOfSelectedRows={selectedRowKeys.length}
          handleDownloadClick={handleDownloadClick}
          handleDeleteClick={handleDeleteClick}
        />
        {!!baseFilter && baseFilter.isToDisplaySearchAndFilter && (
          <div ref={baseTableFilterRef} className={selectedRowKeys.length > 0 ? 'hidden' : 'visible pb-[16px] min-h-[44px]'}>
            <BaseFilter
              ref={baseFilter.refFilter}
              name={baseFilter.name}
              baseFilterSecurityProps={baseFilter.baseFilterSecurityProps}
              locale={baseFilter.localeFilter}
              className={baseFilter.classNameFilter}
              options={baseFilter.optionsFilter}
              showFilterNumber={baseFilter.showFilterNumber}
              searchKeyword={baseFilter.searchKeyword}
              searchMaxLength={baseFilter.searchMaxLength}
              isShowFilter={baseFilter.isShowFilter}
              isShowSearch={baseFilter.isShowSearch}
              inputSearchSecurityProps={baseFilter.inputSearchSecurityProps}
              defaultValues={baseFilter.defaultValues}
              validateForm={baseFilter.validateForm}
              secondFilter={baseFilter.secondFilter}
              placeholder={baseFilter.placeholder}
              renderFormContent={baseFilter.renderFormContent}
              onSearch={baseFilter.onSearchFilter}
              onChange={baseFilter.onChangeFilter}
              onClearAllFilter={baseFilter.onClearAllFilter}
              onClear={baseFilter.onClearFilter}
              onApply={baseFilter.onApplyFilter}
            />
          </div>
        )}

        <div ref={baseTableRef}>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
            <SortableContext items={dataSource?.map((i: any) => i.key)} strategy={verticalListSortingStrategy}>
              <Table<T>
                rowKey='key'
                bordered={false}
                rowSelection={rowSelection}
                style={{ height: heightTableBody ?? '' }}
                showSorterTooltip={false}
                className={`base-table-data ${!dataSource?.length ? 'empty-data' : ''} `}
                dataSource={dataSource}
                locale={customLocale}
                pagination={showPagination ? pagination : false}
                scroll={handleScroll()}
                columns={columnsTable.map((col: ColumnType<T>, index: number) => ({
                  ...col,
                  ..._getColumnSearchProps(col)
                }))}
                footer={!!footerTable?.showFooter && dataSource?.length ? _renderFooter : undefined}
                components={{ body: { row: RowTable } }}
                {...restProps}
              />
            </SortableContext>
          </DndContext>
        </div>
      </div>
    </BaseTableWrapper>
  );
});
export default BaseTable;
