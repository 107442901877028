export enum PROJECT_STATUS {
  NOT_START = 'not-start',
  PROCESSING = 'processing',
  VISA_ISSUANCE = 'visa-issuance',
  COMPLETED = 'completed',
  CANCEL = 'cancel'
}

export const PROJECT_TABS: { [index: string]: string } = {
  BASIC_INFORMATION: 'Basic_information',
  SCHEDULE: 'Schedule',
  DOCUMENTS: 'Required_documents',
  QUESTION: 'Question',
  CUSTOMER_REQUEST: 'Customer_request_list',
  TODO: 'List_task',
  MEMO: 'Memo'
};

export enum SUPPORT_TYPE_NAME {
  FULL = 'フルサポート',
  NORMAL = 'サポート'
}

export const DISABLE_ASSIGNEE = ['赴任者（研修生）のビザ取得手続き', '赴任者（研修生）＋ご家族のビザ取得手続き'];

export enum PROJECT_STATUS_ID {
  NOT_STARTED = '0884121d-0adb-49e9-9f31-6d9b1b3b815a',
  IN_PROGRESS = 'a471467a-36af-4687-a786-674b85f62520',
  DELAY = '92d583f1-ae08-4acd-a361-110972e45874',
  COMPLETED = 'da842f61-e2ec-44fc-a761-5eab8bdd5259',
  CANCELLED = '2ae1e710-864a-4fdc-81af-b6b9829244c4'
}
