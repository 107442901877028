import * as yup from 'yup';

import { NOTE_ANNOUNCEMENT_MAX_LENGTH, NOTE_FILED_MAX_LENGTH, TEXT_FIELD_MAX_LENGTH, TEXT_FIELD_MIN_LENGTH } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

import { ATTACHMENT_STATUS } from '../view/models';

export const FormCreateAnnouncement = yup.object().shape({
  title: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_001_input',
        optionsTx: { field: 'announcement:add:title:label' }
      })
    )
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'announcement:add:title:label', limited: TEXT_FIELD_MAX_LENGTH }
      })
    ),
  content: yup
    .string()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_001_input',
        optionsTx: { field: 'announcement:add:content:label' }
      })
    )
    .min(
      TEXT_FIELD_MIN_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_001_input',
        optionsTx: { field: 'announcement:add:content:label', limited: TEXT_FIELD_MIN_LENGTH }
      })
    )
    .htmlStrippedMaxLength(
      NOTE_ANNOUNCEMENT_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'announcement:add:content:label', limited: NOTE_ANNOUNCEMENT_MAX_LENGTH }
      })
    ),
  organizationId: yup
    .array()
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_select',
        optionsTx: { field: 'announcement:add:target_audience:label' }
      })
    )
    .test(
      'invalid-length',
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_select',
        optionsTx: { field: 'announcement:add:target_audience:label' }
      }),
      (value) => value.length > 0
    )
});

export const FormEditAnnouncement = FormCreateAnnouncement.shape({
  attachments: yup.array().of(
    yup.object().shape({
      status: yup.string().nullable(),
      rejectReason: yup
        .string()
        .nullable()
        .test({
          name: 'rejectReason',
          exclusive: false,
          params: {},
          message: stringifyObjectValidateYup({
            keyT: 'common:MSG_C_001_input',
            optionsTx: { field: 'announcement:reason_remand:label', limited: TEXT_FIELD_MIN_LENGTH }
          }),
          test: (value, ctx) => {
            if (ctx.parent.status === ATTACHMENT_STATUS.REJECT) {
              if (!value || value.length < TEXT_FIELD_MIN_LENGTH) {
                return false;
              }
              return true;
            }
            return true;
          }
        })
        .htmlStrippedMaxLength(
          NOTE_FILED_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'announcement:reason_remand:label', limited: NOTE_FILED_MAX_LENGTH }
          })
        )
    })
  )
});
