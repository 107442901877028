import { Tag } from 'antd';
import classNames from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PROCESS_TRAVEL_NAME } from '@pages/project-management/project-list-v2/models';

import BaseTable from '@/components/table/BaseTable';

import { DataViewer } from '@/utils/helpers/common';
import { TODO_SUPPORT_GROUPS_OPTION, TODO_SUPPORT_TASK_TYPE } from '@/utils/interfaces';

import { TASK_TYPE, TASK_TYPE_COLOR } from '../constants';
import { ITemplateProcess } from '../modules';

import './TemplateProcesses.scss';

interface IProcessHiddenItem extends ITemplateProcess {
  hidden: boolean;
}

// this class handle colSpan of customize table which have expandable & sub header
class RowHiddenController {
  static mainColSpan = 2;
  static onSubHeader = (record: IProcessHiddenItem, columnId: string) => {
    if (!record?.hidden) return { colSpan: columnId === 'name' ? 3 : 0 };
    return {};
  };
  static generateHiddenItem = (): IProcessHiddenItem => {
    return {
      templateId: '',
      processId: '',
      displayOrder: -1,
      id: '',
      templateProcessTasks: [],
      hidden: true
    };
  };
}

const TemplateProcesses = ({ data = [] }: { data: ITemplateProcess[] }) => {
  const { t } = useTranslation();

  const refTable = useRef<any>(null);

  const CATEGORY_PROCESS_OPTION_MAPPING: { [key: string]: string } = {
    [TASK_TYPE.MOT]: 'MOT',
    [TASK_TYPE.TODO]: 'TODO',
    [TASK_TYPE.OTHER]: t('template_layout:other_category'),
    [TASK_TYPE.SUPPORT]: t('task:type_support')
  };

  const calculateGroup = (): { [key in 'TODO' | 'OTHER_AND_MOT']: number }[] => {
    const results: any = Array.from({ length: data.length }, () => ({
      TODO: 0,
      OTHER_AND_MOT: 0
    }));
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const tasks = item?.templateProcessTasks ?? [];
      for (const element of tasks) {
        const type = element?.taskType;
        if (!type) continue;
        if ([TASK_TYPE.MOT, TASK_TYPE.OTHER].includes(type)) ++results[i]['OTHER_AND_MOT'];
        if (TODO_SUPPORT_GROUPS_OPTION.includes(type as TODO_SUPPORT_TASK_TYPE)) ++results[i]['TODO'];
      }
    }
    return results;
  };

  const generateProcessColumns = () => {
    const group = calculateGroup();
    return [
      {
        title: t('template_layout:category'),
        dataIndex: 'name',
        key: 'name',
        width: 295,
        className: '!text-start h-[62px]',
        colSpan: RowHiddenController.mainColSpan,
        onCell: (record: IProcessHiddenItem) => RowHiddenController.onSubHeader(record, 'name'),
        render: (_: string, record: any) => {
          const isTravel = record.processName === PROCESS_TRAVEL_NAME;
          return (
            <div className={classNames('flex gap-[10px] items-center process-sub-header', isTravel && '-ml-[40px]')}>
              <p className='text-lnk font-bold'>{DataViewer.display(record?.processName)}</p>
              {!isTravel && (
                <>
                  <Tag color='#DBDBE0' className='text-[14px] !text-lnk font-bold'>
                    {`TODO, ${t('task:type_support')}: ${group[record.displayOrder]?.['TODO'] || 0}`}
                  </Tag>
                  <Tag color='#DBDBE0' className='text-[14px] !text-lnk font-bold'>
                    {`${t('template_layout:other_category')}, MOT: ${group[record.displayOrder]?.['OTHER_AND_MOT'] || 0}`}
                  </Tag>
                </>
              )}
            </div>
          );
        }
      },
      {
        title: t('template_layout:title'),
        width: 500,
        dataIndex: 'taskName',
        key: 'taskName',
        onCell: (record: IProcessHiddenItem) => RowHiddenController.onSubHeader(record, 'taskName')
      },
      {
        title: t('template_layout:task_content'),
        dataIndex: 'detail',
        key: 'detail',
        onCell: (record: IProcessHiddenItem) => RowHiddenController.onSubHeader(record, 'detail')
      }
    ];
  };

  const generateTaskColumns = () => {
    return [
      {
        title: t('template_layout:category').toUpperCase(),
        dataIndex: 'typeId',
        key: 'typeId',
        width: 265,
        render: (_: string, record: any) => {
          const color = TASK_TYPE_COLOR[record.taskType as TASK_TYPE];
          return <Tag color={color}>{DataViewer.display(CATEGORY_PROCESS_OPTION_MAPPING[record.taskType])}</Tag>;
        }
      },
      {
        title: t('template_layout:title').toUpperCase(),
        key: 'submitterId',
        width: 500,
        render: (_: any, record: any) => {
          return <p>{DataViewer.display(record.taskName)}</p>;
        }
      },
      {
        title: t('template_layout:task_content').toUpperCase(),
        key: 'task_content',
        className: 'max-w-[400px]',
        render: (_: any, record: any) => {
          return <p dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(record?.detail) }}></p>;
        }
      }
    ];
  };

  // Empty Table []
  const getEmptyDataAlert = () => {
    return (
      <div className='text-center'>
        <p className='body-400'>{t('common:MSG_093')}</p>
      </div>
    );
  };

  const produceDataToView = (data: ITemplateProcess[]) => {
    const newData = [...data];
    const hiddenData = RowHiddenController.generateHiddenItem();
    newData.push(hiddenData);
    return newData;
  };

  return (
    <div className='view-template-process-table'>
      <BaseTable
        ref={refTable}
        className='w-full require-documents-table'
        rowKey='id'
        rowClassName={(record) => (record.processName === PROCESS_TRAVEL_NAME ? 'process-row-disable' : '')}
        dataSource={produceDataToView(data)}
        columns={generateProcessColumns()}
        pagination={false}
        emptyDataAlert={getEmptyDataAlert()}
        onRow={(record) => {
          if (record.hidden) return { style: { display: 'none' } };
          return {};
        }}
        expandable={{
          columnWidth: 42,
          defaultExpandedRowKeys: data.filter((item) => item.processName !== PROCESS_TRAVEL_NAME).map((item) => item.id),
          rowExpandable: (record) => !record.hidden && record.processName !== PROCESS_TRAVEL_NAME,
          expandedRowRender: (record) => {
            const tasks = record.templateProcessTasks ?? [];
            return (
              <div className='table-items'>
                <div className='table-items-collapse !w-[41px]' />
                <div className='flex-1 table-items-table'>
                  {!tasks.length ? (
                    getEmptyDataAlert()
                  ) : (
                    <BaseTable
                      ref={refTable}
                      className='w-full base-table-bordered'
                      rowKey='id'
                      tableLayout='fixed'
                      showHeader={false}
                      columns={generateTaskColumns()}
                      dataSource={tasks}
                      pagination={false}
                      emptyDataAlert={<></>}
                    />
                  )}
                </div>
              </div>
            );
          }
        }}
      />
    </div>
  );
};

export default TemplateProcesses;
