import { Dayjs } from 'dayjs';

import { AttachmentStatus } from '@/utils/constants/file';

import { StatusName } from '../../detail/constant';

export interface IFormAddTaskOfProject {
  countryId: string | null;
  taskMasterId: string | null;
  processId: any | null;
  category: any | null;
  detail?: string;
  projectTaskAttachmentFiles?: IFileAttachment[];
  taskInternalDocuments?: IDocument[];
  taskRequireDocuments?: IDocument[];
  version?: number;
  projectProcessId?: string;
  taskId?: string;
}

export interface IFileAttachment {
  id?: string | null;
  name?: string;
  blobPath?: string;
  status?: AttachmentStatus | null;
  displayOrder?: number;
  requestApproval?: boolean;
  version?: number;
  createdBy?: string | null;
}

export interface IDocument {
  id?: string | null;
  documentName?: string | null;
  numberOfCopies: string | null;
  description?: string | null;
  taskRequireDocumentAttachmentFiles?: any[];
  isConfirm?: boolean;
  dateConfirm?: string | null;
  nameConfirm?: string | null;
  displayOrder?: number;
  projectInternalDocumentId?: string | null;
  projectRequiredDocumentId?: string | null;
  version?: number;
  note?: string | null;
  deleted?: boolean;
}

export interface IFormDetailTask {
  deadline: string | null | Dayjs;
  picId: IOptions[] | IOptions | null;
  status: string | null;
  taskRequireDocuments: TaskRequireDocument[];
}

export interface IAttachmentTaskDetail {
  id: string | null;
  name: string | null;
  blobPath: string | null;
  contentType: string | null;
  fileSize: number;
  displayOrder: number;
  requestApproval: boolean;
  createdName: string | null;
  createdDate: string | null;
  createdBy: string | null;
  updatedDate?: string;
  status: AttachmentStatus;
  msgErr?: string;
  rejectReason?: string;
  deleted?: boolean;
}

export interface ProjectTaskPic {
  id: string;
  picId: string;
  picName: string;
  picEmail: string;
  picStatus: boolean;
  picCompanyId: string;
  picCompanyName: string;
  version: number;
  deleted: boolean;
  createdName: string;
}

export interface TaskInternalDocument {
  id: string;
  taskId: string;
  projectInternalDocumentId: string;
  isConfirm: boolean;
  displayOrder: number;
  version: number;
  createdDate: string;
}

export interface TaskRequireDocument {
  id: string;
  taskId: string;
  projectRequiredDocumentId: string;
  isConfirm: boolean;
  nameConfirm: string;
  dateConfirm: string;
  displayOrder: number;
  version: number;
  createdDate: string;
  taskRequireDocumentAttachmentFiles: TaskRequireDocumentAttachmentFile[];
  deleted?: boolean;
}

export interface TaskRequireDocumentAttachmentFile {
  id: string;
  taskRequireDocumentId: string;
  name: string;
  blobPath: string;
  displayOrder: number;
  version: number;
  createdDate: string;
  status?: string;
}

export interface IDetailTaskData {
  id: string | null;
  projectProcessId: string | null;
  projectProcessName: string | null;
  taskId: string | null;
  taskType: string | null;
  taskName: string | null;
  managementCode: string | null;
  detail: string | null;
  statusId: string | null;
  prevStatusId: string | null;
  statusName: StatusName | null;
  displayOrder: number | null;
  deleted: boolean | null;
  version: number | null;
  countryId: string | null;
  countryName: string | null;
  createdName: string | null;
  updatedName: string | null;
  completeName: string | null;
  completeDate: string | null;
  completeBy: string | null;
  deadline: string | null;
  createdBy: string | null;
  createdDate: string | null;
  updatedBy: string | null;
  updatedDate: string | null;
  departureDate: string | null;
  projectTaskAttachmentFiles: IAttachmentTaskDetail[];
  taskInternalDocuments: TaskInternalDocument[];
  taskRequireDocuments: TaskRequireDocument[];
  projectTaskPics: ProjectTaskPic[];
  applicantName?: string;
  applicantCompanyName?: string;
  applicantCountryName?: string;
  visaCategoryName?: string;
}

export interface IOptions {
  label: string;
  value: string;
  id?: string;
}

export interface IFormStakeHolder {
  stakeHolderId: string;
  stakeHolderName: string;
  stakeHolderEmail: string;
}

export interface IFormMOT {
  name: string;
  email: string;
  status?: boolean;
  id?: string;
}

export enum StatusCategory {
  TASK = 'task',
  TASK_LIST = 'task_list',
  TASK_SUPPORT = 'task_support'
}

export interface IStatus {
  id: string;
  name: string;
  types?: StatusCategory[];
}

export interface ITaskMaster extends IOptions {
  id?: string;
  name?: string;
  type?: string;
  nameSearch?: string;
  managementCode?: string;
  managementCodeSearch?: string;
  content?: string;
  contentSearch?: string;
  countryId?: string;
  countryName?: string;
  deleted?: boolean;
  createdBy?: string;
  createdName?: string;
  createdDate?: string;
  updatedBy?: string;
  updatedName?: string;
  updatedNameSearch?: string;
  updatedDate?: string;
  version?: number;
}

export interface IFormUpdateTask {
  projectId?: string;
  processId?: string;
  taskMasterId?: string;
  detail?: string;
  projectTaskAttachmentFiles?: ProjectTaskAttachmentFile[];
  taskRequireDocuments: IDocument[];
  taskInternalDocuments: IDocument[];
}

export interface ProjectTaskAttachmentFile {
  id?: string | null;
  name?: string;
  blobPath?: string;
  contentType?: string;
  fileSize?: number;
  deleted?: boolean;
  status?: AttachmentStatus | null;
  displayOrder?: number;
  createdDate?: string;
  createdName?: string;
  requestApproval?: boolean;
  version?: number;
}

export interface ProjectTaskRequireDocumentAttachmentFile {
  id?: string;
  name?: string;
  blobPath?: string;
  contentType?: string;
  fileSize?: number;
  deleted?: boolean;
  status?: string;
}
