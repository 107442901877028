import { RESPONSE_TYPE } from '@/layouts/contants';

import { FIELD_FILTER_NOT_COUNT } from '@/pages/notification-management/constants';

import i18n from '../../i18n';
import { API } from '../constants/Apis';
import { HTTP_STATUS_CODE } from '../constants/Http';
import {
  ACCOUNT_MASTER_LIST_URL,
  APPROVE_REJECT_ACCOUNT_MASTER_URL,
  EDIT_PROJECT_URL,
  NOT_EXITS_URL,
  PROJECT_QUESTION_DETAIL_URL,
  STAKEHOLDER_VIEW_URL,
  VIEW_ANNOUNCEMENT_URL,
  VIEW_PROJECT_URL,
  VIEW_TASK_OF_PROJECT_URL
} from '../constants/RouteContants';
import { Ii18n } from '../interfaces/i18n';
import { INoticeData, IParamTypeNoticeProps } from '../interfaces/notificationInterface';
import { getProjectInfoProject } from '../services/ProjectApiService';
import { PAGE_SEND_NOTICE, TYPE_NOTICE_MESSAGE } from './constants';
import { parseJson } from './globalHelper';
import { fetchInfo } from './routeLoaders';

const { t }: Ii18n = i18n;

const createParamTypeNotice = (
  messageType: string,
  convertParam: IParamTypeNoticeProps,
  origin: boolean,
  isInternal: boolean,
  originUserName: string
) => {
  const internalName = (originUserName || convertParam.UserName)?.slice(0, 3).toUpperCase();
  const externalName = convertParam.UserName?.slice(0, 3).toUpperCase();
  const commonParams = {
    userName: origin && isInternal ? t('notification:myself') : isInternal ? internalName : externalName,
    projectCode: convertParam.ProjectCode,
    companyName: convertParam.CompanyName,
    travelOrApplicantName: convertParam.TraverllerName || convertParam.ApplicantName,
    createDate: convertParam.CreateDate
  };

  const titles = {
    [TYPE_NOTICE_MESSAGE.AN_001]: t('common:MSG_AN_001', { AnnouncementName: convertParam.AnnouncementName, Title: convertParam.Title }),
    [TYPE_NOTICE_MESSAGE.AN_002]: t('common:MSG_AN_002', { Title: convertParam.Title }),
    [TYPE_NOTICE_MESSAGE.C_016]: t('common:MSG_C_016', { CreatedName: convertParam.CreatedName }),
    [TYPE_NOTICE_MESSAGE.C_017]: t('common:MSG_C_017', { UserName: convertParam.UserName }),
    [TYPE_NOTICE_MESSAGE.C_018]: t('common:MSG_C_018', { UserName: convertParam.UserName }),
    [TYPE_NOTICE_MESSAGE.P_007]: t('common:MSG_P_007', { UserName: convertParam.UserName }),
    [TYPE_NOTICE_MESSAGE.P_008]: t('common:MSG_P_008', { UserName: convertParam.UserName }),
    [TYPE_NOTICE_MESSAGE.P_011]: t('common:MSG_P_011', { CreatedName: convertParam.CreatedName }),
    [TYPE_NOTICE_MESSAGE.P_014]: convertParam.PicName
      ? t('common:MSG_P_014', { PicName: convertParam.PicName, Title: convertParam.Title })
      : t('common:MSG_P_014_sub', { Title: convertParam.Title }),
    [TYPE_NOTICE_MESSAGE.P_015]: convertParam.PicName
      ? t('common:MSG_P_015', { PicName: convertParam.PicName, Title: convertParam.Title })
      : t('common:MSG_P_015_sub', { Title: convertParam.Title }),
    [TYPE_NOTICE_MESSAGE.P_016]: convertParam.PicName
      ? t('common:MSG_P_016', { PicName: convertParam.PicName })
      : t('common:MSG_P_016_sub', { PicName: convertParam.UserName }),
    [TYPE_NOTICE_MESSAGE.P_017]: t('common:MSG_P_017', { Title: convertParam.Title }),
    [TYPE_NOTICE_MESSAGE.P_018]: t('common:MSG_P_018', { Title: convertParam.Title }),
    [TYPE_NOTICE_MESSAGE.P_019]: t('common:MSG_P_019', { Title: convertParam.Title }),
    [TYPE_NOTICE_MESSAGE.P_020]: t('common:MSG_P_020'),
    [TYPE_NOTICE_MESSAGE.P_028]: t('common:MSG_P_028', { UserName: convertParam.UserName }),
    [TYPE_NOTICE_MESSAGE.P_029]: t('common:MSG_P_029', { UserName: convertParam.UserName }),
    [TYPE_NOTICE_MESSAGE.P_039]: t('common:MSG_P_039')
  };

  return {
    ...commonParams,
    title: titles[messageType]
  };
};

const returnPageCreateNotice = async (data: INoticeData) => {
  // Return page that generated the message notice
  const { content, destinationTab, type, referenceId, params } = data;
  const convertParam = parseJson(params ?? '');

  const navigateMapping = {
    [PAGE_SEND_NOTICE.BASIC]: VIEW_PROJECT_URL(referenceId),
    [PAGE_SEND_NOTICE.DRAFT]: EDIT_PROJECT_URL(referenceId),
    [PAGE_SEND_NOTICE.ANNOUNCEMENT]: VIEW_ANNOUNCEMENT_URL(referenceId, convertParam.AnnouncementId),
    [PAGE_SEND_NOTICE.PROJECT_TASK_DETAIL]: VIEW_TASK_OF_PROJECT_URL(referenceId, convertParam.TaskId || convertParam.ProjectTaskId),
    [PAGE_SEND_NOTICE.USER_LIST]: ACCOUNT_MASTER_LIST_URL,
    [PAGE_SEND_NOTICE.STAKE_HOLDER]: STAKEHOLDER_VIEW_URL(referenceId),
    [PAGE_SEND_NOTICE.EDIT_USER]: APPROVE_REJECT_ACCOUNT_MASTER_URL(referenceId),
    [PAGE_SEND_NOTICE.QUESTIONAIRE]: PROJECT_QUESTION_DETAIL_URL(referenceId, convertParam.ObjectId),
    [PAGE_SEND_NOTICE.NOT_FOUND]: NOT_EXITS_URL
  };

  if (content === TYPE_NOTICE_MESSAGE.P_014 && destinationTab === PAGE_SEND_NOTICE.PROJECT_TASK_DETAIL) {
    try {
      await fetchInfo(API.GET_DETAIL_TASK(referenceId, convertParam.TaskId));
    } catch (error: any) {
      if (error?.response?.status === HTTP_STATUS_CODE.NOT_FOUND) return navigateMapping[PAGE_SEND_NOTICE.NOT_FOUND];
    }
  }

  if (content === TYPE_NOTICE_MESSAGE.AN_001 && destinationTab === PAGE_SEND_NOTICE.ANNOUNCEMENT) {
    try {
      await await fetchInfo(API.GET_ANNOUNCEMENT_DETAIL(convertParam.AnnouncementId));
    } catch (error: any) {
      if (error?.response?.status === HTTP_STATUS_CODE.NOT_FOUND) return navigateMapping[PAGE_SEND_NOTICE.NOT_FOUND];
    }
  }

  if (
    (content === TYPE_NOTICE_MESSAGE.P_007 && destinationTab === PAGE_SEND_NOTICE.EDIT_USER) ||
    ([TYPE_NOTICE_MESSAGE.P_020, TYPE_NOTICE_MESSAGE.P_039].includes(content) &&
      destinationTab === PAGE_SEND_NOTICE.STAKE_HOLDER &&
      type === RESPONSE_TYPE.RESULTS)
  ) {
    const { data: projectInfo } = await getProjectInfoProject(referenceId);
    if (content === TYPE_NOTICE_MESSAGE.P_007) {
      if (!projectInfo?.projectId || projectInfo.isDraft) return navigateMapping[PAGE_SEND_NOTICE.EDIT_USER];
      return navigateMapping[PAGE_SEND_NOTICE.STAKE_HOLDER];
    }
    if ([TYPE_NOTICE_MESSAGE.P_020, TYPE_NOTICE_MESSAGE.P_039].includes(content)) {
      if (!projectInfo?.projectId) return navigateMapping[PAGE_SEND_NOTICE.USER_LIST];
      return projectInfo.isDraft ? navigateMapping[PAGE_SEND_NOTICE.DRAFT] : navigateMapping[PAGE_SEND_NOTICE.STAKE_HOLDER];
    }
  }

  return navigateMapping[destinationTab];
};

const convertParamCountNotice = (data: any, isInternal: boolean) => {
  const countUnNoticeInternal = {
    'PROJECT.ASSIGN': data?.project || 0,
    'TASK.ASSIGN': data.task || 0,
    'DOCUMENT.APPROVAL_RESULT': data.approvalResult || 0,
    'DOCUMENT.APPROVAL_REQUEST': data.approvalRequest || 0,
    EXTERNAL: data.external || 0
  };
  const countUnNoticeExternal = {
    PROJECT_TASK: data.projectTask || 0,
    ANNOUNCEMENT: data.announcement || 0,
    OTHERS: data.others || 0
  };

  return isInternal ? countUnNoticeInternal : countUnNoticeExternal;
};

const countFiledFilterNotice = (values: any) => {
  let totalFilter = 0;
  if (values) {
    totalFilter = Object.keys(values)
      .filter((key) => values[key] === true && !FIELD_FILTER_NOT_COUNT.includes(key))
      .reduce((sum, key) => sum + values[key], 0);
  }

  return totalFilter;
};

const countTotalUnReadNotice = (values: any) => {
  return Object.keys(values).reduce((sum, key) => sum + values[key], 0);
};

export { createParamTypeNotice, returnPageCreateNotice, convertParamCountNotice, countFiledFilterNotice, countTotalUnReadNotice };
