import { Col, Row } from 'antd';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { GO_WITH_FAMILY } from '@/pages/project-management/project-list/constants';

import { BaseButton } from '@/components/base-button/BaseButton';
import { BaseCard } from '@/components/base-card/BaseCard';
import BaseRadioGroups from '@/components/common/base-radio';
import FormDateInput from '@/components/common/date-input';
import FormItem from '@/components/common/form-item';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import { PlusIcon } from '@/components/icon-svg/IconSvg';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { APPLICANT_GENDER } from '@/utils/constants';
import { REQUIRED_DOT } from '@/utils/constants/AppConstants';
import { isEmptyField } from '@/utils/helpers/globalHelper';

import { useFormScenarioCContext } from '..';
import { relativePath } from '../../utils';
import { FAMILY_MEMBER_FIELDS, MAX_FIELD_PERSON_NUMBER, PROJECT_FAMILY_PATH } from '../constants';

const PARENT_PATH = 'steps[1]';

export default function Step2() {
  const { t } = useTranslation();
  const { customCountryOptions, filterOption } = useOptionsGlobal();
  const form = useFormScenarioCContext<'step-3'>();
  const PATHS = {
    GO_WITH_FAMILY: relativePath('goWithFamily', PARENT_PATH),
    PROJECT_FAMILIES: relativePath(PROJECT_FAMILY_PATH, PARENT_PATH)
  };
  const [families] = form.watch([PATHS.PROJECT_FAMILIES]);
  const { append, remove } = useFieldArray({
    name: PATHS.PROJECT_FAMILIES,
    control: form.control
  });
  const gender = [
    {
      label: t('common:male'),
      value: APPLICANT_GENDER.MALE,
      id: APPLICANT_GENDER.MALE
    },
    {
      label: t('common:female'),
      value: APPLICANT_GENDER.FEMALE,
      id: APPLICANT_GENDER.FEMALE
    }
  ];

  const counters = (index: number) => {
    const watchingFieldPath = {
      all: FAMILY_MEMBER_FIELDS.map((field) => relativePath(field, `${PATHS.PROJECT_FAMILIES}[${index}]`))
    };
    const watchFields = form.watch(watchingFieldPath.all);
    const watchFieldsValid = watchFields.filter((field: any) => !isEmptyField(field));
    const results = {
      total: FAMILY_MEMBER_FIELDS.length,
      questions: watchFieldsValid.length ?? 0
    };
    return results;
  };
  const getChildrenPath = (index: number, field: string) => `${PATHS.PROJECT_FAMILIES}[${index}].${field}`;

  const addNewMember = () => {
    if (families?.length >= MAX_FIELD_PERSON_NUMBER) return;
    append({
      lastName: '',
      firstName: '',
      lastNameRomaji: '',
      firstNameRomaji: '',
      primaryNational: null,
      primaryNationalName: '',
      secondaryNational: null,
      secondaryNationalName: '',
      dateOfBirth: '',
      attendanceTime: '',
      uid: uuid()
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };
  return (
    <div className='mt-5'>
      <p className='title-20 text-black'>{t('project:add:family_information:title')}</p>
      <p className='subtitle-18 text-black mt-3'>
        {t('project:add:family_information:sub_title')}
        <span className='text-textError'>{REQUIRED_DOT}</span>
      </p>
      <FormItem name={PATHS.GO_WITH_FAMILY}>
        <BaseRadioGroups
          isVertical
          className='mt-3'
          options={[
            {
              label: t('project:add:family_information:yes'),
              value: GO_WITH_FAMILY.YES
            },
            {
              label: t('project:add:family_information:no'),
              value: GO_WITH_FAMILY.NO
            }
          ]}
          onChange={(value: any) => {
            if (value === GO_WITH_FAMILY.NO) {
              form.setValue(PATHS.PROJECT_FAMILIES, []);
              form.clearErrors(PATHS.PROJECT_FAMILIES);
            } else if (value === GO_WITH_FAMILY.YES && !families?.length) addNewMember();
          }}
        />
      </FormItem>
      {form.watch(PATHS.GO_WITH_FAMILY) === GO_WITH_FAMILY.YES && (
        <>
          <p className='subtitle-18 text-black mt-3'>{t('project:add:family_information:rule')}</p>
          {families?.map((field: any, index: number) => (
            <BaseCard
              className='mt-3'
              key={field.uid}
              showIconRemove={families?.length > 1}
              index={index}
              onRemove={handleRemove}
              title={t('project:add:family_information:family_number', { number: index + 1 })}
              total={counters(index).total}
              questions={counters(index).questions}
            >
              <div className='grid gap-4'>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormInput
                      required
                      defaultValue={form.getValues(getChildrenPath(index, 'lastName'))}
                      name={getChildrenPath(index, 'lastName')}
                      labelTx='project:add:applicant_representative:last_name'
                      placeholderTx='project:add:applicant_representative:example_yamada'
                    />
                  </Col>
                  <Col span={6}>
                    <FormInput
                      required
                      defaultValue={form.getValues(getChildrenPath(index, 'firstName'))}
                      name={getChildrenPath(index, 'firstName')}
                      labelTx='project:add:applicant_representative:first_name'
                      placeholderTx='project:add:applicant_representative:example_taro'
                    />
                  </Col>
                  <Col span={6}>
                    <FormInput
                      required
                      defaultValue={form.getValues(getChildrenPath(index, 'lastNameRomaji'))}
                      name={getChildrenPath(index, 'lastNameRomaji')}
                      labelTx='project:add:applicant_representative:last_name_romaji'
                      placeholderTx='project:add:applicant_representative:example_yamada_passport_notation'
                    />
                  </Col>
                  <Col span={6}>
                    <FormInput
                      required
                      defaultValue={form.getValues(getChildrenPath(index, 'firstNameRomaji'))}
                      name={getChildrenPath(index, 'firstNameRomaji')}
                      labelTx='project:add:applicant_representative:first_name_romaji'
                      placeholderTx='basic_information:place_holder_first_name_romaji'
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <FormSelect
                      required
                      id={getChildrenPath(index, 'primaryNational')}
                      name={getChildrenPath(index, 'primaryNational')}
                      label={t('project:add:applicant_representative:nationality') ?? ''}
                      options={customCountryOptions}
                      filterOption={filterOption}
                      placeholder={String(t('project:add:applicant_representative:example_japan'))}
                    />
                  </Col>
                  <Col span={6}>
                    <FormSelect
                      name={getChildrenPath(index, 'secondaryNational')}
                      label={t('project:add:applicant_representative:second_nationality') ?? ''}
                      options={customCountryOptions}
                      filterOption={filterOption}
                      placeholder={String(t('project:add:applicant_representative:example_america'))}
                    />
                  </Col>
                  <Col span={6}>
                    <FormDateInput
                      required
                      name={getChildrenPath(index, 'dateOfBirth')}
                      labelTx='project:add:applicant_representative:date_of_birth'
                    />
                  </Col>
                  <Col span={6}>
                    <FormSelect
                      required
                      id={getChildrenPath(index, 'gender')}
                      name={getChildrenPath(index, 'gender')}
                      label={t('project:add:applicant_representative:sex') ?? ''}
                      options={gender}
                      placeholder={String(t('project:add:please_select_field', { field: t('project:add:applicant_representative:sex') }))}
                    />
                  </Col>
                </Row>
              </div>
            </BaseCard>
          ))}
          <div className='max-w-[140px] mt-3'>
            <BaseButton
              icon={<PlusIcon />}
              type='secondary'
              block
              size='medium'
              disabled={families?.length === MAX_FIELD_PERSON_NUMBER}
              onClick={addNewMember}
            >
              {t('project:add:family_information:add_family_member_button')}
            </BaseButton>
          </div>
        </>
      )}
    </div>
  );
}
