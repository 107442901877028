import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { FormInput } from '@/components/form-input/FormInput';

import { useFormScenarioAContext } from '../..';
import CounterLabel from '../../../components/counter-label';
import { relativePath } from '../../../utils';

interface IVisaInformationProps {
  parentPath: string;
}

const VisaInformation = ({ parentPath }: IVisaInformationProps) => {
  const formMethod = useFormScenarioAContext();
  const PATHS = {
    ASSIGNMENT_COUNTRY: relativePath('steps[0].projectAssignmentInfo.countryId'),
    VISA_CATEGORY: relativePath('visaCategoryName', parentPath)
  };
  const [visaCategoryName] = formMethod.watch([PATHS.VISA_CATEGORY]);

  const { t } = useTranslation();

  const counters = () => {
    let current = 0;
    const total = 1;
    if (visaCategoryName) {
      current++;
    }
    return `${current}/${total}`;
  };
  return (
    <>
      <CounterLabel title={t('project:scenario_b:visa_information')} count={counters()} />
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            name={PATHS.VISA_CATEGORY}
            labelTx={t('project:scenario_b:visa_category:label')}
            placeholderTx={t('project:scenario_b:visa_category:placeholder')}
            style={{ flex: 'auto', height: '40px', borderRadius: '10px' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default VisaInformation;
