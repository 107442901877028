import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormScenarioBContext } from '@pages/project-management/add-edit/scenario/type-b';
import { GENDER_OPTIONS } from '@pages/project-management/add-edit/scenario/type-b/constants';

import { BaseCard } from '@/components/base-card/BaseCard';
import FormDateInput from '@/components/common/date-input';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';

import useCountField from '@/hooks/useCountField';
import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { FAMILY_MEMBER_FIELDS } from './constants';

interface IFamilyMemberProps {
  index: number;
  parentPath: string;
  showTrashIcon: boolean;
  onRemove: (index: number) => void;
}
const FamilyMember = ({ parentPath, index, showTrashIcon, onRemove }: IFamilyMemberProps) => {
  const { t } = useTranslation();
  const { answered, total } = useCountField(`${parentPath}[${index}]`, FAMILY_MEMBER_FIELDS);
  const { getValues } = useFormScenarioBContext<'step-3'>();
  const getFieldPath = (index: number, field: string) => `${parentPath}[${index}].${field}`;
  const { customCountryOptions, filterOption } = useOptionsGlobal();

  const gender = GENDER_OPTIONS.map((item) => ({ ...item, label: t(item.label) }));

  return (
    <BaseCard
      showIconRemove={showTrashIcon}
      index={index}
      onRemove={onRemove}
      title={t('project:add:family_information:family_number', { number: index + 1 })}
      total={total}
      questions={answered}
    >
      <div className='grid gap-4'>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              required
              defaultValue={getValues(getFieldPath(index, 'lastName'))}
              name={getFieldPath(index, 'lastName')}
              labelTx='project:add:applicant_representative:last_name'
              placeholderTx='project:add:applicant_representative:example_yamada'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required
              defaultValue={getValues(getFieldPath(index, 'firstName'))}
              name={getFieldPath(index, 'firstName')}
              labelTx='project:add:applicant_representative:first_name'
              placeholderTx='project:add:applicant_representative:example_taro'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required
              defaultValue={getValues(getFieldPath(index, 'lastNameRomaji'))}
              name={getFieldPath(index, 'lastNameRomaji')}
              labelTx='project:add:applicant_representative:last_name_romaji'
              placeholderTx='project:add:applicant_representative:example_yamada_passport_notation'
            />
          </Col>
          <Col span={6}>
            <FormInput
              required
              defaultValue={getValues(getFieldPath(index, 'firstNameRomaji'))}
              name={getFieldPath(index, 'firstNameRomaji')}
              labelTx='project:add:applicant_representative:first_name_romaji'
              placeholderTx='basic_information:place_holder_first_name_romaji'
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormSelect
              required
              id={getFieldPath(index, 'primaryNational')}
              name={getFieldPath(index, 'primaryNational')}
              label={t('project:add:applicant_representative:nationality') ?? ''}
              options={customCountryOptions}
              filterOption={filterOption}
              placeholder={String(t('project:add:applicant_representative:example_japan'))}
            />
          </Col>
          <Col span={6}>
            <FormSelect
              name={getFieldPath(index, 'secondaryNational')}
              label={t('project:add:applicant_representative:second_nationality') ?? ''}
              options={customCountryOptions}
              filterOption={filterOption}
              placeholder={String(t('project:add:applicant_representative:example_america'))}
            />
          </Col>
          <Col span={6}>
            <FormDateInput required name={getFieldPath(index, 'dateOfBirth')} labelTx='project:add:applicant_representative:date_of_birth' />
          </Col>
          <Col span={6}>
            <FormSelect
              required
              id={getFieldPath(index, 'gender')}
              name={getFieldPath(index, 'gender')}
              label={t('project:add:applicant_representative:sex') ?? ''}
              options={gender}
              placeholder={String(t('project:add:please_select_field', { field: t('project:add:applicant_representative:sex') }))}
            />
          </Col>
        </Row>
      </div>
    </BaseCard>
  );
};

export default FamilyMember;
