import { useAppSelector } from '@/hooks';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { BaseCard } from '@/components/base-card/BaseCard';
import { FormInput } from '@/components/form-input/FormInput';
import { FormSelect } from '@/components/form-select/FormSelect';
import { InfoIcon } from '@/components/icon-svg/IconSvg';

import useOptionsGlobal from '@/hooks/useOptionsGlobal';

import { Counters } from '@/utils/helpers/counters';

import { useFormScenarioCContext } from '..';
import { relativePath } from '../../utils';
import { ASSIGNMENT_INFO_FIELDS } from '../constants';

const PARENT_PATH = 'steps[2]';

export default function Step4() {
  const travelTypes = useAppSelector((state) => state.projectV2.travelTypes.data);
  const PATHS = {
    PROJECT_ASSIGNMENT_INFO: relativePath('projectAssignmentInfo', PARENT_PATH),
    TRAVEL_TYPE: relativePath('travelTypeId', PARENT_PATH)
  };
  const { t } = useTranslation();
  const form = useFormScenarioCContext<'step-4'>();
  const { customCountryOptions, filterOption } = useOptionsGlobal();
  const counters = new Counters(
    {
      sub: ASSIGNMENT_INFO_FIELDS
    },
    form.watch(PATHS.PROJECT_ASSIGNMENT_INFO)
  );
  const answered = counters.get('sub');
  return (
    <div className='mt-5'>
      <p className='title-20 text-black'>{t('project:add:assignment_info:title')}</p>
      <div className='flex py-2'>
        <div className='py-[2px] mr-[4px]'>
          <InfoIcon width={14.63} height={14.63} />
        </div>
        <div className='font-normal leading-[22px] text-[14px] text-[#525A68]'>
          <p>{t('project:add:assignment_info:rule')}</p>
        </div>
      </div>
      <BaseCard title={t('project:scenario_b:assignment_information')} total={4} questions={answered}>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              required
              defaultValue={form.getValues('projectAssignmentInfo.name')}
              name={`${PATHS.PROJECT_ASSIGNMENT_INFO}.name`}
              labelTx='basic_information:assignee_trainee_name'
              placeholder={`${t('common:MSG_001_textbox', {
                field: t('basic_information:assignee_trainee_name')
              })}`}
            />
          </Col>
          <Col span={6}>
            <FormInput
              required
              defaultValue={form.getValues('projectAssignmentInfo.companyName')}
              name={`${PATHS.PROJECT_ASSIGNMENT_INFO}.companyName`}
              labelTx='basic_information:assignment_training_company_name'
              placeholderTx='basic_information:place_holder_company_name_of_assignee'
            />
          </Col>
          <Col span={6}>
            <FormSelect
              required
              id={`${PATHS.PROJECT_ASSIGNMENT_INFO}.countryId`}
              name={`${PATHS.PROJECT_ASSIGNMENT_INFO}.countryId`}
              label={t('basic_information:destination_training_country') || ''}
              options={customCountryOptions}
              filterOption={filterOption}
              placeholder={String(t('basic_information:place_holder_second_nationality'))}
            />
          </Col>
          <Col span={6}>
            <FormInput
              required
              defaultValue={form.getValues(`${PATHS.PROJECT_ASSIGNMENT_INFO}.address`)}
              name={`${PATHS.PROJECT_ASSIGNMENT_INFO}.address`}
              label={t('basic_information:assignment_training_city')}
              placeholderTx={String(t('basic_information:place_holder_address_of_the_destination'))}
            />
          </Col>
        </Row>
      </BaseCard>
      <BaseCard title={t('basic_information:travel_type')} total={1} questions={form.watch(`${PATHS.TRAVEL_TYPE}`) ? 1 : 0} className='mt-3'>
        <Row gutter={16}>
          <Col span={12}>
            <FormSelect
              name={PATHS.TRAVEL_TYPE}
              label={String(t('basic_information:travel_type'))}
              placeholder={String(
                t('placeholder:select', {
                  field: t('basic_information:travel_type')
                })
              )}
              options={travelTypes}
            />
          </Col>
        </Row>
      </BaseCard>
    </div>
  );
}
