import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IAccountData } from '@/pages/notification-management/models';

import { HTTP_STATUS_CODE } from '@/utils/constants/Http';
import { convertParamCountNotice, countFiledFilterNotice, countTotalUnReadNotice } from '@/utils/helpers/notification';
import { INoticeData, IParamFilterNoticeProps } from '@/utils/interfaces/notificationInterface';
import { getCountNotificationsUnRead, getNotifications, readAlertNotification, readNotification } from '@/utils/services/notificationApiService';

import { RootState } from '../store';

interface IPopupNoticeState {
  show: boolean;
  url?: string;
  dataNotice?: INoticeData;
  title?: string;
  projectCode?: string;
  projectCompany?: string;
  projectAddress?: string;
}

type NoticeType = {
  data: INoticeData;
  orderBy: string;
};

interface ICountNoticeUnRead {
  'PROJECT.ASSIGN'?: number;
  'TASK.ASSIGN'?: number;
  'DOCUMENT.APPROVAL_RESULT'?: number;
  'DOCUMENT.APPROVAL_REQUEST'?: number;
  EXTERNAL?: number;
  PROJECT_TASK?: number;
  ANNOUNCEMENT?: number;
  OTHERS?: number;
  [key: string]: any;
}

interface INoticeStore {
  openNotice: boolean;
  refreshPage: boolean;
  popupNotice: IPopupNoticeState;
  onOffAlert: boolean;
  countFieldFilterNotice: number;
  payloadSearchNotice: IParamFilterNoticeProps;
  payloadFilterNotice: IParamFilterNoticeProps;
  totalNoticeUnRead: number;
  drawerNoticeUnRead: ICountNoticeUnRead;
  noticeData: {
    loading: boolean;
    data: INoticeData[];
  };
  currentTabNotice: string;
}

const nameSpace = 'usr/notify';

// Define the initial state using that type
const initialState: INoticeStore = {
  openNotice: false,
  refreshPage: false,
  popupNotice: {
    show: false
  },
  onOffAlert: false,
  countFieldFilterNotice: 0,
  payloadSearchNotice: {},
  payloadFilterNotice: {},
  totalNoticeUnRead: 0,
  drawerNoticeUnRead: {},
  noticeData: {
    loading: false,
    data: []
  },
  currentTabNotice: ''
};

export const projectSlice = createSlice({
  name: nameSpace,
  initialState,
  reducers: {
    setOpenNotice: (state, action: PayloadAction<boolean>) => {
      state.openNotice = action.payload;
    },
    setRefreshPage: (state, action: PayloadAction<boolean>) => {
      state.refreshPage = action.payload;
    },
    setPopupNotice: (state, action: PayloadAction<IPopupNoticeState>) => {
      state.popupNotice = action.payload;
    },
    setOnOffAlert: (state, action: PayloadAction<boolean>) => {
      state.onOffAlert = action.payload;
    },
    setCountFieldFilterNotice: (state, action: PayloadAction<number>) => {
      state.countFieldFilterNotice = action.payload;
    },
    setPayloadSearchNotice: (state, action: PayloadAction<IParamFilterNoticeProps>) => {
      state.payloadSearchNotice = action.payload;
    },
    setPayloadFilterNotice: (state, action: PayloadAction<IParamFilterNoticeProps>) => {
      state.payloadFilterNotice = action.payload;
      state.countFieldFilterNotice = countFiledFilterNotice(action.payload) ?? 0;
    },
    setTotalNoticeUnRead: (state, action: PayloadAction<number>) => {
      state.totalNoticeUnRead = action.payload;
    },
    setDrawerNoticeUnRead: (state, action: PayloadAction<ICountNoticeUnRead>) => {
      state.drawerNoticeUnRead = action.payload;
    },
    setCurrentTabNotice: (state, action: PayloadAction<string>) => {
      state.currentTabNotice = action.payload;
    },
    setNoticeData: (state, action: PayloadAction<INoticeData[]>) => {
      state.noticeData.data = action.payload;
    },
    incrementNotice: (state: any, action: PayloadAction<NoticeType>) => {
      const { orderBy, data } = action.payload;
      if (orderBy === 'desc') {
        state.noticeData.data.unshift(data);
      } else if (orderBy === 'asc') {
        state.noticeData.data.push(data);
      }
    },
    changeCountNoticeUnReadWithType: (state, action: PayloadAction<{ type: keyof ICountNoticeUnRead; count: number }>) => {
      state.totalNoticeUnRead = state.totalNoticeUnRead + action.payload.count;
      const newCount = state.drawerNoticeUnRead[action.payload.type];
      state.drawerNoticeUnRead = {
        ...state.drawerNoticeUnRead,
        [action.payload.type]: Number(newCount) + action.payload.count
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataNotice.pending, (state) => {
      state.noticeData.loading = true;
    });
    builder.addCase(fetchDataNotice.fulfilled, (state, action) => {
      state.noticeData.data = action.payload?.data;
      state.noticeData.loading = false;
    });
    builder.addCase(fetchCountNoticeUnRead.fulfilled, (state, action) => {
      state.totalNoticeUnRead = countTotalUnReadNotice(action.payload?.countReadUnNotice);
      state.drawerNoticeUnRead = action.payload?.countReadUnNotice ?? {};
    });
    builder.addCase(fetchReadNotice.fulfilled, (state, action) => {
      state.totalNoticeUnRead = state.totalNoticeUnRead - 1;
      state.totalNoticeUnRead = countTotalUnReadNotice(action.payload?.countReadUnNotice);
      state.drawerNoticeUnRead = action.payload?.countReadUnNotice ?? {};
    });
    builder.addCase(fetchUpdateNotice.fulfilled, (state, action) => {
      state.noticeData.data = action.payload?.data ?? [];
    });
  }
});

export const fetchDataNotice = createAsyncThunk(`${nameSpace}/search`, async (paramFilter: IParamFilterNoticeProps) => {
  try {
    // Call api get notice data
    const { data: dataNotice } = await getNotifications(paramFilter);
    return {
      data: dataNotice.data
    };
  } catch (error) {}
});

export const fetchCountNoticeUnRead = createAsyncThunk(`${nameSpace}/readed`, async (isInternalRole: boolean) => {
  try {
    // Call api reset count unread notice
    const { data: unreadNoticeData } = await getCountNotificationsUnRead();

    return {
      countReadUnNotice: convertParamCountNotice(unreadNoticeData, isInternalRole)
    };
  } catch (error) {}
});

// Call api list count notice unread and update notice data
export const fetchReadNotice = createAsyncThunk(
  `${nameSpace}/count-unread`,
  async (payload: { notice?: INoticeData; typeNotice?: string; isReadAlert?: boolean; isInternal: boolean; payloadRead?: IAccountData[] }) => {
    const { notice, typeNotice, isReadAlert, isInternal, payloadRead = [] } = payload;
    let unreadNoticeData = {};

    try {
      const payload = isReadAlert
        ? { notifyId: String(notice?.id), origin: Boolean(notice?.origin), originId: notice?.originUserId }
        : { Readeds: payloadRead };
      // Call api readed notice in popup alert or api readed notice
      const result = isReadAlert ? await readAlertNotification(payload) : await readNotification(payload);
      if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
        // Call api reset count unread notice
        unreadNoticeData = (await getCountNotificationsUnRead())?.data;
      }

      return {
        type: notice?.type ?? typeNotice,
        countReadUnNotice: convertParamCountNotice(unreadNoticeData, isInternal)
      };
    } catch (error) {}
  }
);

export const fetchUpdateNotice = createAsyncThunk(
  `${nameSpace}/update`,
  async (
    payload: {
      notice?: INoticeData;
      isReadAlert?: boolean;
      payloadRead?: IAccountData[];
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const data = state.notice.noticeData.data;
    const { notice, isReadAlert, payloadRead = [] } = payload;

    // Update data notice read
    let updateData = structuredClone(data);
    if (isReadAlert) {
      // For notice alert
      const indexNoticeAlert = updateData.findIndex(
        (item) => item.id === notice?.id && (notice?.origin ? item.origin : item.originUserId === notice?.originUserId)
      );
      updateData[indexNoticeAlert] = { ...updateData[indexNoticeAlert], readed: true };
    } else {
      // For notice read in drawer
      updateData = data.map((item) => {
        if (payloadRead.some((el) => el.id === item.id)) {
          return { ...item, readed: true };
        }
        return item;
      });
    }

    return {
      data: updateData
    };
  }
);

export { nameSpace };
export const {
  setOpenNotice,
  setRefreshPage,
  setPopupNotice,
  setOnOffAlert,
  setCountFieldFilterNotice,
  setPayloadSearchNotice,
  setPayloadFilterNotice,
  setCurrentTabNotice,
  setNoticeData,
  incrementNotice,
  changeCountNoticeUnReadWithType
} = projectSlice.actions;

export default projectSlice.reducer;
