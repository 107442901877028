import classNames from 'classnames';
import { t } from 'i18next';

import BaseInput, { IBaseInputProps } from '@/components/common/base-input';
import FormItem from '@/components/common/form-item';

import { MAX_LENGTH_TEXT } from '../../constants';
import { ValidateRules } from '../../helpers';
import { AnswerType } from '../../modals';

export interface ITextProps extends IBaseInputProps {
  name: string;
  unit?: string;
  type?: AnswerType;
  maxLength?: number;
}

const FixUnit: React.FC<ITextProps> = ({
  type,
  name,
  unit,
  className,
  readOnly,
  placeholder = '________',
  maxLength = MAX_LENGTH_TEXT,
  ...restProps
}) => {
  const validateObj =
    type && ValidateRules[type]
      ? {
          [type]: ValidateRules[type]
        }
      : undefined;

  return (
    <div className='w-full flex space-x-2 items-center'>
      <div className='w-[80px] truncate h-full'>
        <FormItem
          showErrorOption='icon'
          label={t('project:question_detail:answer_title') ?? ''}
          showLabel={false}
          name={name}
          rules={{ maxLength, validate: validateObj }}
          needTrimValue={true}
        >
          <BaseInput
            readOnly={readOnly}
            placeholder={readOnly ? '' : placeholder}
            id={name}
            hideBorder
            margin={{ left: 0 }}
            className={classNames(className ?? '', 'text-right')}
            {...restProps}
          />
        </FormItem>
      </div>
      <div>
        <span>{unit ?? ''}</span>
      </div>
    </div>
  );
};

export default FixUnit;
