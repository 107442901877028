import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { BaseCollapse } from '@/components/base-collapse/BaseCollapse';
import { FormInput } from '@/components/form-input/FormInput';

type Props = {
  formMethod: any;
};

const VisaInformationComponent = (props: Props) => {
  const { formMethod } = props;
  const { t } = useTranslation();

  const watchFields = formMethod.watch(['visaCategoryName']);
  const watchFieldsValid = watchFields.filter((field: any) => field);

  return (
    <BaseCollapse id='request_detail' title={t('basic_information:visa_information_label')} total={1} questions={watchFieldsValid.length}>
      <Row gutter={16}>
        <Col span={12}>
          <FormInput
            name='visaCategoryName'
            labelTx={t('basic_information:visa_category_textbox:label')}
            placeholderTx={t('basic_information:visa_category_textbox:placeholder')}
            style={{ flex: 'auto', height: '40px', borderRadius: '10px' }}
          />
        </Col>
      </Row>
    </BaseCollapse>
  );
};

export default VisaInformationComponent;
