import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IBaseInputProps } from '@/components/common/base-input';
import FormItem from '@/components/common/form-item';
import MaskedInput from '@/components/common/mask-input';

interface IDateTimeProps extends IBaseInputProps {
  name: string;
}

const YearMonthDate: React.FC<IDateTimeProps> = ({ readOnly, name, ...restProps }) => {
  const formMethod = useFormContext();
  const { t } = useTranslation();
  const nameOrigin = name.replace('answeredEn', 'answered');

  return (
    <div className='w-full'>
      <FormItem
        name={nameOrigin}
        isContainer={true}
        showErrorOption='icon'
        rules={{
          validate: {
            validDate: (value) => {
              if (!value?.year && !value?.month && !value?.date) {
                return true;
              }

              if (value?.year && (+value?.year > 9998 || +value?.year < 1900)) {
                return false;
              }
              if (value?.month && (+value?.month > 12 || value?.month < 1)) {
                return false;
              }
              if (value?.date && (+value?.date < 1 || +value?.date > 31)) {
                return false;
              }
              if (value?.year && value?.month && value?.date) {
                return dayjs(
                  `${(value?.year ?? '').padStart(4, '0')}-${(value?.month ?? '').padStart(2, '0')}-${(value?.date ?? '').padStart(2, '0')}`,
                  'YYYY-MM-DD',
                  true
                ).isValid();
              }
              return true;
            }
          }
        }}
      >
        <div className='h-full flex space-x-2' id={nameOrigin}>
          <div className='w-[40px] truncate h-full'>
            <FormItem name={`${nameOrigin}.year`}>
              <MaskedInput
                mask='9999'
                inputMode='numeric'
                maskChar={''}
                readOnly={readOnly}
                placeholder={readOnly ? '' : '____'}
                hideBorder
                margin={{ left: 0 }}
                onChange={() => {
                  formMethod.trigger(nameOrigin);
                }}
                className='text-right'
                {...restProps}
              />
            </FormItem>
          </div>
          <div>
            <span>{t('project:question_detail:year_month_date:year')}</span>
          </div>
          <div className='w-[20px] truncate h-full'>
            {
              <FormItem name={`${nameOrigin}.month`}>
                <MaskedInput
                  mask='99'
                  inputMode='numeric'
                  maskChar={''}
                  readOnly={readOnly}
                  placeholder={readOnly ? '' : '__'}
                  hideBorder
                  margin={{ left: 0 }}
                  onChange={() => {
                    formMethod.trigger(nameOrigin);
                  }}
                  className='text-right'
                  {...restProps}
                />
              </FormItem>
            }
          </div>
          <div>
            <span>{t('project:question_detail:year_month_date:month')}</span>
          </div>
          <div className='w-[20px] truncate h-full'>
            <FormItem name={`${nameOrigin}.date`}>
              <MaskedInput
                mask='99'
                inputMode='numeric'
                maskChar={''}
                readOnly={readOnly}
                placeholder={readOnly ? '' : '__'}
                hideBorder
                margin={{ left: 0 }}
                onChange={() => {
                  formMethod.trigger(nameOrigin);
                }}
                className='text-right'
                {...restProps}
              />
            </FormItem>
          </div>
          <div>
            <span>{t('project:question_detail:year_month_date:date')}</span>
          </div>
        </div>
      </FormItem>
    </div>
  );
};

export default YearMonthDate;
