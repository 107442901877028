import { Checkbox, Switch, Tooltip } from 'antd';
import classNames from 'classnames';
import { camelCase } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  COPY_ACTION_CELL_WIDTH,
  QUESTION_ACTION_CELL_WIDTH,
  TRANSLATE_CELL_WIDTH,
  TYPE_SHARE_DATA_BETWEEN_LANGUAGES,
  additionalFieldPostal
} from '../constants';
import { mappingRenderFunction } from '../helpers';
import { CONTENT_TYPE, IQuestion, IQuestionOption } from '../modals';
import Table, { ITableItem } from './types/Table';
import Text from './types/Text';
import TranslateFlag from './types/TranslateFlg';

interface IQuestionContentProps {
  question: IQuestion;
  mode: 'view' | 'edit';
  name: string;
  showTranslationData?: boolean;
  showTranslateFlg?: boolean;
  showCopyFlg?: boolean;
  isAncestor?: boolean;
  cellWidth?: number;
  showButtonChangeVisibleExternal?: boolean;
  copyAnswerFromApplicant?: (checked: boolean, name: string[]) => void;
  isTranslateData?: boolean;
}

const QuestionContent: React.FC<IQuestionContentProps> = ({
  isAncestor: isAncestor,
  cellWidth,
  question,
  mode,
  name,
  showTranslationData,
  showTranslateFlg,
  showCopyFlg,
  isTranslateData,
  showButtonChangeVisibleExternal,
  copyAnswerFromApplicant
}) => {
  const isViewMode = mode === 'view';
  const { t } = useTranslation();
  const form = useFormContext();
  const getTextPair = (questions: IQuestion[]): { [key: string]: { left?: IQuestion; right?: IQuestion } } => {
    const listTextPair = questions
      .map((i, index) => ({ ...i, index }))
      .filter((i) => [CONTENT_TYPE.TEXTPAIR_LEFT, CONTENT_TYPE.TEXTPAIR_RIGHT].includes(i.type) && i.level !== 1);
    if (listTextPair.length) {
      const pairDict = listTextPair.reduce((dict: { [key: string]: { left?: IQuestion; right?: IQuestion } }, cur) => {
        if (cur.type === CONTENT_TYPE.TEXTPAIR_LEFT && cur.id) {
          dict[cur.id] = {
            ...dict[cur.id],
            left: { ...cur, no: cur.index }
          };
        }
        if (cur.type === CONTENT_TYPE.TEXTPAIR_RIGHT && cur.pairId) {
          dict[cur.pairId] = {
            ...dict[cur.pairId],
            right: { ...cur, no: cur.index }
          };
        }
        return dict;
      }, {});

      return pairDict;
    }
    return {};
  };

  const generateCellAdditions = (
    items: {
      key: 'copy' | 'translate' | 'externalVisible';
      fieldNames: string[];
      isEmptyField?: boolean;
    }[]
  ): ITableItem[] => {
    const additionalCells: ITableItem[] = [];
    items.forEach((item) => {
      if (item.key === 'copy') {
        additionalCells.push({
          content: item?.isEmptyField ? (
            <></>
          ) : (
            <Checkbox
              onChange={(e) => {
                if (isViewMode) {
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
                copyAnswerFromApplicant && copyAnswerFromApplicant(e.target.checked, item.fieldNames);
              }}
              checked={!!form.getValues(`${item.fieldNames[0] ?? ''}.answer.isCopyAnswer`)}
            >
              <Tooltip title={t('project:question_detail:copy_from_applicant_tooltip')}>{t('project:question_detail:copy_from_applicant')}</Tooltip>
            </Checkbox>
          ),
          style: { width: `${COPY_ACTION_CELL_WIDTH}px` }
        });
      }

      if (item.key === 'translate') {
        additionalCells.push({
          content: item?.isEmptyField ? <></> : <TranslateFlag translateFlag={!!form.getValues(`${item.fieldNames[0] ?? ''}.translateFlag`)} />,
          style: { width: `${TRANSLATE_CELL_WIDTH}px` }
        });
      }

      if (item.key === 'externalVisible') {
        additionalCells.push({
          content: item?.isEmptyField ? (
            <></>
          ) : (
            <Switch
              onClick={(checked) => {
                item.fieldNames.forEach((fieldName) => {
                  form.setValue(`${fieldName ?? ''}.visibleExternalUser`, checked, {
                    shouldDirty: true,
                    shouldTouch: true
                  });
                });
              }}
              disabled={form.getValues(`${item.fieldNames[0] ?? ''}._disabled`)}
              checked={!!form.getValues(`${item.fieldNames[0] ?? ''}.visibleExternalUser`)}
            />
          ),
          style: { width: `${QUESTION_ACTION_CELL_WIDTH}px` },
          className: `max-w-[${QUESTION_ACTION_CELL_WIDTH}px] text-center`
        });
      }
    });
    return additionalCells.map((i) => ({ ...i, className: 'additional-cell' }));
  };

  const renderQuestionRow = (
    questionRender: IQuestion,
    containCellTranslateStatus: boolean = false,
    containCellCopied: boolean = false,
    isTranslate: boolean,
    containCellVisible: boolean = false
  ) => {
    const headerFontClass = 'body-700 whitespace-pre-line';
    const headerClass = `!w-[150px]`;
    const row: ITableItem[] = [];
    const subTableContent: ITableItem[][] = [];
    const answerKey = isTranslate ? 'answeredEn' : 'answered';

    const renderFunc = mappingRenderFunction[questionRender.type ?? ''];
    const question: IQuestion = {
      ...questionRender,
      labelInput:
        questionRender.type === CONTENT_TYPE.DATETIMEFROM_TO
          ? [t('project:question_detail:type:date_time_from'), t('project:question_detail:type:date_time_to')]
          : questionRender.labelInput
    };
    // Flag to check if should add translate flag cell on the end of question content
    let shouldAddAdditionalCell = (containCellTranslateStatus || showCopyFlg || containCellVisible) && !question.questions?.length;
    const hasChildren = question.questions?.length;

    const formKey = `${name}.answer.${answerKey}`;

    // If question is not level 1, create a title cell
    if (question.level !== 1 && question.type !== CONTENT_TYPE.NONE) {
      row.push({
        isTitle: true,
        className: headerClass,
        content: <p className={headerFontClass}>{question.title}</p>
      });
    }

    // Get selected item if question is radio
    const { selectedItem, selectedIndex } =
      question?.options?.reduce(
        (
          values: {
            selectedItem: IQuestionOption | undefined;
            selectedIndex: number | undefined;
          },
          item,
          index
        ) => {
          if (item.id === question.answer?.answered?.id) {
            return { selectedItem: item, selectedIndex: index };
          }
          return values;
        },
        {
          selectedItem: undefined,
          selectedIndex: undefined
        }
      ) ?? {};

    const additionalItems: ('copy' | 'translate' | 'externalVisible')[] = [];

    if (containCellCopied) {
      additionalItems.push('copy');
    }
    if (containCellTranslateStatus) {
      additionalItems.push('translate');
    }
    if (containCellVisible) {
      additionalItems.push('externalVisible');
    }

    if (question.remark) {
      subTableContent.push([
        {
          content: (
            <Table
              items={[
                [
                  {
                    content: <p className='font-bold'>{isTranslate ? question.remarkEn || question.remark : question.remark}</p>
                  }
                ]
              ]}
              className='sub-table'
            />
          )
        },
        ...generateCellAdditions(additionalItems.map((key) => ({ key, fieldNames: [], isEmptyField: true })))
      ]);
      shouldAddAdditionalCell = false;
    }
    if (renderFunc) {
      const key = `${name}.answer.${TYPE_SHARE_DATA_BETWEEN_LANGUAGES.includes(question.type) ? 'answered' : answerKey}`;
      const itemsRender: ITableItem[] = renderFunc({
        question,
        readOnly: isViewMode,
        name: key,
        isTranslateData: isTranslate,
        disabledDateFrom:
          question.type !== CONTENT_TYPE.DATETIMEFROM_TO
            ? undefined
            : (d) => {
                return form.getValues(`${key}.to`) && d.startOf('day').isAfter(form.getValues(`${key}.to`));
              },
        disabledDateTo:
          question.type !== CONTENT_TYPE.DATETIMEFROM_TO
            ? undefined
            : (d) => {
                return form.getValues(`${key}.from`) && d.startOf('day').isBefore(form.getValues(`${key}.from`));
              },
        showTranslateData: showTranslationData,
        onChangeUnit(_value, label) {
          form.setValue(`${name}.answer.answered.unit.title`, label, { shouldDirty: true });
        }
      }).map((i) => ({ content: i }));
      const isRadioWithChildren = selectedItem && selectedItem.questions?.length;
      const haveAdditionalCell = containCellTranslateStatus || containCellCopied;
      const postalItems: any[] = [];

      if (question.type === CONTENT_TYPE.POSTAL_CODE) {
        itemsRender.unshift({
          isTitle: true,
          className: headerClass,
          content: <p className={headerFontClass}>{t('postal_code:title')}</p>
        });
        additionalFieldPostal.forEach(({ key, type: fieldType }) => {
          const fieldKey = camelCase(key);
          postalItems.push([
            {
              content: (
                <Table
                  items={[
                    [
                      {
                        isTitle: true,
                        className: headerClass,
                        content: <p className={headerFontClass}>{t(`postal_code:${key}`)}</p>
                      },
                      {
                        content: (
                          <Text
                            type={isTranslate ? undefined : fieldType}
                            placeholder={t(`postal_code:${key}_placeholder`) ?? ''}
                            name={`${formKey}.${fieldKey}`}
                            readOnly={isViewMode}
                          />
                        )
                      }
                    ]
                  ]}
                  className='sub-table'
                />
              )
            }
          ]);
        });
      }

      if (haveAdditionalCell && (hasChildren || isRadioWithChildren || question.remark)) {
        shouldAddAdditionalCell = false;
        subTableContent.push([
          {
            content: <Table items={[[...itemsRender], ...postalItems]} className='sub-table' />
          },
          ...generateCellAdditions(additionalItems.map((key) => ({ key, fieldNames: [name] })))
        ]);
      } else {
        subTableContent.push([{ content: <Table items={[[...itemsRender], ...postalItems]} className='sub-table' /> }]);
      }
    }

    if (question.type === CONTENT_TYPE.RADIO) {
      if (selectedItem && selectedItem.questions?.length) {
        const fieldName = `${name}.options.${selectedIndex}`;
        const textPair = getTextPair(selectedItem.questions);

        selectedItem.questions.forEach((q, qIndex) => {
          if (q.type === CONTENT_TYPE.TEXTPAIR_LEFT) {
            const leftItem = textPair[q?.id ?? '']?.left;
            const rightItem = textPair[q?.id ?? '']?.right;
            subTableContent.push([
              {
                content: (
                  <Table
                    items={[
                      [
                        {
                          content: leftItem ? (
                            <Text
                              name={`${fieldName}.questions.${qIndex}.answer.${answerKey}`}
                              labelInput={leftItem.title}
                              labelClassName={[headerFontClass, 'text-nowrap'].join(' ')}
                              readOnly={isViewMode}
                              type={isTranslate ? undefined : leftItem.answerLanguage}
                              placeholder={leftItem.placeHolder}
                            />
                          ) : (
                            <></>
                          )
                        },
                        {
                          content: rightItem ? (
                            <Text
                              name={`${fieldName}.questions.${rightItem.no}.answer.${answerKey}`}
                              labelInput={rightItem.title}
                              labelClassName={[headerFontClass, 'text-nowrap'].join(' ')}
                              readOnly={isViewMode}
                              type={isTranslate ? undefined : rightItem.answerLanguage}
                              placeholder={rightItem.placeHolder}
                            />
                          ) : (
                            <></>
                          )
                        }
                      ]
                    ]}
                    className='sub-table'
                  />
                )
              },
              ...generateCellAdditions(
                additionalItems.map((key) => ({
                  key,
                  fieldNames: [`${fieldName}.questions.${qIndex}`, rightItem ? `${fieldName}.questions.${rightItem.no}` : '']
                }))
              )
            ]);
            return;
          }

          if (q.type === CONTENT_TYPE.TEXTPAIR_RIGHT) {
            return;
          }
          subTableContent.push([
            {
              className: 'w-full',
              content: (
                <QuestionContent
                  showTranslateFlg={containCellTranslateStatus}
                  showCopyFlg={containCellCopied}
                  key={q.id}
                  question={q}
                  mode={mode}
                  name={`${fieldName}.questions.${qIndex}`}
                  copyAnswerFromApplicant={copyAnswerFromApplicant}
                  isTranslateData={isTranslate}
                  showTranslationData={showTranslationData}
                  showButtonChangeVisibleExternal={containCellVisible}
                />
              )
            }
          ]);
        });
      }
    }

    if (question.questions?.length) {
      const textPair = getTextPair(question.questions);
      question.questions.forEach((q, qIndex) => {
        if (q.type === CONTENT_TYPE.TEXTPAIR_LEFT) {
          const leftItem = textPair[q?.id ?? '']?.left;
          const rightItem = textPair[q?.id ?? '']?.right;
          subTableContent.push([
            {
              content: (
                <Table
                  className='sub-table'
                  items={[
                    [
                      {
                        content: leftItem ? (
                          <Text
                            name={`${name}.questions.${qIndex}.answer.${answerKey}`}
                            labelInput={leftItem.title}
                            labelClassName={[headerFontClass, 'text-nowrap'].join(' ')}
                            readOnly={isViewMode}
                            type={isTranslate ? undefined : leftItem.answerLanguage}
                            placeholder={leftItem.placeHolder}
                          />
                        ) : (
                          <></>
                        )
                      },
                      {
                        content: rightItem ? (
                          <Text
                            name={`${name}.questions.${rightItem.no}.answer.${answerKey}`}
                            labelInput={rightItem.title}
                            labelClassName={[headerFontClass, 'text-nowrap'].join(' ')}
                            readOnly={isViewMode}
                            type={isTranslate ? undefined : rightItem.answerLanguage}
                            placeholder={rightItem.placeHolder}
                          />
                        ) : (
                          <></>
                        )
                      },
                      ...generateCellAdditions(
                        additionalItems.map((key) => ({
                          key,
                          fieldNames: [`${name}.questions.${qIndex}`, rightItem ? `${name}.questions.${rightItem.no}` : '']
                        }))
                      )
                    ]
                  ]}
                />
              )
            }
          ]);
          shouldAddAdditionalCell = false;
          return;
        }

        if (q.type === CONTENT_TYPE.TEXTPAIR_RIGHT) {
          return;
        }
        subTableContent.push([
          {
            content: (
              <QuestionContent
                showTranslateFlg={containCellTranslateStatus}
                showCopyFlg={containCellCopied}
                question={q}
                mode={mode}
                name={`${name}.questions.${qIndex}`}
                copyAnswerFromApplicant={copyAnswerFromApplicant}
                isTranslateData={isTranslate}
                showTranslationData={showTranslationData}
                showButtonChangeVisibleExternal={containCellVisible}
              />
            )
          }
        ]);
      });
    }

    row.push({ content: <Table className='sub-table' items={subTableContent} titleHeader={undefined} /> });
    if (shouldAddAdditionalCell && question.type !== CONTENT_TYPE.NONE) {
      row.push(...generateCellAdditions(additionalItems.map((key) => ({ key, fieldNames: [name] }))));
    }
    return row;
  };

  const renderChildren = (questionRender: IQuestion) => {
    const needRenderTranslate = showTranslationData && isAncestor;
    // When item have translation data, all additional column (isTranslated, couldCopy) should be display on translated column
    const row: ITableItem[] = renderQuestionRow(
      questionRender,
      !needRenderTranslate && showTranslateFlg,
      !needRenderTranslate && showCopyFlg,
      isTranslateData === undefined && isAncestor ? false : !!isTranslateData,
      !needRenderTranslate && showButtonChangeVisibleExternal
    ).map((i) => ({
      ...i,
      className: classNames(i.className, isAncestor ? 'origin-data' : '')
    }));
    const rowEn: ITableItem[] = needRenderTranslate
      ? renderQuestionRow(
          questionRender,
          needRenderTranslate && showTranslateFlg,
          needRenderTranslate && showCopyFlg,
          isTranslateData === undefined && isAncestor ? true : !!isTranslateData,
          needRenderTranslate && showButtonChangeVisibleExternal
        ).map((i) => ({
          ...i,
          className: classNames(i.className, isAncestor ? 'translate-data' : '')
        }))
      : [];
    const rowItem = [...row, ...rowEn];
    if (rowItem.length === 2 && isAncestor) {
      if (needRenderTranslate) {
        rowItem[0].style = { ...rowItem[0].style, width: `${cellWidth || 0}px` };
      }
    }
    return <Table className='sub-table' items={[rowItem]} titleHeader={undefined} />;
  };

  return (
    <>
      {/* Render other components based on type */}
      {renderChildren(question)}
    </>
  );
};

export default QuestionContent;
