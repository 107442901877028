import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';

import { DataViewer } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';

import { IDataDetail } from '../utils';

interface InformationProps {
  data?: IDataDetail;
}

const Information = ({ data }: InformationProps) => {
  const { t }: Ii18n = useTranslation();
  return (
    <Row className='col-span-1 flex items-center p-[16px] gap-x-[80px] gap-y-[12px] rounded-[10px] bg-white border-gray2 mb-[12px] border-[1px] border-solid'>
      <Col span={24} className='flex text-lnk leading-[22px]'>
        <span className='shrink-0'>{t('project_detail:application')}：</span>
        <AppTooltip className='truncate' title={data?.projectInfor?.applicant}>
          <span className='font-bold'>{DataViewer.display(data?.projectInfor?.applicant)}</span>
        </AppTooltip>
      </Col>
      <Col span={10} className='flex text-lnk leading-[22px]'>
        <span className='shrink-0'>{t('project_detail:country_of_application')}：</span>
        <AppTooltip className='truncate' title={data?.projectInfor?.applicantCountry}>
          <span className='font-bold'>{DataViewer.display(data?.projectInfor?.applicantCountry)}</span>
        </AppTooltip>
      </Col>
      <Col span={10} className='flex text-lnk leading-[22px]'>
        <span className='shrink-0'>{t('basic_information:travel_type')}：</span>
        <AppTooltip className='truncate' title={data?.projectInfor?.travelTypeName}>
          <span className='font-bold'>{DataViewer.display(data?.projectInfor?.travelTypeName)}</span>
        </AppTooltip>
      </Col>
      <Col span={10} className='flex text-lnk leading-[22px]'>
        <span className='shrink-0'>{t('project_detail:category')}：</span>
        <AppTooltip className='truncate' title={data?.projectInfor?.visaCategory}>
          <span className='font-bold'>{DataViewer.display(data?.projectInfor?.visaCategory)}</span>
        </AppTooltip>
      </Col>
      <Col span={10} className='flex text-lnk leading-[22px]'>
        <span className='shrink-0'>{t('project_detail:schedule_departure_date')}：</span>
        <AppTooltip className='truncate' title={data?.projectInfor?.departureDate}>
          <span className='font-bold'>{DataViewer.display(data?.projectInfor?.departureDate)}</span>
        </AppTooltip>
      </Col>
    </Row>
  );
};

export default Information;
