import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Table, { ITableItem } from './Table';
import Text from './Text';
import TextArea from './TextArea';
import YearMonthDate from './YearMonthDate';

export interface ICareer {
  name: string;
  readOnly?: boolean;
  groupCount: number;
}

export interface ICareerDetail {
  index: number;
  name: string;
  readOnly?: boolean;
}

const Career: React.FC<ICareer> = ({ name, readOnly, groupCount }) => {
  const { t } = useTranslation();

  const generateItems = ({ name, readOnly }: ICareerDetail) => {
    const items: ITableItem[][] = [];
    const headerFontClass = 'body-700 whitespace-pre-line';
    const headerClass = `!w-[100px]`;

    // Push title
    items.push([
      {
        content: <p className={headerFontClass}>{t('project:question_detail:career:title')}</p>,
        className: classNames(headerClass, 'bg-gray2 w-full text-center')
      }
    ]);

    // Push items for each group
    new Array(groupCount).fill(null).forEach((_, index) => {
      items.push([
        {
          className: classNames(headerClass, 'bg-gray1'),
          content: (
            <p className={headerFontClass}>
              {t('project:question_detail:career:header')}
              {index + 1}
            </p>
          )
        }
      ]); // header
      items.push([
        {
          className: headerClass,
          content: <p className={headerFontClass}>{t('project:question_detail:career:company_name')}</p>,
          isTitle: true
        },
        {
          content: (
            <Text placeholder={t('project:question_detail:career:common_placeholder')} readOnly={readOnly} name={`${name}.${index}.companyName`} />
          )
        }
      ]); // companyName
      items.push([
        {
          className: headerClass,
          content: <p className={headerFontClass}>{t('project:question_detail:career:department_name')}</p>,
          isTitle: true
        },
        {
          content: (
            <Text placeholder={t('project:question_detail:career:common_placeholder')} readOnly={readOnly} name={`${name}.${index}.departmentName`} />
          )
        }
      ]); // departmentName
      items.push([
        {
          className: headerClass,
          content: <p className={headerFontClass}>{t('project:question_detail:career:job_title')}</p>,
          isTitle: true
        },
        {
          content: (
            <Text placeholder={t('project:question_detail:career:common_placeholder')} readOnly={readOnly} name={`${name}.${index}.jobTitle`} />
          )
        }
      ]); // jobTitle
      items.push([
        {
          className: headerClass,
          content: <p className={headerFontClass}>{t('project:question_detail:career:job_detail')}</p>,
          isTitle: true
        },
        {
          content: (
            <TextArea placeholder={t('project:question_detail:career:common_placeholder')} readOnly={readOnly} name={`${name}.${index}.jobDetail`} />
          )
        }
      ]); // jobDetail
      items.push([
        {
          className: headerClass,
          content: <p className={headerFontClass}>{t('project:question_detail:career:duration')}</p>,
          isTitle: true
        },
        {
          content: (
            <div className='flex'>
              <div className='w-[200px]'>
                <YearMonthDate readOnly={readOnly} name={`${name}.${index}.from`} />
              </div>
              ～
              <div className='w-[200px] ml-6'>
                <YearMonthDate readOnly={readOnly} name={`${name}.${index}.to`} />
              </div>
            </div>
          )
        }
      ]); // from - to
    });

    return items;
  };

  return (
    <>
      <Table items={generateItems({ name, readOnly, index: 0 })} fixedWidth={[100, -1]} />
    </>
  );
};

export default Career;
