import * as yup from 'yup';

import { schemaDateForm } from '@/components/common/date-input';

import { TEXT_FIELD_MAX_LENGTH } from '@/utils/constants/AppConstants';
import { stringifyObjectValidateYup } from '@/utils/method';

export const stepThreeSchema = yup.object().shape({
  projectFamilies: yup.array().of(
    yup.object().shape({
      lastName: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:applicant_representative:last_name' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:applicant_representative:last_name', limited: TEXT_FIELD_MAX_LENGTH }
          })
        ),
      firstName: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:applicant_representative:first_name' }
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: { field: 'project:add:applicant_representative:first_name', limited: TEXT_FIELD_MAX_LENGTH }
          })
        ),
      lastNameRomaji: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:applicant_representative:last_name_romaji' }
          })
        )
        .halfSize(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_028'
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: {
              field: 'project:add:applicant_representative:last_name_romaji',
              limited: TEXT_FIELD_MAX_LENGTH
            }
          })
        ),
      firstNameRomaji: yup
        .string()
        .required(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_001_textbox',
            optionsTx: { field: 'project:add:applicant_representative:first_name_romaji' }
          })
        )
        .halfSize(
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_028'
          })
        )
        .max(
          TEXT_FIELD_MAX_LENGTH,
          stringifyObjectValidateYup({
            keyT: 'common:MSG_C_011',
            optionsTx: {
              field: 'project:add:applicant_representative:first_name_romaji',
              limited: TEXT_FIELD_MAX_LENGTH
            }
          })
        ),
      primaryNational: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'project:add:applicant_representative:nationality' }
        })
      ),
      dateOfBirth: schemaDateForm(true),
      gender: yup.string().required(
        stringifyObjectValidateYup({
          keyT: 'common:MSG_001_select',
          optionsTx: { field: 'project:add:applicant_representative:sex' }
        })
      )
    })
  ),
  familyAttendanceTime: yup
    .string()
    .max(
      TEXT_FIELD_MAX_LENGTH,
      stringifyObjectValidateYup({
        keyT: 'common:MSG_C_011',
        optionsTx: { field: 'project:add:family_information:attendance_time', limited: TEXT_FIELD_MAX_LENGTH }
      })
    )
    .required(
      stringifyObjectValidateYup({
        keyT: 'common:MSG_001_textbox',
        optionsTx: { field: 'project:add:family_information:attendance_time' }
      })
    )
});
