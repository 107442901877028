import { DefaultOptionType } from 'antd/es/select';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IStepThree } from '@/pages/project-management/add-edit/models/scenarioB';

import { BaseCard } from '@/components/base-card/BaseCard';
import { convertObjDatetoString } from '@/components/common/date-input';

import { ICountryOption } from '@/hooks/useOptionsGlobal';

import { DataViewer } from '@/utils/helpers/common';
import { Counters } from '@/utils/helpers/counters';

import CardInfo from './CardInfo';

interface IFamilyInfoProps {
  data: IStepThree;
  countries: ICountryOption[];
  genders: DefaultOptionType[];
}
const FamilyInfo = ({ data, countries, genders }: IFamilyInfoProps) => {
  const { t } = useTranslation();
  const { projectFamilies = [] } = data ?? {};
  if (!projectFamilies.length) return <></>;

  return (
    <div className='flex flex-col gap-[8px]'>
      {projectFamilies.map((member, i) => {
        const { primaryNational, secondaryNational } = member ?? {};
        const primaryNationalName = countries?.find((item) => item.value === primaryNational)?.label;
        const secondaryNationalName = countries?.find((item) => item.value === secondaryNational)?.label;
        const genderLabel = genders.find((item) => item.value === (member?.gender as unknown))?.label;
        const schema = {
          data: ['lastName', 'firstName', 'lastNameRomaji', 'firstNameRomaji', 'primaryNational', 'secondaryNational', 'dateOfBirth', 'gender']
        };
        const counters = new Counters(schema, member);

        const memberInfo = [
          {
            items: [
              { title: DataViewer.display(member?.lastName), label: 'basic_information:surname' },
              { title: DataViewer.display(member?.firstName), label: 'basic_information:given_name' },
              { title: DataViewer.display(member?.lastNameRomaji), label: 'basic_information:last_name_romaji' },
              { title: DataViewer.display(member?.firstNameRomaji), label: 'basic_information:first_name_romaji' }
            ]
          },
          {
            items: [
              { title: DataViewer.display(primaryNationalName), label: 'basic_information:nationality' },
              { title: DataViewer.display(secondaryNationalName), label: 'basic_information:second_nationality' },
              { title: convertObjDatetoString(member?.dateOfBirth), label: 'basic_information:date_of_birth' },
              { title: DataViewer.display(genderLabel), label: 'basic_information:gender' }
            ]
          }
        ];
        return (
          <BaseCard
            key={member.id || i}
            title={`${t('basic_information:family_information')}${i + 1}`}
            total={schema.data.length}
            questions={counters.get('data')}
            className='scenario-b-card'
          >
            <CardInfo columns={memberInfo} />
          </BaseCard>
        );
      })}
    </div>
  );
};
export default FamilyInfo;
