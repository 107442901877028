import { Collapse, Tag } from 'antd';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AttachmentFiles from '@/pages/announcement-management/view/componnents/AttachmentFiles';
import { AttachmentFile } from '@/pages/announcement-management/view/models';
import { STATUS_NAME, StatusName } from '@pages/project-management/detail/constant';
import { renderTaskStatus } from '@pages/project-management/detail/utils/helper';

import { BaseButton } from '@/components/base-button/BaseButton';
import { ConfirmCancelEditing } from '@/components/confirm-popup';
import { CaretDownBold } from '@/components/icon-svg/IconSvg';
import AppTooltip from '@components/app-tooltip/AppTooltip';

import useAuthorization from '@/hooks/useAuthorization';

import { ATTACHMENT_FILE_STATUS } from '@/utils/constants/file';
import { Ii18n } from '@/utils/interfaces/i18n';
import { DataViewer } from '@utils/helpers/common';

import WarningWaitingApprove from '@assets/icons/WarningWaitingApprove.svg';

import { DEFAULT_ACTIVE_COLLAPSE } from '../../constants';
import { IAttachmentTaskDetail, IDetailTaskData } from '../../models';
import FileUploadDetail from './FileUploadDetail';
import TaskRequiredDocumentsView from './TaskRequiredDocuments';

import '../index.scss';

interface DetailTaskExternalProps {
  detailData: IDetailTaskData;
  handleCancelUploadFileExternal: (e: any) => Promise<void>;
  handleUploadFileExternal: (e: any) => void;
}

const DetailTaskExternal = ({ detailData, handleCancelUploadFileExternal, handleUploadFileExternal }: DetailTaskExternalProps) => {
  const { t }: Ii18n = useTranslation();
  const form = useFormContext();
  const { isDirty, isSubmitting } = form.formState;
  const { taskRequireDocuments } = form.getValues();
  const { isExternalRole, isInternalRole } = useAuthorization();

  const isTaskDelay = () => {
    return detailData?.statusName !== STATUS_NAME.COMPLETED && dayjs(detailData?.deadline).isBefore(dayjs(), 'day');
  };

  const renderPicName = () => {
    if (!detailData || !detailData.projectTaskPics.length) return;
    if (detailData && detailData.projectTaskPics.length === 1) return detailData.projectTaskPics[0].picName;
    const multiplePicName = detailData.projectTaskPics.map((item: any) => {
      if (!item?.picName) return;
      return item?.picStatus ? item.picName : `(${t('account_list:status_account:inactive')}) ${item.picName}`;
    });
    return multiplePicName.join('、');
  };

  const renderExpandIcon = (isActive?: boolean) => {
    return <CaretDownBold type='caret-right' className={`${isActive ? 'rotate-0' : '-rotate-90'}`} />;
  };

  const renderExtraRequiredDocuments = () => (
    <div className='form-view-task-button flex gap-[16px] mr-[5px]'>
      <BaseButton disabled={!isDirty} type='tertiary' onClick={handleCancelUploadFileExternal}>
        {t('button:cancel')}
      </BaseButton>
      <BaseButton disabled={!isDirty || isSubmitting} onClick={handleUploadFileExternal}>
        {t('button:submit')}
      </BaseButton>
    </div>
  );

  const attachmentFilesAvailable = detailData?.projectTaskAttachmentFiles.filter(
    (item: IAttachmentTaskDetail) => item.status === ATTACHMENT_FILE_STATUS.APPROVAL || item.status === ATTACHMENT_FILE_STATUS.DONE
  );
  return (
    <>
      <div className='view-task-detail-project__content'>
        <div className='mb-[12px] px-[20px] py-[12px] bg-white border border-gray2 rounded-[8px]'>
          <div className='flex items-center gap-[63px] justify-between mb-[34px]'>
            <div className='flex gap-[63px] text-lnk min-w-0'>
              <span className='text-18 whitespace-nowrap'>
                {t('project:task_of_project:deadline')}：<span className='font-bold text-18'>{DataViewer.displayTime(detailData?.deadline)}</span>
              </span>
              <AppTooltip title={renderPicName()}>
                <span className='text-18 truncate'>
                  {t('project:task_of_project:mot_pick:label')}：
                  <span className='truncate font-bold text-18'>{DataViewer.display(renderPicName())}</span>
                </span>
              </AppTooltip>
            </div>
            {isTaskDelay() ? (
              <Tag
                className='flex items-center justify-center !px-[8px] !py-[2px] !gap-[4px] !rounded-[4px] !bg-yellow10 !border-warning !text-yellow '
                color='#F2A227'
                icon={<WarningWaitingApprove />}
              >
                {t('project:task_of_project:delay')}
              </Tag>
            ) : (
              <div>
                {renderTaskStatus(
                  detailData?.statusName as StatusName,
                  'flex items-center justify-center !px-[8px] !py-[3px] !gap-[4px] !rounded-[4px]'
                )}
              </div>
            )}
          </div>
          <Collapse
            ghost
            items={[
              {
                key: '1',
                label: <span className='text-[18px] font-semibold leading-[25px] text-lnk'>{t('project:task_of_project:task_description')}</span>,
                children: <FileUploadDetail description={detailData?.detail || ''} />
              }
            ]}
            expandIcon={({ isActive }) => renderExpandIcon(isActive)}
            defaultActiveKey={DEFAULT_ACTIVE_COLLAPSE}
          />
          {!!attachmentFilesAvailable.length && (
            <Collapse
              ghost
              items={[
                {
                  key: '1',
                  label: <span className='text-[18px] font-semibold leading-[25px] text-lnk'>{t('project:task_of_project:attachments')}</span>,
                  children: (
                    <AttachmentFiles
                      attachments={(attachmentFilesAvailable || []) as AttachmentFile[]}
                      showInfo={false}
                      isShowTitle={false}
                      isExternalRole={isExternalRole}
                    />
                  )
                }
              ]}
              expandIcon={({ isActive }) => renderExpandIcon(isActive)}
              defaultActiveKey={DEFAULT_ACTIVE_COLLAPSE}
            />
          )}
        </div>
        {(isExternalRole && !!taskRequireDocuments?.length) || (isInternalRole && taskRequireDocuments) ? (
          <div className='px-[20px] py-[12px] bg-white gap-[12px] border border-gray2 rounded-[8px]'>
            <Collapse
              ghost
              items={[
                {
                  key: '1',
                  label: <span className='text-[18px] font-semibold leading-[25px] text-lnk'>{t('project:task_of_project:required_documents')}</span>,
                  children: <TaskRequiredDocumentsView />,
                  extra: renderExtraRequiredDocuments()
                }
              ]}
              expandIcon={({ isActive }) => renderExpandIcon(isActive)}
              defaultActiveKey={DEFAULT_ACTIVE_COLLAPSE}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <ConfirmCancelEditing condition={isDirty && !isSubmitting} />
    </>
  );
};

export default DetailTaskExternal;
