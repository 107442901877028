import { Col, Row, Space } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { renderFileItem } from '@/components/form-attachment-table/AttachmentFile';

import { FORMAT_DATE_EN_HH_MM_SS } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';
import { Ii18n } from '@/utils/interfaces/i18n';

import { WrapLabelWithContent } from '../../components/WrapLayout';
import { AttachmentFile } from '../models';

type Props = {
  attachments: AttachmentFile[];
  showInfo?: boolean;
  isShowTitle?: boolean;
  isExternalRole?: boolean;
};

type RenderAttachment = {
  attachment: AttachmentFile;
  i: number;
  t: TFunction | Ii18n['t'];
  showInfo?: boolean;
  isExternalRole?: boolean;
};

export const renderAttachmentFile = ({ attachment, i, t, showInfo = true, isExternalRole }: RenderAttachment) => {
  const createdName = attachment?.updatedStatus
    ? attachment?.createdName
    : `(${t('account_list:status_account:inactive')}) ${attachment.createdName}`;
  const renderInformation = () => (
    <Space className='ml-[20px]' size={20}>
      <div className='text-textGray flex flex-wrap'>
        {t('announcement:uploader')}：
        <AppTooltip title={createdName}>
          <span className='ml-[2px] truncate w-[100px] inline-block'>{createdName}</span>
        </AppTooltip>
      </div>
      <span className='text-textGray'>
        {t('announcement:upload_time')}：{DataViewer.localTimeBy(attachment?.createdDate, FORMAT_DATE_EN_HH_MM_SS)}
      </span>
    </Space>
  );

  return (
    <div key={i} className='flex justify-start items-baseline'>
      <p className='mr-[8px] w-[18px]'>{i + 1}</p>
      {renderFileItem({ attachment, t, isExternalRole })}
      {showInfo && renderInformation()}
    </div>
  );
};

const AttachmentFiles = ({ attachments = [], showInfo = true, isShowTitle = true, isExternalRole }: Props) => {
  const { t } = useTranslation();

  if (!attachments.length) return null;

  return (
    <WrapLabelWithContent title={isShowTitle ? t('announcement:attachment') : ''}>
      <div className='p-[16px] border-collapse mt-[1px]'>
        <Row gutter={16}>
          <Col>{attachments.map((attachment, i: number) => renderAttachmentFile({ attachment, i, t, showInfo, isExternalRole }))}</Col>
        </Row>
      </div>
    </WrapLabelWithContent>
  );
};

export default AttachmentFiles;
