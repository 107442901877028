import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AppTooltip from '@/components/app-tooltip/AppTooltip';

import { setOpenNotice, setRefreshPage } from '@/redux/notice/noticeReducer';
import { AppDispatch } from '@/redux/store';

import useAuthorization from '@/hooks/useAuthorization';

import { FORMAT_TIME } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';
import { ID_SYSTEM } from '@/utils/helpers/constants';
import { parseJson } from '@/utils/helpers/globalHelper';
import { createParamTypeNotice, returnPageCreateNotice } from '@/utils/helpers/notification';
import { INoticeData } from '@/utils/interfaces/notificationInterface';
import { ColorDefault } from '@/utils/themes/color';

import System from '@/assets/icons/notification/System.svg';

type ITypeNoticeProps = {
  data: INoticeData;
  index: number;
  onRead: (item: INoticeData) => void;
};

const TypeMessageNotice = ({ data, onRead }: ITypeNoticeProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { isInternalRole } = useAuthorization();
  const isNoticeSystem = data.createdBy === ID_SYSTEM;
  const convertParam = parseJson(data?.params ?? '');
  const paramTypeNotice = createParamTypeNotice(data.content, convertParam, data.origin, isInternalRole, String(data.originUserName));
  const title = [paramTypeNotice?.projectCode, paramTypeNotice?.companyName, paramTypeNotice?.travelOrApplicantName].filter((i) => i).join(' ');

  const returnPageNotice = async (data: INoticeData) => {
    // Off drawer form notice
    dispatch(setOpenNotice(false));

    const urlNavigate = await returnPageCreateNotice(data);
    // Go to page send notice
    if (urlNavigate && urlNavigate !== window.location.pathname) {
      navigate(urlNavigate);
    } else {
      // Set state show loading refresh page is true
      dispatch(setRefreshPage(true));

      // Set state show loading refresh page is false
      setTimeout(() => {
        dispatch(setRefreshPage(false));
      }, 0);
    }
  };

  return (
    <>
      <div className='form-image center-item'>
        {!data.readed && <div className='online w-[14px] h-[14px]'></div>}
        <div
          className='avatar w-[50px] h-[50px] center-item justify-center body-700'
          style={{ backgroundColor: isNoticeSystem ? ColorDefault.white : convertParam.color || data.color || data.originUserColor }}
        >
          {isNoticeSystem ? <System /> : <>{paramTypeNotice?.userName}</>}
        </div>
      </div>
      <div className='form-message'>
        <a
          className='cursor-pointer max-w-[450px]'
          onClick={() => {
            returnPageNotice(data);
            onRead(data);
          }}
        >
          <AppTooltip className='form-title' title={paramTypeNotice?.title}>
            <span className={`${!data.readed ? 'body-700' : 'body-400'}`}>{paramTypeNotice?.title}</span>
          </AppTooltip>
        </a>
        <AppTooltip
          className={`d-inline-flex cursor-pointer gap-4 ${!data.readed ? 'not-read' : 'readed'}`}
          title={title}
          onClick={() => {
            returnPageNotice(data);
            onRead(data);
          }}
        >
          {paramTypeNotice?.projectCode && (
            <a className='max-w-[150px]'>
              <span className='block truncate'>{paramTypeNotice?.projectCode}</span>
            </a>
          )}
          {paramTypeNotice?.companyName && (
            <a className='max-w-[150px]'>
              <span className='block truncate'>{paramTypeNotice?.companyName}</span>
            </a>
          )}
          {paramTypeNotice?.travelOrApplicantName && (
            <a className='max-w-[150px]'>
              <span className='block truncate'>{paramTypeNotice?.travelOrApplicantName}</span>
            </a>
          )}
        </AppTooltip>
        <div className='justify-space-between w-[125px]'>
          <span className='body-small-regular'>{DataViewer.displayTime(paramTypeNotice?.createDate)}</span>
          <span className='body-small-regular'>{DataViewer.displayTime(paramTypeNotice?.createDate, FORMAT_TIME)}</span>
        </div>
      </div>
    </>
  );
};

export default TypeMessageNotice;
