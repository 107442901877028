import { Layout, theme } from 'antd';
import React, { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import LoadingPageContent from '../components/loading-page-content/LoadingPageContent';
import { AuthContext } from '../context/AuthContext';
import useAuthorization from '../hooks/useAuthorization';
import useFilter from '../hooks/useFilter';
import { LOCALSTORAGE } from '../utils/constants/AppConstants';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

import './AppLayout.scss';

const { Content } = Layout;

const contentStyle: React.CSSProperties = {
  padding: '16px 20px',
  overflow: 'auto',
  height: 'calc(100vh - 80px)'
};

const App: React.FC = () => {
  const statusCollapsed = JSON.parse(localStorage.getItem(LOCALSTORAGE.STATUS_COLLAPSED) ?? 'false');
  const [collapsed, setCollapsed] = useState(statusCollapsed);
  const { user } = useAuthorization();
  const { filteredValues } = useFilter();

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const toggleSider = () => {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
    localStorage.setItem(LOCALSTORAGE.STATUS_COLLAPSED, JSON.stringify(newCollapsed));
  };

  const dataContext = useMemo(() => {
    return {
      user,
      isAuthenticated: true,
      filteredValues
    };
  }, [user, filteredValues]);

  if (!user) {
    return <></>;
  }

  return (
    <AuthContext.Provider value={dataContext}>
      <Layout style={{ minHeight: '100vh' }} hasSider>
        <Sidebar toggleSider={toggleSider} collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout className='site-layout'>
          <Header colorBgContainer={colorBgContainer} />
          <Content style={contentStyle} className='relative'>
            <Outlet />
            <LoadingPageContent />
          </Content>
        </Layout>
      </Layout>
    </AuthContext.Provider>
  );
};

export default App;
