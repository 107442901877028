import { yupResolver } from '@hookform/resolvers/yup';
import React, { Dispatch } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '@/components/base-button/BaseButton';
import BaseModal from '@/components/base-modal/BaseModal';
import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';
import { FormInput } from '@/components/form-input/FormInput';

import useMutation from '@/hooks/useMutation';

import { HTTP_STATUS_CODE } from '@/utils/constants/Http';

import EditHistory from '../../components/edit-history/EditHistory';
import { IAddEditForm, ICompany } from '../models';
import { schemaAddEdit, validateDuplicate } from './valdiations';

export interface IAddEditModalProps {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  detail?: Partial<ICompany>;
  wrapperRef: React.MutableRefObject<any>;
  onSubmitted: (status: boolean) => void;
}

const AddEditModal = (props: IAddEditModalProps) => {
  const { open, setOpen, detail, wrapperRef, onSubmitted } = props;
  const { t } = useTranslation();
  const form = useForm<IAddEditForm>({
    mode: 'all',
    values: { id: detail?.id, name: detail?.name, version: detail?.version },
    resolver: yupResolver(schemaAddEdit),
    delayError: 300
  });
  const isEditMode = detail?.id && detail?.name && typeof detail?.version === 'number';
  const { loading, mutate } = useMutation(isEditMode ? `/mst/companies/${detail.id}` : `/mst/companies/`, {
    method: isEditMode ? 'PUT' : 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: true,
    defaultSuccessMsg: 'MSG_C_003',
    paramMsg: {
      item: t(isEditMode ? 'button:keep' : 'button:create'),
      field: t('master_data:company:add_edit:company_field_label'),
      name: t('master_data:company:add_edit:company_field_label')
    }
  });

  const onClose = () => {
    setOpen(false);
    form.reset();
  };

  const handleCloseCreateModal = async () => {
    if (form.formState.isDirty) {
      const showPopup = await showBasePopup({ type: TYPE_ICON.CONFIRM_CANCEL_EDIT });
      if (showPopup !== 'confirm') {
        return;
      }
    }
    onClose();
  };

  const onSubmit = async (values: IAddEditForm) => {
    const isValid = values.name && (await validateDuplicate(values));
    if (!isValid) {
      form.setError('name', {
        message: t('common:MSG_C_019', { field: t('master_data:company:add_edit:company_field_label' || '') }) ?? ''
      });
      return;
    }
    const result = await mutate({ ...values });
    if (result?.status === HTTP_STATUS_CODE.SUCCESS) {
      onSubmitted(true);
    }
  };

  const disableSaveBtn = () => {
    return (!form.formState.isDirty && isEditMode) || form.formState.isSubmitting || loading;
  };

  return (
    <>
      <BaseModal
        title={t(`master_data:company:add_edit:${isEditMode ? 'edit_title' : 'add_title'}`)}
        className='company-master__add-edit-modal'
        onCancel={handleCloseCreateModal}
        maskClosable={false}
        openModal={open}
        destroyOnClose
        getContainer={() => wrapperRef?.current ?? document.body}
        contentElement={
          <div className='base-popup change-section'>
            <FormProvider {...form}>
              <div>
                <FormInput
                  placeholder={t('master_data:company:add_edit:company_field_placeholder') ?? ''}
                  name='name'
                  labelTx={t('master_data:company:add_edit:company_field_label') || ''}
                  required
                />
              </div>
              {isEditMode && <EditHistory className='mt-4' data={detail} />}
              <div className='flex mt-[12px] justify-end phrase-btn-create-edit'>
                <BaseButton disabled={false} type='tertiary' size='large' className='w-[120px] !h-[38px]' onClick={handleCloseCreateModal}>
                  {t('button:cancel')}
                </BaseButton>
                <BaseButton
                  type='primary'
                  size='large'
                  htmlType='submit'
                  className='ml-[10px] w-[120px] !h-[38px]'
                  disabled={disableSaveBtn()}
                  onClick={form.handleSubmit(onSubmit)}
                >
                  <span> {t('button:keep')} </span>
                </BaseButton>
              </div>
            </FormProvider>
          </div>
        }
      />
    </>
  );
};

export default AddEditModal;
