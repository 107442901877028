import { axiosInstance } from '../../config/http';

type IUserAssigned = {
  order: string;
  hidden: boolean;
  reporterFlag: boolean;
  reporter: string[];
};

type INoticeFilter = {
  type?: string;
  hidden?: boolean;
  flag?: boolean;
  searchKey?: string;
  pageIndex?: number;
  pageSize?: number;
};

type IAlertNotice = {
  notifyId?: string;
  origin?: boolean;
  originId?: string;
};

export const getNoticeList = (page: number, limit: number, search?: string) => {
  const res = axiosInstance.get(`/get-notifications`, {
    params: {
      page,
      limit,
      search
    }
  });
  return res;
};

export const getNotifications = async (payload: INoticeFilter) => {
  const res = await axiosInstance.post(`/usr/notify/search`, payload);
  return res;
};

export const getCountNotificationsUnRead = async () => {
  const res = await axiosInstance.post(`/usr/notify/count-unread`);
  return res;
};

export const hiddenNotification = async (body: { HiddenNotices: { id: string; originUserId: string | undefined }[] }) => {
  const res = await axiosInstance.post(`/usr/notify/hidden`, body);
  return res;
};

export const readNotification = async (body: { Readeds?: { id: string }[] }) => {
  const res = await axiosInstance.post(`/usr/notify/readed`, body);
  return res;
};

export const readAlertNotification = async (body: IAlertNotice) => {
  const res = await axiosInstance.put(`/usr/notify/readed-alert`, body);
  return res;
};

export const getAssignNotify = async () => {
  const res = await axiosInstance.get(`/usr/notify/assign`);
  return res;
};

export const updateAssignNotify = async (listUser: IUserAssigned) => {
  const res = await axiosInstance.post(`/usr/notify/assign`, listUser);
  return res;
};
