import { Button } from 'antd';
import classNames from 'classnames';

import { ATTACHMENT_STATUS, AttachmentFile, AttachmentStatus, RenderAttachment } from '@/pages/announcement-management/view/models';

import { MAX_CAPACITY_FILE, UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';
import { handleDownloadSingleFile } from '@/utils/helpers/fileHelper';
import { decodeFileName } from '@/utils/helpers/globalHelper';

import IconPaperError from '@/assets/icons/IconUploadResponse.svg';
import IconPaperPendingClip from '@/assets/icons/Paperclip-Yellow.svg';
import IconPaperclip from '@/assets/icons/Paperclip.svg';
import WarningIcon from '@/assets/icons/WarningCircle.svg';
import IconClose from '@/assets/icons/close.svg';

import AppTooltip from '../app-tooltip/AppTooltip';
import { IconDownload } from '../icon-svg/IconSvg';

export type RenderFile = {
  attachment: AttachmentFile;
  t: RenderAttachment['t'];
  isEdit?: boolean;
  isExternalRole?: boolean;
  classContainer?: string;
  extraAction?: React.ReactNode;
  rejectElement?: React.ReactNode;
};

class FileColor {
  color: string;
  icon: React.FC<React.SVGProps<SVGElement>>;
  subText: string | null;
  borderColor: string;
  isDownload: boolean;

  constructor(status: AttachmentStatus) {
    const fileColorUppercaseMapper: Record<string, FileColor> = {
      [ATTACHMENT_STATUS.APPROVAL.toUpperCase()]: {
        color: 'positive',
        icon: IconPaperclip,
        subText: 'announcement:approved',
        borderColor: 'border-b-gray2',
        isDownload: true
      },
      [ATTACHMENT_STATUS.REJECT.toUpperCase()]: {
        color: 'negative',
        icon: IconClose,
        subText: 'announcement:reject',
        borderColor: 'border-b-negative',
        isDownload: true
      },
      [ATTACHMENT_STATUS.WAITING.toUpperCase()]: {
        color: 'yellow',
        icon: IconPaperPendingClip,
        subText: 'announcement:pending',
        borderColor: 'border-b-yellow',
        isDownload: true
      },
      [UPLOAD_STATUS.DONE.toUpperCase()]: {
        color: 'gray2',
        icon: IconPaperclip,
        subText: null,
        borderColor: 'border-b-gray2',
        isDownload: true
      },
      [UPLOAD_STATUS.ERROR.toUpperCase()]: {
        color: 'negative',
        icon: IconPaperError,
        subText: null,
        borderColor: 'border-b-gray2',
        isDownload: false
      },
      [UPLOAD_STATUS.UPLOADING.toUpperCase()]: {
        color: 'gray2',
        icon: IconPaperclip,
        subText: null,
        borderColor: 'border-b-gray2',
        isDownload: false
      }
    };
    const { color, icon, borderColor, subText, isDownload } =
      fileColorUppercaseMapper[status.toUpperCase()] ?? fileColorUppercaseMapper[ATTACHMENT_STATUS.APPROVAL.toUpperCase()] ?? {};
    this.color = color;
    this.icon = icon;
    this.borderColor = borderColor;
    this.subText = subText;
    this.isDownload = isDownload;
  }
}

export const renderFileItem = ({ attachment, t, isEdit = false, classContainer, extraAction, rejectElement, isExternalRole = false }: RenderFile) => {
  const { name, blobPath, status = ATTACHMENT_STATUS.DONE, rejectReason, createdDate, msgErr, requestApproval } = attachment;
  const { color, subText, icon: Icon, borderColor, isDownload } = new FileColor(status);
  const downloadAttachment = () => {
    if (!isDownload) return;
    handleDownloadSingleFile(blobPath, name);
  };
  const renderSubtext = () => {
    if (!createdDate || isExternalRole) return null;
    if (isEdit && !requestApproval && status === ATTACHMENT_STATUS.DONE) return null; // hide sub text of file is not need confirm when edit
    return <span className={classNames('w-[56px] text-end', `text-${color}`, `text-custom-${color}`)}>{t(subText ?? '')}</span>;
  };

  const renderName = (name: string) => {
    if (!name) return '';
    if (name.length <= MAX_CAPACITY_FILE) return name;
    return name.substring(0, MAX_CAPACITY_FILE).concat('...');
  };

  return (
    <div className='flex flex-col max-w-[500px] grow'>
      <div
        className={classNames(
          `w-[500px] h-[38px] grow flex justify-start items-center border border-solid border-t-0 border-r-0 border-l-0`,
          borderColor,
          classContainer
        )}
      >
        <div className='flex justify-center items-center'>
          <Icon width={18} height={18} />
        </div>
        <AppTooltip
          className={classNames('px-[10px] truncate', status === ATTACHMENT_STATUS.ERROR ? 'text-negative' : '')}
          title={decodeFileName(name)}
        >
          <span onClick={downloadAttachment} className={classNames(isDownload && 'cursor-pointer')}>
            {renderName(decodeFileName(name))}
          </span>
        </AppTooltip>
        <div className='flex justify-center items-center ml-auto'>
          {renderSubtext()}
          {status === ATTACHMENT_STATUS.ERROR && (
            <AppTooltip title={msgErr}>
              <WarningIcon className='h-[20px] w-[20px] cursor-pointer hover:opacity-80 mr-1' />
            </AppTooltip>
          )}
          {isDownload && (
            <Button
              type='text'
              size='small'
              icon={<IconDownload className='h-[20px] w-[20px] download hover:cursor-pointer' />}
              onClick={downloadAttachment}
            />
          )}
          {extraAction || null}
        </div>
      </div>
      {status === ATTACHMENT_STATUS.REJECT &&
        (rejectElement || (
          <div className=' bg-gray1 p-[12px] flex align-top gap-2'>
            <div className='whitespace-nowrap'>{t('announcement:reason')} :</div>
            <div
              className='flex-1'
              style={{ wordBreak: 'break-word' }}
              dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(rejectReason) }}
            />
          </div>
        ))}
    </div>
  );
};
