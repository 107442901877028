import { useAppSelector } from '@/hooks';
import { Space } from 'antd';
import { useContext, useRef } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ExternalProxyContext } from '@pages/project-management/add-edit/ProjectExternalProxy';

import { BaseButton } from '@/components/base-button/BaseButton';
import { TYPE_ICON, showBasePopup } from '@/components/base-popup/BasePopup';

import { getProjectDetails } from '@/redux/project-v2/projectSelector';

import useMutation from '@/hooks/useMutation';

import { API } from '@/utils/constants/Apis';
import { UPLOAD_STATUS } from '@/utils/constants/AppConstants';
import { CREATE_PROJECT_SUCCESS, PROJECT_LIST_URL, SAVE_AS_DRAFT_PROJECT_SUCCESS } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';
import { validateWithoutErrorTypes } from '@/utils/helpers/validateHelper';

import CaretLeft from '@/assets/icons/CaretLeft.svg?react';

import { SCENARIO_TYPES } from '../../contants';
import { IFormScenarioD } from '../../models';
import { FormObserver } from '../../models/projectExternalProxy';
import ScenarioDContent from './ScenarioD';
import { REQUEST_DETAIL } from './contants';

import './Styles.scss';

export default function ScenarioDForm() {
  const { loading, setLoading } = useContext(ExternalProxyContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useAppSelector(getProjectDetails);
  const { id } = useParams();
  const stepsRef = useRef<any>(null);
  const formObserver = useFormContext<FormObserver<'scenario-d'>>();
  const [selectedStep, scenarioType] = formObserver.watch(['selectedStep', 'scenarioType']);
  const { defaultValues: defaultFormValues } = formObserver.formState;
  const defaultValues = defaultFormValues?.steps?.[0];

  const { mutate: createScenarioD } = useMutation(API.CREATE_SCENARIO_D, {
    method: 'POST',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const { mutate: updateScenarioD } = useMutation(API.CREATE_SCENARIO_D, {
    method: 'PUT',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });

  const formatProjectAttachments = (formData: any) => {
    let projectAttachments = formData?.projectAttachments
      ?.filter((file: any) => file.status === UPLOAD_STATUS.DONE)
      ?.map((file: any, index: number) => ({
        ...file,
        displayOrder: index,
        deleted: file?.deleted ?? false
      }));

    defaultValues?.projectAttachments?.forEach((item: any) => {
      if (!projectAttachments.find((i: any) => i.id === item.id) && item.id) {
        projectAttachments.push({ ...item, deleted: true });
      }
    });

    return projectAttachments;
  };

  const onSave = () => {
    formObserver.handleSubmit(async (formValues) => {
      const values = formValues?.steps?.[0];
      const showPopup = await showBasePopup({
        title: String(t('common:MSG_P_012.title')),
        msg: String(t('common:MSG_P_012.content')),
        type: TYPE_ICON.CONFIRM
      });

      if (showPopup !== TYPE_ICON.CONFIRM) return;

      setLoading(true);
      const result = !id ? await createScenarioD(getPayload(values, false)) : await updateScenarioD(getPayload(values, false));
      if (result) {
        setTimeout(() => {
          setLoading(false);
          navigate(CREATE_PROJECT_SUCCESS);
        }, 500);
        return;
      }
      setLoading(false);
    }, onError)();
  };

  const onError = async (err: any) => {
    let firstInValidKey: string | undefined = Object.keys(err)?.[0];
    const formCollapseMapping = {
      steps: stepsRef
    };
    // Open all collapse invalid
    for (let key in err) {
      if (formCollapseMapping.hasOwnProperty(key) && key in formCollapseMapping) {
        const formRef = formCollapseMapping[key as keyof typeof formCollapseMapping];
        const idRef = formRef.current?.getIdElement();
        formRef.current?.setCollapse(idRef);
      }
    }
    // Scroll to first collapse invalid
    if (firstInValidKey !== undefined && formCollapseMapping.hasOwnProperty(firstInValidKey) && firstInValidKey in formCollapseMapping) {
      const formRef = formCollapseMapping[firstInValidKey as keyof typeof formCollapseMapping];
      const idRef = formRef.current?.getIdElement();
      const element = document.getElementById(`base-collapse-${idRef}`);
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onSaveAsDraft = async () => {
    const { steps } = formObserver.formState.errors ?? {};
    const hasErrorNotRequired = validateWithoutErrorTypes((steps as FieldValues)?.[0], ['required', 'min']);

    if (hasErrorNotRequired) return;

    const values = formObserver.getValues('steps.0');
    const showPopup = await showBasePopup({
      title: String(t('common:MSG_P_002_title')),
      msg: String(t('common:MSG_P_002_content')),
      type: TYPE_ICON.CONFIRM
    });

    if (showPopup !== TYPE_ICON.CONFIRM) return;

    setLoading(true);
    const result = !id ? await createScenarioD(getPayload(values)) : await updateScenarioD(getPayload(values));
    if (result) {
      formObserver.reset((prev) => ({ ...prev, isDirty: false }), { keepValues: true, keepDirty: false });

      setTimeout(() => {
        setLoading(false);
        navigate(SAVE_AS_DRAFT_PROJECT_SUCCESS(result?.data || ''));
      }, 500);
      return;
    }
    setLoading(false);
  };

  const getPayload = (values: IFormScenarioD, isDraft = true) => {
    // Check key '認証' if present will always be at the beginning of the array
    const reorderedArray = values.requestDetail?.includes(REQUEST_DETAIL.CERTIFICATION)
      ? [REQUEST_DETAIL.CERTIFICATION, ...values.requestDetail.filter((item) => item !== REQUEST_DETAIL.CERTIFICATION)]
      : values.requestDetail;

    const convertRequestDetail = reorderedArray?.join('、');

    const hasTranslation = values.requestDetail?.includes(REQUEST_DETAIL.TRANSLATION);
    const valueNoteSpecial = String(values.noteSpecial);
    const note = `
      <div style='display: flex; gap: 4px'>
        <strong id='label-phone-scenario-d' style="flex-shrink: 0">${t('inquiry:phone_label')}</strong>: <strong id='value-phone-scenario-d' style="white-space: pre-line; word-break: break-word">${values.phoneNumber}</strong>
      </div>
      <div style='display: flex; gap: 4px'>
        <strong id='label-request-detail-scenario-d' style="flex-shrink: 0">${t('project:scenario:request_details')}</strong>: <strong id='value-request-detail-scenario-d' style="white-space: pre-line; word-break: break-word">${convertRequestDetail}</strong>
      </div>
      ${
        hasTranslation
          ? `<div style='display: flex; gap: 4px'>
            <strong id='label-note-special-scenario-d' style="flex-shrink: 0">${t('basic_information:translation_language')}</strong>: <strong id='value-note-special-scenario-d' style="white-space: pre-line; word-break: break-word">${valueNoteSpecial}</strong>
          </div>`
          : ''
      }
      <div style='display: flex; gap: 4px'>
        <strong style='width: 58px; white-space: nowrap' id='label-note-scenario-d'>${t('basic_information:note')}</strong>: <strong id='value-note-scenario-d' style="white-space: pre-line; word-break: break-word">${values.requestContent}</strong>
      </div>
    `;
    let command: any = {
      noteSpecial: values.noteSpecial ?? '',
      phoneNumber: values.phoneNumber,
      requestContent: values.requestContent,
      requestDetail: `${convertRequestDetail ?? ''}${hasTranslation ? `(${t('basic_information:translation_language')} : ${valueNoteSpecial})` : ''}`,
      projectAttachments: formatProjectAttachments(values),
      note,
      isDraft,
      version: data?.version ?? ''
    };
    if (id) {
      command['id'] = id;
    }
    if (values.countryId) {
      command['countryId'] = values.countryId;
    }
    return {
      command: JSON.stringify(command)
    };
  };

  const onBack = () => {
    const hasError = DataViewer.isShallowEmpty(formObserver.formState.errors);
    if (hasError) {
      formObserver.setValue('selectedStep', 1, { shouldDirty: false });
    }
  };

  return (
    <div className='flex flex-col gap-4 h-full'>
      <div className='sticky flex justify-between top-0 z-50 bg-gray1'>
        <div className='title-24 whitespace-pre-line font-bold text-24'>
          {!!id ? (
            <p className='title-24 whitespace-pre-line font-bold text-24'>
              <span className='title-24 text-negative'>{t('basic_information:isDraft')}</span> {data?.code}
            </p>
          ) : (
            t('project:add:title')
          )}
        </div>
        <Space size={16}>
          <div className='min-w-[120px]'>
            <BaseButton
              block
              size='medium'
              type='tertiary'
              disabled={loading}
              onClick={() => {
                onBack();
              }}
            >
              <img src={CaretLeft} alt=''></img>
              {t('button:back')}
            </BaseButton>
          </div>
          <div className='min-w-[120px]'>
            <BaseButton block size='medium' type='tertiary' disabled={loading} onClick={() => navigate(PROJECT_LIST_URL)}>
              {t('button:cancel')}
            </BaseButton>
          </div>
          <div className='min-w-[120px]'>
            <BaseButton block size='medium' type='secondary' disabled={loading} onClick={onSaveAsDraft}>
              {t('common:button:save_daft')}
            </BaseButton>
          </div>
          <div className='min-w-[120px]'>
            <BaseButton block size='medium' disabled={loading} onClick={onSave}>
              <span> {t('button:send')} </span>
            </BaseButton>
          </div>
        </Space>
      </div>
      <div className='h-[calc(-136px + 100vh)] overflow-y-scroll w-full border border-solid border-gray2 rounded-lg p-4 bg-white'>
        <p className='subtitle-18'>{t('project:scenario:title_1')}</p>
        <p className='subtitle-18'>{t('project:scenario:title_2')}</p>
        {selectedStep === 2 && scenarioType === SCENARIO_TYPES.SCENARIO_D && <ScenarioDContent ref={stepsRef} />}
      </div>
    </div>
  );
}

export const useFormScenarioDContext = useFormContext<FormObserver<'scenario-d'>>;
