import { useAppDispatch } from '@/hooks';
import { READED } from '@/widgets/Comment/constants';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { STATUS_NAME } from '@/pages/project-management/detail/constant';
import { ITask } from '@/pages/project-management/detail/utils';
import { isPending, renderTaskStatus } from '@/pages/project-management/detail/utils/helper';

import EmptyTextData from '@/components/EmptyData/EmptyTextData';
import AppTooltip from '@/components/app-tooltip/AppTooltip';
import { showBasePopup } from '@/components/base-popup/BasePopup';
import TableBase from '@/components/common/table-base';
import { TagTaskType } from '@/components/common/task/TagTaskType';

import { setAlertNotification } from '@/redux/globalReducer';

import useAuthorization from '@/hooks/useAuthorization';
import useMutation from '@/hooks/useMutation';

import { TYPE } from '@/utils/constants/AppConstants';
import { VIEW_TASK_OF_PROJECT_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';

import ChatIcon from '@/assets/icons/Chats.svg';

interface ITaskTableProps {
  listTask: ITask[];
  fetchData?: () => void;
}

const TaskTable = ({ listTask, fetchData }: ITaskTableProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { isExternalRole } = useAuthorization();
  const { mutate: deleteTaskProject } = useMutation(`/prj/projects/${id}/task`, {
    method: 'DELETE',
    bodyType: 'json',
    showToastError: true,
    showToastSuccess: false
  });
  const onClickChatIcon = (projectId: string, taskId: string) => {
    return navigate(`${VIEW_TASK_OF_PROJECT_URL(projectId ?? '', taskId ?? '')}?${READED}=true`);
  };

  const generateColumns = () => {
    return [
      {
        key: 'taskType',
        width: 62,
        render: (record: ITask) => {
          return (
            <div className='font-medium task-type-tag'>
              <TagTaskType type={record.taskType} />
            </div>
          );
        }
      },
      {
        key: 'title',
        width: 340,
        render: (record: ITask) => {
          const showNameTask = isExternalRole && record.preStatusName === STATUS_NAME.TODO;
          return showNameTask ? (
            <AppTooltip className='block max-w-full w-fit truncate' title={record?.taskName}>
              <span>{DataViewer.display(record?.taskName)}</span>
            </AppTooltip>
          ) : (
            <AppTooltip className='block max-w-full w-fit truncate' title={record?.taskName}>
              <Link to={`${VIEW_TASK_OF_PROJECT_URL(id ?? '', record.id ?? '')}?${READED}=true`} className='underline font-normal'>
                {record?.taskName}
              </Link>
            </AppTooltip>
          );
        }
      },
      {
        key: 'comment',
        width: 48,
        render: (record: ITask) => {
          const disabledLink = isExternalRole && record.preStatusName === STATUS_NAME.TODO;
          return (
            !disabledLink &&
            record.hasComments && (
              <button
                onClick={() => onClickChatIcon(id ?? '', record.id ?? '')}
                className={classNames(
                  `m-0 p-0 bg-[transparent] cursor-pointer border-0 relative w-[20px] flex items-center justify-center after:absolute after:h-[6px] after:w-[6px] after:bg-negative after:rounded-full after:top-[-2px] after:right-[-2px] after:border-[2px] after:border-white after:border-solid`,
                  record?.hasUnreadComments && `after:content-['']`
                )}
              >
                <ChatIcon />
              </button>
            )
          );
        }
      },

      {
        key: 'deadline',
        width: 130,
        render: (record: ITask) => {
          return (
            <div className='font-normal'>
              {t('project_detail:task_list:deadline')} : {DataViewer.displayTime(record?.deadline)}
            </div>
          );
        }
      },
      {
        key: 'pic',
        width: 242,
        render: (record: ITask) => {
          return (
            <AppTooltip className='block max-w-full w-fit truncate' title={record.picName}>
              <div className='font-normal'>
                {t('project_detail:task_list:task_pic')} : {DataViewer.display(record.picName)}
              </div>
            </AppTooltip>
          );
        }
      },
      {
        key: 'status',
        width: 122,
        className: 'flex justify-end',
        render: (record: ITask) => {
          const status = isPending(record) ? STATUS_NAME.PENDING : record?.statusName;
          return renderTaskStatus(status, 'ml-auto');
        }
      }
    ];
  };

  const listDataTask = useMemo(() => {
    return listTask.map((item: ITask) => ({
      ...item,
      key: item.id
    }));
  }, [listTask]);

  const handleDeleteRow = async (keys: React.Key[], records: any[] | undefined) => {
    if (!records) return;
    const idsToDelete = records.map((item) => ({
      id: item.id || '',
      version: item.version || ''
    }));

    if (!idsToDelete.length) {
      return 0;
    }

    const showPopup = await showBasePopup({
      type: 'discard',
      title: t('common:MSG_C_012:title', { field: t('project_detail:task_list:task') }) ?? '',
      msg: t('common:MSG_C_012:description', { field: t('project_detail:task_list:task') }) ?? ''
    });

    if (showPopup === 'confirm') {
      const result = await deleteTaskProject({
        tasks: idsToDelete
      });
      if (!result) return 0;
      const { data } = result;

      if (data.successCount === keys.length) {
        dispatch(
          setAlertNotification({
            show: true,
            type: TYPE.SUCCESS,
            message: t('common:MSG_C_003', { item: t('master_data:phrase:delete') })
          })
        );
      }

      if (data.successCount < keys.length) {
        dispatch(
          setAlertNotification({
            show: true,
            type: TYPE.SUCCESS,
            message: t('common:MSG_C_020', { deletable: data.successCount, total: keys.length })
          })
        );
      }
      fetchData && fetchData();
      return data.successCount;
    }
  };

  const getEmptyDataAlert = (): React.ReactNode => {
    return <EmptyTextData className='text-center' text={'template_layout:emty_table'} />;
  };

  return (
    <div className='table-items pl-[40px]'>
      <div className='table-items-collapse'></div>
      <div className='flex-1 table-items-table'>
        {listDataTask.length ? (
          <TableBase
            className='base-table-bordered'
            columns={generateColumns()}
            dataSource={listDataTask}
            pagination={false}
            rowClassName={(record: ITask) => `bg-blue3 ${isPending(record) ? 'row-delay' : ''}`}
            rowSelection={
              isExternalRole
                ? null
                : {
                    getCheckboxProps: (record: ITask) => ({
                      disabled: record.preStatusName !== STATUS_NAME.TODO
                    }),
                    onCell: () => {
                      return {
                        style: {
                          backgroundColor: 'transparent',
                          borderLeft: 'none'
                        }
                      };
                    }
                  }
            }
            titleSelectedRowsCustom={`${t('project_detail:task_list:selected')}`}
            showDelete
            showHeader={false}
            handleDeleteClick={handleDeleteRow}
          />
        ) : (
          getEmptyDataAlert()
        )}
      </div>
    </div>
  );
};

export default TaskTable;
