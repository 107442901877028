import { useTranslation } from 'react-i18next';

import { StepsProcess } from '@/components/step-process/StepsProcess';

import useFetch from '@/hooks/useFetch';

import { Ii18n } from '@/utils/interfaces/i18n';
import { PROJECT_STATUS_ID } from '@utils/constants/project';

import { IStatus, PROCESS_TRAVEL_NAME } from '../../project-list-v2/models';
import { IDataDetail } from '../utils';

interface ProcessProps {
  data: IDataDetail;
}

const ProcessDetail = ({ data }: ProcessProps) => {
  const { t }: Ii18n = useTranslation();
  const { data: listStatus } = useFetch<IStatus[]>('/prj/status', 'GET');
  const isDoneAllTask =
    !!data.projectProcesses?.length &&
    !data.projectProcesses.some((process) => process.processName !== PROCESS_TRAVEL_NAME && !process.completedDate);
  const isCompleted =
    [PROJECT_STATUS_ID.COMPLETED, PROJECT_STATUS_ID.CANCELLED].includes((data.statusId as PROJECT_STATUS_ID) ?? '') || isDoneAllTask;

  return (
    <div className='flex flex-col rounded-[10px] gap-[6px] p-[16px] bg-white border-gray2 border-[1px] border-solid'>
      <div className='text-lnk text-[18px] font-semibold leading-[25px]'>{t('project_detail:procedual_process')}</div>
      <div className='project-detail-process p-[12px] rounded-[8px]'>
        <StepsProcess stepData={data.projectProcesses} listStatus={listStatus || []} disabled={isCompleted} active={data.active} />
      </div>
    </div>
  );
};

export default ProcessDetail;
