import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import EmptyTextData from '@/components/EmptyData/EmptyTextData';
import TableBase, { FetchColumnsType } from '@/components/common/table-base';

import { FORMAT_DATE_EN_HH_MM_SS, TYPE } from '@/utils/constants/AppConstants';
import { DataViewer } from '@/utils/helpers/common';

import { TaskInternalDocument } from '../../models';

interface TaskInternalDocumentsViewProps {
  taskInternalDocuments: TaskInternalDocument[];
}
export default function TaskInternalDocumentsView({ taskInternalDocuments }: TaskInternalDocumentsViewProps) {
  const { t } = useTranslation();
  const columnsDocumentList: FetchColumnsType<any> = [
    {
      title: t('project:task_of_project:document_code'),
      key: 'displayOrder',
      width: 80,
      render: (_value: any, _record: any, index: number) => {
        return <div className='text-center'>{DataViewer.display(_record.displayOrder)}</div>;
      }
    },
    {
      title: t('project:task_of_project:document_name'),
      dataIndex: 'managementCode',
      width: 350,
      className: 'min-w-[350px]',
      render(_value: string, _record: any, index: number) {
        return <div className='block max-w-full w-fit whitespace-pre-wrap break-all'>{DataViewer.display(_record.managementCode)}</div>;
      }
    },
    {
      title: t('project:task_of_project:number_of_copies'),
      dataIndex: 'numberOfCopy',
      width: 80,
      render(_value: any, record: any, index: number) {
        return <span className='block max-w-full w-fit whitespace-pre-wrap break-all'>{DataViewer.display(record?.numberOfCopy)}</span>;
      }
    },
    {
      title: t('project:task_of_project:description'),
      dataIndex: 'note',
      width: 350,
      className: 'max-w-[350px]',
      ellipsis: false,
      render(value: any, record: any, index: number) {
        return <div className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(record?.note) }}></div>;
      }
    },
    {
      title: t('project_management:tabs:required_documents_table:correspondence_situation'),
      dataIndex: 'status',
      render(_value: any, record: any, index: number) {
        return <div>{record.isConfirm ? t('project:task_of_project:compatible') : t('project:task_of_project:not_supported')}</div>;
      },
      width: 100
    },
    {
      title: t('project:task_of_project:counterpart'),
      dataIndex: 'confirmedBy',
      render(_value: any, record: any, index: number) {
        const nameConfirm = () => {
          if (isNil(record?.statusConfirm)) return null;
          return record?.statusConfirm ? record.nameConfirm : `(${t('account_list:status_account:inactive')}) ${record.nameConfirm}`;
        };
        return (
          <div className='block max-w-full w-fit whitespace-pre-wrap break-all'>{DataViewer.display(record.isConfirm ? nameConfirm() : null)}</div>
        );
      },
      width: 140
    },
    {
      title: t('project:task_of_project:corresponding_date'),
      dataIndex: 'confirmedDate',
      render(_value: any, record: any, index: number) {
        return (
          <div className='whitespace-pre-wrap'>{DataViewer.localTimeBy(record.isConfirm ? record.dateConfirm : null, FORMAT_DATE_EN_HH_MM_SS)}</div>
        );
      },
      width: 120
    }
  ];

  return (
    <div>
      <TableBase
        className='w-full mt-[8px] border-no-data'
        showAddLine={false}
        columns={columnsDocumentList}
        showDownload={false}
        pagination={false}
        deleteConfirmPopup={{
          type: TYPE.DISCARD,
          title: t('common:MSG_029.1_title', { item: t('basic_information:project') }) ?? '',
          msg:
            `${t('common:MSG_029.1_description', { item: t('basic_information:project') })} ${t('common:MSG_029_description2', {
              item: t('basic_information:project')
            })}` ?? ''
        }}
        dataSource={taskInternalDocuments}
        rowSelection={null}
        locale={{ emptyText: <EmptyTextData className='text-center mt-4' text={'common:MSG_093'} /> }}
      />
    </div>
  );
}
