/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';

import { showTutorialPopup } from '@/pages/tutorial';

import { changeCountNoticeUnReadWithType, incrementNotice, setPopupNotice } from '@/redux/notice/noticeReducer';
import { setQuestionnaireConfirmLock } from '@/redux/questionnaire/questionnaireReducer';
import { AppDispatch } from '@/redux/store';

import useAuthorization from '@/hooks/useAuthorization';
import useEventListener, { CUSTOM_HOOK_EVENTS } from '@/hooks/useEventListener';

import { QUERY_SORT } from '@/utils/constants';
import { createParamTypeNotice, returnPageCreateNotice } from '@/utils/helpers/notification';
import { getCountries } from '@/utils/services/country';
import { getProcessPatterns } from '@/utils/services/template';

import { useAppSelector } from '../hooks';
import useSignalr from '../hooks/useSignalr';
import { setCountries, setIsForceLogout, setLoadingPageGlobal, setProcessPatterns, setUserProfile } from '../redux/globalReducer';
import {
  ERROR_CODE,
  EXTERNAL_ADMIN,
  EXTERNAL_GENERAL_USER,
  INTERNAL_ADMIN,
  INTERNAL_GENERAL_USER,
  LOCALSTORAGE,
  SIGNALR_METHODS
} from '../utils/constants/AppConstants';
import { NOT_AUTHORIZED_URL, NOT_EXITS_URL, SOMETHING_WENT_WRONG_URL } from '../utils/constants/RouteContants';
import { cleanLocalCache, parseJson } from '../utils/helpers/globalHelper';
import { getUserInfo } from '../utils/services/AuthenticationApiService';
import { RESPONSE_TYPE } from './contants';

interface IAppWrapperProps {
  children: React.ReactNode;
}

export const AppWrapper = ({ children }: IAppWrapperProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isInternalRole } = useAuthorization();
  const auth = useAuth();
  const [isUserLoaded, setUserLoaded] = useState<boolean>(false);

  const userProfile = useAppSelector((x) => x.global.userProfile);
  const currentTab = useAppSelector((state) => state.notice.currentTabNotice);
  const payloadSearch = useAppSelector((state) => state.notice.payloadSearchNotice);
  const payloadFilter = useAppSelector((state) => state.notice.payloadFilterNotice);
  const { isConnected, connect, disconnect, registerAndReceiveMessage, unRegisterAndReceiveMessage } = useSignalr();

  // Get data user info
  const getDataUserProfile = async (token: string) => {
    try {
      dispatch(setLoadingPageGlobal(true));
      const { data } = await getUserInfo(token);
      const role = data?.roleId || '';
      const groups: string[] = [role].filter((g) => g);
      const userProfile = {
        role: data?.rights[0],
        projects: [],
        groups,
        ...data
      };
      localStorage.setItem(LOCALSTORAGE.USER, JSON.stringify(userProfile));
      dispatch(setUserProfile(userProfile));
      if ([INTERNAL_ADMIN, INTERNAL_GENERAL_USER].includes(userProfile.role)) {
        await getProcessPatternsData();
      }
      const isExternalRole = [EXTERNAL_ADMIN, EXTERNAL_GENERAL_USER].includes(userProfile?.role);
      if (!userProfile.readedTutorial && isExternalRole) showTutorialPopup();
      setUserLoaded(true);
    } catch (error: any) {
      if (error && error.response && error.response.status === 401) {
        logout();
      }
      setUserLoaded(false);
      if (
        error &&
        error.response &&
        (error.response.status === 500 || error.code === ERROR_CODE.ERR_BAD_RESPONSE || error.code === ERROR_CODE.ERR_NETWORK)
      ) {
        window.location.href = SOMETHING_WENT_WRONG_URL;
      }
    } finally {
      dispatch(setLoadingPageGlobal(false));
    }
  };

  const getDataCountries = async () => {
    try {
      const { data } = await getCountries();
      dispatch(setCountries(data));
    } catch (error) {
      dispatch(setCountries([]));
    }
  };

  const getProcessPatternsData = async () => {
    try {
      const { data } = await getProcessPatterns();
      dispatch(setProcessPatterns(data));
    } catch (error) {
      dispatch(setProcessPatterns([]));
    }
  };

  const logout = async () => {
    await auth?.signoutRedirect();
    dispatch(setIsForceLogout(true));
    cleanLocalCache();
  };

  useEventListener(CUSTOM_HOOK_EVENTS.ACCESS_DENIED, () => logout());
  useEffect(() => {
    function handleUserLoaded(user: any) {
      if (user.access_token) {
        localStorage.setItem(LOCALSTORAGE.ACCESS_TOKEN, user.access_token);
        localStorage.setItem(LOCALSTORAGE.REFRESS_TOKEN, user.refresh_token);
      }
    }

    const handleUserSignout = () => {
      // listen for signout event from other apps in this session
      auth.removeUser();
    };

    auth.events.addUserLoaded(handleUserLoaded);
    auth.events.addUserSignedOut(handleUserSignout);

    return () => {
      auth.events.removeUserLoaded(handleUserLoaded);
      auth.events.removeUserSignedOut(handleUserSignout);
    };
  }, []);

  useEffect(() => {
    const { pathname } = window?.location ?? {};
    const ignore = pathname?.includes(NOT_EXITS_URL) || pathname?.includes(NOT_AUTHORIZED_URL) || pathname?.includes(SOMETHING_WENT_WRONG_URL);
    if (ignore) return;

    const authUpdated = auth.isAuthenticated && !auth.isLoading && auth.user;
    const accessToken = auth?.user?.access_token;
    if (authUpdated && accessToken) {
      localStorage.setItem(LOCALSTORAGE.ACCESS_TOKEN, accessToken);
      getDataUserProfile(accessToken);

      getDataCountries();
      dispatch(setIsForceLogout(false));
    }
  }, [auth]);

  useEffect(() => {
    if (isUserLoaded) {
      connect();
    }
    return () => {
      disconnect();
    };
  }, [isUserLoaded]);

  useEffect(() => {
    if (auth && auth.user && userProfile && isConnected) {
      const handleMessage = async (response: any) => {
        const data = parseJson(response?.payload ?? '');
        const type = response?.type;
        // Check if socket is yours
        const isShowNotice = (payloadFilter?.reporter ?? []).includes(data?.originUserId || userProfile.id);

        if ([RESPONSE_TYPE.CHANGE_PASSWORD, RESPONSE_TYPE.FORCE_LOGOUT].includes(response.type)) {
          if (auth.isAuthenticated) await logout();
          return;
        }

        if (RESPONSE_TYPE.UNLOCKQUESTIONNAIRE.includes(response.type)) {
          dispatch(setQuestionnaireConfirmLock(parseJson(response?.payload ?? '')));
        }

        if (
          [
            RESPONSE_TYPE.PROJECT_INTERNAL,
            RESPONSE_TYPE.TASK,
            RESPONSE_TYPE.RESULTS,
            RESPONSE_TYPE.REQUEST,
            RESPONSE_TYPE.EXTERNAL,
            RESPONSE_TYPE.PROJECT_EXTERNAL,
            RESPONSE_TYPE.ANNOUNCEMENT,
            RESPONSE_TYPE.QUESTIONAIRE,
            RESPONSE_TYPE.OTHERS
          ].includes(type) &&
          data.createdBy !== userProfile.id &&
          isShowNotice
        ) {
          // Convert parameters taken from socket
          const param = parseJson(data?.params ?? '');
          const paramTypeNotice = createParamTypeNotice(data.content, param, data.data, isInternalRole, String(data.originUserName));
          const urlNavigate = await returnPageCreateNotice(data);
          dispatch(
            setPopupNotice({
              show: true,
              dataNotice: data,
              url: urlNavigate,
              title: paramTypeNotice.title,
              projectCode: paramTypeNotice.projectCode,
              projectCompany: paramTypeNotice.companyName,
              projectAddress: paramTypeNotice.travelOrApplicantName
            })
          );
        }

        // If is your notice then convert notice param socket and push new data notice and re-count unread messages
        if (
          ((data?.createdBy === userProfile.id && !data?.origin) || (data?.createdBy !== userProfile.id && isShowNotice)) &&
          !payloadSearch.searchKey
        ) {
          if (data.type === currentTab && !payloadFilter.hidden && !payloadFilter.flag) {
            // Push new data notice call form socket
            dispatch(incrementNotice({ data, orderBy: payloadFilter.order ?? QUERY_SORT.DESC }));
          }

          // Re-count unread messages
          dispatch(changeCountNoticeUnReadWithType({ type, count: 1 }));
        }
      };
      registerAndReceiveMessage(SIGNALR_METHODS.REGISTER_USER_UPN, userProfile?.upn || '', SIGNALR_METHODS.RECEIVE_USER_MESSAGE, handleMessage);
      registerAndReceiveMessage(SIGNALR_METHODS.REGISTER_LIST_GROUP, userProfile?.groups || [], SIGNALR_METHODS.RECEIVE_GROUP_MESSAGE, handleMessage);
    }
    return () => {
      if (auth && auth.user) {
        unRegisterAndReceiveMessage(SIGNALR_METHODS.UNREGISTER_LIST_GROUP, userProfile?.groups || [], SIGNALR_METHODS.RECEIVE_GROUP_MESSAGE);
      }
    };
  }, [isConnected, currentTab, payloadFilter, payloadSearch]);

  useEffect(() => {
    if (auth.isLoading && auth.activeNavigator !== 'signinSilent') {
      dispatch(setLoadingPageGlobal(true));
      return;
    }
    dispatch(setLoadingPageGlobal(false));
  }, [auth.isLoading]);

  if (auth.isLoading && auth.activeNavigator !== 'signinSilent') {
    return <></>;
  }

  return <div>{children}</div>;
};
