import { useAppSelector } from '@/hooks';
import { Tag } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import type { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Control, UseFormReturn, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TASK_TYPE } from '@/pages/template-management/view/constants';
import { ITemplateProcess } from '@/pages/template-management/view/modules';
import { PROCESS_TRAVEL_NAME } from '@pages/project-management/project-list-v2/models';

import EmptyTextData from '@/components/EmptyData/EmptyTextData';
import BaseTable from '@/components/table/BaseTable';

import { TODO_SUPPORT_GROUPS_OPTION } from '@/utils/interfaces';
import { IDocument, ITask } from '@/utils/interfaces/template';
import { checkFormNotHasData, handleClassList, handleClassListLastRow } from '@/utils/method';

import TaskTable from './TaskTable';

import './RequiredDocuments.scss';

interface ITableParams {
  pagination: TablePaginationConfig;
  sorter: SorterResult<IDocument> | SorterResult<IDocument>[] | undefined;
  filters: Record<string, FilterValue | null | undefined>;
}

interface IRequiredDocumentsProps {
  name: 'documents';
  register: any;
  control: Control;
  documents?: ITask[];
  documentOptions: DefaultOptionType[];
  formMethod: UseFormReturn<any, any>;
  loadingData: boolean;
  refreshSearchWhenOpen: boolean;
  onPopupScroll: () => void;
  onHandleSearchDocuments: (keyword: string) => void;
}

interface IProcessHiddenItem extends ITemplateProcess {
  hidden: boolean;
}

// this class handle colSpan of customize table which have expandable & sub header
class RowHiddenController {
  static mainColSpan = 2;
  static onSubHeader = (record: IProcessHiddenItem, columnId: string) => {
    if (!record?.hidden) return { colSpan: columnId === 'name' ? 3 : 0 };
    return {};
  };
  static generateHiddenItem = (): IProcessHiddenItem => {
    return {
      templateId: '',
      processId: '',
      displayOrder: -1,
      id: '',
      templateProcessTasks: [],
      hidden: true
    };
  };
}

const RequiredDocuments = forwardRef(
  (
    {
      name,
      control,
      documentOptions,
      documents,
      formMethod,
      loadingData,
      refreshSearchWhenOpen,
      onPopupScroll,
      onHandleSearchDocuments
    }: IRequiredDocumentsProps,
    ref
  ) => {
    const [t] = useTranslation();
    const { fields, update, replace } = useFieldArray({
      name,
      control,
      keyName: 'key'
    });

    const refRequire = useRef<HTMLDivElement>(null);
    const refTable = useRef<any>(null);
    const [filterInfo, setFilterInfo] = useState<any>();
    const [showEmpty, setShowEmpty] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<any[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const patterns = useAppSelector((state) => state.global.processPatterns);
    const { patternId, initDocuments } = formMethod.watch();
    const CATEGORY_PROCESS_OPTION: DefaultOptionType[] = [
      { value: 'OTHER', label: t('template_layout:other_category') },
      { value: 'TODO', label: 'TODO' },
      { value: 'MOT', label: 'MOT' },
      { value: 'SUPPORT', label: t('task:type_support') }
    ];
    /**
     * Declare state
     */
    const { submitCount, errors } = formMethod.formState;

    useEffect(() => {
      if (!submitCount) return;
      const errorDoc = errors?.documents instanceof Array ? errors?.documents : [];
      const rowKeys = errorDoc.map((_: any, i: number) => (fields?.[i] as any)?.['rowKey']);
      setExpandedKeys(rowKeys);
    }, [submitCount]);

    useEffect(() => {
      // TODO: need move this lifeCycle to the AddEditTemplateLayout.tsx
      // If we change field in this effect, formMethod is trigger change isDirty = true => it's wrong with the first load.
      const patternSelected = patterns?.find((item) => item?.id === patternId);
      const process = patternSelected?.processes.map((item) => ({
        ...item,
        rowKey: item.id,
        templateProcessTasks: [],
        processId: item.id
      }));
      if (patternId === '') replace([]);
      if (!patternId) return;
      replace(process);
      process?.forEach((item: any, index: number) => {
        initDocuments?.forEach((init: any) => {
          if (item?.processId === init?.processId) {
            const initData = cloneDeep(init);
            item.templateProcessTasks = initData?.templateProcessTasks?.map((i: any) => {
              return { ...i, templateProcessId: init?.id, key: i?.id };
            });
            item.id = init.id;
            update(index, item);
          }
        });
      });
    }, [patternId, initDocuments]);

    const [tableParams] = useState<ITableParams>({
      pagination: {},
      sorter: undefined,
      filters: {}
    });

    const calculateGroup = (data: any[]): { [key in 'TODO' | 'OTHER_AND_MOT']: number } => {
      const results = {
        TODO: 0,
        OTHER_AND_MOT: 0
      };
      for (let j = 0; j < data?.length; j++) {
        const type = data[j]?.taskType;
        if (!type) continue;
        if ([TASK_TYPE.MOT, TASK_TYPE.OTHER].includes(type)) ++results['OTHER_AND_MOT'];
        if (TODO_SUPPORT_GROUPS_OPTION.includes(type)) ++results['TODO'];
      }
      return results;
    };

    const generateProcessColumns = () => {
      return [
        {
          title: t('template_layout:category'),
          dataIndex: 'name',
          key: 'name',
          width: 305,
          className: 'min-w-[168px] max-w-[210px] h-[38px]',
          colSpan: RowHiddenController.mainColSpan,
          onCell: (record: IProcessHiddenItem) => RowHiddenController.onSubHeader(record, 'name'),
          render: (_: string, record: any, index: number) => {
            const group = calculateGroup(formMethod.getValues(`${name}.${index}.templateProcessTasks`));
            const isTravel = record.name === PROCESS_TRAVEL_NAME;
            return (
              <div className={classNames('flex gap-[10px] items-center', isTravel && '-ml-[40px]')}>
                <p className='text-lnk font-bold'>{record?.name ?? ''}</p>
                {!isTravel && (
                  <>
                    <Tag color='#DBDBE0' className='text-[14px] !text-lnk font-bold'>
                      {`TODO, ${t('task:type_support')}: ${group['TODO']}`}
                    </Tag>
                    <Tag color='#DBDBE0' className='text-[14px] !text-lnk font-bold'>
                      {`${CATEGORY_PROCESS_OPTION[0].label}, MOT: ${group['OTHER_AND_MOT']}`}
                    </Tag>
                  </>
                )}
              </div>
            );
          }
        },
        {
          title: t('template_layout:title'),
          dataIndex: 'taskName',
          key: 'taskName',
          width: 500,
          className: 'min-w-[190px] max-w-[190px] h-[38px]',
          onCell: (record: IProcessHiddenItem) => RowHiddenController.onSubHeader(record, 'taskName')
        },
        {
          title: t('project:template_layout:task_content'),
          dataIndex: 'detail',
          key: 'detail',
          className: 'min-w-[220px] h-[38px]',
          onCell: (record: IProcessHiddenItem) => RowHiddenController.onSubHeader(record, 'detail')
        }
      ];
    };

    useEffect(() => {
      handleClassList();
    }, []);

    // handle page change, page size change, filter, sorting
    const handleTableChange = (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null | undefined>,
      sorter: SorterResult<IDocument> | SorterResult<IDocument>[] | undefined
    ) => {
      refTable.current?.clearSelected();
      // Update filters
      const newFilters = {
        ...tableParams.filters,
        ...filters
      };
      // Update sorter
      setFilterInfo(newFilters);
    };

    useEffect(() => {
      if (!filterInfo) {
        return;
      }
      if (!checkFormNotHasData(filterInfo)) {
        let allValueFilter: any = [];
        Object.keys(filterInfo).forEach((item) => {
          if (filterInfo[item]) {
            allValueFilter = allValueFilter.concat(filterInfo[item]);
          }
        });
      } else {
        setFilterData([]);
        setShowEmpty(false);
      }
    }, [JSON.stringify(filterInfo)]);

    // Empty Table data
    const getEmptyDataAlert = (): React.ReactNode => {
      return (
        <EmptyTextData
          className='text-center'
          text={filterInfo && !checkFormNotHasData(filterInfo) ? 'common:MSG_038' : 'template_layout:emty_table'}
        />
      );
    };

    const handleRowClass = (record: IDocument, index: number) => {
      let className: string = `document-row-${index}`;
      if (filterData.length && !showEmpty) {
        const isExit = filterData.find((item) => item.id === record.id);
        if (!isExit) {
          className += ' hidden-row-filtered';
        }
      }
      if (!filterData.length && showEmpty) {
        className += ' hidden-row-filtered';
      }
      if (record.name === PROCESS_TRAVEL_NAME) {
        className += ' process-row-disable';
      }
      return className;
    };

    useEffect(() => {
      if (filterData.length) {
        handleClassListLastRow(fields, 'document-row');
      }
    }, [filterData.length]);

    useEffect(() => {
      if (formMethod.formState.isSubmitted) {
        const { error } = formMethod.getFieldState('documents');
        if (error) {
          setFilterInfo(null);
          setFilterData([]);
          handleClassListLastRow(fields, 'document-row', true);
        }
      }
    }, [formMethod.formState.isSubmitted]);

    useEffect(() => {
      setExpandedKeys(fields.filter((item: any) => item.name !== PROCESS_TRAVEL_NAME).map((e: any) => e.rowKey));
    }, [fields]);

    const handleOnExpand = (expanded: boolean, record: any) => {
      const newExpanded = [...expandedKeys];
      if (expanded && record.rowKey) {
        newExpanded.push(record.rowKey);
      } else {
        const matchIndex = newExpanded.findIndex((key) => key === record.rowKey);
        if (matchIndex !== -1) newExpanded.splice(matchIndex, 1);
      }
      setExpandedKeys(newExpanded);
    };

    const produceDataToView = (data: any[]) => {
      const newData = [...data];
      const hiddenData = RowHiddenController.generateHiddenItem();
      newData.push(hiddenData);
      return newData;
    };

    return (
      <div id={`${fields.length && showEmpty ? 'require-documents-empty' : 'require-documents'}`} ref={refRequire}>
        {patternId ? (
          <BaseTable
            // ref={refTable}
            rowKey={'rowKey'}
            columns={generateProcessColumns()}
            dataSource={produceDataToView(fields)}
            className='text-[#12212E] font-semibold require-documents-table'
            onChange={handleTableChange}
            pagination={false}
            emptyDataAlert={getEmptyDataAlert()}
            classEmptyCustom='!py-0'
            onRow={(record) => {
              if (record.hidden) return { style: { display: 'none' } };
              return {};
            }}
            rowClassName={(record, index) => handleRowClass(record, index)}
            expandable={{
              expandedRowKeys: expandedKeys,
              onExpand: handleOnExpand,
              expandedRowRender: (record, index) => {
                if (record.hidden) return null;
                return (
                  <TaskTable
                    name={`${name}.${index}.templateProcessTasks`}
                    documentOptions={documentOptions}
                    documents={documents}
                    formMethod={formMethod}
                    loadingData={loadingData}
                    onPopupScroll={onPopupScroll}
                    onHandleSearchDocuments={onHandleSearchDocuments}
                    parentIndex={index}
                    processItem={record as unknown as ITemplateProcess}
                    hideAddButton={record.name === PROCESS_TRAVEL_NAME}
                  />
                );
              },
              rowExpandable: (record) => record.name !== PROCESS_TRAVEL_NAME
            }}
          />
        ) : (
          getEmptyDataAlert()
        )}
        {fields.length && showEmpty ? (
          <div className='flex justify-center py-[46px] flex-col items-center custom-empty'>
            <div className='text-center'>
              <p className='!body-400'>{t('common:MSG_038')}</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);

export default RequiredDocuments;
