import { QUERY_CONDITION } from '@/utils/constants';
import QueryFactory, { QueryItem } from '@/utils/helpers/filters/base';
import { formatDateToUTC, getEndOfDay } from '@/utils/helpers/globalHelper';

import { IFilterSearchForm, IQueryFilterParams } from './interface';

export class TaskMasterQueryFactory extends QueryFactory {
  getByCategory(category: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const categoryQuery = new QueryItem().modules.guidWith(category, { fieldTitle: 'type' });
    newQuerySection.childrens?.push(categoryQuery);
    this.append(newQuerySection);
    return this;
  }

  getByCountryId(countryId: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const countryIdQuery = new QueryItem().modules.guidWith(countryId, { fieldTitle: 'countryId' });
    newQuerySection.childrens?.push(countryIdQuery);
    this.append(newQuerySection);
    return this;
  }

  getByProjectId(projectId: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    const countryIdQuery = new QueryItem().modules.guidWith(projectId, { fieldTitle: 'projectId' });
    newQuerySection.childrens?.push(countryIdQuery);
    this.append(newQuerySection);
    return this;
  }

  updatedDateFrom(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.fromDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }

  updatedDateTo(date: string) {
    const newQuerySection = this.initNewQuery(this.data?.length ? QUERY_CONDITION.AND : QUERY_CONDITION.EMPTY);
    newQuerySection?.childrens?.push(new QueryItem().modules.toDate(date, { fieldTitle: 'updatedDate' }));
    this.append(newQuerySection);
    return this;
  }
}

export const generateFilter = (params?: IQueryFilterParams): string => {
  const filterFactory = new TaskMasterQueryFactory();

  if (params?.keyword) {
    const fields = [
      'nameSearch.ToLower()',
      'ContentSearch.ToLower()',
      'CountryName.ToLower()',
      'UpdatedNameSearch.ToLower()',
      'ManagementCodeSearch.ToLower()'
    ];
    filterFactory.searchingBy(fields, params.keyword.toLowerCase());
  }

  if (params?.category) {
    filterFactory.getByCategory(params.category);
  }

  if (params?.countryId) {
    filterFactory.getByCountryId(params.countryId);
  }

  if (params?.projectId !== undefined) {
    filterFactory.getByProjectId(params.projectId);
  }
  if (params?.updatedDateFrom) {
    filterFactory.updatedDateFrom(formatDateToUTC(params?.updatedDateFrom));
  }

  if (params?.updatedDateTo) {
    filterFactory.updatedDateTo(formatDateToUTC(getEndOfDay(params.updatedDateTo)));
  }

  return filterFactory.sanitize().stringifyData();
};

export const DEFAULT_FILTER_SEARCH_FORM: IFilterSearchForm = {
  keyword: '',
  primary: {
    category: '',
    countryId: '',
    updatedDateFrom: '',
    updatedDateTo: ''
  }
};
