import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch, useParams } from 'react-router-dom';

import EmptyTextData from '@/components/EmptyData/EmptyTextData';
import { BaseButton } from '@/components/base-button/BaseButton';
import TableBase, { FetchColumnsType } from '@/components/common/table-base';
import { FormCheckbox } from '@/components/form-checkbox/FormCheckbox';
import { FormSelect } from '@/components/form-select/FormSelect';

import useFetch from '@/hooks/useFetch';

import { API } from '@/utils/constants/Apis';
import { FORMAT_DATE_EN_HH_MM_SS, MAX_CAPACITY_FILE, TYPE } from '@/utils/constants/AppConstants';
import { EDIT_TASK_OF_PROJECT_URL } from '@/utils/constants/RouteContants';
import { DataViewer } from '@/utils/helpers/common';

import TrashBlack from '@/assets/icons/TrashBlack.svg';

import { DEFAULT_VALUE_TASK_INTERNAL_DOCUMENT } from '../constants';
import { IDocument, IFormAddTaskOfProject } from '../models';
import { getDocuments } from '../utils';

interface ITaskInternalDocumentsProps {
  defaultValueForm: IFormAddTaskOfProject;
}

export default function TaskInternalDocuments({ defaultValueForm }: ITaskInternalDocumentsProps) {
  const { t } = useTranslation();
  const { idProject } = useParams();
  const [optionsDocumentTotal, setOptionsDocumentTotal] = useState<any[]>([]);
  const { data: optionsDocument } = useFetch<IDocument[]>(API.GET_INTERNAL_DOCUMENT_PROJECT(idProject ?? ''), 'GET');
  const modeEdit = useMatch(EDIT_TASK_OF_PROJECT_URL(':idProject', ':idTask'));
  const form = useFormContext();
  const {
    fields: documentList,
    append,
    remove,
    update
  } = useFieldArray({
    name: 'taskInternalDocuments',
    control: form.control
  });

  const getColumnDocument = (): FetchColumnsType<any> => [
    {
      title: t('project:task_of_project:document_code'),
      key: 'document_code',
      width: 80,
      render: (_value: any, record: any) => {
        return <div className='text-center'>{DataViewer.display(record?.displayOrder)}</div>;
      }
    },
    {
      title: t('project:task_of_project:document_name'),
      dataIndex: 'documentName',
      render(_value: string, _record: any, index: number) {
        return (
          <div>
            <FormSelect
              className='w-full'
              options={getOptionsDocument(index)}
              optionLabelProp='display'
              name={`taskInternalDocuments.${index}.projectInternalDocumentId`}
              placeholder={String(t('placeholder:select', { field: t('required_documents:document_name') }))}
              handleChange={(val) => {
                const document = optionsDocumentTotal?.find((item: any) => item.id === val) || DEFAULT_VALUE_TASK_INTERNAL_DOCUMENT;
                update(index, {
                  ...form.getValues(`taskInternalDocuments.${index}`),
                  ...document,
                  id: null,
                  projectInternalDocumentId: val ?? null
                });
              }}
            ></FormSelect>
          </div>
        );
      }
    },
    {
      title: t('project:task_of_project:number_of_copies'),
      dataIndex: 'numberOfCopies',
      width: 80,
      render(_value: any, record: any) {
        return <span className='block max-w-full w-fit whitespace-pre-wrap break-all'>{DataViewer.display(record?.numberOfCopy)}</span>;
      }
    },
    {
      title: t('project:task_of_project:description'),
      dataIndex: 'notes',
      render(_value: any, record: any) {
        return <div dangerouslySetInnerHTML={{ __html: DataViewer.displayAsSanitizeHTML(record?.note) }} className='whitespace-normal'></div>;
      }
    },
    {
      title: t('project_management:tabs:required_documents_table:correspondence_situation'),
      dataIndex: 'status',
      width: 120,
      render(_value: any, record: any, index: number) {
        return (
          <div className='flex justify-start whitespace-nowrap'>
            <FormCheckbox
              name={`taskInternalDocuments.${index}.isConfirm`}
              handleChange={(val) => {
                update(index, {
                  ...form.getValues(`taskInternalDocuments.${index}`),
                  isConfirm: val,
                  nameConfirm: null,
                  dateConfirm: null
                });
              }}
            ></FormCheckbox>
            <p className='ml-4'>
              {t(
                !form.watch(`taskInternalDocuments.${index}.isConfirm`)
                  ? 'project:task_of_project:not_compatible'
                  : 'project:task_of_project:compatible'
              )}
            </p>
          </div>
        );
      }
    },
    {
      title: t('project:task_of_project:counterpart'),
      dataIndex: 'nameConfirm',
      width: 140,
      render(_value: any, record: any) {
        const nameConfirm = () => {
          if ((!modeEdit && !record.nameConfirm) || !record.nameConfirm) return null;
          return record?.statusConfirm ? record.nameConfirm : `(${t('account_list:status_account:inactive')}) ${record.nameConfirm}`;
        };
        return (
          <span className='block max-w-full w-fit whitespace-pre-wrap break-all'>{DataViewer.display(record.isConfirm ? nameConfirm() : null)}</span>
        );
      }
    },
    {
      title: t('project:task_of_project:corresponding_date'),
      dataIndex: 'dateConfirm',
      width: 120,
      render(_value: any, record: any) {
        const renderDate = () => {
          if (!modeEdit && !record.dateConfirm) return null;
          return record.isConfirm ? record.dateConfirm : null;
        };
        return <span className='whitespace-pre-wrap'>{DataViewer.localTimeBy(record.isConfirm ? renderDate() : null, FORMAT_DATE_EN_HH_MM_SS)}</span>;
      }
    },
    {
      dataIndex: 'delete',
      width: 40,
      align: 'center',
      render(_value: any, _record: any, index: number) {
        return (
          <div
            className='cursor-pointer'
            onClick={() => {
              remove(index);
            }}
          >
            <TrashBlack width={18} height={18} />
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    if (defaultValueForm?.taskInternalDocuments?.length && modeEdit && optionsDocumentTotal) {
      const list = getDocuments(defaultValueForm.taskInternalDocuments);
      setOptionsDocumentTotal([...optionsDocumentTotal, ...list]);
    }
  }, [modeEdit, defaultValueForm]);

  useEffect(() => {
    if (optionsDocument) setOptionsDocumentTotal([...optionsDocumentTotal, ...optionsDocument]);
  }, [optionsDocument, modeEdit]);

  const getOptionsDocument = (indexOptions: number) => {
    const listIdDocument = form.getValues(`taskInternalDocuments`).map((item: any) => item?.projectInternalDocumentId);
    const documentId = form.getValues(`taskInternalDocuments.${indexOptions}.projectInternalDocumentId`);
    return optionsDocumentTotal
      ?.filter((item: any) => {
        if (documentId && item.id === documentId) return true;
        return !listIdDocument.includes(item.id);
      })
      .map((item: any) => ({ ...item, label: item.documentName, value: item.id, display: item.managementCode }));
  };
  return (
    <div>
      <TableBase
        className='w-full'
        showAddLine={false}
        columns={getColumnDocument()}
        showDownload={false}
        pagination={false}
        deleteConfirmPopup={{
          type: TYPE.DISCARD,
          title: t('common:MSG_029.1_title', { item: t('basic_information:project') }) ?? '',
          msg:
            `${t('common:MSG_029.1_description', { item: t('basic_information:project') })} ${t('common:MSG_029_description2', {
              item: t('basic_information:project')
            })}` ?? ''
        }}
        dataSource={documentList}
        rowSelection={null}
        locale={{ emptyText: <EmptyTextData className='text-center mt-4' text={'common:MSG_093'} /> }}
      />
      <BaseButton
        disabled={documentList.length >= MAX_CAPACITY_FILE}
        className='mt-4 max-w-[120px]'
        type='secondary'
        icon={<PlusOutlined />}
        onClick={() => {
          append(DEFAULT_VALUE_TASK_INTERNAL_DOCUMENT);
        }}
      >
        {t('project:task_of_project:add_document')}
      </BaseButton>
    </div>
  );
}
