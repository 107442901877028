import { useTranslation } from 'react-i18next';

import { IStepTwo } from '@/pages/project-management/add-edit/models/scenarioB';

import { BaseCard } from '@/components/base-card/BaseCard';

import CardInfo from './CardInfo';

interface IVisaInfoProps {
  data: IStepTwo;
}
const VisaInfo = ({ data }: IVisaInfoProps) => {
  const { visaCategoryName } = data ?? {};
  const { t } = useTranslation();

  const columns = [
    {
      items: [{ title: visaCategoryName, label: 'project:scenario_b:visa_category:label' }]
    }
  ];

  return (
    <BaseCard title={t('project:scenario_b:visa_information')} total={1} questions={visaCategoryName ? 1 : 0} className='scenario-b-card'>
      <CardInfo columns={columns} />
    </BaseCard>
  );
};

export default VisaInfo;
